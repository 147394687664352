import { useCallback, useEffect, useMemo, useState } from 'react';
import { get, set } from 'idb-keyval';
import { Fylke, Kommune, Kontraktsomrade } from '@/domain/omrader';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { Data, Datakatalog } from './data';
import {
    transformFylke,
    transformKommune,
    transformKontraktsomrade,
    transformRiksvegrute,
    transformVegobjekttype,
} from '@/server/transformers';
import { getClientSessionId, getHeaders } from '@/server/server';

interface StoredData<T = unknown> {
    etag: string | undefined | null;
    data: T | undefined;
}

/**
 * Fetch data, cache in IndexedDB and revalidated using ETags
 * Inspired by the 'stale-while-revalidate' cache strategy.
 * If data is available in indexeddb, this will be used while ETags are validated.
 */
const useIDBRevalidate = <T>(url: string) => {
    const [error, setError] = useState<string>();
    const [data, setData] = useState<StoredData<T>>({
        etag: undefined,
        data: undefined,
    });

    const loadFromIDB = useCallback(() => {
        get(url)
            .then(loaded => {
                if (loaded) {
                    if (typeof loaded === 'object') setData(loaded);
                    else throw Error(`Data from IDB ${loaded} is not a valid StoredData Entry`);
                } else setData({ data: undefined, etag: null });
            })
            .catch(e => {
                setError(e);
            });
    }, [url]);

    const loadFromRemote = useCallback(() => {
        const headers = getHeaders(getClientSessionId());
        const promise = data.etag
            ? fetch(url, { headers: { 'If-None-Match': data.etag, ...headers } })
            : fetch(url, { headers });
        promise
            .then(async response => {
                if (response.status === 302) {
                    return;
                } else if (response.status === 200) {
                    const updated = {
                        etag: response.headers.get('etag'),
                        data: await response.json(),
                    };
                    setData(updated);
                    return set(url, updated);
                }
            })
            .catch(e => {
                setError(e);
            });
    }, [data.etag, url]);

    useEffect(() => {
        if (error) throw Error(error);
    }, [error]);
    useEffect(() => {
        if (data.etag === undefined) loadFromIDB();
        else loadFromRemote();
    }, [url, data.etag, loadFromIDB, loadFromRemote]);
    return { data: data.data, error };
};
export const useDataLoader = (url: string) => {
    const { data: counties } = useIDBRevalidate<Fylke[]>(
        `${url}/omrader/fylker?inkluder=kartutsnitt,senterpunkt,vegobjekt`
    );
    const { data: municipalities } = useIDBRevalidate<Kommune[]>(
        `${url}/omrader/kommuner?inkluder=kartutsnitt,senterpunkt,vegobjekt`
    );
    const { data: riksvegruter } = useIDBRevalidate<Kontraktsomrade[]>(
        `${url}/omrader/riksvegruter?inkluder=vegobjekt`
    );
    const { data: contractAreas } = useIDBRevalidate<Kontraktsomrade[]>(
        `${url}/omrader/kontraktsomrader?inkluder=vegobjekt`
    );
    const { data: featureTypes } = useIDBRevalidate<Vegobjekttype[]>(`${url}/vegobjekttyper?inkluder=alle`);
    const datacatalog = useMemo(
        () => featureTypes && new Datakatalog(featureTypes?.map(f => transformVegobjekttype(f))),
        [featureTypes]
    );
    const data = useMemo(
        () =>
            new Data(
                datacatalog,
                counties?.map(transformFylke),
                municipalities?.map(transformKommune),
                contractAreas?.map(transformKontraktsomrade),
                riksvegruter?.map(transformRiksvegrute)
            ),
        [datacatalog, counties, municipalities, contractAreas, riksvegruter]
    );
    return municipalities && counties && contractAreas && datacatalog && riksvegruter ? data : null;
};

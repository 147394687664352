import { Kommune } from '@/domain/omrader';
import { transformGeometri } from './geometri';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export function transformKommuner(kommunerResponse: unknown): Kommune[] {
    return (kommunerResponse as []).map(kommune => transformKommune(kommune));
}

export function transformKommune(data: unknown) {
    return data
        ? new Kommune(
              data['navn'],
              data['nummer'],
              data['fylke'],
              transformGeometri(data['kartutsnitt']),
              transformGeometri(data['senterpunkt']),
              Vegobjekt.dummy(data['vegobjekt'].id, data['vegobjekt'].type)
          )
        : null;
}

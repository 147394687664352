import { Omrade } from './Omrade';
import { Vegobjekt } from '../vegobjekter/Vegobjekt';

export class Riksvegrute extends Omrade {
    constructor(
        readonly navn: string,
        readonly nummer: number,
        readonly beskrivelse: string = '',
        readonly periode: string,
        readonly vegobjekt: Vegobjekt = null
    ) {
        super(navn, nummer, null, null, vegobjekt);
    }

    override(modifyObject: { [P in keyof Riksvegrute]?: Riksvegrute[P] }): Riksvegrute {
        return Object.assign(Object.create(Riksvegrute.prototype), { ...this, ...modifyObject });
    }

    withVegobjekt(vegobjekt: Vegobjekt): Riksvegrute {
        return this.override({ vegobjekt });
    }

    withVisible(visible: boolean): Riksvegrute {
        return this.override({ visible });
    }

    toString(): string {
        return `${this.navn} ${this.nummer} ${this.periode}`;
    }

    toFilterString(): string {
        return `${this.navn} ${this.periode}`;
    }

    getAreaType(): string {
        return 'Riksvegrute';
    }

    toParams(): string {
        return this.navn + ' ' + this.periode;
    }
}

import 'ol/ol.css';
import Draw from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { getArea, getLength } from 'ol/sphere';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Coordinate } from 'ol/coordinate';
import { unByKey } from 'ol/Observable';
import { useMap } from '@/state/context/MapContext';
import { HelpTooltip, HelpTooltipText } from './HelpTooltip';
import { MeasureTooltip } from './MeasureTooltip';
import useMapLayer from '../useMapLayer';
import { MeasurementType } from '@/state/MeasurementState';
import { useSelector } from 'react-redux';
import { RootState } from '@/state';
import { LineString, Polygon } from 'ol/geom';

export function MeasurementLayer({ zIndex }: { zIndex: number }) {
    const { map } = useMap();
    const projection = map.getView().getProjection();

    const [measures, setMeasures] = useState([]);
    const [helpTooltip, setHelpTooltip] = useState<HelpTooltipText>(HelpTooltipText.START);

    const measurementType = useSelector(
        ({ measurementState }: RootState) => measurementState.measurementType
    ) as MeasurementType;

    const formatArea = function (polygon: Polygon): string {
        const area = getArea(polygon, { projection });
        const formattedArea =
            area > 10000
                ? `${Math.round((area / 1000000) * 100) / 100} km\u00B2`
                : `${Math.round(area * 100) / 100} m\u00B2`;
        return `${formattedArea} (${formatLength(polygon)})`;
    };

    const formatLength = function (line: LineString | Polygon): string {
        const length = getLength(line, { projection });
        return length > 999 ? `${Math.round((length / 1000) * 100) / 100} km` : `${Math.round(length)} m`;
    };

    const onDrawStart = useCallback(
        evt => {
            const target = evt.feature;
            setHelpTooltip(HelpTooltipText.CONTINUE);
            const tooltipCoord: Coordinate = evt?.feature?.getGeometry()?.getLastCoordinate();
            const latest = { coordinates: tooltipCoord, value: 0, locked: false };
            setMeasures([...measures, latest]);
            target.getGeometry().on('change', e => {
                const geom = e.target;
                const value = geom instanceof Polygon ? formatArea(geom) : formatLength(geom);
                const coordinates = geom.getLastCoordinate();
                setMeasures([...measures, { ...latest, coordinates, value }]);
            });
        },
        [setHelpTooltip, setMeasures, formatArea, formatLength]
    );

    const onDrawEnd = useCallback(() => {
        setHelpTooltip(HelpTooltipText.START);
        setMeasures(prevMeasures => {
            const latest = prevMeasures[prevMeasures.length - 1];
            return [...prevMeasures.slice(0, -1), { ...latest, locked: true }];
        });
    }, [setHelpTooltip, setMeasures]);

    const layer = useMemo(
        () =>
            new VectorLayer({
                source: new VectorSource(),
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(128, 128, 128, 0.4)',
                    }),
                    stroke: new Stroke({
                        color: '#00506E',
                        width: 3,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#00506E',
                        }),
                    }),
                }),
            }),
        []
    );

    const draw = useMemo(() => {
        const style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
        });

        const drawObject = new Draw({
            source: layer.getSource(),
            type: measurementType,
            condition: e => e?.originalEvent?.button === 0,
            style: style,
        });

        return drawObject;
    }, [layer, measurementType]);

    useMapLayer({ layer, index: zIndex });
    useEffect(() => {
        map.addInteraction(draw);
        return () => {
            map.removeInteraction(draw);
        };
    }, [map, draw]);

    useEffect(() => {
        const startKey = draw.on('drawstart', onDrawStart);
        const endKey = draw.on('drawend', onDrawEnd);
        return () => {
            unByKey(startKey);
            unByKey(endKey);
        };
    }, [draw, onDrawStart, onDrawEnd]);

    return (
        <div id={'measurement-layer'}>
            <HelpTooltip tip={helpTooltip} />
            {measures.map(({ coordinates, value, locked }, i) => (
                <MeasureTooltip key={i} coordinates={coordinates} value={value} locked={locked} />
            ))}
        </div>
    );
}

import { ActionType, SplashChangedAction } from './actiontypes';
import { SplashState } from '@/state/SplashState';
import { Integration } from '@/middleware/integration';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EksportChangelog } from '@/server/responses';

export type changeSplashT = (splash: SplashState) => (dispatch) => void;
export const changeSplash: changeSplashT = (splash: SplashState) => dispatch => {
    const changeSplashAction: SplashChangedAction = {
        type: ActionType.SHOW_SPLASH,
        state: splash,
    };
    dispatch(changeSplashAction);
};

export const fetchVegkartChangelog = createAsyncThunk<string, never, { extra: Integration }>(
    'splash/fetchVegkartChangelog',
    async (_, { extra }) => {
        return extra.server.getChangelog();
    }
);
export const fetchEksportChangelog = createAsyncThunk<EksportChangelog, never, { extra: Integration }>(
    'splash/fetchEksportChangelog',
    async (_, { extra }) => {
        return extra.server.getExportChangelog();
    }
);

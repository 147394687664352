import React from 'react';
import { TrinaryQueryParam, VegnettParameter } from '@/middleware/queries';
import { TriStateToggle } from '../../objects/TriStateToggle';
import { VegnettFilterOption } from './FilterOptions';

const INKLUDER_OPTIONS: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.ARM, null, 'Armer'),
    new VegnettFilterOption(VegnettParameter.KRYSSYSTEM, null, 'Kryssystem'),
    new VegnettFilterOption(VegnettParameter.SIDEANLEGG, null, 'Sideanlegg'),
];

interface BooleanProps {
    arm: TrinaryQueryParam;
    kryssystem: TrinaryQueryParam;
    sideanlegg: TrinaryQueryParam;
}

interface Props {
    booleans: BooleanProps;
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) => void;
}

export const ArmKryssSideOptions = ({ booleans, handleFilterChange }: Props) => {
    return (
        <div className="c-road-net__filter-group">
            <h3 className="c-road-net__filter-header">Filtrér på</h3>
            {INKLUDER_OPTIONS.map(option => {
                const currentOption = booleans[option.paramType];

                return (
                    <TriStateToggle
                        key={option.paramType}
                        checked={currentOption === TrinaryQueryParam.TRUE}
                        indeterminate={currentOption === TrinaryQueryParam.NOT_SET}
                        label={option.label}
                        contentList={['Uten', '', 'Med']}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFilterChange(
                                e,
                                new VegnettFilterOption(option.paramType, currentOption, '')
                            )
                        }
                    />
                );
            })}
        </div>
    );
};

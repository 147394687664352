import { Kontraktsomrade, Riksvegrute, Vegsystemreferanse } from '../omrader';
import { Stedfesting } from './Stedfesting';
import { WKTObject } from '../WKTObject';
import { Detaljniva, DetaljnivaAdditional } from '@/middleware/queries';

export class Vegobjekt {
    static dummy(id: number, typeId: number): Vegobjekt {
        return new Vegobjekt(
            id,
            null,
            new VegobjektMetadata({ id: typeId, navn: null }, null, null, null),
            null,
            null,
            null,
            null
        );
    }

    constructor(
        readonly id: number,
        readonly href: string,
        readonly metadata: VegobjektMetadata,
        readonly egenskaper: VegobjektEgenskap[],
        readonly geometri: WKTObject,
        readonly lokasjon: VegobjektLokasjon,
        readonly relasjoner: VegobjektRelasjoner,
        readonly segmenter?: Vegsegment[]
    ) {}
}

export class VegobjektMetadata {
    constructor(
        readonly type: { id: number; navn: string },
        readonly version: number,
        readonly startdato: string,
        readonly sist_modifisert: string,
        readonly sluttdato?: string
    ) {}
}

export class VegobjektLokasjon {
    constructor(
        readonly kommuner: number[],
        readonly fylker: number[],
        readonly kontraktsområder: Kontraktsomrade[],
        readonly riksvegruter: Riksvegrute[],
        readonly vegsystemreferanser: Vegsystemreferanse[],
        readonly stedfestinger: Stedfesting[],
        readonly geometri: WKTObject,
        readonly strekningslengde: number = 0
    ) {}
}

export class VegobjektEgenskap {
    constructor(
        readonly id: number,
        readonly navn: string,
        readonly egenskapstype: string,
        readonly datatype: string,
        readonly stedfestingstype: string = null,
        readonly verdi: string | number = null,
        readonly enum_id: number = null,
        readonly href: string = null,
        readonly verifiseringsdato: string = null,
        readonly datafangstdato: string = null,
        readonly kvalitet: VegobjektEgenskapKvalitetAttributt[] = null,
        readonly enhet: VegobjektEgenskapEnhet = null
    ) {}

    erGeometri(): boolean {
        return (
            this.datatype === 'GeomPunkt' ||
            this.datatype === 'GeomLinje eller Kurve' ||
            this.datatype === 'GeomFlate'
        );
    }

    erAssosiasjon(): boolean {
        return this.datatype === 'Assosiasjon';
    }
}

export class VegobjektEgenskapEnhet {
    constructor(readonly id: number, readonly navn: string, readonly kortnavn: string) {}
}

export class VegobjektEgenskapKvalitetAttributt {
    public static comparator(
        a: VegobjektEgenskapKvalitetAttributt,
        b: VegobjektEgenskapKvalitetAttributt
    ): number {
        return a?.displayName?.localeCompare(b.displayName);
    }

    constructor(readonly name: string, readonly displayName: string, readonly value: number) {}
}

export enum Kvalitettype {
    synbarhet = 'Synbarhet',
    målemetodeHøyde = 'Målemetode, høyde',
    målemetode = 'Målemetode',
    nøyaktighet = 'Nøyaktighet',
    nøyaktighetHøyde = 'Nøyaktighet, høyde',
    maksimaltAvvik = 'Maksimalt avvik',
    datafangstmetode = 'Datafangstmetode',
    datafangstmetodeHøyde = 'Datafangstmetode, Høyde',
}

export enum Relasjonstype {
    Mor,
    Datter,
}

export class VegobjektRelasjoner {
    constructor(readonly barn: VegobjektRelasjon[], readonly foreldre: VegobjektRelasjon[]) {}
}

export class VegobjektRelasjon {
    constructor(
        readonly type: { id: number; navn: string },
        readonly vegobjekter: Vegobjekt[],
        readonly relasjonstype: Relasjonstype
    ) {}
}

export class Vegsegment {
    constructor(
        readonly veglenkesekvensid: number,
        readonly startposisjon: number,
        readonly sluttposisjon: number,
        readonly detaljniva: Detaljniva | DetaljnivaAdditional,
        readonly geometri: WKTObject,
        readonly feltoversikt: string[]
    ) {}
}

export function vegobjektVersionsComparator(vegobjektA: Vegobjekt, vegobjektB: Vegobjekt) {
    return vegobjektA.metadata.version - vegobjektB.metadata.version;
}

export function vegobjektStartDatesComparator(vegobjekt: Vegobjekt) {
    return new Date(vegobjekt.metadata.startdato) <= new Date();
}

export enum OmraderQuery {
    FYLKE = 'fylke',
    KOMMUNE = 'kommune',
    KONTRAKTSOMRADE = 'kontraktsomrade',
    RIKSVEGRUTE = 'riksvegrute',
    VEGSYSTEMREFERANSE = 'vegsystemreferanse',
    POLYGON = 'polygon',
}

export class OmraderQueries {
    constructor(
        readonly fylke: string[] = [],
        readonly kommune: string[] = [],
        readonly kontraktsomrade: string[] = [],
        readonly riksvegrute: string[] = [],
        readonly vegsystemreferanse: string[] = [],

        readonly polygon: string = null
    ) {}

    override(modifyObject: { [P in keyof OmraderQueries]?: OmraderQueries[P] }): OmraderQueries {
        return Object.assign(Object.create(OmraderQueries.prototype), { ...this, ...modifyObject });
    }

    withFylker(fylke: string[]) {
        return this.override({ fylke });
    }

    withKommuner(kommune: string[]) {
        return this.override({ kommune });
    }

    withKontraktsomrader(kontraktsomrade: string[]) {
        return this.override({ kontraktsomrade });
    }

    withRiksvegruter(riksvegrute: string[]) {
        return this.override({ riksvegrute });
    }

    withVegsystemreferanser(vegsystemreferanse: string[]) {
        return this.override({ vegsystemreferanse });
    }

    withPolygon(polygon: string) {
        return this.override({ polygon });
    }

    split(): OmraderQueries[] {
        const queries: OmraderQueries[] = [];

        if (this.fylke?.length > 0) {
            queries.push(
                new OmraderQueries().withFylker(this.fylke).withVegsystemreferanser(this.vegsystemreferanse)
            );
        }

        if (this.kommune?.length > 0) {
            queries.push(
                new OmraderQueries()
                    .withKommuner(this.kommune)
                    .withVegsystemreferanser(this.vegsystemreferanse)
                    .withPolygon(this.polygon)
            );
        }

        if (this.kontraktsomrade?.length > 0) {
            queries.push(
                new OmraderQueries()
                    .withKontraktsomrader(this.kontraktsomrade)
                    .withVegsystemreferanser(this.vegsystemreferanse)
                    .withPolygon(this.polygon)
            );
        }

        if (this.riksvegrute?.length > 0) {
            queries.push(
                new OmraderQueries()
                    .withRiksvegruter(this.riksvegrute)
                    .withVegsystemreferanser(this.vegsystemreferanse)
                    .withPolygon(this.polygon)
            );
        }
        if (this.polygon) {
            queries.push(
                new OmraderQueries()
                    .withVegsystemreferanser(this.vegsystemreferanse)
                    .withPolygon(this.polygon)
            );
        }

        if (queries.length == 0 && this.vegsystemreferanse?.length > 0) {
            queries.push(new OmraderQueries().withVegsystemreferanser(this.vegsystemreferanse));
        }

        return queries;
    }

    areasLength() {
        let length = 0;
        for (const key of Object.keys(this)) {
            if (key === OmraderQuery.POLYGON) {
                length += this.polygon ? 1 : 0;
            } else if (key !== OmraderQuery.VEGSYSTEMREFERANSE) {
                length += this[key].length;
            }
        }
        return length;
    }

    toParams(omrader?: OmraderQuery[]): Record<string, string> {
        const params: Record<string, string> = {};

        let keys: string[];

        if (!omrader) {
            keys = Object.keys(this);
        } else {
            keys = omrader.map(omrade => omrade.toString());
        }

        keys.forEach(key => {
            if (this[key] instanceof Array && this[key].length > 0) {
                params[key] = this[key].join();
            } else if (typeof this[key] === 'string' && this[key] !== '' && this[key] !== null) {
                params[key] = this[key];
            }
        });

        return params;
    }
}

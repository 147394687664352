import React from 'react';

interface SelectProps {
    label: string;
    options: string[];
    value: string;
    className?: string;
    ariaLabel?: string;
    id?: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SimpleSelect = ({
    label,
    options,
    value,
    className,
    ariaLabel,
    id = 'simple-select',
    onChange,
}: SelectProps) => {
    return (
        <div className="select-container">
            <label className="o-select-text" htmlFor={id}>
                {label}
            </label>
            <select
                id={id}
                className={`o-select-select ${className || ''}`}
                onChange={onChange}
                value={value}
                aria-label={ariaLabel ?? label}
            >
                {options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SimpleSelect;

import * as React from 'react';
import DropdownContainer from '@/components/avgrensning/search/dropdown/DropdownContainer';
import DropdownItem, { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';

interface SearchContainerProps {
    focusIndex: number;
    items: DropdownItemData[];
    onDropdownItemSelected?: (DropdownItemData) => void;
}
export const SearchContainer = ({ focusIndex, items, onDropdownItemSelected }: SearchContainerProps) => {
    if (!items || items.length === 0) return null;
    return (
        <DropdownContainer focusIndex={focusIndex} role={'listbox'} id={'aa'}>
            {items?.map((item, index) => (
                <DropdownItem
                    key={item.subtitle + item.title + index}
                    focusIndex={focusIndex}
                    index={index}
                    buttonClassName={item.header ? 'header' : 'item'}
                    clickHandler={onDropdownItemSelected}
                    item={item}
                />
            ))}
        </DropdownContainer>
    );
};

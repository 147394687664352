import {
    ActionType,
    GetState,
    ReceivedVOTAction,
    ReceiveSelectedObjectAction,
    VOFailedAction,
    VOFetchAction,
    VOReceivedAction,
} from '@/actions/actiontypes';
import { Vegobjekt, vegobjektVersionsComparator } from '@/domain/vegobjekter/Vegobjekt';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import * as selectors from '../selectors/selectors';
import { stateMap, stateOmrader, stateVegnett, stateVegobjekter } from '@/selectors/selectors';
import hash from 'object-hash';
import {
    CategoryType,
    MapState,
    OmraderState,
    ValgtState,
    VegkartError,
    VegnettState,
    VegobjekterState,
    VegobjekttypeCategoryState,
    VegobjekttypeState,
    VegobjekttypeStateError,
} from '@/state';
import { createLogger } from '@/utils/Logger';
import { Integration } from './integration';
import {
    CommonQueries,
    Egenskapsfilter,
    MapQueries,
    OmraderQueries,
    OperatorE,
    VegnettQueries,
    VegobjekterQueries,
} from './queries';
import { VegobjekterResult, VegobjekterStatistic } from '@/domain/vegobjekter/VegobjekterResult';
import { handleError } from '@/actions/metaActions';
import { Data } from '@/bootstrap/data';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { numericSortComparator } from '@/utils/Utils';
import { Fylke, Kommune } from '@/domain/omrader';
import { getFylkerForQuery } from './fetchHelpers';
import { ApiError, AuthenticationError } from '@/server/server';
import { ColorIndex } from '@/utils/ColorHelper';
import { FeatureFlag, flags } from '@/featureFlags';

const log = createLogger('fetchVegobjekter');

export const fetchSelectedVegobjekt =
    (valgtState: ValgtState) => async (dispatch, getState: GetState, integration: Integration) => {
        const timestamp = getState().searchState.searchDropdownState.timestamp || new Date();
        const fetchCompleteVegobjekt = async (vegobjekt: Vegobjekt) => {
            const type = vegobjekt.metadata.type.id;
            const id = vegobjekt.id;
            const minimal = await integration.server.getVegobjektVersionsMinimal(id, type);
            const versions = minimal
                .filter(v => new Date(v.metadata.startdato) <= timestamp)
                .sort(vegobjektVersionsComparator)
                .map(v => v.metadata.version);
            if (versions.length == 0) return null;
            return integration.server.getVegobjekt(id, type, versions.pop());
        };

        try {
            const completed = await Promise.all(valgtState.vegobjekter.map(fetchCompleteVegobjekt));
            const receivedSelectedAction: ReceiveSelectedObjectAction = {
                type: ActionType.VALGT_OBJEKT_RECEIVED,
                selection: valgtState.withFetching(false).withVegobjekter(completed.filter(v => !!v)),
            };
            dispatch(receivedSelectedAction);
        } catch (e) {
            dispatch(
                handleError(
                    new VegkartError('Kunne ikke motta vegobjekt', { e, vegobjekt: valgtState.vegobjekter })
                )
            );
        }
    };

export const fetchVegobjekt =
    (nvdbId: number) => (dispatch, getState: GetState, integration: Integration) => {
        log('fetchVegobjekt {}', nvdbId);
        const timestamp = getState().searchState.searchDropdownState.timestamp || new Date();
        return integration.server.getVegobjekt(nvdbId).then(
            (vegobjekt: Vegobjekt) => {
                log('receiveVegobjekt {}', vegobjekt.id);
                if (new Date(vegobjekt.metadata.startdato) <= timestamp) {
                    const receivedSelectedAction: ReceiveSelectedObjectAction = {
                        type: ActionType.VALGT_OBJEKT_RECEIVED,
                        selection: ValgtState.vegobjektSelection(vegobjekt),
                    };
                    dispatch(receivedSelectedAction);
                } else {
                    integration.server
                        .getVegobjektVersionsMinimal(vegobjekt.id, vegobjekt.metadata.type.id)
                        .then((vegobjekterFromServer: Vegobjekt[]) => {
                            integration.server
                                .getVegobjekt(
                                    vegobjekt.id,
                                    vegobjekt.metadata.type.id,
                                    vegobjekterFromServer
                                        .filter(v => new Date(v.metadata.startdato) <= timestamp)
                                        .sort(vegobjektVersionsComparator)
                                        .pop()?.metadata.version
                                )
                                .then((vegobjektFromServer: Vegobjekt) => {
                                    const receivedSelectedAction: ReceiveSelectedObjectAction = {
                                        type: ActionType.VALGT_OBJEKT_RECEIVED,
                                        selection: ValgtState.vegobjektSelection(vegobjektFromServer),
                                    };
                                    dispatch(receivedSelectedAction);
                                });
                        });
                }
            },
            err =>
                dispatch(
                    handleError(
                        new VegkartError('Kunne ikke motta vegobjekt', { err, vegobjekttype: nvdbId })
                    )
                )
        );
    };

/**
 * Fetches a single vegobjekttype from the NVDB API Datakatalog.
 *
 * @param typeId, the type ID of the vegobjekttype to fetch information about.
 *
 * @OnSuccess Dispatches the resulting information to the redux store.
 * @OnError Dispatches an error message instead.
 */
export const fetchVegobjektType =
    (typeId: number) => (dispatch, getState: GetState, integration: Integration) => {
        log('fetchVegobjektType {}', typeId);
        return integration.server.getVegobjekttype(typeId).then(
            (vegobjekttype: Vegobjekttype) => {
                log('receiveVegobjekttype {}', vegobjekttype.id);
                const receivedVOTAction: ReceivedVOTAction = {
                    type: ActionType.DATAKATALOG_VEGOBJEKTTYPE_RECEIVED,
                    vegobjekttype,
                };
                dispatch(receivedVOTAction);
            },
            err =>
                dispatch(
                    handleError(
                        new VegkartError('Kunne ikke motta vegobjekttype', { err, vegobjekttype: typeId })
                    )
                )
        );
    };

export const executeAllSearches = () => (dispatch, getState: GetState) => {
    log('executeAllSearches');
    const typesInSearch = selectors.stateSelectedRoadObjectTypes(getState());

    typesInSearch.forEach(type => {
        dispatch(fetchVegobjekter(type));
    });
};

function mapError(e: Error): VegobjekttypeStateError {
    if (e instanceof AuthenticationError) return 'auth';
    else if (e instanceof ApiError && e.msg.code === 4015) return 'not-indexed';
    else if (e instanceof ApiError) return 'api';
    else return 'unknown';
}

export const fetchVegobjekter =
    (votState: VegobjekttypeState) => async (dispatch, getState: GetState, integration: Integration) => {
        // States
        const mapState: MapState = selectors.stateMap(getState());
        const omraderState: OmraderState = selectors.stateOmrader(getState());
        const vegnettState: VegnettState = selectors.stateVegnett(getState());
        const vegobjekterState: VegobjekterState = selectors.stateVegobjekter(getState());
        const typeHasCategory = votState.category !== null && votState.categoryIsValid();
        const typeId = votState.typeId;
        const staticData: Data = integration.data;
        const vegobjekttype: Vegobjekttype = integration.data.datakatalog.getTypeById(typeId);
        const preferences = selectors.statePreferences(getState());

        // Query classes
        const commonQueries: CommonQueries = new CommonQueries();
        const mapQueries: MapQueries = mapState.toQueries();
        const omraderQueries: OmraderQueries = omraderState.toQueries();
        const vegnettQueries: VegnettQueries = vegnettState.filter;
        let vegobjekterQueries: VegobjekterQueries = vegobjekterState.typeToQueries(typeId);

        // Timestamp
        const timestamp = getState().searchState.searchDropdownState.timestamp;

        // Dispatch fetching action for animated loader-icons
        const fetchVegobjekterAction: VOFetchAction = {
            type: ActionType.VEGOBJEKTER_FETCH_INITIATED,
            votTypeId: typeId,
        };
        dispatch(fetchVegobjekterAction);
        const timeBeforeFetch = new Date().getTime();

        // Fetch all relevant statistics.
        let totalStatistics = null;
        let extentStatistics = null;
        try {
            totalStatistics =
                omraderQueries.areasLength() > 0
                    ? await fetchVegobjekterStatistics(
                          typeId,
                          new MapQueries(),
                          omraderQueries,
                          vegnettQueries,
                          vegobjekterQueries,
                          integration,
                          null,
                          [],
                          timestamp
                      )
                    : null;
            extentStatistics = await fetchVegobjekterStatistics(
                typeId,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                null,
                [],
                timestamp
            );
        } catch (e) {
            const error = mapError(e);
            console.log(e);
            const action: VOFailedAction = {
                type: ActionType.VEGOBJEKTER_FETCH_FAILED,
                votTypeId: typeId,
                error,
            };
            return dispatch(action);
        }

        // Fetch triggered later if there are too many results to display.
        let areaStatistics: VegobjekterStatistic[] = [];

        if (
            hasSearchStateChanged(
                typeId,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                getState
            )
        ) {
            const actionType = ActionType.VEGOBJEKTER_FETCH_ABORTED;
            const timeAfterFetch = new Date().getTime();
            integration.server.postPerformanceLogEntry(actionType, timeAfterFetch - timeBeforeFetch, {
                vegobjekttype: typeId,
            });
            return;
        }

        // Based on totals and geometry type, determine if we just display statistics or the actual objects.
        const pointLimit = integration.config.maximum_number_of_objects_point;
        const lineLimit = integration.config.maximum_number_of_objects_line;
        let vegobjekterResult: VegobjekterResult = null;
        let probeResult: RoadSysRefAndTotalT = null;
        if (vegobjekttype.isPoint() && extentStatistics.antall > pointLimit) {
            // Don't fetch, show statistics.
            areaStatistics = await fetchVegobjekterStatisticByArea(
                typeId,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                staticData.fylker,
                staticData.kommuner,
                timestamp
            );

            vegobjekterResult = new VegobjekterResult([], totalStatistics, extentStatistics, areaStatistics);

            const receivedVegobjekterAction: VOReceivedAction = {
                type: ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                votTypeId: typeId,
                result: vegobjekterResult,
                categoryStates: [],
            };
            dispatch(receivedVegobjekterAction);
            const timeAfterFetch = new Date().getTime();
            integration.server.postPerformanceLogEntry(
                ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                timeAfterFetch - timeBeforeFetch,
                {
                    vegobjekttype: typeId,
                }
            );
            return;
        } else if (vegobjekttype.isLine() && extentStatistics.antall > lineLimit) {
            // We'll try finding a proper number of roadSysRefs to display objects for.
            probeResult = await getRoadSysRefAndTotalFromProbeResult(
                typeId,
                commonQueries,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                timestamp
            );

            if (
                hasSearchStateChanged(
                    typeId,
                    mapQueries,
                    omraderQueries,
                    vegnettQueries,
                    vegobjekterQueries,
                    getState
                )
            ) {
                const actionType = ActionType.VEGOBJEKTER_FETCH_ABORTED;
                const timeAfterFetch = new Date().getTime();
                integration.server.postPerformanceLogEntry(actionType, timeAfterFetch - timeBeforeFetch, {
                    vegobjekttype: typeId,
                });
                return;
            }
            if (probeResult.statistic.antall > lineLimit) {
                // Failed, don't fetch and show statistics instead.
                areaStatistics = await fetchVegobjekterStatisticByArea(
                    typeId,
                    mapQueries,
                    omraderQueries,
                    vegnettQueries,
                    vegobjekterQueries,
                    integration,
                    staticData.fylker,
                    staticData.kommuner,
                    timestamp
                );

                vegobjekterResult = new VegobjekterResult(
                    [],
                    totalStatistics,
                    extentStatistics,
                    areaStatistics
                );

                const receivedVegobjekterAction: VOReceivedAction = {
                    type: ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    votTypeId: typeId,
                    result: vegobjekterResult,
                    categoryStates: [],
                };
                dispatch(receivedVegobjekterAction);
                const timeAfterFetch = new Date().getTime();
                integration.server.postPerformanceLogEntry(
                    ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    timeAfterFetch - timeBeforeFetch,
                    {
                        vegobjekttype: typeId,
                    }
                );
                return;
            } else {
                // Success, adjust roadsysrefs.
                totalStatistics = extentStatistics;
                extentStatistics = probeResult.statistic;
            }
        }
        // Finally, we fetch the objects.
        if (typeHasCategory) {
            fetchVegobjekterByCategories(
                votState,
                commonQueries,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                getState,
                probeResult?.roadSysRefs ?? [],
                timeBeforeFetch,
                timestamp
            ).then(categoryStatesResponse => {
                // Build result, dispatch action, and post performance log entry if needed.
                vegobjekterResult = new VegobjekterResult(
                    [],
                    totalStatistics,
                    extentStatistics,
                    areaStatistics
                );

                const receivedVegobjekterAction: VOReceivedAction = {
                    type: ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    votTypeId: typeId,
                    result: vegobjekterResult,
                    categoryStates: categoryStatesResponse,
                };

                dispatch(receivedVegobjekterAction);

                const timeAfterFetch = new Date().getTime();
                integration.server.postPerformanceLogEntry(
                    ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    timeAfterFetch - timeBeforeFetch,
                    {
                        vegobjekttype: typeId,
                    }
                );
            });
        } else {
            fetchVegobjekterByOmradeType(
                typeId,
                commonQueries,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                getState,
                null,
                probeResult?.roadSysRefs ?? [],
                timeBeforeFetch
            ).then(vegobjekterResponse => {
                // Build result, dispatch action, and post performance log entry if needed.
                vegobjekterResult = new VegobjekterResult(
                    vegobjekterResponse,
                    totalStatistics,
                    extentStatistics,
                    areaStatistics
                );

                const receivedVegobjekterAction: VOReceivedAction = {
                    type: ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    votTypeId: typeId,
                    result: vegobjekterResult,
                    categoryStates: [],
                };

                dispatch(receivedVegobjekterAction);

                const timeAfterFetch = new Date().getTime();
                integration.server.postPerformanceLogEntry(
                    ActionType.VEGOBJEKTER_FETCH_RECEIVED,
                    timeAfterFetch - timeBeforeFetch,
                    {
                        vegobjekttype: typeId,
                    }
                );
            });
        }
    };

interface RoadSysRefAndTotalT {
    roadSysRefs: string[];
    statistic: VegobjekterStatistic;
}
async function getRoadSysRefAndTotalFromProbeResult(
    typeId: number,
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    timestamp: Date
): Promise<RoadSysRefAndTotalT> {
    // If vegsystemreferanse already exists in search, override probing.
    if (omraderQueries.split().length > 0 && omraderQueries.vegsystemreferanse?.length > 0) {
        let antall = 0;
        let lengde = 0;
        for (const omradetypeQueries of omraderQueries.split()) {
            await integration.server
                .getVegobjekterStatistics(
                    typeId,
                    mapQueries,
                    omradetypeQueries,
                    vegnettQueries,
                    vegobjekterQueries,
                    timestamp
                )
                .then(response => {
                    antall += response.antall;
                    lengde += response.lengde;
                });
        }
        return { roadSysRefs: omraderQueries.vegsystemreferanse, statistic: { antall, lengde } };
    }
    // Probe each area for each vegkategori.
    const roadRefRequestAll = ['E', 'R', 'F', 'K', 'P', 'S'];
    const roadRefRequest = [...roadRefRequestAll];
    const roadRefResult = new Map<string, VegobjekterStatistic>();

    for (const roadRef of roadRefRequestAll) {
        let antall = 0;
        let lengde = 0;
        if (omraderQueries.areasLength() > 0) {
            for (const omradetypeQueries of omraderQueries.split()) {
                await integration.server
                    .getVegobjekterStatistics(
                        typeId,
                        mapQueries,
                        omradetypeQueries.withVegsystemreferanser([roadRef]),
                        vegnettQueries,
                        vegobjekterQueries,
                        timestamp
                    )
                    .then(response => {
                        antall += response.antall;
                        lengde += response.lengde;
                    });
            }
        } else {
            await integration.server
                .getVegobjekterStatistics(
                    typeId,
                    mapQueries,
                    omraderQueries.withVegsystemreferanser([roadRef]),
                    vegnettQueries,
                    vegobjekterQueries,
                    timestamp
                )
                .then(response => {
                    antall += response.antall;
                    lengde += response.lengde;
                });
        }
        roadRefResult.set(roadRef, { antall, lengde });
    }

    const totalAll = getTotal(roadRefResult);
    let total = totalAll;

    while (total.antall > integration.config.maximum_number_of_objects_line && roadRefResult.size > 0) {
        roadRefResult.delete(roadRefRequest.pop());
        total = getTotal(roadRefResult);
    }

    return roadRefResult.size > 0
        ? { roadSysRefs: roadRefRequest, statistic: total }
        : { roadSysRefs: roadRefRequestAll, statistic: totalAll };
}

function getTotal(map: Map<string, VegobjekterStatistic>): VegobjekterStatistic {
    let antall = 0;
    let lengde = 0;
    for (const value of map.values()) {
        antall += value.antall;
        lengde += value.lengde;
    }
    return { antall, lengde };
}

const fetchVegobjekterStatisticByArea = async (
    typeId: number,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    fylker: Fylke[],
    kommuner: Kommune[],
    timestamp
): Promise<VegobjekterStatistic[]> => {
    const results: VegobjekterStatistic[] = [];
    if (omraderQueries.kommune?.length > 0) {
        for (const kommune of omraderQueries.kommune.map(n =>
            kommuner.find(k => k.nummer.toString() === n)
        )) {
            const queries = omraderQueries.withKommuner([kommune.toParams()]);
            await integration.server
                .getVegobjekterStatistics(
                    typeId,
                    mapQueries,
                    queries,
                    vegnettQueries,
                    vegobjekterQueries,
                    timestamp
                )
                .then(response => {
                    if (response.antall > 0)
                        results.push(new VegobjekterStatistic(response.antall, response.lengde, kommune));
                });
        }
    } else
        for (const fylke of getFylkerForQuery(omraderQueries, fylker)) {
            const queries = omraderQueries.withFylker([fylke.toParams()]);
            await integration.server
                .getVegobjekterStatistics(
                    typeId,
                    mapQueries,
                    queries,
                    vegnettQueries,
                    vegobjekterQueries,
                    timestamp
                )
                .then(response => {
                    if (response.antall > 0)
                        results.push(new VegobjekterStatistic(response.antall, response.lengde, fylke));
                });
        }

    return results;
};

/*
 * Fetch statistics for Vegobjekttype (VOT)
 */
export const fetchVegobjekterStatistics = async (
    typeId: number,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    egenskapsfilters: Egenskapsfilter[] = null,
    vegsystemreferanser: string[] = [],
    timestamp: Date = null
): Promise<VegobjekterStatistic> => {
    // Prepare adjustments to search queries if they exist.
    const adjustedOmraderQueries =
        vegsystemreferanser.length > 0 ? omraderQueries.withVegsystemreferanser(vegsystemreferanser) : null;
    const kategorisertVegobjekterQueries = egenskapsfilters
        ? vegobjekterQueries.withKategorifilter(egenskapsfilters)
        : null;

    const { antall, lengde } = await integration.server.getVegobjekterStatistics(
        typeId,
        mapQueries,
        adjustedOmraderQueries ?? omraderQueries,
        vegnettQueries,
        kategorisertVegobjekterQueries ?? vegobjekterQueries,
        timestamp
    );
    return { antall, lengde };
};

const hashFilter = (filters: Egenskapsfilter[]) =>
    hash(filters.map(({ verdi, egenskapstype, operator }) => [verdi, egenskapstype, operator]));
const fetchVegobjekterByCategories = async (
    vegobjekttypeState: VegobjekttypeState,
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    getState: GetState,
    vegsystemreferanser: string[],
    timeBeforeFetch: number,
    timestamp: Date
): Promise<VegobjekttypeCategoryState[]> => {
    const categoryType: Egenskapstype = vegobjekttypeState.category;
    const categorizedResults: VegobjekttypeCategoryState[] = [];
    let egenskapsFilters: Egenskapsfilter[][];
    const visibilities = vegobjekttypeState.categoryStates?.reduce(
        (acc, { filters, visible }) => ({ ...acc, [hashFilter(filters)]: visible }),
        {}
    );
    const colorIndexes = vegobjekttypeState.categoryStates?.reduce(
        (acc, { filters, color }) => ({ ...acc, [hashFilter(filters)]: color }),
        {}
    );
    if (categoryType.erEnum()) {
        // Categorize by enum //
        egenskapsFilters = categoryType.tillatte_verdier
            .map(tillattVerdi => new Egenskapsfilter(categoryType, OperatorE.EQ, tillattVerdi))
            // Use a special case instead of null - this is the "no value" category
            .concat(new Egenskapsfilter(categoryType, OperatorE.NO_VALUE))
            .map(filter => [filter]);
    } else {
        const intervals = [...vegobjekttypeState.intervals].sort(numericSortComparator);
        egenskapsFilters = [[new Egenskapsfilter(categoryType, OperatorE.LEQ, intervals[0])]];

        for (let i = 1; i < intervals.length; i++) {
            egenskapsFilters.push([
                new Egenskapsfilter(categoryType, OperatorE.GT, intervals[i - 1]),
                new Egenskapsfilter(categoryType, OperatorE.LEQ, intervals[i]),
            ]);
        }
        egenskapsFilters.push([
            new Egenskapsfilter(categoryType, OperatorE.GT, intervals[intervals.length - 1]),
        ]);
        egenskapsFilters.push([new Egenskapsfilter(categoryType, OperatorE.NO_VALUE)]);
    }

    const statistics = await integration.server.getVegobjekterStatistics(
        vegobjekttypeState.typeId,
        mapQueries,
        omraderQueries,
        vegnettQueries,
        vegobjekterQueries,
        timestamp
    );
    if (statistics.antall < 1000 && flags().has(FeatureFlag.LOCAL_CATEGORIES)) {
        // Inefficient filtering, hangs ui thread when using many filters (i.e. Skiltplate -> skiltnummer)
        const vegobjekter = await fetchVegobjekterRecursively(
            vegobjekttypeState.typeId,
            commonQueries,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries.withInkluder([...vegobjekterQueries.inkluder, 'egenskaper']),
            integration,
            getState,
            null,
            vegsystemreferanser,
            timeBeforeFetch
        );
        return egenskapsFilters.map(filter => {
            const filtered = vegobjekter.filter(v => Egenskapsfilter.satisfies(v, filter));
            const vegobjekterResult = new VegobjekterResult(filtered, null, {
                antall: filtered.length,
                lengde: Number.parseFloat(
                    filtered
                        .map(v => v.lokasjon.strekningslengde)
                        .filter(v => !!v)
                        .reduce((sum, length) => sum + length, 0)
                        .toFixed(3)
                ),
            });
            const filterHash = hashFilter(filter);
            const visible = filterHash in visibilities ? visibilities[filterHash] : true;
            return new VegobjekttypeCategoryState(
                categoryType.erEnum() ? CategoryType.ENUM : CategoryType.INTERVAL,
                filter,
                null,
                vegobjekterResult,
                false,
                visible
            );
        });
    }

    for (const filter of egenskapsFilters) {
        // Fetch statistics
        const totalCategoryStatistics =
            omraderQueries.areasLength() > 0
                ? await fetchVegobjekterStatistics(
                      vegobjekttypeState.typeId,
                      new MapQueries(),
                      omraderQueries,
                      vegnettQueries,
                      vegobjekterQueries,
                      integration,
                      filter,
                      vegsystemreferanser,
                      timestamp
                  )
                : null;

        const extentCategoryStatistics = await fetchVegobjekterStatistics(
            vegobjekttypeState.typeId,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries,
            integration,
            filter,
            vegsystemreferanser,
            timestamp
        );

        await fetchVegobjekterByOmradeType(
            vegobjekttypeState.typeId,
            commonQueries,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries,
            integration,
            getState,
            filter,
            vegsystemreferanser,
            timeBeforeFetch
        ).then(response => {
            const vegobjekterResult = new VegobjekterResult(
                response,
                totalCategoryStatistics,
                extentCategoryStatistics
            );
            const filterHash = hashFilter(filter);
            const visible = filterHash in visibilities ? visibilities[filterHash] : true;
            let colorIndex: ColorIndex = null;
            if (filterHash in colorIndexes) {
                colorIndex = colorIndexes[filterHash];
            }
            if (colorIndex === null && vegobjekterResult.vegobjekter.length > 0) {
                colorIndex = integration.colorHandler.getNextColorIndex();
            } else if (colorIndex !== null && vegobjekterResult.vegobjekter.length === 0) {
                integration.colorHandler.decrementColor(colorIndex);
                colorIndex = null;
            }
            categorizedResults.push(
                new VegobjekttypeCategoryState(
                    categoryType.erEnum() ? CategoryType.ENUM : CategoryType.INTERVAL,
                    filter,
                    colorIndex,
                    vegobjekterResult,
                    false,
                    visible
                )
            );
        });
    }
    return categorizedResults;
};

const fetchVegobjekterByOmradeType = async (
    typeId: number,
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    getState: GetState,
    egenskapsfilters: Egenskapsfilter[] = null,
    vegsystemreferanser: string[] = [],
    timeBeforeFetch = 0
): Promise<Vegobjekt[]> => {
    let uniqueVegobjekter: Set<Vegobjekt> = new Set<Vegobjekt>();

    if (omraderQueries.areasLength() > 0) {
        const omradeTypeLenker: Vegobjekt[] = await fetchVegobjekterRecursively(
            typeId,
            commonQueries,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries,
            integration,
            getState,
            egenskapsfilters,
            vegsystemreferanser,
            timeBeforeFetch
        );
        uniqueVegobjekter = new Set<Vegobjekt>([...uniqueVegobjekter, ...omradeTypeLenker]);
        return Array.from(uniqueVegobjekter);
    } else {
        return await fetchVegobjekterRecursively(
            typeId,
            commonQueries,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries,
            integration,
            getState,
            egenskapsfilters,
            vegsystemreferanser,
            timeBeforeFetch
        );
    }
};

const fetchVegobjekterRecursively = async (
    typeId: number,
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    integration: Integration,
    getState: GetState,
    egenskapsfilters: Egenskapsfilter[] = null,
    vegsystemreferanser: string[] = [],
    timeBeforeFetch: number
): Promise<Vegobjekt[]> => {
    // Check base search for any change in parameters. Abort if there are any.
    if (
        hasSearchStateChanged(
            typeId,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            vegobjekterQueries,
            getState
        )
    ) {
        log('Search query has changed, aborting old search');
        const actionType = ActionType.VEGOBJEKTER_FETCH_ABORTED;
        const timeAfterFetch = new Date().getTime();
        integration.server.postPerformanceLogEntry(actionType, timeAfterFetch - timeBeforeFetch, {
            vegobjekttype: typeId,
        });
        return [];
    }
    const timestamp = getState().searchState.searchDropdownState.timestamp;
    // Prepare adjustments to search queries if they exist.
    const adjustedOmraderQueries =
        vegsystemreferanser.length > 0 ? omraderQueries.withVegsystemreferanser(vegsystemreferanser) : null;
    const kategorisertVegobjekterQueries = egenskapsfilters
        ? vegobjekterQueries.withKategorifilter(egenskapsfilters)
        : null;
    // Start recursion.
    const fragment = await integration.server.getVegobjekter(
        typeId,
        commonQueries,
        mapQueries,
        adjustedOmraderQueries ?? omraderQueries,
        vegnettQueries,
        kategorisertVegobjekterQueries ?? vegobjekterQueries,
        timestamp
    );

    // Continue recursion if we haven't reached the end page.
    if (fragment.metadata.returnert > 0) {
        return fragment.objekter.concat(
            await fetchVegobjekterRecursively(
                typeId,
                commonQueries.withStart(fragment.metadata.neste.start),
                mapQueries,
                omraderQueries,
                vegnettQueries,
                vegobjekterQueries,
                integration,
                getState,
                egenskapsfilters,
                vegsystemreferanser,
                timeBeforeFetch
            )
        );
    } else {
        return fragment.objekter;
    }
};

const hasSearchStateChanged = (
    typeId: number,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    vegobjekterQueries: VegobjekterQueries,
    getState: GetState
): boolean => {
    return (
        JSON.stringify(stateMap(getState()).toQueries()) !== JSON.stringify(mapQueries) ||
        JSON.stringify(stateOmrader(getState()).toQueries()) !== JSON.stringify(omraderQueries) ||
        JSON.stringify(stateVegnett(getState()).toQueries()) !== JSON.stringify(vegnettQueries) ||
        JSON.stringify(stateVegobjekter(getState()).typeToQueries(typeId).withInkluder([])) !==
            JSON.stringify(vegobjekterQueries.withInkluder([]))
    );
};

import { Kvalitettype, VegobjektEgenskapKvalitetAttributt } from '@/domain/vegobjekter/Vegobjekt';
import { WKTObject } from '@/domain/WKTObject';
import { nullIfUndefined } from '@/utils/Utils';

export function transformGeometri(data: unknown): WKTObject {
    return data
        ? new WKTObject(
              data['srid'],
              data['wkt'],
              nullIfUndefined(data['egengeometri']),
              nullIfUndefined(data['forenklet'])
          )
        : null;
}

export function transformKvalitet(kvalitet: Record<string, number>): VegobjektEgenskapKvalitetAttributt[] {
    return Object.entries(kvalitet).map(
        ([field, value]) => new VegobjektEgenskapKvalitetAttributt(field, Kvalitettype[field], value)
    );
}

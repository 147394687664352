import * as React from 'react';
import { connect } from 'react-redux';
import { Omrade } from '@/domain/omrader';
import { VegobjekterStatistic } from '@/domain/vegobjekter/VegobjekterResult';
import * as selectors from '../../selectors/selectors';
import {
    MapState,
    OmraderState,
    PreferencesState,
    RootState,
    ValgtState,
    VegobjekttypeCategoryState,
    VegobjekttypeState,
} from '@/state';
import { resolveColor } from '@/utils/ColorHelper';
import { OmraderFeatureLayer } from './layers/OmraderFeatureLayer';
import { ValgtLayer } from './layers/SelectedFeatureLayer';
import { VegnettLayer } from './layers/VegnettFeatureLayer';
import { FeaturesLayer } from './layers/VegobjekterFeatureLayer';
import { Logo } from '../info/Logo';
import { LogoButton } from './controls/LogoButton';
import { CurrentPosition } from './controls/CurrentPosition';
import { TileServiceSelection } from './controls/TileServiceSelection';
import { ZoomControls } from './controls/ZoomControls';
import { MapMoved } from './handlers/MapMovedHandler';
import { FeatureSelectionHandler } from './handlers/FeatureSelectionHandler';
import { FeatureHoverHandler } from './handlers/FeatureHoverHandler';
import { PlacenameMarkerPopup } from './overlays/PlacenameMarkerPopup';
import { RoadReferencePopup } from './overlays/RoadReferencePopup';
import ZoomToExtentHandler from './handlers/ZoomToExtentHandler';
import { Map } from 'ol';
import { createMap, MapContextValues, MapProvider } from '@/state/context/MapContext';
import { zoomToExtent, zoomToExtentT } from '@/actions/mapActions';
import { Omradeantall } from './layers/Omradeantall';
import { LinkQueryLayer } from './layers/LinkQueryFeatureLayer';
import { Measure } from './controls/Measure';
import { MeasurementLayer } from './layers/MeasurementLayer';
import { ContextMenu } from './handlers/ContextMenu';
import { MapMarkerLayer } from './layers/MapMarkerLayer';
import { MousePosition } from './controls/MousePosition';
import { TileServiceLayers } from './layers/TileServiceLayers';
import { Toolbar } from '@/components/Toolbar';
import { PolygonLayer } from './layers/PolygonLayer';
import { PropertyBoundariesLayer } from '@/components/map/layers/PropertyBoundariesLayer';

interface VegkartMapProps {
    mapState: MapState;
    omraderState: OmraderState;
    roadObjectTypes: VegobjekttypeState[];
    selectedVegobjekt: ValgtState;
    zoom: number;
    measuring: boolean;
    drawingPolygon: boolean;
    aerialViewActive: boolean;
    zoomToExtent: zoomToExtentT;
    preferences: PreferencesState;
}

class OLMapContainer extends React.Component<VegkartMapProps, undefined> {
    private rotDisplacements: Array<number | string> = [];
    private readonly map: Map;
    private mapDiv: HTMLDivElement;

    constructor(props: VegkartMapProps) {
        super(props);
        const { zoom, center } = this.props.mapState;
        this.map = createMap(center, zoom);
    }

    componentDidMount() {
        this.map.setTarget(this.mapDiv);
    }

    componentWillUnmount() {
        this.map.setTarget();
    }

    render() {
        const mapContext: MapContextValues = {
            map: this.map,
        };
        const areaCounters = this.props.roadObjectTypes
            .filter(featureType => !!featureType?.result)
            .filter(
                featureType =>
                    featureType?.result.extentStatistics?.antall <= 0 ||
                    featureType?.result.vegobjekter.length <= 0
            )
            .map(featureType => this.areaCount(featureType, featureType.result.areaStatistics));
        return (
            <MapProvider value={mapContext}>
                <div className="c-map">
                    {/* Canvas and Layers */}
                    <div className="c-map__canvas" ref={el => (this.mapDiv = el)} aria-label={'Kartbilde'}>
                        <TileServiceLayers />
                        {this.omraderLayers()}
                        {areaCounters}
                        <FeaturesLayer featureTypes={this.props.roadObjectTypes} zIndex={101} />
                        <MapMarkerLayer />
                        <LinkQueryLayer />
                        <VegnettLayer />
                        {this.props.preferences.showPropertyBoundaries && (
                            <PropertyBoundariesLayer zIndex={1} />
                        )}
                        <ValgtLayer zIndex={102} />
                        {this.props.measuring && <MeasurementLayer zIndex={101} />}
                        {this.props.drawingPolygon && <PolygonLayer zIndex={102} />}
                    </div>

                    {/* Handlers */}
                    <MapMoved />
                    <ZoomToExtentHandler />
                    <ContextMenu />
                    {!this.props.measuring && <FeatureHoverHandler />}
                    {!this.props.measuring && <FeatureSelectionHandler />}
                    <RoadReferencePopup />
                    <PlacenameMarkerPopup />

                    {/* Map Controls */}
                    <Logo />
                    <div className="c-map__controls">
                        <div className="c-map__control-group t-visible-mobile">
                            <LogoButton />
                        </div>
                        <Toolbar
                            id={'mapcontrols-toolbar'}
                            orientation={'vertical'}
                            ariaLabel={'Kartverktøy'}
                        >
                            <CurrentPosition />
                            <TileServiceSelection />
                            <ZoomControls zoomType={'in'} />
                            <ZoomControls zoomType={'out'} />
                            <Measure />
                        </Toolbar>
                        <MousePosition />
                    </div>
                </div>
            </MapProvider>
        );
    }

    omraderLayers() {
        const { kommune, fylke, polygon } = this.props.omraderState;
        const omrader: Omrade[] = [...kommune, ...fylke, polygon];

        return omrader
            .filter(a => a?.vegobjekt.geometri && a.visible)
            .map(a => (
                <OmraderFeatureLayer
                    key={`${a.navn}-${a.nummer}`}
                    area={a}
                    areaGeometry={a.vegobjekt.geometri}
                    isNiBLayer={this.props.aerialViewActive}
                    zoom={this.props.zoom}
                />
            ));
    }

    areaCount(rot: VegobjekttypeState, statistics: VegobjekterStatistic[]) {
        return statistics
            .filter(s => s.antall > 0)
            .map(s => {
                const colorString = resolveColor(rot.color);

                return this.areaCountElement(rot, s, colorString);
            });
    }

    areaCountElement(
        rot: VegobjekttypeState,
        s: VegobjekterStatistic,
        colorString: string,
        cat: VegobjekttypeCategoryState = null,
        catIndex: number = null
    ) {
        const rotCategoryKey = `${rot.typeId}${!!catIndex ? '-i' + catIndex : ''}`;
        const componentKey = `${rotCategoryKey}-${s.omrade}`;

        return (
            <Omradeantall
                key={componentKey}
                visible={cat ? rot.visible && cat.visible : rot.visible}
                antall={s.antall}
                omrade={s.omrade}
                colorString={colorString}
                displacement={this.getDisplacementIndex(rotCategoryKey)}
                vegobjektTypeId={rot.typeId}
            />
        );
    }

    getDisplacementIndex(id: string | number): number {
        if (this.rotDisplacements.indexOf(id) === -1) {
            this.rotDisplacements.push(id);
        }
        return this.rotDisplacements.indexOf(id);
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        mapState: selectors.stateMap(state),
        omraderState: selectors.stateOmrader(state),
        aerialViewActive: selectors.aerialViewActive(state),
        measuring: selectors.measurementActive(state),
        drawingPolygon: state.drawingPolygon,
        roadObjectTypes: selectors.stateSelectedRoadObjectTypes(state),
        selectedVegobjekt: selectors.stateValgt(state),
        zoom: selectors.stateZoom(state),
        preferences: selectors.statePreferences(state),
    };
};

const mapDispatchToProps = {
    zoomToExtent,
};

export default connect(mapStateToProps, mapDispatchToProps)(OLMapContainer);

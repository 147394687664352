import { Vegobjekt, VegobjektEgenskap } from '@/domain/vegobjekter/Vegobjekt';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import Linkify from 'linkify-react';
import { hasAccess } from '@/utils/Utils';
import { Role } from '@/state';
import compareProperties from '../../util/compareProperties';
import { isEmpty } from '@/components/avgrensning/valgt/VegobjektComponent';
import React from 'react';

type EgenskaperProps = {
    vegobjekt: Vegobjekt;
    isLoggedIn: boolean;
    vegobjekttype: Vegobjekttype;
    roles: Record<Role, boolean>;
};
export function Egenskaper({ vegobjekt, isLoggedIn, vegobjekttype, roles }: EgenskaperProps) {
    const unauthorized = vegobjekttype.egenskapstyper
        .filter(e => e.sensitivitet > 0)
        .some(egenskapstype => !hasAccess(egenskapstype.sensitivitet, roles));

    const filtered = vegobjekt?.egenskaper
        .filter(
            (egenskap: VegobjektEgenskap) =>
                (!isEmpty(egenskap.verdi) || !!egenskap.href) &&
                !egenskap.erGeometri() &&
                !egenskap.erAssosiasjon()
        )
        .sort((a, b) => compareProperties(a, b, vegobjekttype))
        .map(egenskap => renderProperty(egenskap, vegobjekttype));

    return !isEmpty(filtered) ? (
        <>
            <hr />
            <h4 className="c-valgt-features__subheader">Egenskaper</h4>
            {filtered}
            {unauthorized && [
                <dt key={`sensitive__dt-p`} className="c-valgt-features__dt">
                    Sensitive opplysninger:
                </dt>,
                <dd key={`sensitive-dd`} className="c-valgt-features__dd">
                    {!isLoggedIn && 'Data er skjermet. Innlogging og gyldig tilgang kreves'}
                    {isLoggedIn && unauthorized && 'Du har ikke tilgang til å se denne informasjonen.'}
                </dd>,
            ]}
        </>
    ) : null;
}

function renderProperty(e: VegobjektEgenskap, vegobjekttype: Vegobjekttype) {
    const egenskapstype = vegobjekttype.egenskapstypeById(e.id);
    const hrefOrVerdi = e.href ?? `${e.verdi} ${e?.enhet?.kortnavn ?? ''}`;
    const egenskapsverdi = (
        <Linkify
            options={{
                className: 'c-valgt-features-dd__value-link',
                target: '_blank',
            }}
        >
            {hrefOrVerdi}
        </Linkify>
    );
    if (egenskapstype) {
        return [
            <dt key={`dt${e.id}`} className="c-valgt-features__dt" title={egenskapstype.beskrivelse}>
                {egenskapstype.navn}
            </dt>,
            <dd key={`dd${e.id}`} className="c-valgt-features__dd">
                {egenskapsverdi}
            </dd>,
        ];
    } else {
        return null;
    }
}

export default Egenskaper;

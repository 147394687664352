import * as React from 'react';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { zoomToExtent } from '@/actions/mapActions';
import { Node } from '@/domain/vegnett/Node';
import { addLinkQuery, clearNodeQuery } from '@/actions/valgtActions';
import { defaultColor } from '@/utils/ColorHelper';
import { useDispatch } from '@/state/store';

interface Props {
    node: Node;
}

export const NodeComponent = ({ node }: Props) => {
    const dispatch = useDispatch();
    return (
        <div>
            {/* Header */}
            <div className="c-valgt-header" style={{ backgroundColor: defaultColor }}>
                <h3 className="c-valgt-header__title">Node: {node.id}</h3>
                <div className="c-valgt-tools">
                    <button
                        className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                        onClick={() => dispatch(zoomToExtent(kartutsnittFromWktStrings(node.geometri.wkt)))}
                        title={'Viser veglenkesegmentet i kartet'}
                    >
                        Zoom til
                    </button>
                </div>
                <button
                    title="Fjern"
                    onClick={() => dispatch(clearNodeQuery(node.id))}
                    className="c-valgt-header__button"
                    aria-label="Lukk objektvisning"
                >
                    <i className="o-icon-remove c-valgt-header__close-icon" />
                </button>
            </div>
            {/* Body */}
            <dl className="c-valgt-features">
                <div className="c-relaterte-objekter">
                    <h4 key="foreldreobjekter" className="c-relaterte-objekter__relasjon">
                        Porter
                    </h4>
                    <ul className="c-relaterte-list">
                        {node.porter.map(port => (
                            <li key={port.id}>
                                <b style={{ textDecoration: 'unset', marginRight: '1rem' }}>{port.id}:</b>
                                <button
                                    type="button"
                                    className="c-relaterte-list__button"
                                    onClick={() => {
                                        dispatch(addLinkQuery(`@${port.tilkobling.veglenkesekvensid}`));
                                    }}
                                >
                                    {port.tilkobling.veglenkesekvensid}
                                </button>
                                <a style={{ marginLeft: '0.5rem' }} href={port.tilkobling.href}>
                                    (api)
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <hr />
                {/* API-link */}
                <dt className="c-valgt-features__dt">Objektid:</dt>
                <dd className="c-valgt-features__dd">
                    <a href={node.href} target="_blank" rel="noopener noreferrer">
                        {node.id}
                    </a>
                </dd>
            </dl>
        </div>
    );
};

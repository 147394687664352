import { Fill, Stroke, Style } from 'ol/style';

export function getAreaOutlineStyle(isNibLayer: boolean, zoom: number): Style {
    /* Note the slight difference in opacity calucations, the white overlay needs higher opacity to be visisble.
       For zoom levels below 4 opacity is kept constant. At this level the whole of Norway is visible. */
    const zoomCoefficient = zoom < 4 ? 0 : (zoom - 4) / 14;
    if (isNibLayer) {
        const opacity = 0.5 - zoomCoefficient * 0.5;
        const colorString = 'rgba(255,255,255, ' + opacity + ')';
        return new Style({
            fill: new Fill({ color: colorString }),
            stroke: new Stroke({ color: 'rgba(130, 130, 130, 1)', width: 2 }),
        });
    } else {
        const opacity = 0.15 - zoomCoefficient * 0.15;
        const colorString = 'rgba(0,0,0, ' + opacity + ')';
        return new Style({
            fill: new Fill({ color: colorString }),
            stroke: new Stroke({ color: 'rgba(100, 100, 100, 1)', width: 2 }),
        });
    }
}

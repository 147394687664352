import * as uuidgen from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { useMap } from '@/state/context/MapContext';
import { EventsKey } from 'ol/events';
import { ControlledMenu, MenuDivider, MenuItem, useMenuState } from '@szhsin/react-menu';
import { MapBrowserEvent } from 'ol';
import '@szhsin/react-menu/dist/core.css';
import copy from 'clipboard-copy';
import { Types } from 'ol/MapBrowserEventType';
import { Severity, VegkartError } from '@/state';
import { ActionType, AddPointAction } from '@/actions/actiontypes';
import { download } from '@/utils/Utils';
import { handleError } from '@/actions/metaActions';
import { useDispatch } from '@/state/store';

const fNumber = (number: number) => number.toFixed(0);

export const ContextMenu = () => {
    const { map } = useMap();
    const dispatch = useDispatch();
    const [menuProps, toggle] = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);
    const createMarker = useCallback(() => {
        const action: AddPointAction = {
            type: ActionType.MAP_POINT_ADDED,
            point: { id: uuidgen.v4(), geometry: coordinates },
        };
        dispatch(action);
    }, [dispatch, coordinates]);
    const showMenu = useCallback(
        (e: MapBrowserEvent<MouseEvent>) => {
            e.preventDefault();
            const { clientX: x, clientY: y } = e.originalEvent;
            setAnchorPoint({ x, y });
            const [east, north] = e.coordinate;
            setCoordinates([east, north]);
            toggle(true);
        },
        [toggle, setAnchorPoint]
    );

    const canvasToBlob = useCallback(async (): Promise<Blob> => {
        const { default: html2canvas } = await import('html2canvas');
        const viewport = map.getViewport();
        const mapCanvas = viewport.querySelector('canvas');
        const overlayCanvas = await html2canvas(viewport.querySelector('.ol-overlaycontainer'), {
            backgroundColor: null,
        });
        const canvas = document.createElement('canvas');
        canvas.width = mapCanvas.width;
        canvas.height = mapCanvas.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(mapCanvas, 0, 0);
        ctx.drawImage(overlayCanvas, 0, 0);
        return new Promise(resolve => {
            canvas.toBlob(blob => resolve(blob));
        });
    }, [map]);

    const saveMapImage = useCallback(async () => {
        const blob = await canvasToBlob();
        const url = URL.createObjectURL(blob);
        download(url, `vegkart-${Date.now()}.png`);
    }, [canvasToBlob]);

    const copyMapImage = useCallback(async () => {
        if (!window?.navigator?.clipboard?.write)
            return dispatch(
                handleError(new VegkartError('Støttes ikke av din nettleser.', {}, Severity.WARN))
            );
        const blob = await canvasToBlob();
        return window.navigator.clipboard.write([
            new ClipboardItem({
                [blob.type]: blob,
            }),
        ]);
    }, [canvasToBlob, dispatch]);

    useEffect(() => {
        const event = 'contextmenu' as Types;
        const key: EventsKey = map.on(event, showMenu);
        return () => {
            map.un(event, key.listener);
        };
    }, [map, showMenu]);

    return (
        <ControlledMenu {...menuProps} anchorPoint={anchorPoint} onClose={() => toggle(false)}>
            <MenuItem onClick={copyMapImage}>Kopier kartbilde</MenuItem>
            <MenuItem onClick={saveMapImage}>Lagre kartbilde</MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => copy(coordinates.map(fNumber).reverse().join(' '))}>
                Kopier koordinater
            </MenuItem>
            <MenuItem onClick={createMarker}>Lag markør</MenuItem>
            <MenuItem disabled={true}>
                Nord:{' '}
                <span style={{ userSelect: 'all', fontWeight: 'normal' }}>{fNumber(coordinates[1])}</span>
            </MenuItem>
            <MenuItem disabled={true}>
                Øst:{' '}
                <span style={{ userSelect: 'all', fontWeight: 'normal' }}>{fNumber(coordinates[0])}</span>
            </MenuItem>
        </ControlledMenu>
    );
};

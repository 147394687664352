import { Feature, Geolocation } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import * as React from 'react';
import { forwardRef, useEffect, useMemo } from 'react';
import { GeolocationState, Severity, VegkartError } from '@/state';
import { FeatureLayerType, FeatureLayerTypeKey } from '../layers/FeatureLayerType';
import { userPositionStyle } from '../style/UserPositionStyle';
import { useMap } from '@/state/context/MapContext';
import { handleError } from '@/actions/metaActions';
import { setGeolocationState } from '@/actions/overlayActions';
import { unByKey } from 'ol/Observable';
import useMapLayer from '../layers/useMapLayer';
import { stateGeolocationState } from '@/selectors/selectors';
import { useDispatch, useSelector } from '@/state/store';

const CurrentPosition = forwardRef<HTMLButtonElement>((_, ref) => {
    const { map } = useMap();
    const geolocationState = useSelector(stateGeolocationState);
    const dispatch = useDispatch();
    const geolocation = useMemo(() => {
        const gl = new Geolocation({
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: map.getView().getProjection(),
        });
        gl.on('error', error =>
            dispatch(
                handleError(
                    new VegkartError(
                        getErrorMessage(error as unknown as GeolocationPositionError),
                        { error },
                        Severity.ERROR
                    )
                )
            )
        );
        return gl;
    }, [dispatch, map]);

    const positionFeature = useMemo(() => {
        const feature = new Feature();
        feature.setStyle(userPositionStyle);
        feature.set(FeatureLayerTypeKey, FeatureLayerType.USER_POSITION);
        return feature;
    }, []);

    const accuracyFeature = useMemo(() => {
        const feature = new Feature();
        feature.set(FeatureLayerTypeKey, FeatureLayerType.USER_POSITION);
        return feature;
    }, []);

    const layer = useMemo(
        () =>
            new VectorLayer({
                source: new VectorSource({
                    features: [positionFeature, accuracyFeature],
                }),
            }),
        [positionFeature, accuracyFeature]
    );
    useMapLayer({ layer, index: 1 });

    function enableFollowMe() {
        if (geolocation.getTracking() === false) {
            geolocation.setTracking(true);
        }
        layer.setVisible(true);
        dispatch(setGeolocationState(GeolocationState.Follow));
    }

    function disableFollowMe() {
        dispatch(setGeolocationState(GeolocationState.Active));
        if (geolocation.getTracking() === true) {
            geolocation.setTracking(false);
        }
        layer.setVisible(false);
    }

    function toggleFollowMe() {
        if (geolocationState === GeolocationState.Follow) {
            disableFollowMe();
        } else {
            enableFollowMe();
        }
    }
    useEffect(() => {
        const events = [
            geolocation.once('change:position', () => {
                const coordinates = geolocation.getPosition();
                if (coordinates) {
                    map.getView().setCenter(coordinates);
                }
            }),
            geolocation.on('change:position', () => {
                const coordinates = geolocation.getPosition();
                if (coordinates) positionFeature.setGeometry(new Point(coordinates));
            }),
            geolocation.on('change:accuracyGeometry', () => {
                const accuracyGeometry = geolocation.getAccuracyGeometry();
                if (accuracyGeometry) {
                    accuracyFeature.setGeometry(accuracyGeometry);
                }
            }),
        ];
        return () => {
            events.forEach(e => unByKey(e));
        };
    }, [accuracyFeature, geolocation, map, positionFeature]);

    if ('geolocation' in navigator) {
        const isActivated = geolocationState === GeolocationState.Follow;
        const isActivatedClass = isActivated ? ' c-current-position__get-position--active' : '';
        const isActivatedIcon = isActivated ? 'o-icon-gps-tracked' : 'o-icon-gps-untracked';
        return (
            <button
                ref={ref}
                aria-pressed={isActivated}
                title={'Vis min posisjon'}
                type="button"
                className={`c-current-position__get-position o-btn-square${isActivatedClass} c-map-controls__button`}
                onClick={toggleFollowMe}
            >
                <i className={isActivatedIcon + ' c-current-position__gps-icon'} />
            </button>
        );
    } else {
        return null;
    }
});
function getErrorMessage(error: GeolocationPositionError) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return 'Vi har ikke tilgang til din lokasjon.';
        case error.POSITION_UNAVAILABLE:
            return 'Lokasjon ikke tilgjengelig.';
        case error.TIMEOUT:
            return 'Tidsavbrudd ved henting av lokasjon.';
        default:
            return 'Kunne ikke hente lokasjon.';
    }
}
CurrentPosition.displayName = 'CurrentPosition';
export { CurrentPosition };

import { useEffect } from 'react';
import { useMap } from '@/state/context/MapContext';
import { Layer } from 'ol/layer';

export default function useMapLayer({ layer, index }: { layer: Layer; index?: number }) {
    const { map } = useMap();

    useEffect(() => {
        if (!map) {
            return;
        }

        map.addLayer(layer);

        layer.setZIndex(index ?? 1001);

        return () => {
            if (map) {
                map.removeLayer(layer);
            }
        };
    }, [index, layer, map]);
}

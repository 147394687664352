import { ActionType, MapAction } from '@/actions/actiontypes';
import { MapState } from '@/state';

const initialState = new MapState();

export const mapReducer = (mapState: MapState = initialState, action: MapAction) => {
    switch (action.type) {
        // Start-up
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.mapState;
        case ActionType.HASH_CHANGED:
            return action.state.mapState;
        // Map
        case ActionType.MAP_MOVED:
            return mapState
                .withCenterAndZoom(action.center, action.zoom)
                .withKartutsnitt(action.kartutsnitt)
                .withZoomToExtent(null);
        case ActionType.MAP_CENTER_AND_ZOOM_SET:
            return mapState.withCenterAndZoom(action.center, action.zoom);
        case ActionType.MAP_LAYER_SET:
            return mapState.withMapLayer(action.maplayer);
        case ActionType.MAP_EXTENT_ZOOMED_TO:
            return mapState.withZoomToExtent(action.kartutsnitt);
        case ActionType.MAP_POINT_REMOVED:
            return mapState.withPoints(mapState.points.filter(p => p.id !== action.id));
        case ActionType.MAP_POINT_ADDED:
            return mapState.withPoints([...mapState.points, action.point]);
        case ActionType.MAP_BAAT_TOKEN_SET:
            return mapState.withBaatToken(action.baatToken);
        default:
            return mapState;
    }
};

import { Data } from '@/bootstrap/data';
import * as selectors from '../../../selectors/selectors';
import { SearchResult } from './SearchResult';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { useCallback, useContext, useMemo } from 'react';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { Fylke, Kommune, Kontraktsomrade, Land, Riksvegrute } from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';
import { Stedsnavn } from '@/server/responses';
import formatLocationDetails from '@/components/avgrensning/search/dropdown/formatLocationDetails';
import { SearchSuggestion, useSearchSuggestion } from '@/components/avgrensning/search/useSearchSuggestion';
import {
    addFylkeToSearch,
    addKommuneToSearch,
    addKontraktsomradeToSearch,
    addLandToSearch,
    addRiksvegruteToSearch,
    addVSRToSearch,
} from '@/actions/omraderActions';
import { addVegobjekttypeToSearch } from '@/actions/vegobjekterActions';
import { getLocationIconName } from '@/components/avgrensning/search/dropdown/locationTypeIconName';
import { setPlacenameMarker } from '@/actions/overlayActions';
import { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';
import { Dispatch, useDispatch, useSelector } from '@/state/store';
import { stateUser } from '@/selectors/selectors';

export const navnFilter = searchText => (o: { navn: string; id?: number | string }) =>
    `${o.id}${o.navn?.toLowerCase()}`.includes(searchText?.toLowerCase());

const filterValues = (data: Data, searchText: string): SearchResult => {
    if (searchText.length === 0) {
        return new SearchResult();
    }
    const filter = navnFilter(searchText);
    return new SearchResult(
        data.datakatalog.vegobjekttyper.filter(filter).sort(prefixComparator(searchText, v => v.navn)),
        data.fylker.filter(filter),
        data.kommuner.filter(filter),
        data.kontraktsomrader.filter(filter),
        data.riksvegruter.filter(filter),
        data.land.filter(filter),
        data.vegkategorier.filter(filter)
    );
};

export const useSearchResult = searchText => {
    const dispatch = useDispatch();
    const { data } = useContext(ConfigAndDataContext);
    const { isLoggedIn } = useSelector(stateUser);
    const placeNames = useSelector(selectors.statePlacenames);
    const suggestion = useSearchSuggestion(searchText);
    const searchResult = useMemo(() => filterValues(data, searchText), [data, searchText]);
    const fylkeByNumber = useCallback((county: number) => data.fylker.find(f => f.nummer === county), [data]);

    const dropdownItems: DropdownItemData[] = useMemo(() => {
        const groupedByCategory: Record<string, DropdownItemData[]> = [
            ...suggestion.map(s => suggestionToEntry(s, dispatch)),
            ...searchResult.vegobjekttyper.map(v => vegobjekttypeToEntry(v, dispatch, isLoggedIn)),
            ...searchResult.land.map(l => landToEntry(l, dispatch)),
            ...searchResult.fylker.map(f => fylkeToEntry(f, dispatch)),
            ...searchResult.kommuner.map(k => kommuneToEntry(k, dispatch, fylkeByNumber)),
            ...searchResult.vegkategorier.map(v => vegkategoriToEntry(v, dispatch)),
            ...searchResult.riksvegruter.map(r => riksvegruteToEntry(r, dispatch)),
            ...searchResult.kontraktsomrader.map(k => kontraktsomradeToEntry(k, dispatch)),
            ...(placeNames?.placeNames ?? []).map(s => stedsnavnToEntry(s, dispatch)),
        ].reduce(
            (all, entry) => ({ ...all, [entry.category]: [...(all[entry.category] || []), entry] }),
            {} as Record<string, DropdownItemData[]>
        );

        return Object.entries(groupedByCategory)
            .filter(([_, entries]) => entries.length >= 1)
            .flatMap(([category, data]) => [
                { title: category, header: true, subtitle: `(${data.length} treff)`, disabled: true },
                ...data,
            ]);
    }, [
        suggestion,
        searchResult.vegobjekttyper,
        searchResult.land,
        searchResult.fylker,
        searchResult.kommuner,
        searchResult.vegkategorier,
        searchResult.riksvegruter,
        searchResult.kontraktsomrader,
        placeNames?.placeNames,
        dispatch,
        isLoggedIn,
        fylkeByNumber,
    ]);
    return dropdownItems;
};

function prefixComparator<T>(prefix: string, keyExtractor: (data: T) => string) {
    return (a: T, b: T) => {
        const keyA = keyExtractor(a);
        const keyB = keyExtractor(b);
        const prefixA = keyA.toLowerCase().startsWith(prefix.toLowerCase());
        const prefixB = keyB.toLowerCase().startsWith(prefix.toLowerCase());
        if (prefixA && !prefixB) return -1;
        else if (!prefixA && prefixB) return 1;
        else if (prefixA && prefixB) return keyA.localeCompare(keyB);
        return 0;
    };
}

function kommuneToEntry(
    kommune: Kommune,
    dispatch: Dispatch,
    fylkeByNumber: (number) => Fylke
): DropdownItemData {
    return {
        title: kommune.navn,
        category: 'Kommune',
        subtitle: `Kommune i ${fylkeByNumber(kommune.fylke).navn}`,
        onClick: () => {
            dispatch(addKommuneToSearch(kommune));
        },
    };
}

function fylkeToEntry(fylke: Fylke, dispatch: Dispatch): DropdownItemData {
    return {
        title: fylke.navn,
        category: 'Fylke',
        subtitle: 'Fylke',
        onClick: () => {
            dispatch(addFylkeToSearch(fylke));
        },
    };
}

export function vegobjekttypeToEntry(
    vegobjekttype: Vegobjekttype,
    dispatch: Dispatch,
    isLoggedIn: boolean
): DropdownItemData {
    const sensitive = vegobjekttype.sensitiv;
    const excludedFromIndexing = [521, 524, 525, 526, 562, 641, 644, 645, 793].includes(vegobjekttype.id);
    let subtitle = `Vegobjekttype ${vegobjekttype.id}`;
    if (sensitive && !excludedFromIndexing)
        subtitle += isLoggedIn ? ' (Skjermet type)' : ' (Skjermet type, krever innlogging)';
    else if (excludedFromIndexing) subtitle += ' (Ikke tilgjengelig i Vegkart)';

    return {
        title: vegobjekttype.navn,
        category: 'Vegobjekttype',
        onClick: () => {
            dispatch(addVegobjekttypeToSearch(vegobjekttype));
        },
        disabled: excludedFromIndexing || (!isLoggedIn && sensitive),
        subtitle,
    };
}

function riksvegruteToEntry(riksvegrute: Riksvegrute, dispatch: Dispatch): DropdownItemData {
    return {
        title: riksvegrute.navn,
        category: 'Riksvegrute',
        subtitle: `Riksvegrute, ${riksvegrute.beskrivelse}`,
        onClick: () => {
            dispatch(addRiksvegruteToSearch(riksvegrute));
        },
    };
}
function vegkategoriToEntry(vegkategori: Vegkategori, dispatch: Dispatch): DropdownItemData {
    return {
        title: vegkategori.navn,
        category: 'Vegkategori',
        subtitle: 'Vegkategori',
        onClick: () => {
            dispatch(addVSRToSearch(vegkategori));
        },
    };
}
function kontraktsomradeToEntry(kontraktsomrade: Kontraktsomrade, dispatch: Dispatch): DropdownItemData {
    return {
        title: kontraktsomrade.navn,
        category: 'Kontraktsområde',
        subtitle: 'Kontraktsområde',
        onClick: () => {
            dispatch(addKontraktsomradeToSearch(kontraktsomrade));
        },
    };
}

function landToEntry(land: Land, dispatch: Dispatch): DropdownItemData {
    return {
        title: land.navn,
        category: 'Land',
        subtitle: 'Land',
        onClick: () => {
            dispatch(addLandToSearch(land));
        },
    };
}

function stedsnavnToEntry(stedsnavn: Stedsnavn, dispatch: Dispatch): DropdownItemData {
    return {
        title: stedsnavn.name,
        category: 'Stedsnavn',
        subtitle: formatLocationDetails(stedsnavn),
        icon: getLocationIconName(stedsnavn),
        onClick: () => {
            dispatch(setPlacenameMarker(stedsnavn));
        },
    };
}

function suggestionToEntry(suggestion: SearchSuggestion, dispatch: Dispatch): DropdownItemData {
    return {
        title: suggestion.title,
        category: 'Forslag',
        subtitle: suggestion.description,
        onClick: () => suggestion.action(dispatch),
        disabled: suggestion.disabled,
    };
}

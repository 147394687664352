import { ActionType, UpdatePreferencesAction } from '@/actions/actiontypes';
import { Projeksjon } from '@/domain/Projeksjon';
import { statePreferences, stateSplash } from '@/selectors/selectors';
import { PreferencesState } from '@/state';
import { SplashState } from '@/state/SplashState';
import { useDispatch, useSelector } from '@/state/store';
import { SVVButton } from '@komponentkassen/svv-button';
import { SVVModal } from '@komponentkassen/svv-modal';
import { useState } from 'react';
import SimpleSelect from '../objects/SimpleSelect';
import SimpleToggle from '../objects/SimpleToggle';
import { ExportEnumFormat } from '@/domain/ExportEnumFormat';

interface PreferencesDialogProps {
    handleWindowChange: (window: SplashState) => void;
}

interface ToggleProps {
    name: string;
    label: string;
}

interface SelectProps extends ToggleProps {
    options: string[];
}

export const PreferencesDialog = ({ handleWindowChange }: PreferencesDialogProps) => {
    const preferences = useSelector(statePreferences);
    const modalState = useSelector(stateSplash);
    const dispatch = useDispatch();

    const [selectedPreferences, setSelectedPreferences] = useState({ ...preferences });
    const [showWarning, setShowWarning] = useState(false);

    const handleToggle = (name: string) => {
        setSelectedPreferences({
            ...selectedPreferences,
            [name]: !selectedPreferences[name],
        });
    };

    const handleSelect = (name: string, event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPreferences({
            ...selectedPreferences,
            [name]: event.target.value,
        });
    };

    const handleClose = () => {
        if (JSON.stringify(preferences) !== JSON.stringify(selectedPreferences) && !showWarning) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            handleWindowChange(SplashState.NONE);
        }
    };

    const handleSubmit = () => {
        const action: UpdatePreferencesAction = {
            type: ActionType.PREFERENCES_UPDATE,
            ...selectedPreferences,
        };
        dispatch(action);
        handleWindowChange(SplashState.NONE);
    };

    const handleReset = () => {
        setSelectedPreferences(new PreferencesState());
    };

    const WarningModal = () => (
        <SVVModal
            show={showWarning}
            title="Advarsel!"
            onClose={() => {
                setShowWarning(!showWarning);
            }}
            rejectButton={
                <SVVButton color="secondary" onClick={() => setShowWarning(!showWarning)}>
                    Nei
                </SVVButton>
            }
            acceptButton={<SVVButton onClick={() => handleClose()}>Ja</SVVButton>}
        >
            <div className="c-preferences-toggle-content">
                Du har ulagrede endringer, er du sikker på at du vil fortsette?
            </div>
        </SVVModal>
    );

    const Toggle = ({ name, label }: ToggleProps) => (
        <SimpleToggle
            className="c-preferences-toggle"
            checked={selectedPreferences[name]}
            label={label}
            onChange={() => handleToggle(name)}
        />
    );

    const Select = ({ name, label, options }: SelectProps) => (
        <SimpleSelect
            label={label}
            options={options}
            value={selectedPreferences[name]}
            onChange={e => handleSelect(name, e)}
        />
    );

    return (
        <>
            <WarningModal />
            <SVVModal
                show={modalState === SplashState.PREFERENCES && !showWarning}
                onClose={() => handleClose()}
                title="Innstillinger"
                rejectButton={
                    <SVVButton onClick={() => handleReset()} color="secondary">
                        Nullstill innstillinger
                    </SVVButton>
                }
                acceptButton={<SVVButton onClick={() => handleSubmit()}>Lagre innstillinger</SVVButton>}
                disableCloseOnRejectClick
            >
                <div className="c-preferences-toggle-content">
                    <h2 className="c-preferences-heading">Vegkart</h2>
                    <Toggle name="includeTrafikantgruppe" label="Vis trafikantgruppe i vegsystemreferanse" />
                    <Toggle name="transformTinyGeometries" label="Vis små strekningsgeometrier som punkt" />
                    <Toggle name="disableClusters" label="Vis punktgruppe som separate punkter" />
                    <Toggle name="showQualityInfo" label="Vis kvalitetsinformasjon for valgt objekt" />
                    <Toggle name="showMetadata" label="Vis metadata for valgt objekt" />
                    <Toggle name="showPropertyBoundaries" label="Vis eiendomsgrenser" />
                </div>
                <div className="c-preferences-toggle-content">
                    <h2 className="c-preferences-heading">Eksport</h2>
                    <Select
                        name="exportProjection"
                        label="Velg eksportprojeksjon"
                        options={Object.values(Projeksjon)}
                    />
                    <Select
                        name="exportEnumFormat"
                        label="Velg format for tillatte verdier (enum) på SOSI-fil"
                        options={Object.values(ExportEnumFormat)}
                    />
                </div>
            </SVVModal>
        </>
    );
};

PreferencesDialog.displayName = 'PreferencesDialog';
export default PreferencesDialog;

import * as React from 'react';
import { connect } from 'react-redux';
import {
    AdskilteLop,
    Detaljniva,
    Trafikantgruppe,
    TrinaryQueryParam,
    TypeVeg,
    Veglenketype,
    VegnettQueries,
    VegnettParameter,
} from '@/middleware/queries';
import { AdskilteLopOptions } from './AdskilteLopOptions';
import { ArmKryssSideOptions } from './ArmKryssSideOptions';
import { DetaljnivaOptions } from './DetaljnivaOptions';
import { VegnettFilterOption } from './FilterOptions';
import { Retningsgrunnlag } from './Retningsgrunnlag';
import { VeglenketypeOptions } from './VeglenketypeOptions';
import { ALLE_GAENDE, ALLE_KJORENDE, VegutvalgOptions } from './VegutvalgOptions';
import { setRoadNetFilterT } from '@/actions/vegnettActions';
import { VegnettRetning } from '@/state';

interface TagProps {
    vegnettfilter: VegnettQueries;
    vegnettRetning: VegnettRetning;
    setVegnettfilter: setRoadNetFilterT;
}

type Props = TagProps;

class VegnettFilterComponent extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    render() {
        const filter = this.props.vegnettfilter;
        const retning = this.props.vegnettRetning;

        return (
            <section className="c-road-net__panel">
                <VegutvalgOptions typeveg={filter.typeveg} handleFilterChange={this.handleFilterChange} />
                <ArmKryssSideOptions
                    booleans={{
                        arm: filter.arm,
                        kryssystem: filter.kryssystem,
                        sideanlegg: filter.sideanlegg,
                    }}
                    handleFilterChange={this.handleFilterChange}
                />
                <VeglenketypeOptions
                    veglenketype={filter.veglenketype}
                    handleFilterChange={this.handleFilterChange}
                />
                <AdskilteLopOptions
                    adskilteLop={filter.adskiltelop}
                    handleFilterChange={this.handleFilterChange}
                />
                <DetaljnivaOptions
                    detaljniva={filter.detaljniva}
                    handleFilterChange={this.handleFilterChange}
                />
                <Retningsgrunnlag vegnettRetning={retning} />
            </section>
        );
    }

    handleFilterChange(e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) {
        const filter = this.props.vegnettfilter;
        const setFilter = this.props.setVegnettfilter;

        switch (filterOption.paramType) {
            case VegnettParameter.TRAFIKANTGRUPPE:
                switch (filterOption.optionType) {
                    case Trafikantgruppe.KJORENDE:
                        const kjorendeTypes = ALLE_KJORENDE.map(option => option.optionType as TypeVeg);
                        e.target.checked
                            ? setFilter(filter.withTypeveg(filter.typeveg.concat(kjorendeTypes)))
                            : setFilter(
                                  filter.withTypeveg(
                                      filter.typeveg.filter(item => !kjorendeTypes.includes(item))
                                  )
                              );
                        break;
                    case Trafikantgruppe.GAENDE:
                        const gaendeTypes = ALLE_GAENDE.map(option => option.optionType as TypeVeg);
                        e.target.checked
                            ? setFilter(filter.withTypeveg(filter.typeveg.concat(gaendeTypes)))
                            : setFilter(
                                  filter.withTypeveg(
                                      filter.typeveg.filter(item => !gaendeTypes.includes(item))
                                  )
                              );
                        break;
                }
                break;
            case VegnettParameter.TYPEVEG:
                const typeVegOption = filterOption.optionType as TypeVeg;
                e.target.checked
                    ? setFilter(filter.withTypeveg(filter.typeveg.concat([typeVegOption])))
                    : setFilter(filter.withTypeveg(filter.typeveg.filter(item => item !== typeVegOption)));
                break;
            case VegnettParameter.ARM:
                const armOption = filterOption.optionType as TrinaryQueryParam;
                if (armOption === TrinaryQueryParam.NOT_SET) {
                    setFilter(filter.withArm(TrinaryQueryParam.FALSE));
                } else if (armOption === TrinaryQueryParam.FALSE) {
                    setFilter(filter.withArm(TrinaryQueryParam.TRUE));
                } else if (armOption === TrinaryQueryParam.TRUE) {
                    setFilter(filter.withArm(TrinaryQueryParam.NOT_SET));
                }
                break;
            case VegnettParameter.KRYSSYSTEM:
                const kryssystemOption = filterOption.optionType as TrinaryQueryParam;
                if (kryssystemOption === TrinaryQueryParam.NOT_SET) {
                    setFilter(filter.withKryssystem(TrinaryQueryParam.FALSE));
                } else if (kryssystemOption === TrinaryQueryParam.FALSE) {
                    setFilter(filter.withKryssystem(TrinaryQueryParam.TRUE));
                } else if (kryssystemOption === TrinaryQueryParam.TRUE) {
                    setFilter(filter.withKryssystem(TrinaryQueryParam.NOT_SET));
                }
                break;
            case VegnettParameter.SIDEANLEGG:
                const sideanleggOption = filterOption.optionType as TrinaryQueryParam;
                if (sideanleggOption === TrinaryQueryParam.NOT_SET) {
                    setFilter(filter.withSideanlegg(TrinaryQueryParam.FALSE));
                } else if (sideanleggOption === TrinaryQueryParam.FALSE) {
                    setFilter(filter.withSideanlegg(TrinaryQueryParam.TRUE));
                } else if (sideanleggOption === TrinaryQueryParam.TRUE) {
                    setFilter(filter.withSideanlegg(TrinaryQueryParam.NOT_SET));
                }
                break;
            case VegnettParameter.VEGLENKETYPE:
                const veglenkeOption = filterOption.optionType as Veglenketype;
                if (veglenkeOption === Veglenketype.NOT_SET) {
                    setFilter(filter.withVeglenketype(Veglenketype.HOVED));
                } else if (veglenkeOption === Veglenketype.HOVED) {
                    setFilter(filter.withVeglenketype(Veglenketype.KONNEKTERING));
                } else if (veglenkeOption === Veglenketype.KONNEKTERING) {
                    setFilter(filter.withVeglenketype(Veglenketype.NOT_SET));
                }
                break;
            case VegnettParameter.ADSKILTELOP:
                const adskilteLopOption = filterOption.optionType as AdskilteLop;
                e.target.checked
                    ? setFilter(filter.withAdskiltelop(filter.adskiltelop.concat([adskilteLopOption])))
                    : setFilter(
                          filter.withAdskiltelop(
                              filter.adskiltelop.filter(item => item !== adskilteLopOption)
                          )
                      );
                break;
            case VegnettParameter.DETALJNIVA:
                const detaljnivaOption = filterOption.optionType as Detaljniva;
                e.target.checked
                    ? setFilter(filter.withDetaljniva(filter.detaljniva.concat([detaljnivaOption])))
                    : setFilter(
                          filter.withDetaljniva(filter.detaljniva.filter(item => item !== detaljnivaOption))
                      );
                break;
        }
    }
}

export const VegnettFilter = connect(null)(VegnettFilterComponent);

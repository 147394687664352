import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import React from 'react';

interface LenkeegenskapProps {
    property: string;
    value?: string | JSX.Element;
    vegnettlenke: Vegnettlenke;
    key?: string | number;
}

export default function Lenkeegenskap({
    property,
    value,
    vegnettlenke,
    key = vegnettlenke.referanse,
}: LenkeegenskapProps): JSX.Element | null {
    const verdi: string | undefined = value ?? vegnettlenke[property];
    const capitalized = `${property.charAt(0).toUpperCase()}${property.substring(1)}:`;

    if (verdi !== undefined) {
        return (
            <React.Fragment key={`rnFragment${key}`}>
                <dt key={`dtRn${key}`} className="c-valgt-features__dt" title={capitalized}>
                    {capitalized}
                </dt>
                <dd key={`ddRn${key}`} className="c-valgt-features__dd">
                    {verdi}
                </dd>
            </React.Fragment>
        );
    } else {
        return null;
    }
}

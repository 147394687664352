import { BaatToken } from '@/components/map/layers/useRefreshBaatToken';
import { TileService } from '@/components/map/map-utils/WebMapTileServices';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { MapPoint } from '@/domain/kart/MapPoint';
import {
    Fylke,
    Kommune,
    Kontraktsomrade,
    Land,
    Omrade,
    Riksvegrute,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { Polygon } from '@/domain/omrader/Polygon';
import { Node } from '@/domain/vegnett/Node';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { VegobjekterResult } from '@/domain/vegobjekter/VegobjekterResult';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { Egenskapsfilter, VegnettQueries } from '@/middleware/queries';
import { Stedsnavn, StedsnavnResponse } from '@/server/responses';
import {
    GeolocationState,
    LoginWindowState,
    PreferencesState,
    RootState,
    SearchDropdownState,
    ValgtState,
    VegkartError,
    VegnettRetning,
    VegnettState,
    VegobjekttypeCategoryState,
    VegobjekttypeState,
    VegobjekttypeStateError,
    VegsystemreferanseLookup,
} from '@/state';
import { MeasurementPoint, MeasurementType } from '@/state/MeasurementState';
import { SplashState } from '@/state/SplashState';

export type GetState = () => RootState;

export enum ActionType {
    BOOTSTRAP = 'BOOTSTRAP',
    HASH_CHANGED = 'HASH_CHANGED',
    WINDOW_UNFOCUSED = 'WINDOW_UNFOCUSED',

    ERROR_OCCURRED = 'ERROR_OCCURRED',
    ERROR_DISMISSED = 'ERROR_DISMISSED',

    MAP_EXTENT_ZOOMED_TO = 'MAP_EXTENT_ZOOMED_TO',
    MAP_MOVED = 'MAP_MOVED',
    MAP_CENTER_AND_ZOOM_SET = 'MAP_CENTER_AND_ZOOM_SET',
    MAP_LAYER_SET = 'MAP_LAYER_SET',
    MAP_POINT_ADDED = 'MAP_POINT_ADDED',
    MAP_POINT_REMOVED = 'MAP_POINT_REMOVED',
    MAP_BAAT_TOKEN_SET = 'MAP_BAAT_TOKEN_SET',

    OMRADE_LAND_ADDED = 'OMRADE_LAND_ADDED',
    OMRADE_FYLKE_ADDED = 'OMRADE_FYLKE_ADDED',
    OMRADE_KOMMUNE_ADDED = 'OMRADE_KOMMUNE_ADDED',
    OMRADE_POLYGON_ADDED = 'OMRADE_POLYGON_ADDED',
    OMRADE_KONTRAKTSOMRADE_ADDED = 'OMRADE_KONTRAKTSOMRADE_ADDED',
    OMRADE_RIKSVEGRUTE_ADDED = 'OMRADE_RIKSVEGRUTE_ADDED',
    OMRADE_VEGSYSTEMREFERANSE_ADDED = 'OMRADE_VEGSYSTEMREFERANSE_ADDED',
    OMRADE_VEGSYSTEMREFERANSE_UPDATED = 'OMRADE_VEGSYSTEMREFERANSE_UPDATED',
    OMRADE_GEOMETRI_RECEIVED = 'OMRADE_GEOMETRI_RECEIVED',
    OMRADE_REMOVED = 'OMRADE_REMOVED',
    OMRADE_TOGGLED = 'OMRADE_TOGGLED',

    VEGOBJEKTER_TYPE_ADDED = 'VEGOBJEKTER_TYPE_ADDED',
    VEGOBJEKTER_TYPE_TOGGLED = 'VEGOBJEKTER_TYPE_TOGGLED',
    VEGOBJEKTER_TYPE_REMOVED = 'VEGOBJEKTER_TYPE_REMOVED',
    IS_ADDING_CAT_TO_VOT = 'IS_ADDING_CAT_TO_VOT',
    VEGOBJEKTER_CATEGORY_ADDED = 'VEGOBJEKTER_CATEGORY_ADDED',
    VEGOBJEKTER_CATEGORY_TOGGLED = 'VEGOBJEKTER_CATEGORY_TOGGLED',
    VEGOBJEKTER_CATEGORY_UPDATED = 'VEGOBJEKTER_CATEGORY_UPDATED',
    VEGOBJEKTER_CATEGORY_REMOVED = 'VEGOBJEKTER_CATEGORY_REMOVED',
    VEGOBJEKTER_FILTER_ADDED = 'VEGOBJEKTER_FILTER_ADDED',
    VEGOBJEKTER_FILTER_UPDATED = 'VEGOBJEKTER_FILTER_UPDATED',
    VEGOBJEKTER_FILTER_REMOVED = 'VEGOBJEKTER_FILTER_REMOVED',
    VEGOBJEKTER_FETCH_INITIATED = 'VEGOBJEKTER_FETCH_INITIATED',
    VEGOBJEKTER_FETCH_RECEIVED = 'VEGOBJEKTER_FETCH_RECEIVED',
    VEGOBJEKTER_FETCH_ABORTED = 'VEGOBJEKTER_FETCH_ABORTED',
    VEGOBJEKTER_FETCH_FAILED = 'VEGOBJEKTER_FETCH_FAILED',
    VEGOBJEKTER_CATEGORY_RECEIVED = 'VEGOBJEKTER_CATEGORY_RECEIVED',
    VEGOBJEKT_ADDED = 'VEGOBJEKT_ADDED',

    VEGNETT_TOGGLED = 'VEGNETT_TOGGLED',
    VEGNETT_FETCH_INITIATED = 'VEGNETT_FETCH_INITIATED',
    VEGNETT_FETCH_RECEIVED = 'VEGNETT_FETCH_RECEIVED',
    VEGNETT_FETCH_ABORTED = 'VEGNETT_FETCH_ABORTED',
    VEGNETT_FILTER_UPDATED = 'VEGNETT_FILTER_UPDATED',
    VEGNETT_DIRECTION_SET = 'VEGNETT_DIRECTION_SET',

    VALGT_OBJEKT_SELECTED = 'VALGT_OBJEKT_SELECTED',
    VALGT_OBJEKT_RECEIVED = 'VALGT_OBJEKT_RECEIVED',
    VALGT_OBJEKT_REMOVED = 'VALGT_OBJEKT_REMOVED',
    VALGT_CLUSTER_REMOVED = 'VALGT_CLUSTER_REMOVED',
    VALGT_COLOR_UPDATED = 'VALGT_COLOR_UPDATED',
    VALGT_LENKE_RECEIVED = 'VALGT_LENKE_RECEIVED',
    VALGT_LENKE_REMOVED = 'VALGT_LENKE_REMOVED',
    VALGT_NODE_RECEIVED = 'VALGT_NODE_RECEIVED',
    VALGT_NODE_REMOVED = 'VALGT_NODE_REMOVED',

    OVERLAY_GEOLOCATION_SET = 'OVERLAY_GEOLOCATION_SET',
    OVERLAY_VEGSYSTEMREFERANSE_ADDED = 'OVERLAY_VEGSYSTEMREFERANSE_ADDED',
    OVERLAY_VEGSYSTEMREFERANSE_REMOVED = 'OVERLAY_VEGSYSTEMREFERANSE_REMOVED',
    OVERLAY_STEDSNAVN_ADDED = 'OVERLAY_STEDSNAVN_ADDED',

    MEASURE_SET_ACTIVE = 'MEASURE_SET_ACTIVE',
    MEASURE_TOGGLE_ACTIVE = 'MEASURE_TOGGLE_ACTIVE',
    MEASURE_ADD_POINT = 'MEASURE_ADD_POINT',
    MEASURE_MODIFY_POINT = 'MEASURE_MODIFY_POINT',

    SEARCH_DROPDOWN_SET = 'SEARCH_DROPDOWN_SET',
    SEARCH_TIMESTAMP_SET = 'SEARCH_TIMESTAMP_SET',
    SEARCH_NUMBER_OF_LIST_ITEMS_SET = 'SEARCH_NUMBER_OF_LIST_ITEMS_SET',
    SEARCH_HIGHLIGHTED_SET = 'SEARCH_HIGHLIGHTED_SET',
    SEARCH_STEDSNAVN_RECEIVED = 'SEARCH_STEDSNAVN_RECEIVED',
    SEARCH_STEDSNAVN_CLEARED = 'SEARCH_STEDSNAVN_CLEARED',

    DATAKATALOG_VEGOBJEKTTYPE_SELECTED = 'DATAKATALOG_VEGOBJEKTTYPE_SELECTED',
    DATAKATALOG_VEGOBJEKTTYPE_RECEIVED = 'DATAKATALOG_VEGOBJEKTTYPE_RECEIVED',

    MOBILE_SEARCH_OPEN_SET = 'MOBILE_SEARCH_OPEN_SET',
    MOBILE_FILTER_OPEN_SET = 'MOBILE_FILTER_OPEN_SET',

    SHOW_SPLASH = 'SHOW_SPLASH',

    TIMESTAMP_CHANGED = 'TIMESTAMP_CHANGED',

    SHOW_LOGIN = 'SHOW_LOGIN',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',

    CONFIG_UPDATE = 'CONFIG_UPDATE',
    PREFERENCES_UPDATE = 'PREFERENCES_UPDATE',
    PREFERENCES_RESET = 'PREFERENCES_RESET',
    TOGGLE_POLYGON_DRAW = 'TOGGLE_POLYGON_DRAW',
}

// Meta-actions
export interface BootstrapAction {
    type: ActionType.BOOTSTRAP;
    bootstrappedState: RootState;
}

export interface TogglePolygonDrawAction {
    type: ActionType.TOGGLE_POLYGON_DRAW;
    drawing: boolean;
}

export interface UpdateConfigAction {
    type: ActionType.CONFIG_UPDATE;
    key: string;
    value: string | number | boolean;
}

export interface HashChangedAction {
    type: ActionType.HASH_CHANGED;
    state: RootState;
}

export interface ErrorAction {
    type: ActionType.ERROR_OCCURRED;
    error: VegkartError;
}
export interface DismissErrorAction {
    type: ActionType.ERROR_DISMISSED;
    error: VegkartError;
}
export interface WindowUnfocusedAction {
    type: ActionType.WINDOW_UNFOCUSED;
    hash: string;
}

export type MetaAction =
    | BootstrapAction
    | HashChangedAction
    | ErrorAction
    | DismissErrorAction
    | WindowUnfocusedAction;

// Map-actions
export interface MapMovedAction {
    type: ActionType.MAP_MOVED;
    center: [number, number];
    zoom: number;
    kartutsnitt: Kartutsnitt;
}
export interface SetZoomAndCenterAction {
    type: ActionType.MAP_CENTER_AND_ZOOM_SET;
    center: [number, number];
    zoom: number;
}
export interface SetMapLayerAction {
    type: ActionType.MAP_LAYER_SET;
    maplayer: TileService;
}

export interface ZoomToExtentAction {
    type: ActionType.MAP_EXTENT_ZOOMED_TO;
    kartutsnitt: Kartutsnitt;
}
export interface RemovePointAction {
    type: ActionType.MAP_POINT_REMOVED;
    id: string;
}
export interface AddPointAction {
    type: ActionType.MAP_POINT_ADDED;
    point: MapPoint;
}

export interface SetBaatTokenAction {
    type: ActionType.MAP_BAAT_TOKEN_SET;
    baatToken: BaatToken;
}

export type MapAction =
    | BootstrapAction
    | HashChangedAction
    | MapMovedAction
    | SetZoomAndCenterAction
    | SetMapLayerAction
    | RemovePointAction
    | AddPointAction
    | ZoomToExtentAction
    | SetBaatTokenAction;

// Search-actions
export interface SetDropdownAction {
    type: ActionType.SEARCH_DROPDOWN_SET;
    searchDropdownState: SearchDropdownState;
}
export interface SetSearchTimestampAction {
    type: ActionType.SEARCH_TIMESTAMP_SET;
    date: Date;
}

export interface ReceivedStedsnavnAction {
    type: ActionType.SEARCH_STEDSNAVN_RECEIVED;
    stedsnavn: StedsnavnResponse;
}

export interface ClearedStedsnavnAction {
    type: ActionType.SEARCH_STEDSNAVN_CLEARED;
}

export interface SelectedDataKatVOTAction {
    type: ActionType.DATAKATALOG_VEGOBJEKTTYPE_SELECTED;
    selectedVOTHistory: Vegobjekttype[];
}
export interface ReceivedVOTAction {
    type: ActionType.DATAKATALOG_VEGOBJEKTTYPE_RECEIVED;
    vegobjekttype: Vegobjekttype;
}

//// Mobile search-actions
export interface SetFilterPanelAction {
    type: ActionType.MOBILE_FILTER_OPEN_SET;
    filterPanelOpen: boolean;
}
export interface SetSearchPanelAction {
    type: ActionType.MOBILE_SEARCH_OPEN_SET;
    searchPanelOpen: boolean;
}

export type SearchAction =
    | BootstrapAction
    | HashChangedAction
    | SetDropdownAction
    | SetSearchTimestampAction
    | ReceivedStedsnavnAction
    | ClearedStedsnavnAction
    | SelectedDataKatVOTAction
    | ReceivedVOTAction
    | SetFilterPanelAction
    | SetSearchPanelAction
    | VOAddAction;

// Omrade-actions
export interface AddLandAction {
    type: ActionType.OMRADE_LAND_ADDED;
    land: Land;
}
export interface AddFylkeAction {
    type: ActionType.OMRADE_FYLKE_ADDED;
    fylke: Fylke;
}
export interface AddPolygonAction {
    type: ActionType.OMRADE_POLYGON_ADDED;
    polygon: Polygon;
}
export interface AddKommuneAction {
    type: ActionType.OMRADE_KOMMUNE_ADDED;
    kommune: Kommune;
}
export interface AddKontraktsomradeAction {
    type: ActionType.OMRADE_KONTRAKTSOMRADE_ADDED;
    kontraktsomrade: Kontraktsomrade;
}
export interface AddRiksvegruteAction {
    type: ActionType.OMRADE_RIKSVEGRUTE_ADDED;
    riksvegrute: Riksvegrute;
}
export interface AddVSRAction {
    type: ActionType.OMRADE_VEGSYSTEMREFERANSE_ADDED;
    vegsystemreferanse: Vegsystemreferanse;
}
export interface UpdateVSRAction {
    type: ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED;
    previousVsr: Vegsystemreferanse;
    newVsr: Vegsystemreferanse;
}
export interface RemoveOmradeAction {
    type: ActionType.OMRADE_REMOVED;
    omrade: Omrade;
}
export interface ToggleOmradeAction {
    type: ActionType.OMRADE_TOGGLED;
    omrade: Omrade;
}
export interface ReceivedOmradeGeometryAction {
    type: ActionType.OMRADE_GEOMETRI_RECEIVED;
    omrade: Omrade | Kommune;
    vegobjekt: Vegobjekt;
}

export type OmraderAction =
    | BootstrapAction
    | HashChangedAction
    | AddLandAction
    | AddFylkeAction
    | AddKommuneAction
    | AddKontraktsomradeAction
    | AddPolygonAction
    | AddRiksvegruteAction
    | AddVSRAction
    | UpdateVSRAction
    | RemoveOmradeAction
    | ReceivedOmradeGeometryAction
    | ToggleOmradeAction;

export interface ModifyMeasuringPointAction {
    type: ActionType.MEASURE_MODIFY_POINT;
    modify: Partial<MeasurementPoint>;
}

export interface AddMeasuringPointAction {
    type: ActionType.MEASURE_ADD_POINT;
    point: MeasurementPoint;
}

export interface SetMeasuringAction {
    type: ActionType.MEASURE_SET_ACTIVE;
    active: boolean;
}
export interface ToggleMeasuringAction {
    type: ActionType.MEASURE_TOGGLE_ACTIVE;
    measurementType: MeasurementType;
    active: boolean;
}

export type MeasurementAction =
    | BootstrapAction
    | AddMeasuringPointAction
    | ModifyMeasuringPointAction
    | SetMeasuringAction
    | ToggleMeasuringAction;

// Vegobjekter-actions
// RETHINK: Change to id and let reducer handle the lookup?
export interface AddVegobjektAction {
    type: ActionType.VEGOBJEKT_ADDED;
    vegobjekt: Vegobjekt;
}
export interface RefreshAction {
    type: ActionType.TIMESTAMP_CHANGED;
}
export interface VOAddAction {
    type: ActionType.VEGOBJEKTER_TYPE_ADDED;
    vegobjekttype: VegobjekttypeState;
}
export interface VOToggleAction {
    type: ActionType.VEGOBJEKTER_TYPE_TOGGLED;
    votTypeId: number;
}
export interface VORemoveAction {
    type: ActionType.VEGOBJEKTER_TYPE_REMOVED;
    votTypeId: number;
}
// Categorization
export interface VOAddCategoryAction {
    type: ActionType.VEGOBJEKTER_CATEGORY_ADDED;
    egenskapstype: Egenskapstype;
    votTypeId: number;
}
export interface IsAddingCatToVOAction {
    type: ActionType.IS_ADDING_CAT_TO_VOT;
    categorizing: boolean;
}
export interface VOSetCategoryAction {
    type: ActionType.VEGOBJEKTER_CATEGORY_UPDATED;
    votTypeId: number;
    egenskapstype: Egenskapstype;
    interval: number[];
    absoluteIntervals: boolean;
}
export interface VOToggleCategoryAction {
    type: ActionType.VEGOBJEKTER_CATEGORY_TOGGLED;
    votTypeId: number;
    votCategoryState: VegobjekttypeCategoryState;
}
export interface VORemoveCategoryAction {
    type: ActionType.VEGOBJEKTER_CATEGORY_REMOVED;
    votTypeId: number;
}
// Filtering
export interface VOAddFilterAction {
    type: ActionType.VEGOBJEKTER_FILTER_ADDED;
    votTypeId: number;
}
export interface VOUpdateFilterAction {
    type: ActionType.VEGOBJEKTER_FILTER_UPDATED;
    votTypeId: number;
    filter: Egenskapsfilter;
}
export interface VORemoveFilterAction {
    type: ActionType.VEGOBJEKTER_FILTER_REMOVED;
    votTypeId: number;
    filter: Egenskapsfilter;
}
// Fetching
export interface VOFetchAction {
    type: ActionType.VEGOBJEKTER_FETCH_INITIATED;
    votTypeId: number;
}
export interface VOReceivedAction {
    type: ActionType.VEGOBJEKTER_FETCH_RECEIVED;
    votTypeId: number;
    result: VegobjekterResult;
    categoryStates: VegobjekttypeCategoryState[];
}

export interface VOFailedAction {
    type: ActionType.VEGOBJEKTER_FETCH_FAILED;
    votTypeId: number;
    error: VegobjekttypeStateError;
}

export interface VOAbortedAction {
    type: ActionType.VEGOBJEKTER_FETCH_ABORTED;
}

export type VegobjekterAction =
    | BootstrapAction
    | HashChangedAction
    | VOAddAction
    | VOToggleAction
    | VORemoveAction
    | VOAddCategoryAction
    | IsAddingCatToVOAction
    | VOSetCategoryAction
    | VOToggleCategoryAction
    | VORemoveCategoryAction
    | VOAddFilterAction
    | VOUpdateFilterAction
    | VORemoveFilterAction
    | VOFetchAction
    | VOFailedAction
    | VOReceivedAction
    | VOAbortedAction;

// Vegnett-actions
export interface FetchVegnettAction {
    type: ActionType.VEGNETT_FETCH_INITIATED;
}
export interface ReceivedVegnettAction {
    type: ActionType.VEGNETT_FETCH_RECEIVED;
    receivedVegnettState: VegnettState;
}

export interface AbortedVegnettAction {
    type: ActionType.VEGNETT_FETCH_ABORTED;
}

export interface ToggleVegnettVisibilityAction {
    type: ActionType.VEGNETT_TOGGLED;
}
export interface SetVegnettFilterAction {
    type: ActionType.VEGNETT_FILTER_UPDATED;
    vegnettFilter: VegnettQueries;
}
export interface SetVegnettRetningAction {
    type: ActionType.VEGNETT_DIRECTION_SET;
    vegnettRetning: VegnettRetning;
}

export type VegnettAction =
    | BootstrapAction
    | HashChangedAction
    | FetchVegnettAction
    | ReceivedVegnettAction
    | AbortedVegnettAction
    | SetVegnettFilterAction
    | SetVegnettRetningAction
    | ReceiveSelectedLinkAction
    | RemoveSelectedLinkAction
    | ToggleVegnettVisibilityAction;

// Valgt-actions
export interface SelectedObjectAction {
    type: ActionType.VALGT_OBJEKT_SELECTED;
    selection: ValgtState;
}
export interface ReceiveSelectedObjectAction {
    type: ActionType.VALGT_OBJEKT_RECEIVED;
    selection: ValgtState;
}
export interface ReceiveSelectedNodeAction {
    type: ActionType.VALGT_NODE_RECEIVED;
    node: Node;
}
export interface ReceiveSelectedLinkAction {
    type: ActionType.VALGT_LENKE_RECEIVED;
    linkQueryString: string;
    links: Vegnettlenke[];
}
export interface RemoveSelectedLinkAction {
    type: ActionType.VALGT_LENKE_REMOVED;
    linkQueryString: string;
}
export interface RemoveSelectedNodeAction {
    type: ActionType.VALGT_NODE_REMOVED;
    id: number;
}

export interface RemoveSelectedClusterAction {
    type: ActionType.VALGT_CLUSTER_REMOVED;
}
export interface RemoveSelectedObjectAction {
    type: ActionType.VALGT_OBJEKT_REMOVED;
    id?: number;
}
export interface UpdateSelectedColorAction {
    type: ActionType.VALGT_COLOR_UPDATED;
    color: string;
}

export type ValgtAction =
    | BootstrapAction
    | HashChangedAction
    | SelectedObjectAction
    | ReceiveSelectedObjectAction
    | ReceiveSelectedLinkAction
    | RemoveSelectedLinkAction
    | ReceiveSelectedNodeAction
    | RemoveSelectedNodeAction
    | UpdateSelectedColorAction
    | RemoveSelectedObjectAction
    | RemoveSelectedClusterAction;

// Overlay-actions
export interface SetGeolocationAction {
    type: ActionType.OVERLAY_GEOLOCATION_SET;
    geolocationState: GeolocationState;
}
export interface AddVSRLookupAction {
    type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_ADDED;
    vsrLookup: VegsystemreferanseLookup;
}
export interface RemoveVSRLookupAction {
    type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_REMOVED;
}
export interface SetStedsnavnMarkerAction {
    type: ActionType.OVERLAY_STEDSNAVN_ADDED;
    stedsnavn: Stedsnavn;
}

export type OverlayAction =
    | BootstrapAction
    | HashChangedAction
    | SetGeolocationAction
    | AddVSRLookupAction
    | RemoveVSRLookupAction
    | SetStedsnavnMarkerAction;

export interface SplashChangedAction {
    type: ActionType.SHOW_SPLASH;
    state: SplashState;
}

export interface LoginWindowChangedAction {
    type: ActionType.SHOW_LOGIN;
    state: LoginWindowState;
}

export interface UserLoginAction {
    type: ActionType.LOGIN;
    idToken: string;
}

export interface UserLogoutAction {
    type: ActionType.LOGOUT;
}
export type SplashAction = SplashChangedAction | BootstrapAction;

export type LoginWindowAction = LoginWindowChangedAction;

export type UserAction = UserLoginAction | UserLogoutAction | BootstrapAction;

export type UpdatePreferencesAction = { type: ActionType.PREFERENCES_UPDATE } & Partial<PreferencesState>;

export interface ResetPreferencesAction {
    type: ActionType.PREFERENCES_RESET;
}
export type PreferencesAction = UpdatePreferencesAction | ResetPreferencesAction | BootstrapAction;

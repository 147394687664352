import { Toolbar } from '@/components/Toolbar';
import { FeatureFlag, flags } from '@/featureFlags';
import { stateMapLayer } from '@/selectors/selectors';
import { useDispatch, useSelector } from '@/state/store';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { setMapLayer as _setMapLayer } from '../../../actions/mapActions';
import { TileService } from '../map-utils/WebMapTileServices';
import { TileServiceOption } from './TileServiceOption';

const TileServiceSelection = forwardRef<HTMLButtonElement>((_, ref) => {
    const self = useRef<HTMLDivElement>();
    const [expanded, setExpanded] = useState(false);
    const mapLayer = useSelector(stateMapLayer);
    const dispatch = useDispatch();
    const changeTileServices = useCallback(
        layer => {
            if (mapLayer !== layer) dispatch(_setMapLayer(layer));
        },
        [dispatch, mapLayer]
    );

    const handleMouseDown = useCallback((e: MouseEvent) => {
        if (e.target instanceof Element && self.current && !self.current.contains(e.target)) {
            setExpanded(false);
        }
    }, []);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setExpanded(false);
        }
    }, []);

    useEffect(() => {
        if (expanded) {
            document.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [expanded, handleMouseDown, handleKeyDown]);

    return (
        <div className="c-map-select-layer c-map-controls__button" ref={self}>
            <button
                ref={ref}
                type="button"
                className={`c-map-select-layer__expand o-btn-square`}
                title={`${expanded ? 'Lukk kartlagvelger' : 'Åpne kartlagvelger'}`}
                onClick={() => setExpanded(!expanded)}
            >
                <i className="o-icon-layer c-zoom-controls__layer-icon" />
            </button>

            {expanded === true && (
                <Toolbar
                    orientation={'horizontal'}
                    className={'c-map-select-layer-container'}
                    ariaLabel={'Kartlagvelger'}
                >
                    <>
                        <TileServiceOption
                            label={'Norgeskart'}
                            active={mapLayer === TileService.GEODATA}
                            iconName={'o-icon-map-default'}
                            onClick={() => changeTileServices(TileService.GEODATA)}
                            large
                        />
                        <TileServiceOption
                            label={'Flyfoto'}
                            active={mapLayer === TileService.NIB}
                            iconName={'o-icon-map-photograph'}
                            onClick={() => changeTileServices(TileService.NIB)}
                        />
                        <TileServiceOption
                            label={'Gråtone'}
                            active={mapLayer === TileService.GRAYSCALE}
                            iconName={'o-icon-map-landscape'}
                            onClick={() => changeTileServices(TileService.GRAYSCALE)}
                        />
                    </>
                    {(flags().has(FeatureFlag.EXTRA_TILES) || process.env.NODE_ENV === 'development') && (
                        <>
                            <TileServiceOption
                                label={'Kanvas'}
                                active={mapLayer === TileService.KANVAS}
                                iconName={'o-icon-map-default'}
                                onClick={() => changeTileServices(TileService.KANVAS)}
                            />
                            <TileServiceOption
                                label={'Kanvas - Mørk'}
                                active={mapLayer === TileService.KANVAS_DARK}
                                iconName={'o-icon-map-landscape'}
                                onClick={() => changeTileServices(TileService.KANVAS_DARK)}
                            />
                            <TileServiceOption
                                label={'Basis'}
                                active={mapLayer === TileService.BASIS}
                                iconName={'o-icon-map-default'}
                                onClick={() => changeTileServices(TileService.BASIS)}
                            />
                            <TileServiceOption
                                label={'Basis - Grå'}
                                active={mapLayer === TileService.BASIS_GRAY}
                                iconName={'o-icon-map-landscape'}
                                onClick={() => changeTileServices(TileService.BASIS_GRAY)}
                            />
                        </>
                    )}
                </Toolbar>
            )}
        </div>
    );
});

TileServiceSelection.displayName = 'TileServiceSelection';
export { TileServiceSelection };

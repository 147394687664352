import * as React from 'react';
import { connect } from 'react-redux';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { VegobjekttypeCategoryState, VegobjekttypeState, VegobjekttypeStateError } from '@/state';
import { ColorIndex, resolveColor } from '@/utils/ColorHelper';
import Accordion from '../../objects/Accordion';
import { Loading } from '../../objects/Loading';
import { Categorize } from './categorize/Categorize';
import { CategoryResult } from './categorize/CategoryResult';
import { DownloadResultsBox } from './DownloadResultsBox';
import { Filtere } from './filter/Filtere';
import { HvaInfo } from './HvaInfo';
import { RoadRefOrAreaSummary } from './RoadRefOrAreaSummary';
import {
    addFilterToVegobjekttype,
    addFilterToVegobjekttypeT,
    removeVegobjekttype,
    removeVegobjekttypeT,
} from '@/actions/vegobjekterActions';
import { HitStatistics } from './HitStatistics';

interface TagProps {
    vegobjekttype: Vegobjekttype;
    vegobjekttypeData: VegobjekttypeState;
    farge: ColorIndex;
    isLoggedIn: boolean;
}

interface DispatchProps {
    doAddFilter: addFilterToVegobjekttypeT;
    doRemoveRoadObjectType: removeVegobjekttypeT;
}

type Props = TagProps & DispatchProps;

const mapDispatchToProps = {
    doAddFilter: addFilterToVegobjekttype,
    doRemoveRoadObjectType: removeVegobjekttype,
};

class VegobjekttypeContainerComponent extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showCategoryResults: true,
        };
    }

    render() {
        const { vegobjekttypeData, vegobjekttype, farge } = this.props;
        const category = vegobjekttypeData.category;
        const categoryStates = vegobjekttypeData.categoryStates;
        const colorIndex = vegobjekttypeData.color;
        const { result } = vegobjekttypeData;
        const { id } = vegobjekttype;
        const mainColor = resolveColor(colorIndex);
        const hasCategoryResults =
            categoryStates && categoryStates.length > 0 && category && category.egenskapstype !== null;

        return (
            <li
                tabIndex={-1}
                key={this.props.vegobjekttypeData.typeId}
                className={'o-list__element c-what-list__element'}
                onKeyDown={e => {
                    if (
                        e.key === 'Backspace' &&
                        (e.target as HTMLElement).classList.contains('c-hva-list-element')
                    ) {
                        this.props.doRemoveRoadObjectType(vegobjekttypeData);
                    }
                }}
                aria-label={`informasjon om søk etter ${vegobjekttype.navn}`}
            >
                <HvaInfo vegobjekttypeData={vegobjekttypeData} vegobjekttype={vegobjekttype} farge={farge} />

                <Categorize vegobjekttype={vegobjekttype} vegobjekttypeData={vegobjekttypeData} />
                <Filtere vegobjekttype={vegobjekttypeData} egenskapstyper={vegobjekttype.egenskapstyper} />
                {vegobjekttypeData.error && (
                    <section className="c-what-error">
                        <i className="o-img-exclamation c-what-error__icon" />
                        <b>{this.formatError(vegobjekttypeData.error)}</b>
                    </section>
                )}
                {!vegobjekttypeData.error && !hasCategoryResults && (
                    <Accordion
                        id={'dropdown_' + vegobjekttype.id.toString()}
                        className="c-filtere__section-bottom"
                        ariaLabel={`Vis liste av ${vegobjekttype.navn} objekter`}
                        header={this.getResultHeader()}
                    >
                        <RoadRefOrAreaSummary color={mainColor} results={result} />
                        <DownloadResultsBox votState={vegobjekttypeData} color={mainColor} typeId={id} />
                    </Accordion>
                )}
                {!vegobjekttypeData.error && hasCategoryResults && (
                    <ul className="c-category-result-list" aria-label={`Kategorier for ${category.navn}`}>
                        {categoryStates
                            .filter(category => category.result.vegobjekter.length > 0)
                            .sort(VegobjekttypeCategoryState.comparator)
                            .map(category => {
                                //const color = resolveCategoryColorTwo(vegobjekttypeData, category);
                                const color = resolveColor(category.color);
                                return (
                                    <CategoryResult
                                        key={category.toString()}
                                        vegobjekttype={vegobjekttype}
                                        vegobjekttypeData={vegobjekttypeData}
                                        categoryState={category}
                                        result={category.result}
                                        color={color}
                                    />
                                );
                            })}
                    </ul>
                )}
            </li>
        );
    }
    formatError = (error: VegobjekttypeStateError) => {
        const messages: Record<VegobjekttypeStateError, string> = {
            auth: this.props.isLoggedIn
                ? 'Du har ikke tilgang til å se denne informasjonen.'
                : 'Data er skjermet. Innlogging og gyldig tilgang kreves.',
            network: 'Det har oppstått en nettverksfeil ved innlasting av data',
            api: 'Kan ikke hente data, ugyldig spørring',
            unknown: 'Ukjent feil har oppstått ved innlasting.',
            'not-indexed': 'Ikke tilgjengelig i Vegkart',
        };
        return messages[error] || messages['unknown'];
    };
    getResultHeader = () => {
        const { vegobjekttypeData, vegobjekttype } = this.props;
        const { result } = vegobjekttypeData;
        return (
            <>
                {this.props.vegobjekttypeData.loading && (
                    <Loading className="c-hva-info__statistics" type="text" />
                )}
                {result !== null && !this.props.vegobjekttypeData.loading && (
                    <HitStatistics vegobjekttype={vegobjekttype} result={result} />
                )}
            </>
        );
    };
}

export const VegobjekttypeContainer = connect(null, mapDispatchToProps)(VegobjekttypeContainerComponent);

import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { statePreferences } from '@/selectors/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

type MetadataProps = {
    vegobjekt: Vegobjekt;
};

function Metadata({ vegobjekt }: MetadataProps) {
    const preferences = useSelector(statePreferences);

    return preferences.showMetadata ? (
        <>
            <hr />
            <h4 className="c-valgt-features__subheader">Metadata</h4>
            {vegobjekt.lokasjon.stedfestinger && [
                <dt key="features__dt-p" className="c-valgt-features__dt">
                    Stedfestinger:
                </dt>,
                <dd key="stedfestinger-dd" className="c-valgt-features__dd">
                    <ul>
                        {vegobjekt.lokasjon.stedfestinger.map(sf => (
                            <li key={sf.kortform} className="c-valgt-features__dd">
                                {(sf.kortform + ' ')
                                    .concat(sf.retning ? sf.retning + ' ' : '')
                                    .concat(sf.sideposisjon ? sf.sideposisjon + ' ' : '')
                                    .concat(sf.kjørefelt?.length > 0 ? '(' + sf.kjørefelt.join() + ')' : '')}
                            </li>
                        ))}
                    </ul>
                </dd>,
            ]}
            {vegobjekt?.metadata?.startdato && [
                <dt key={'startdate-header_' + vegobjekt.id} className="c-valgt-features__dt">
                    Startdato:
                </dt>,
                <dd
                    key={'startdate-value_' + vegobjekt.id}
                    className="c-valgt-features__dd c-valgt-features-dd"
                >
                    {vegobjekt.metadata.startdato}
                </dd>,
            ]}
            {vegobjekt?.metadata?.sluttdato && [
                <dt key={'enddate-header_' + vegobjekt.id} className="c-valgt-features__dt">
                    Sluttdato:
                </dt>,
                <dd
                    key={'enddate-value_' + vegobjekt.id}
                    className="c-valgt-features__dd c-valgt-features-dd"
                >
                    {vegobjekt.metadata.sluttdato}
                </dd>,
            ]}
            <dt className="c-valgt-features__dt">Objektid:</dt>
            <dd className="c-valgt-features__dd">
                <a
                    href={vegobjekt.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-description="Åpne vegobjekt i api"
                >
                    {vegobjekt.id}
                </a>
            </dd>
        </>
    ) : null;
}

export default Metadata;

import { ActionType, PreferencesAction } from '@/actions/actiontypes';
import { PreferencesState } from '@/state';

const initialState: PreferencesState = new PreferencesState();

export const preferencesReducer = (state: PreferencesState = initialState, action: PreferencesAction) => {
    switch (action.type) {
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.preferences;
        case ActionType.PREFERENCES_UPDATE:
            return state.override(action);
        case ActionType.PREFERENCES_RESET:
            return new PreferencesState();
        default:
            return state;
    }
};

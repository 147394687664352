import * as React from 'react';

export const Logo = () => {
    const isChristmasTime = new Date().getUTCMonth() == 11 ? true : false;
    return (
        <header>
            <a
                className="c-map__header t-hidden-mobile"
                role="link"
                href={'/'}
                title={'Til forsiden'}
                id={'map_main_title'}
                aria-label={'Til forsiden av Vegkart'}
                tabIndex={0}
            >
                <div className="c-map__header-link">Til forsiden</div>
                <h1 className="c-map__header-text">VEGKART</h1>
                {isChristmasTime && <div className="c-map__header-holiday-img t-hidden-mobile" />}
            </a>
        </header>
    );
};

import { MousePosition as OlMousePosition } from 'ol/control';
import { useEffect } from 'react';
import { useMap } from '@/state/context/MapContext';

const formatCoords = (coords: number[], srid: number, reverse: boolean) => {
    const UTMprefix = 'EU89 UTM';
    coords = reverse ? coords.map(c => Math.round(c)).reverse() : coords.map(c => Math.round(c));

    switch (srid) {
        case 25833:
            return `${UTMprefix}33 ${Math.round(coords[0])}N, ${Math.round(coords[1])}Ø`;
        default:
            return `${coords[0]}, ${coords[1]}`;
    }
};

export const MousePosition = () => {
    const { map } = useMap();
    const projectionSRID = parseInt(map.getView().getProjection().getCode().split(':')[1]);

    useEffect(() => {
        const control = new OlMousePosition({ coordinateFormat: c => formatCoords(c, projectionSRID, true) });
        map.addControl(control);
        return () => {
            map.removeControl(control);
        };
    }, [map, projectionSRID]);

    return null;
};

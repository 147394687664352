import * as React from 'react';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import Toast from '../../objects/Toast';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from '@/state/store';
import { stateMap, stateOmrader, stateSearchDropdownState, stateVegnett } from '@/selectors/selectors';
import urlJoin from 'url-join';

const useDownloadUrls = (restapi_url: string, eksport_url: string) => {
    const areaState = useSelector(stateOmrader);
    const areaQueries = areaState.toQueries();
    const mapQueries = useSelector(stateMap).toQueries();
    const roadNetQueries = useSelector(stateVegnett).toQueries();
    const timestamp = useSelector(stateSearchDropdownState).timestamp;
    const params = {
        ...mapQueries.toParams(),
        ...areaQueries.toParams(),
        ...roadNetQueries.withTidspunkt(timestamp).toParams(),
    };
    const base = `/vegnett/veglenkesekvenser/segmentert`;
    const hasOmradeWithGeometry =
        !!params.fylke || !!params.kommune || !!params.kontraktsomrade || !!params.riksvegrute;
    const query =
        '?' +
        Object.keys(params)
            .filter(key => !hasOmradeWithGeometry || (hasOmradeWithGeometry && key !== 'kartutsnitt'))
            .map(key => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

    const apiParam = `&api=${encodeURIComponent(restapi_url)}`;
    const charsetParam = '&tegnsett=utf-8';
    const sosiutsnittParam = !query.includes('kartutsnitt')
        ? '&sosiutsnitt=' +
          kartutsnittFromWktStrings(
              ...areaState
                  .withVegsystemreferanser([])
                  .getFullList()
                  .map(omrade => omrade?.vegobjekt?.geometri?.wkt)
                  .filter(e => !!e)
          ).toQueryString()
        : '';
    return {
        json: urlJoin(restapi_url, base, query),
        sosi: urlJoin(eksport_url, base + '.sos', query + charsetParam + apiParam + sosiutsnittParam),
        csv: urlJoin(eksport_url, base + '.csv', query + apiParam),
    };
};
export const DownloadResultsBox = () => {
    const { restapi_url, eksport_url } = useContext(ConfigAndDataContext).config;
    const { json, sosi, csv } = useDownloadUrls(restapi_url, eksport_url);
    const [downloading, setDownloading] = useState(false);
    const start = useCallback(() => setDownloading(true), []);

    useEffect(() => {
        if (!downloading) return;
        const timeout = setTimeout(() => {
            setDownloading(false);
        }, 5000);
        return () => {
            clearTimeout(timeout);
        };
    }, [downloading]);
    return (
        <div className="c-results-summary t-hidden-mobile">
            <div className="c-results-summary__export c-results-summary-export">
                <div className="c-results-summary-export__links c-results-summary-export-links t-clearfix">
                    <i className="o-icon-download c-results-summary__icon-download" aria-hidden={true} />
                    <label className="c-results-summary__text">Last ned datasett:</label>
                    <a
                        id="csv-link"
                        key="csv-link"
                        className="c-results-summary-export-links__csv"
                        title={'Last ned som CSV'}
                        href={csv}
                        onClick={start}
                    >
                        CSV
                    </a>
                    <a
                        id="sosi-link"
                        key="sosi-link"
                        className="c-results-summary-export-links__sosi disabled"
                        aria-disabled={true}
                        title={'Last ned som SOSI'}
                        href={sosi}
                        onClick={start}
                    >
                        SOSI
                    </a>
                    <a
                        key="api-link"
                        className="c-results-summary-export-links__api"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={json}
                    >
                        API
                    </a>
                </div>
            </div>
            <Toast text={'Nedlasting startet ...'} show={downloading} />
        </div>
    );
};

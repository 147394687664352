import * as React from 'react';

export const LogoButton = () => {
    return (
        <div className="c-map__logo">
            <a className="o-btn-square c-map__logo-link" href="/" title="Tilbake til start">
                <i className="o-icon-svv-logo-mini c-map__logo-icon" />
            </a>
        </div>
    );
};

import { Stedfesting } from '@/domain/vegobjekter/Stedfesting';
import { SuperStedfesting } from '@/domain/vegnett/SuperStedfesting';
import { Innhold, VegobjekttypeStedfesting } from '@/domain/vegobjekter/Vegobjekttype';

export function transformVegobjekttypeStedfesting(data: unknown): VegobjekttypeStedfesting | undefined {
    return data
        ? new VegobjekttypeStedfesting(
              data['id'],
              data['navn'],
              data['kortnavn'],
              data['kategori'],
              data['egenskapstype'],
              data['datatype'],
              data['sensitivitet'],
              data['sorteringsnummer'],
              data['viktighet'],
              data['skrivebeskyttet'],
              data['nøyaktighetskrav_grunnriss'],
              data['høydereferanse_tall'],
              transformStedfestingInnhold(data['innhold'])
          )
        : null;

    function transformStedfestingInnhold(innholdData: unknown) {
        return innholdData
            ? new Innhold(
                  innholdData['id'],
                  innholdData['navn'],
                  innholdData['kortnavn'],
                  innholdData['kategori'],
                  innholdData['geometritype']
              )
            : null;
    }
}

export function transformVegobjektStedfesting(data: unknown) {
    if (!data) return null;
    return new Stedfesting(
        data['veglenkesekvensid'],
        data['kortform'],
        data['startposisjon'] === undefined ? data['relativPosisjon'] : data['startposisjon'],
        data['sluttposisjon'] === undefined ? data['relativPosisjon'] : data['sluttposisjon'],
        data['retning'],
        data['sideposisjon'],
        data['kjørefelt'],
        data['type']
    );
}

export function transformSuperstedfesting(data: unknown): SuperStedfesting {
    if (!data) return null;
    return new SuperStedfesting(
        data['veglenkesekvensid'],
        data['startposisjon'] === undefined ? data['relativPosisjon'] : data['startposisjon'],
        data['sluttposisjon'] === undefined ? data['relativPosisjon'] : data['sluttposisjon'],
        data['relativPosisjon'],
        data['retning'],
        data['sideposisjon'],
        data['kjørefelt'],
        data['kortform']
    );
}

import { useState, useEffect, useRef } from 'react';
import { SyntheticEvent } from 'react';
import { numericSortComparator } from '@/utils/Utils';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import SimpleToggle from '../../../objects/SimpleToggle';
import TooltipWrapper from '@/components/common/TooltipWrapper';

interface TagProps {
    interval: number[];
    absoluteIntervals: boolean;
    egenskapstype: Egenskapstype;
    setInterval: (interval: number[], absoluteIntervals: boolean) => void;
}

const CategoryInterval = ({
    interval: initialInterval,
    absoluteIntervals: initialAbsoluteIntervals,
    setInterval,
}: TagProps) => {
    const firstFocusableRef = useRef<HTMLInputElement>(null);
    const [intervalInputValue, setIntervalInputValue] = useState<string>(initialInterval?.join(' '));
    const [interval, setIntervalState] = useState<number[]>(initialInterval || []);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [absoluteIntervals, setAbsoluteIntervals] = useState<boolean>(initialAbsoluteIntervals);

    useEffect(() => {
        firstFocusableRef.current?.focus();
    }, []);

    useEffect(() => {
        setIntervalInputValue(initialInterval?.join(' '));
        firstFocusableRef.current?.focus();
    }, [initialInterval]);

    const toggleAbsoluteValues = () => {
        setAbsoluteIntervals(!absoluteIntervals);
        validate(intervalInputValue);
    };

    const handleSetInterval = (event: SyntheticEvent) => {
        event.preventDefault();
        if (interval.length > 0 && errorMessages.length === 0) {
            const sortedInterval = parseIntervalValue(intervalInputValue).sort(numericSortComparator);
            setIntervalState(sortedInterval);
            setIntervalInputValue(sortedInterval.toString());
            setInterval(sortedInterval, absoluteIntervals);
        }
    };

    const parseIntervalValue = (intervalValue: string): number[] => {
        if (!intervalValue) return [];
        return intervalValue
            .split(' ')
            .filter(v => v !== '')
            .map(v => parseFloat(v.replace(',', '.')));
    };

    const validate = (intervalValue: string) => {
        const values = parseIntervalValue(intervalValue);
        const newErrorMessages = [];
        const hasInvalidNumbers = values.some(v => isNaN(v));
        if (hasInvalidNumbers) {
            newErrorMessages.push('Kan bare inneholde positive tall');
        }
        const sorted = values.slice().sort(numericSortComparator);

        if (absoluteIntervals && sorted[0] < 0) {
            newErrorMessages.push("Kan bare inneholde positive tall dersom 'Bru' er valgt");
        }

        setErrorMessages(newErrorMessages);
        setIntervalState(values);
        setIntervalInputValue(intervalValue);
    };

    return (
        <div className="c-category-interval">
            <label>Intervaller</label>
            <form className="c-category-interval__form c-category-interval-form" onSubmit={handleSetInterval}>
                <div>
                    <i className="o-icon-signal c-category-interval-form__icon" />
                    <input
                        type="text"
                        value={intervalInputValue}
                        className="c-category-interval-form__interval-input"
                        placeholder="Eksempel 10 50 100"
                        onChange={e => validate(e.target.value)}
                        ref={firstFocusableRef}
                        aria-label="Intervaller"
                        id="interval_intervaller"
                        aria-description="Angi intervaller å kategorisere med"
                    />
                    <input
                        type="submit"
                        value="Søk"
                        id="interval_form_submit"
                        className="c-category-interval-form__submit-input"
                        disabled={interval.length > 0 && errorMessages.length !== 0}
                    />
                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <SimpleToggle
                        checked={absoluteIntervals}
                        label={'Bruk absolutte verdier'}
                        onChange={toggleAbsoluteValues}
                    />
                    <TooltipWrapper content='Ved aktivering av absoluttverdier kategoriseres både positive og negative tall innfor samme intervall. F. eks. vil intervallet "100 - 250" ta med både "-250 - -100" og "100 - 250".'>
                        <i
                            className="material-icons"
                            style={{
                                fontSize: '16px',
                                margin: '0',
                                padding: '0',
                                top: '10',
                                cursor: 'pointer',
                            }}
                        >
                            help_outline
                        </i>
                    </TooltipWrapper>
                </div>
            </form>
            {errorMessages.length > 0 && (
                <ul className="c-category-interval__error-message-list c-category-interval-error-message-list">
                    {errorMessages.map(e => (
                        <li key={e} className="c-category-interval-error-message-list__element o-error">
                            {e}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CategoryInterval;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as uuidgen from 'uuid';
import { Config } from '@/bootstrap/config';
import { Vegsystemreferanse } from '@/domain/omrader';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { Node } from '@/domain/vegnett/Node';
import {
    CommonQueries,
    inkluderBar,
    MapQueries,
    OmraderQueries,
    VegnettQueries,
    VegobjekterQueries,
} from '@/middleware/queries';
import { Severity, VegkartError, VegsystemreferanseLookup } from '@/state';
import { isToday, isUrl, tryTransform } from '@/utils/Utils';
import {
    EksportChangelog,
    ServerStatusResponse,
    StedsnavnResponse,
    VegnettResponse,
    VegobjekterStatisticsResponse,
    VegobjektResponse,
} from './responses';
import {
    transformNode,
    transformRoadReferenceLookup,
    transformSegmentertVegnettResponse,
    transformServerStatus,
    transformStedsnavnResponse,
    transformVegnettlenkesekvens,
    transformVegobjekt,
    transformVegobjektArray,
    transformVegobjekterStatistics,
    transformVegobjektResponse,
    transformVegobjekttype,
    transformVegsystemreferanseGeometri,
} from './transformers';
import { ActionType } from '@/actions/actiontypes';
import { storageHasToken, SVVTOKENS_KEY } from '@/utils/AuthenticationUtils';
import { WKTObject } from '@/domain/WKTObject';
import { groupWkts } from '@/utils/SpatialHelper';

export function getHeaders(clientSessionId: string) {
    return {
        Accept: 'application/vnd.vegvesen.nvdb-v3-rev1+json, application/json',
        'X-Client': 'Vegkart',
        'X-Client-Session': clientSessionId,
    };
}

export function getHeadersWithAuth(clientSessionId: string) {
    return {
        Authorization: getClientAuthToken(),
        Accept: 'application/vnd.vegvesen.nvdb-v3-rev1+json, application/json',
        'X-Client': 'Vegkart',
        'X-Client-Session': clientSessionId,
    };
}

function getClientAuthToken(): string {
    const tokensAsString = localStorage.getItem(SVVTOKENS_KEY);
    const tokens = JSON.parse(tokensAsString);
    return `Bearer ${tokens.idToken}`;
}

export function getClientSessionId(): string {
    let clientSessionId = sessionStorage.getItem('clientSessionId');
    if (!clientSessionId) {
        clientSessionId = uuidgen.v4();
        sessionStorage.setItem('clientSessionId', clientSessionId);
    }
    return clientSessionId;
}
function doFetch(url) {
    return fetch(url.toString(), {
        headers: storageHasToken()
            ? getHeadersWithAuth(getClientSessionId())
            : getHeaders(getClientSessionId()),
        mode: 'cors',
    });
}

export interface ApiErrorMessage {
    code: number;
    message: string;
    message_detailed?: string;
    help_url: string;
}

export class ApiError extends Error {
    private __proto__: ApiError;
    constructor(readonly msg: ApiErrorMessage) {
        super(msg.message);
        this.name = this.constructor.name;
        this.__proto__ = ApiError.prototype;
    }
}

export class AuthenticationError extends Error {
    private __proto__: AuthenticationError;
    constructor(msg: string) {
        super(msg);
        this.name = this.constructor.name;
        this.__proto__ = AuthenticationError.prototype;
    }
}

interface LogEntry {
    session: string;
    message: string;
    data: object;
    hash: string;
    severity: Severity;
    agent: string;
}

const LOG_DELAY_CUTOFF = 5000; // Time in ms to check against when deciding if a performance log entry should be made.

export class Server {
    private readonly apiURL: string;
    private axios: AxiosInstance;
    private SSRIAxios: AxiosInstance;
    private cacheName = 'vegkart-cache';
    private clientSessionId: string = getClientSessionId();
    private readonly splunk?: { token: string; url: string; index: string } = null;
    private readonly eksport_url: string;
    private readonly changelogUrl: string;

    constructor(private config: Config) {
        this.apiURL = config.restapi_url;
        this.axios = axios.create({
            baseURL: config.restapi_url,
            headers: getHeaders(this.clientSessionId),
            timeout: config.ajaxTimeout,
        });
        this.SSRIAxios = axios.create({
            baseURL: config.url_SSRI,
            timeout: config.ajaxTimeout,
        });
        this.eksport_url = config.eksport_url;
        this.changelogUrl = config.changelog_url;

        // remove existing cache on startup
        if ('caches' in window) {
            // noinspection JSIgnoredPromiseFromCall
            caches.delete(this.cacheName);
        }

        // Only use splunk in development

        if (isUrl(config.splunk_url) && !!config.splunk_token)
            this.splunk = { url: config.splunk_url, token: config.splunk_token, index: config.splunk_index };
    }

    // ----- Meta-methods begin ----- //
    getServerStatus(): Promise<ServerStatusResponse> {
        return new Promise<ServerStatusResponse>((resolve, reject) => {
            this.axios
                .get('status', {
                    transformResponse: tryTransform(transformServerStatus),
                })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    private dispatchLog(event: LogEntry): Promise<Response> {
        if (this.splunk) {
            const { token, url, index } = this.splunk;
            return this.axios.post(
                url,
                {
                    host: window.location.host,
                    source: 'vegkart-log',
                    sourcetype: 'vegkart-log',
                    index,
                    event,
                },
                { headers: { Authorization: `Splunk ${token}` } }
            );
        }
    }

    postErrorReport(error: VegkartError) {
        const logEntry: LogEntry = {
            data: { params: error.params, stack: error.stack },
            hash: window.location.hash,
            message: error.message,
            session: this.clientSessionId,
            severity: error.severity,
            agent: window.navigator.userAgent,
        };
        return this.dispatchLog(logEntry);
    }

    postInfoLogEntry(message: string, data?: Record<string, string | number>) {
        return this.dispatchLog({
            message,
            session: this.clientSessionId,
            hash: window.location.hash,
            severity: Severity.INFO,
            agent: window.navigator.userAgent,
            data: { ...data },
        });
    }

    postPerformanceLogEntry(
        action: ActionType,
        milliseconds: number,
        additionalData?: Record<string, string | number>
    ) {
        if (milliseconds > LOG_DELAY_CUTOFF) {
            const entry: LogEntry = {
                message: 'Slow action detected!',
                session: this.clientSessionId,
                hash: window.location.hash,
                severity: Severity.INFO,
                agent: window.navigator.userAgent,
                data: {
                    action: ActionType[action],
                    milliseconds,
                    ...additionalData,
                },
            };

            const isDevelopment = process.env.NODE_ENV === 'development';
            if (isDevelopment) {
                console.group('Performance Log Entry');
                console.info(entry);
                console.groupEnd();
            } else {
                return this.dispatchLog(entry);
            }
        }
    }

    // ----- Meta-methods end ----- //

    // ----- Vegobjekt-methods begin ----- //
    getVegobjekttype(typeId: number): Promise<Vegobjekttype> {
        return new Promise<Vegobjekttype>((resolve, reject) => {
            this.axios
                .get(`/vegobjekttyper/${typeId}?inkluder=alle`, {
                    transformResponse: tryTransform(transformVegobjekttype),
                })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    getVegobjekt(
        vegobjektId: number,
        typeId?: number,
        version?: number,
        includeParam = 'lokasjon,metadata,egenskaper,relasjoner,geometri'
    ): Promise<Vegobjekt> {
        const url = typeId
            ? version
                ? `/vegobjekter/${typeId}/${vegobjektId}/${version}`
                : `/vegobjekter/${typeId}/${vegobjektId}`
            : `vegobjekt`;

        const params = typeId
            ? {
                  dybde: includeParam.includes('relasjoner') ? 1 : undefined, // dybde requires relasjoner
                  inkluder: includeParam,
              }
            : {
                  id: vegobjektId,
              };
        return new Promise<Vegobjekt>((resolve, reject) => {
            this.axios
                .get(url, {
                    params,
                    transformResponse: tryTransform(transformVegobjekt),
                })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    // This function is sometimes used as a preliminary step to find the latest version of a vegobjekt
    // because of redirect-issues in the Edge browser.
    getVegobjektVersionsMinimal(
        vegobjektId: number,
        typeId: number,
        includeParam = 'metadata'
    ): Promise<Vegobjekt[]> {
        const url = `/vegobjekter/${typeId}/${vegobjektId}/versjoner`;

        const params = {
            inkluder: includeParam,
        };

        return new Promise<Vegobjekt[]>((resolve, reject) => {
            this.axios
                .get(url, {
                    params,
                    transformResponse: tryTransform(transformVegobjektArray),
                })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    getVegobjekter(
        typeId: number,
        commonQueries: CommonQueries,
        mapQueries: MapQueries,
        omraderQueries: OmraderQueries,
        vegnettQueries: VegnettQueries,
        vegobjekterQueries: VegobjekterQueries,
        timestamp: Date
    ): Promise<VegobjektResponse> {
        const inkluder: inkluderBar[] = Array.from(
            new Set([...vegobjekterQueries.inkluder, 'metadata', 'lokasjon', 'geometri'])
        );
        let allParams = {
            ...commonQueries.toParams(),
            ...mapQueries.toParams(),
            ...omraderQueries.toParams(),
            ...vegnettQueries.toParams(),
        };
        if (timestamp == null || isToday(timestamp))
            allParams = {
                ...allParams,
                ...vegobjekterQueries.withInkluder(inkluder).toParams(),
            };
        else
            allParams = {
                ...allParams,
                ...vegobjekterQueries.withInkluder(inkluder).withTidspunkt(timestamp).toParams(),
            };
        return new Promise<VegobjektResponse>((resolve, reject) => {
            this.axios
                .get(`/vegobjekter/${typeId}`, {
                    params: allParams,
                    transformResponse: tryTransform(transformVegobjektResponse),
                })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    getVegobjekterStatistics(
        typeId: number,
        mapQueries: MapQueries,
        omraderQueries: OmraderQueries,
        vegnettQueries: VegnettQueries,
        vegobjekterQueries: VegobjekterQueries,
        timestamp: Date
    ): Promise<VegobjekterStatisticsResponse> {
        const url = new URL(`${this.apiURL}/vegobjekter/${typeId}/statistikk`);
        let allParams = {
            ...mapQueries.toParams(),
            ...omraderQueries.toParams(),
            ...vegnettQueries.toParams(),
        };
        if (timestamp == null || isToday(timestamp))
            allParams = { ...allParams, ...vegobjekterQueries.toParams() };
        else allParams = { ...allParams, ...vegobjekterQueries.withTidspunkt(timestamp).toParams() };

        Object.keys(allParams).forEach(key => url.searchParams.append(key, allParams[key]));

        return this.tryCache(url)
            .then(async response => {
                if (response.status === 422) throw new ApiError(await response.json());
                else if (response.status === 404) {
                    const body = await response.json().then(b => (b instanceof Array ? b[0] : b));
                    if (body.code === 4015) throw new ApiError(body);
                } else if (response.status === 403) throw new AuthenticationError(await response.text());
                return response.text();
            })
            .then(tryTransform(transformVegobjekterStatistics));
    }

    /**
     * Check if URL is cached, otherwise perform request and cache the result.
     * Falls back to plain fetch if cache is unsupported
     */
    async tryCache(url: URL): Promise<Response> {
        if ('caches' in window) {
            const cache = await caches.open(this.cacheName);
            let response = await cache.match(url.toString());
            if (!response) {
                response = await doFetch(url.toString());
                await cache.put(url.toString(), response.clone());
            }
            return response;
        } else return doFetch(url.toString());
    }
    // ----- Vegobjekt-methods end ----- //

    // ----- Vegnett-methods begin ----- //

    getVeglenkesekvens(veglenkesekvensId, historisk?): Promise<Vegnettlenke[]> {
        return new Promise<Vegnettlenke[]>((resolve, reject) => {
            this.axios
                .get(
                    `/vegnett/veglenkesekvenser/segmentert/${veglenkesekvensId}${
                        historisk ? '?historisk=true' : ''
                    }`
                )
                .then(response => tryTransform(transformVegnettlenkesekvens)(JSON.stringify(response.data)))
                .then(resolve)
                .catch((e: AxiosError) => (e.response?.status === 404 ? reject(404) : reject(e)));
        });
    }

    getVeg(vegsystemreferanse: Vegsystemreferanse): Promise<WKTObject> {
        const params = {
            vegsystemreferanse: vegsystemreferanse.toParams(),
        };
        return this.axios
            .get(`/veg`, { params })
            .then(response =>
                tryTransform(transformVegsystemreferanseGeometri)(JSON.stringify(response.data))
            )
            .catch(e => {
                console.warn('throwing an error from getVeg!');
                if (e.response?.status === 404) throw 404;
                else throw e;
            });
    }

    private getVegobjekttypeIdFromVegsystemreferanse(vegsystemreferanse: Vegsystemreferanse): number {
        if (vegsystemreferanse.sideanlegg) return 920;
        if (vegsystemreferanse.kryssystem) return 918;
        return 916;
    }

    getGeometryOnStrekning(vegsystemreferanse: Vegsystemreferanse): Promise<WKTObject> {
        const params = {
            vegsystemreferanse: vegsystemreferanse.toParams(),
            kommune: vegsystemreferanse.kommune ?? undefined,
        };

        const typeId = this.getVegobjekttypeIdFromVegsystemreferanse(vegsystemreferanse);
        return this.axios
            .get(`/vegobjekter/${typeId}?inkluder=geometri`, { params })
            .then(response => response.data.objekter.map((o: { geometri: WKTObject }) => o.geometri))
            .then(groupWkts)
            .catch(e => {
                console.warn('throwing an error from getGeometryOnStrekning!');
                if (e.response?.status === 404) throw 404;
                else throw e;
            });
    }

    getGeometryOnVegnettsegment(vegsystemreferanse: Vegsystemreferanse): Promise<WKTObject> {
        const params = {
            vegsystemreferanse: vegsystemreferanse.toParams(),
            kommune: vegsystemreferanse.kommune ?? undefined,
        };
        return this.axios
            .get(`/vegnett/veglenkesekvenser/segmentert?`, { params })
            .then(response => response.data.objekter.map((o: { geometri: WKTObject }) => o.geometri))
            .then(groupWkts)
            .catch(e => {
                console.warn('throwing an error from getGeometryOnVegnettsegment!');
                if (e.response?.status === 404) throw 404;
                else throw e;
            });
    }

    getNode(id: number): Promise<Node> {
        return this.axios
            .get(`/vegnett/noder/${id}`)
            .then(response => tryTransform(transformNode)(JSON.stringify(response.data)))
            .catch(e => {
                if (e.response?.status === 404) throw 404;
                else throw e;
            });
    }

    getSegmentertVegnett(
        commonQueries: CommonQueries,
        mapQueries: MapQueries,
        omraderQueries: OmraderQueries,
        vegnettQueries: VegnettQueries,
        timestamp: Date
    ): Promise<VegnettResponse> {
        const allParams = {
            ...commonQueries.toParams(),
            ...mapQueries.toParams(),
            ...omraderQueries.toParams(),
            ...vegnettQueries.withTidspunkt(timestamp).toParams(),
        };
        return this.axios
            .get('/vegnett/veglenkesekvenser/segmentert', { params: allParams })
            .then(response =>
                tryTransform(transformSegmentertVegnettResponse)(JSON.stringify(response.data))
            );
    }
    // ----- Vegnett-methods end ----- //

    // ----- Coordinate- and placename-methods begin ----- //
    getVSRFromCoordinate(coordinate: [number, number]): Promise<VegsystemreferanseLookup> {
        return this.axios
            .get('posisjon', {
                params: {
                    maks_avstand: 200,
                    nord: coordinate[1],
                    ost: coordinate[0],
                },
            })
            .then(response => tryTransform(transformRoadReferenceLookup)(JSON.stringify(response.data)))
            .catch(
                (e: AxiosError) =>
                    new Promise((resolve, reject) => {
                        e.response?.status === 404 ? reject(404) : reject(e);
                    })
            );
    }

    getCoordinateFromVegsystemreferanse(
        roadsysref: string,
        municipality?: number
    ): Promise<VegsystemreferanseLookup | VegkartError> {
        return new Promise<VegsystemreferanseLookup | VegkartError>((resolve, reject) => {
            this.axios
                .get('veg', {
                    params: {
                        kommune: municipality,
                        vegsystemreferanse: roadsysref,
                    },
                })
                .then(response => tryTransform(transformRoadReferenceLookup)(JSON.stringify(response.data)))
                .then(resolve)
                .catch((e: AxiosError) => (e.response?.status === 404 ? reject(404) : reject(e)));
        });
    }

    getStedsnavn(query: string): Promise<StedsnavnResponse> {
        const queryParams = {
            sok: query.replace(/[;?"']/g, '') + '*',
            utkoordsys: 25833,
            filtrer:
                'metadata.totaltAntallTreff,navn.stedsnavn,navn.stedsnummer,navn.kommuner,navn.fylker,navn.representasjonspunkt,navn.navneobjekttype',
        };
        return new Promise<StedsnavnResponse>((resolve, reject) => {
            this.SSRIAxios.get('/sted', {
                params: queryParams,
                transformResponse: tryTransform(transformStedsnavnResponse),
            })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }
    // ----- Coordinate- and placename-methods end ----- //

    setSVVToken(idToken?: string) {
        if (idToken) this.axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
        else delete this.axios.defaults.headers.common['Authorization'];
    }

    async getExportChangelog(): Promise<EksportChangelog> {
        const response = await this.tryCache(new URL('/changelog/latest', this.eksport_url));
        return (await response.json()) as Promise<EksportChangelog>;
    }

    async getChangelog(): Promise<string> {
        const response = await fetch(this.changelogUrl);
        const json = await response.json();
        return b64DecodeUnicode(json['content']).split('\n').slice(2).join('\n');
    }

    async preauthorizeExportRequest(url: string): Promise<string> {
        const response = await doFetch(url);
        return response.text();
    }

    // ----- Bootstrap methods end ----- //
}

function b64DecodeUnicode(str) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
}

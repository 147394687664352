import { connect } from 'react-redux';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import * as selectors from '../../../selectors/selectors';
import { RootState } from '@/state';
import { kartutsnittToExtent } from '@/utils/SpatialHelper';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';
import { zoomToExtent, zoomToExtentT } from '@/actions/mapActions';

interface StateProps {
    extent: Kartutsnitt;
}

interface DispatchProps {
    zoomToExtent: zoomToExtentT;
}

type Props = StateProps & DispatchProps;

class ZoomToExtentHandler extends React.Component<Props, {}> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    componentDidMount() {
        if (this.props.extent) {
            this.context.map.getView().fit(kartutsnittToExtent(this.props.extent));
        }
    }

    componentDidUpdate() {
        if (this.props.extent) {
            this.context.map.getView().fit(kartutsnittToExtent(this.props.extent), { duration: 300 });
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        extent: selectors.stateZoomToExtent(state),
    };
};
const mapDispatchToProps = {
    zoomToExtent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoomToExtentHandler);

import Overlay from 'ol/Overlay';
import React, { useEffect, useMemo, useState } from 'react';
import { useMap } from '@/state/context/MapContext';

export const enum HelpTooltipText {
    START = 'Trykk for å start en måling',
    CONTINUE = 'Dobbelklikk for å avslutte måling',

    START_POLYGON = 'Trykk for å start en tegning',
    END_POLYGON = 'Dobbelklikk for å avslutte polygon',
}
interface HelpTooltipProps {
    tip: HelpTooltipText;
}
export const HelpTooltip = ({ tip }: HelpTooltipProps) => {
    const { map } = useMap();
    const [showHelp, setShowHelp] = useState(true);
    const overlay = useMemo(() => {
        const o = new Overlay({
            stopEvent: false,
            insertFirst: false,
            offset: [15, 0],
            positioning: 'center-left',
        });
        map.addOverlay(o);
        return o;
    }, [map]);

    useEffect(() => {
        const viewPort = map.getViewport();
        const showHelpEvent = () => setShowHelp(true);
        const hideHelpEvent = () => setShowHelp(false);
        viewPort.addEventListener('mouseleave', hideHelpEvent);
        viewPort.addEventListener('mouseenter', showHelpEvent);
        const pointerMoveHandler = function (evt) {
            if (evt.dragging) return;
            overlay.setPosition(evt.coordinate);
        };
        map.on('pointermove', pointerMoveHandler);
        return () => {
            viewPort.removeEventListener('mouseleave', hideHelpEvent);
            viewPort.removeEventListener('mouseenter', showHelpEvent);
            map.removeEventListener('pointermove', pointerMoveHandler);
        };
    }, [map, overlay]);

    return (
        <div
            id={'measure-help-tooltip'}
            ref={r => overlay.setElement(r)}
            className={`c-map-tooltip ${showHelp ? '' : 't-hidden'}`}
        >
            {tip}
        </div>
    );
};

import { Datakatalog } from '@/bootstrap/data';
import { VegobjektEgenskapKvalitetAttributt } from '@/domain/vegobjekter/Vegobjekt';
import { interpretQuality } from '@/utils/Utils';
import React from 'react';

export default function renderKvalitet(
    quality: VegobjektEgenskapKvalitetAttributt[],
    datakatalog: Datakatalog
): JSX.Element {
    const hiddenQualities = new Set<string>(['maksimaltAvvik']);
    return (
        <>
            {quality
                .filter(k => !hiddenQualities.has(k.name))
                .sort(VegobjektEgenskapKvalitetAttributt.comparator)
                .map(entry => (
                    <React.Fragment key={`fragment${entry.name}`}>
                        <dt className="c-valgt-features__dt" title={entry.displayName}>
                            {entry.displayName}
                        </dt>
                        <dd className="c-valgt-features__dd">{interpretQuality(entry, datakatalog)}</dd>
                    </React.Fragment>
                ))}
            <hr key="quality__separator" />
        </>
    );
}

import React from 'react';
import { Veglenketype, VegnettParameter } from '@/middleware/queries';
import { TriStateToggle } from '../../objects/TriStateToggle';
import { VegnettFilterOption } from './FilterOptions';

const VEGLENKETYPE_OPTIONS: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.VEGLENKETYPE, null, 'Konnektering'),
];

interface Props {
    veglenketype: Veglenketype;
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) => void;
}

export const VeglenketypeOptions = ({ veglenketype, handleFilterChange }: Props) => {
    return (
        <>
            {VEGLENKETYPE_OPTIONS.map(option => (
                <TriStateToggle
                    key={option.paramType}
                    checked={veglenketype === Veglenketype.KONNEKTERING}
                    indeterminate={veglenketype === Veglenketype.NOT_SET}
                    label={option.label}
                    contentList={['Uten', '', 'Med']}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFilterChange(e, new VegnettFilterOption(option.paramType, veglenketype, ''))
                    }
                />
            ))}
        </>
    );
};

import {
    AdskilteLop,
    Detaljniva,
    Trafikantgruppe,
    TrinaryQueryParam,
    TypeVeg,
    Veglenketype,
    VegnettParameter,
} from '@/middleware/queries';

export type VegnettOptionType =
    | Trafikantgruppe
    | Veglenketype
    | TypeVeg
    | Detaljniva
    | AdskilteLop
    | TrinaryQueryParam;

export class VegnettFilterOption {
    constructor(
        readonly paramType: VegnettParameter,
        readonly optionType: VegnettOptionType,
        readonly label: string
    ) {}
}

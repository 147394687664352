import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors/selectors';
import { RootState, UserState, ValgtState, VegobjekttypeState } from '@/state';
import { ClusterComponent } from './ClusterComponent';
import { VeglenkeComponent } from './VeglenkeComponent';
import { VegobjektComponent } from './VegobjektComponent';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { VeglenkerComponent } from './VeglenkerComponent';
import { NodeComponent } from './NodeComponent';
import { resolveColor } from '@/utils/ColorHelper';

interface StateProps {
    selection: ValgtState;
    vegobjekttyperInSearch: VegobjekttypeState[];
    user: UserState;
}

type Props = StateProps;

class ValgtContainer extends React.Component<Props, undefined> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;
    render() {
        const selection = this.props.selection;
        if (!selection) return null;
        const colorMap = this.props.vegobjekttyperInSearch.reduce(
            (map, vegobjekttypestate) => ({
                ...map,
                [vegobjekttypestate.typeId]: resolveColor(vegobjekttypestate.color),
            }),
            {} as Record<number, string>
        );
        return (
            <>
                {selection.vegobjekter.map(v => (
                    <VegobjektComponent
                        key={v.id}
                        user={this.props.user}
                        color={selection.color}
                        vegobjekt={v}
                        vegobjekttyperInSearch={this.props.vegobjekttyperInSearch}
                        datakatalog={this.context.data.datakatalog}
                    />
                ))}
                {selection.isSingle() && selection.singleVegnettlenke() && (
                    <VeglenkeComponent
                        color={selection.color}
                        vegnettlenke={selection.singleVegnettlenke()}
                        datakatalog={this.context.data.datakatalog}
                    />
                )}
                {selection.isCluster() && <ClusterComponent colors={colorMap} selectedFeatures={selection} />}
                {selection.nodes.map(node => (
                    <div
                        key={node.id}
                        className="c-valgt"
                        tabIndex={-1}
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <NodeComponent node={node} />
                    </div>
                ))}
                {Object.entries(selection.linkQueries).length > 0 &&
                    Object.entries(selection.linkQueries).map(([query, links], index) => (
                        <div
                            key={query}
                            className="c-valgt"
                            tabIndex={-1}
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <VeglenkerComponent links={links} queryString={query} colorIndex={index} />
                        </div>
                    ))}
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    selection: selectors.stateValgt(state),
    user: selectors.stateUser(state),
    vegobjekttyperInSearch: selectors.stateSelectedRoadObjectTypes(state),
});

export default connect(mapStateToProps)(ValgtContainer);

import { isToday } from '@/utils/Utils';
import { Egenskapsfilter } from './Egenskapsfilter';
import { OmraderQueries } from './OmraderQueries';
import { OperatorE } from './Operator';

import { format } from 'date-fns';

export type filter = Egenskapsfilter | OmraderQueries;
export type inkluderBar =
    | 'metadata'
    | 'egenskaper'
    | 'relasjoner'
    | 'lokasjon'
    | 'vegsegmenter'
    | 'geometri'
    | 'alle'
    | 'lengde';

export class VegobjekterQueries {
    constructor(
        readonly segmentering: boolean = null,
        readonly egenskaper: Egenskapsfilter[] = [], // filters added as filters in UI
        readonly kategorifilter: Egenskapsfilter[] = [], // filters used for categorization
        readonly inkluder: inkluderBar[] = [],
        readonly tidspunkt: string = new Date().toISOString().split('T')[0],
        readonly absoluteIntervals: boolean = false
    ) {}

    override(modifyObject: { [P in keyof VegobjekterQueries]?: VegobjekterQueries[P] }): VegobjekterQueries {
        return Object.assign(Object.create(VegobjekterQueries.prototype), { ...this, ...modifyObject });
    }

    withSegmentering(segmentering: boolean): VegobjekterQueries {
        return this.override({ segmentering });
    }

    withTidspunkt(timestamp: Date): VegobjekterQueries {
        if (timestamp) {
            const tidspunkt = format(timestamp, 'yyyy-MM-dd');
            return this.override({ tidspunkt });
        } else {
            return this.override({ tidspunkt: null });
        }
    }

    withEgenskap(egenskapsfilter: Egenskapsfilter): VegobjekterQueries {
        const egenskaper = this.egenskaper.concat(egenskapsfilter);
        return this.override({ egenskaper });
    }

    withKategorifilter(egenskapsfilter: Egenskapsfilter[]): VegobjekterQueries {
        const kategorifilter = this.kategorifilter.concat(egenskapsfilter);
        return this.override({ kategorifilter });
    }

    withInkluder(inkluder: inkluderBar[]): VegobjekterQueries {
        return this.override({ inkluder });
    }

    withAbsoluteIntervals(absoluteIntervals): VegobjekterQueries {
        return this.override({ absoluteIntervals });
    }

    toParams() {
        const params: Record<string, string> = {};

        if (this.segmentering !== null) {
            params.segmentering = String(this.segmentering);
        }

        if (!!this.tidspunkt && !isToday(new Date(this.tidspunkt))) {
            params.tidspunkt = this.tidspunkt;
        }

        // If any filter using egenskaper is present, compute the params.egenskap
        if (this.egenskaper.length > 0 || this.kategorifilter.length > 0) {
            const egenskaper = this.allFiltersToParam(this.egenskaper);
            const kategorifilter = this.allFiltersToParam(this.kategorifilter);

            // If absoluteIntervals is active and the type is a number,
            // append inversions of the filter to capture the negative variants
            if (
                this.absoluteIntervals &&
                this.kategorifilter.length > 0 &&
                this.kategorifilter[0].egenskapstype.erTall()
            ) {
                kategorifilter.clear();
                const originalFilterMap = this.filterToMap(this.kategorifilter);
                const [key, filters] = Array.from(originalFilterMap.entries())[0];
                if (this.kategorifilter.length == 1 && this.kategorifilter[0].operator == OperatorE.LEQ) {
                    const newFilter = [filters[0], this.invert(filters[0])];
                    kategorifilter.set(key, '(' + newFilter.join(' AND ') + ')');
                } else if (
                    this.kategorifilter.length == 2 &&
                    this.kategorifilter[0].operator === OperatorE.GT &&
                    this.kategorifilter[1].operator === OperatorE.LEQ
                ) {
                    kategorifilter.set(
                        key,
                        `((${filters[0]} AND ${filters[1]}) OR (${this.invert(filters[0])} AND ${this.invert(
                            filters[1]
                        )}))`
                    );
                } else if (
                    this.kategorifilter.length == 1 &&
                    this.kategorifilter[0].operator == OperatorE.GT
                ) {
                    const newFilter = [filters[0], this.invert(filters[0])];
                    kategorifilter.set(key, '(' + newFilter.join(' OR ') + ')');
                }
            }

            // Merge egenskaper and kategorifilter clauses based on their keys using AND operator
            // before joining the complete expression using AND.
            const merged = [...this.merge(egenskaper, kategorifilter, ' AND ').values()].join(' AND ');
            if (merged.length > 0) params.egenskap = merged;
        }

        if (this.inkluder.length > 0) {
            params.inkluder = this.inkluder.join(',');
        }
        return params;
    }
    static invertOperator(op: OperatorE): OperatorE {
        switch (op) {
            case OperatorE.BEQ:
                return OperatorE.LEQ;
            case OperatorE.LEQ:
                return OperatorE.BEQ;
            case OperatorE.LT:
                return OperatorE.GT;
            case OperatorE.GT:
                return OperatorE.LT;
            default:
                throw new Error('Not supported for inversion');
        }
    }
    invert(filter: Egenskapsfilter): Egenskapsfilter {
        return new Egenskapsfilter(
            filter.egenskapstype,
            VegobjekterQueries.invertOperator(filter.operator),
            -filter.verdi
        );
    }
    /**
     *  Merges two maps
     *  If matching keys are found, their values are joined using a specified delimiter
     */
    merge<T>(a: Map<T, string>, b: Map<T, string>, delimiter: string): Map<T, string> {
        const keys = new Set([...a.keys(), ...b.keys()]);
        const resultMap = new Map();
        for (const key of keys) {
            resultMap.set(key, [a.get(key), b.get(key)].filter(v => v).join(delimiter));
        }
        return resultMap;
    }

    filterToMap(filters: Egenskapsfilter[]): Map<number, Egenskapsfilter[]> {
        return (
            filters
                .filter(egenskapsfilter => egenskapsfilter.isValid())
                // Group by egenskapstype.id
                .reduce((prev, current) => {
                    const currentEgenskapstypeList = prev.get(current.egenskapstype.id) || [];
                    prev.set(current.egenskapstype.id, currentEgenskapstypeList.concat(current));
                    return prev;
                }, new Map<number, Egenskapsfilter[]>())
        );
    }

    allFiltersToParam(allFilters: Egenskapsfilter[]): Map<number, string> {
        const filterGroups = this.filterToMap(allFilters);
        const result = new Map<number, string>();
        for (const [id, values] of filterGroups) {
            result.set(
                id,
                values[0].egenskapstype.erEnum()
                    ? '(' + values.join(' OR ') + ')'
                    : '(' + values.join(' AND ') + ')'
            );
        }
        return result;
    }
}

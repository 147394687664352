import { WKTObject } from '../WKTObject';
import { SuperStedfesting } from './SuperStedfesting';
import { Vegsystemreferanse } from '@/domain/omrader';
import { VegobjektEgenskapKvalitetAttributt } from '../vegobjekter/Vegobjekt';

type Metadata = { startdato?: string; sluttdato?: string };
export class Vegnettlenke {
    static dummy(id: number, lenke: number, segment?: number): Vegnettlenke {
        return new Vegnettlenke(
            null,
            id,
            null,
            null,
            null,
            lenke,
            segment,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }

    constructor(
        readonly href: string,
        // readonly metadata: ???,
        readonly veglenkesekvensid: number,
        readonly startposisjon: number,
        readonly sluttposisjon: number,
        readonly kortform: string,
        readonly veglenkenummer: number,
        readonly segmentnummer: number,
        readonly startnode: string,
        readonly sluttnode: string,
        readonly referanse: string,
        readonly type: string,
        readonly topologinivå: string,
        readonly detaljnivå: string,
        readonly typeVeg: string,
        // readonly typeVeg_sosi: string,
        readonly målemetode: string,
        readonly måledato: string,
        readonly superstedfesting: SuperStedfesting,
        readonly feltoversikt: string[],
        readonly geometri: WKTObject,
        readonly kvalitet: VegobjektEgenskapKvalitetAttributt[],
        readonly lengde: number,
        readonly fylke: number,
        readonly kommune: number,
        readonly vegsystemreferanse: Vegsystemreferanse,
        readonly medium?: Medium,
        readonly metadata?: Metadata
    ) {}
}
export enum Medium {
    T = 'På terrenget/på bakkenivå',
    B = 'I bygning/bygningsmessig anlegg',
    L = 'I luft',
    U = 'Under terrenget',
    S = 'På sjøbunnen',
    O = 'På vannoverflaten',
    V = 'Alltid i vann',
    D = 'Tidvis under vann',
    I = 'På isbre',
    W = 'Under sjøbunnen',
    J = 'Under isbre',
    X = 'Ukjent',
}

export enum VeglenkeBasisInndeling {
    TYPEVEG = 'typeveg',
    DETALJNIVA = 'detaljnivå',
    LENKETYPE = 'type',
    TOPOLOGINIVÅ = 'topologinivå',
    MEDIUM = 'medium',
}

export enum VeglenkeLengdeInndeling {
    MALEMETODE = 'målemetode',
    MALEDATO = 'måledato',
    LENGDE = 'lengde',
}

export enum VeglenkeAdministrativInndeling {
    FYLKE = 'fylke',
    KOMMUNE = 'kommune',
}

export enum VeglenkeStedfestingInndeling {
    VEGLENKESEKVENSID = 'veglenkesekvensid',
    STARTPOSISJON = 'startposisjon',
    SLUTTPOSISJON = 'sluttposisjon',
    VEGLENKENUMMER = 'veglenkenummer',
    SEGMENTNUMMER = 'segmentnummer',
    STARTNODE = 'startnode',
    SLUTTNODE = 'sluttnode',
}

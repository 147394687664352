import { Data, Datakatalog } from '@/bootstrap/data';
import { TileService } from '@/components/map/map-utils/WebMapTileServices';
import { Vegsystemreferanse } from '@/domain/omrader';
import { Polygon } from '@/domain/omrader/Polygon';
import { Node } from '@/domain/vegnett/Node';
import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { WKTObject } from '@/domain/WKTObject';
import { Integration } from '@/middleware/integration';
import { Egenskapsfilter, Operator, OperatorAsString, VegnettQueries } from '@/middleware/queries';
import {
    MapState,
    OmraderState,
    RootState,
    SearchState,
    ValgtState,
    VegnettRetning,
    VegnettState,
    VegobjekterState,
    VegobjekttypeState,
    VegsystemreferanseLookup,
} from '@/state';
import { ColorHandler } from '@/utils/ColorHelper';
import { createVegrefParser } from '@/utils/VegrefParser';
import { parseISO } from 'date-fns';
import qs from 'qs';
import { parse } from 'jsurl2';
import { SplashState } from '../SplashState';
import { filterUnique, uniqueByKortform, uniqueByNavn, uniqueByNummer } from './hashHelper';

function isBase64(str) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export function detectEncoding(input) {
    const jsurlPattern = /[~!()]/;
    const base64Pattern = /^[A-Za-z0-9+/=]+$/;

    if (jsurlPattern.test(input) && !input.includes('&')) {
        return 'jsurl';
    } else if (base64Pattern.test(input) && input.length % 4 === 0) {
        return 'base64';
    } else {
        return 'qs';
    }
}

export function parseHashWithFallback<T>(input: string): T {
    const encoding = detectEncoding(input);
    if (encoding === 'jsurl') {
        return parse(input) as T;
    } else if (encoding === 'base64') {
        return qs.parse(atob(input)) as T;
    } else {
        return qs.parse(input) as T;
    }
}

export function hashToState(integration: Integration, previousState: RootState, incomingUrlState: string) {
    const urlState = `/${incomingUrlState.replace(/typeId/g, 'type_id')}`;
    try {
        return previousState
            .withMap(mapState(urlState))
            .withVegnett(roadNetState(urlState))
            .withOmrader(parseOmraderState(urlState, integration.data))
            .withVegobjekter(hvaState(urlState, integration.data, integration.colorHandler))
            .withValgt(valgtObjekt(urlState))
            .withOverlay(
                previousState.overlayState.withVegsystemreferanseLookup(vegsystemreferanseSok(urlState))
            )
            .withSplash(splash(urlState))
            .withSearch(searchState(urlState));
    } catch (e) {
        return new RootState();
    }
}

const mapLayerPattern = /kartlag:?([^/]+)?/;
const mapStatePattern = /\/@:?([^/]+)?/;
const narStatePattern = /når:([^/]+)?/;
const hvorStatePattern = /hvor:([^/]+)?/;
const roadNetPattern = /\/vegnett:([^/]+)?/;
const hvaStatePattern = /\/hva:([^#\/]+)?/;
const vegobjektPattern = /\/vegobjekt:?([^/]+)?/;
const valgtObjektPattern = /\/valgt:?([^/]+)?/;
const nodePattern = /\/noder:?([^/]+)?/;
const linkQueryPattern = /\/lenker:?([^/]+)?/;
const veglenkePattern = /\/veglenke:?([^/]+)?/;
const vegsystemreferanseCoordinatePattern = /\/vegsystemreferanse:([^/:]+):([^/]+)/;
const vegsystemreferansePatternDot = /\/vegsystemreferanse:(?<kommune>\d{4})?(?<ref>[\d.]+)/;
const vegsystemreferansePattern = /\/vegsystemreferanse:(?<kommune>\d{4})?(?<ref>\w+)/;
const splashPattern = /\/splash:(\w+)/;

function mapLayer(urlState: string): TileService {
    const match = urlState.match(mapLayerPattern)?.[1];
    return match ? TileService[match.toUpperCase()] ?? TileService.GEODATA : TileService.GEODATA;
}

function mapState(urlState: string): MapState {
    const mapLayerState = mapLayer(urlState);
    const match = urlState.match(mapStatePattern)?.[1];
    if (match) {
        const [east, north, zoom] = match.split(',').map(n => parseInt(n, 10));
        if (!east || !north) {
            console.warn('Invalid map center point', [east, north]);
            return new MapState(mapLayerState);
        }
        return new MapState(mapLayerState, [east, north], zoom || 3);
    }
    return new MapState(mapLayerState);
}

function searchState(urlState: string): SearchState {
    const match = urlState.match(narStatePattern)?.[1];
    const state = new SearchState();
    if (match) {
        const dateStr = match.replace('date=', '');
        const parsedDate = parseISO(dateStr);
        if (isNaN(parsedDate.getTime())) {
            console.error('Invalid date format:', dateStr);
            return state;
        }
        return state.withDropdownState(state.searchDropdownState.withTimestamp(parsedDate));
    }
    return state;
}

type HvorUrlState = {
    land?: string[];
    fylke?: number[];
    kommune?: number[];
    kontraktsomrade?: string[];
    riksvegrute?: string[];
    vegsystemreferanse?: string[];
    polygon?: string;
};

function parseOmraderState(urlState: string, data: Data): OmraderState {
    const match = urlState.match(hvorStatePattern)?.[1];
    if (!match) return new OmraderState();

    const hvor = parseHashWithFallback<HvorUrlState>(decodeURIComponent(match));
    const { kommuner, fylker, riksvegruter, kontraktsomrader, land, datakatalog } = data;
    const vegobjekttypeVegsystem = datakatalog.getTypeById(915);
    const vegsysrefParser = createVegrefParser(vegobjekttypeVegsystem);

    return new OmraderState(
        filterUnique(hvor.land || [], land, (a, b) => a.navn.toLowerCase() === b.toLowerCase(), uniqueByNavn),
        filterUnique(hvor.fylke || [], fylker, (a, b) => a.nummer === Number(b), uniqueByNummer),
        filterUnique(hvor.kommune || [], kommuner, (a, b) => a.nummer === Number(b), uniqueByNummer),
        filterUnique(
            hvor.kontraktsomrade || [],
            kontraktsomrader,
            (a, b) => a.navn === b.replace(/[,]/g, ''),
            uniqueByNavn
        ),
        filterUnique(
            hvor.riksvegrute || [],
            riksvegruter,
            (a, b) => `${a.navn} ${a.periode}` === b,
            uniqueByNummer
        ),
        uniqueByKortform((hvor.vegsystemreferanse || []).map(vsr => vegsysrefParser(vsr))),
        hvor.polygon ? new Polygon(new WKTObject(5973, hvor.polygon)) : null
    );
}

function parseJsurlRoadNet(urlState: string): VegnettState {
    const match = urlState.match(roadNetPattern)?.[1]?.split('+');
    if (!match) return new VegnettState().withVisible(false).withFilter(new VegnettQueries());

    const parsedDirection = parseHashWithFallback<VegnettRetning>(match[0]);
    const parsedFilter = Object.assign(new VegnettQueries(), parseHashWithFallback(match[1])).fromHash();
    return new VegnettState().withVisible(true).withDirection(parsedDirection).withFilter(parsedFilter);
}

function parseQsRoadNet(urlState: string): VegnettState {
    const match = urlState.match(roadNetPattern)?.[1];
    if (match) {
        const parsedObject = parseHashWithFallback<{ filter?: any; direction?: any; visible?: boolean }>(
            match
        );
        const parsedFilter = Object.assign(new VegnettQueries(), parsedObject.filter).fromHash();

        return new VegnettState()
            .withVisible(!!parsedObject.visible)
            .withDirection(parsedObject.direction as VegnettRetning)
            .withFilter(parsedFilter);
    }
    return new VegnettState()
        .withVisible(false)
        .withFilter(new VegnettQueries())
        .withDirection(VegnettRetning.METRERING);
}

export function roadNetState(urlState: string): VegnettState {
    const encoding = detectEncoding(urlState);
    if (encoding === 'jsurl') {
        return parseJsurlRoadNet(urlState);
    } else {
        return parseQsRoadNet(urlState);
    }
}

type HvaUrlState = {
    category: { id: number };
    absoluteIntervals: boolean;
    id: string;
    intervals?: [];
    filter: EgenskapsFilterUrl[];
};
type EgenskapsFilterUrl = { type_id: string; operator: OperatorAsString; verdi: string };

function hvaState(urlState: string, data: Data, colorHandler: ColorHandler): VegobjekterState {
    const match = urlState.match(hvaStatePattern)?.[1];
    if (!match) return new VegobjekterState();

    const parsedHash = parseHashWithFallback<HvaUrlState[]>(match);
    const types = parsedHash.hva || parsedHash;
    const { datakatalog } = data;
    return new VegobjekterState(
        types.map(type => {
            const typeId = parseInt(type.id, 10);
            return new VegobjekttypeState(
                typeId,
                colorHandler.getNextColorIndex(),
                type.category ? parseCategory(typeId, type.category, datakatalog) : null,
                type.intervals,
                [],
                (type.filter || []).map(filter => mapEgenskapsfilter(filter, datakatalog.getTypeById(typeId)))
            ).withAbsoluteIntervals(type.absoluteIntervals);
        })
    );
}

function parseCategory(typeId: number, category: { id: number }, datakatalog: Datakatalog): Egenskapstype {
    if (category === null) return null;
    return datakatalog.getTypeById(typeId).egenskapstypeById(category.id);
}

type alphanum = string | number;
function getEgenskapsverdi(
    verdi: alphanum | alphanum[],
    egenskapstype: Egenskapstype
): EgenskapTillattVerdi | number | string | null {
    if (Array.isArray(verdi)) {
        verdi = verdi[0];
    }
    if (verdi === null || typeof verdi === 'object' || egenskapstype === null) {
        return null;
    }
    if (egenskapstype.erEnum()) {
        return egenskapstype.verdiById(typeof verdi === 'number' ? verdi : parseInt(verdi));
    }
    if (egenskapstype.erTall()) {
        return typeof verdi === 'number' ? verdi : parseInt(verdi);
    }
    if (egenskapstype.erFlyttall()) {
        return typeof verdi === 'number' ? verdi : parseFloat(verdi.replace(',', '.'));
    }
    if (egenskapstype.erTekst()) {
        return verdi;
    }
    if (egenskapstype.erDato()) {
        return verdi;
    }
    console.warn('No mapping for egenskapstype ' + egenskapstype.egenskapstype);
    return null;
}

function mapEgenskapsfilter(filter: EgenskapsFilterUrl, vegobjekttype: Vegobjekttype): Egenskapsfilter {
    const egenskapstypeId = parseInt(filter.type_id, 10);
    const operator = Operator.from(filter.operator, filter.verdi[0]);
    const egenskapstype = vegobjekttype.egenskapstypeById(egenskapstypeId);
    const verdi = getEgenskapsverdi(filter.verdi, egenskapstype);
    return new Egenskapsfilter(egenskapstype, operator, verdi);
}

export function valgtObjekt(urlState: string): ValgtState {
    let state = ValgtState.emptySelection();
    const linkMatch = urlState.match(linkQueryPattern)?.[1];
    const vegobjektMatch = urlState.match(vegobjektPattern)?.[1];
    const valgtObjektMatch = urlState.match(valgtObjektPattern)?.[1];
    const veglenkeMatch = urlState.match(veglenkePattern)?.[1];
    const nodeMatch = urlState.match(nodePattern)?.[1];

    if (linkMatch) {
        const queryStrings = parseHashWithFallback<string[]>(linkMatch);
        return new ValgtState()
            .withLinkQueries(queryStrings.reduce((queries, query) => ({ ...queries, [query]: [] }), {}))
            .withFetching(true);
    }

    if (vegobjektMatch) {
        const { id, type } = getIdAndTypeFromMatch(vegobjektMatch);
        return ValgtState.fromIdAndType(id, type).withFetching(true);
    }

    if (valgtObjektMatch) {
        const split = valgtObjektMatch.split(':');
        if (split.length === 1) {
            const { id } = getIdAndTypeFromMatch(valgtObjektMatch);
            state = ValgtState.fromId(id).withFetching(true);
        } else if (split.length === 2) {
            const { id, type } = getIdAndTypeFromMatch(valgtObjektMatch);
            state = ValgtState.fromIdAndType(id, type).withFetching(true);
        } else if (split.length === 3) {
            const { id, lenke, segment } = getIdLenkeAndSegmentFromMatch(valgtObjektMatch);
            state = ValgtState.fromIdAndLenkeAndSegment(id, lenke, segment).withFetching(true);
        }
    } else if (veglenkeMatch) {
        const splitLength = veglenkeMatch.split(':').length;
        if (splitLength === 2) {
            const { id, type } = getIdAndTypeFromMatch(veglenkeMatch);
            state = ValgtState.fromIdAndLenke(id, type);
        } else {
            const { id, lenke, segment } = getIdLenkeAndSegmentFromMatch(veglenkeMatch);
            state = ValgtState.fromIdAndLenkeAndSegment(id, lenke, segment).withFetching(true);
        }
    }

    if (nodeMatch) {
        const nodes = parseHashWithFallback<string[]>(nodeMatch);
        state = state.withNodes(nodes.map(n => new Node(parseInt(n), null, '', '', []))).withFetching(true);
    }

    return state;
}

function getIdAndTypeFromMatch(match: string) {
    const [id, type] = match.split(':').map(Number);
    return { id, type };
}

function getIdLenkeAndSegmentFromMatch(match: string) {
    const [id, lenke, segment] = match.split(':').map(Number);
    return { id, lenke, segment };
}

export function vegsystemreferanseSok(urlState: string): VegsystemreferanseLookup {
    const coordMatch = urlState.match(vegsystemreferanseCoordinatePattern);
    const refMatch =
        urlState.match(vegsystemreferansePattern) || urlState.match(vegsystemreferansePatternDot);

    if (coordMatch) {
        const [x, y] = [parseFloat(coordMatch[1]), parseFloat(coordMatch[2])];
        if (!isNaN(x) && !isNaN(y)) {
            return new VegsystemreferanseLookup(x, y, null, null, null, null);
        }
    } else if (refMatch) {
        const vegsystemreferanse = new Vegsystemreferanse(
            null,
            null,
            null,
            null,
            refMatch.groups['ref'],
            null,
            null
        );
        const kommune = refMatch.groups['kommune'] ? parseInt(refMatch.groups['kommune']) : null;
        return new VegsystemreferanseLookup(
            0,
            0,
            vegsystemreferanse.withKommune(kommune),
            null,
            null,
            null,
            kommune
        );
    }
    return null;
}

function splash(urlState: string): SplashState {
    const match = urlState.match(splashPattern)?.[1];
    return match ? SplashState[match.toUpperCase()] : null;
}

import React from 'react';
import { AdskilteLop, VegnettParameter } from '@/middleware/queries';
import TriStateCheckbox from '../../objects/TriStateCheckbox';
import { VegnettFilterOption } from './FilterOptions';

const ADSKILTE_LOP_OPTIONS: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.ADSKILTELOP, AdskilteLop.NEI, 'Uten'),
    new VegnettFilterOption(VegnettParameter.ADSKILTELOP, AdskilteLop.MED, 'Med'),
    new VegnettFilterOption(VegnettParameter.ADSKILTELOP, AdskilteLop.MOT, 'Mot'),
];

interface Props {
    adskilteLop: AdskilteLop[];
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) => void;
}

export const AdskilteLopOptions = ({ adskilteLop, handleFilterChange }: Props) => {
    return (
        <div className="c-road-net__filter-group">
            <h3 className="c-road-net__filter-header">Adskilte Løp</h3>
            {ADSKILTE_LOP_OPTIONS.map(option => (
                <TriStateCheckbox
                    key={option.optionType}
                    checked={adskilteLop.includes(option.optionType as AdskilteLop)}
                    label={option.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, option)}
                />
            ))}
        </div>
    );
};

import { Config } from '@/bootstrap/config';
import { ImageTile } from 'ol';
import { getWidth } from 'ol/extent';
import * as source from 'ol/source';
import TileGrid from 'ol/tilegrid/TileGrid';
import WMTS from 'ol/tilegrid/WMTS';
import { addProjections, EPSG25833 } from './Projections';

export enum TileService {
    GEODATA,
    NIB,
    GRAYSCALE,
    TOPO4,
    KANVAS,
    KANVAS_DARK,
    BASIS,
    BASIS_GRAY,
}

addProjections();
const projectionExtent = EPSG25833.getExtent();
const size = getWidth(projectionExtent) / 256;
const levels = 19;
export const resolutions = getResolutions(levels, size);
export const matrixIds = getMatrixIds(levels);

function getResolutions(zoomLevels: number, tileSize: number) {
    return new Array(zoomLevels).fill(0).map((val, idx) => tileSize / Math.pow(2, idx));
}

function getMatrixIds(zoomLevels: number): string[] {
    return new Array(zoomLevels).fill(0).map((val, idx) => idx.toString());
}

export const kanvasResolutions = [
    10837.35500804335, 5418.677504021675, 2709.3387520108377, 1354.6693760054188, 677.3346880027094,
    338.6673440013547, 169.33367200067735, 84.66683600033868, 42.33341800016934, 21.16670900008467,
    10.583354500042335, 5.291677250021167, 2.6458386250105836, 1.3229193125052918, 0.6614596562526459,
    0.33072982812632296,
];

export const geodataResolutions = [
    21674.7100160867, 10837.35500804335, 5418.677504021675, 2709.3387520108377, 1354.6693760054188,
    677.3346880027094, 338.6673440013547, 169.33367200067735, 84.66683600033868, 42.33341800016934,
    21.16670900008467, 10.583354500042335, 5.291677250021167, 2.6458386250105836, 1.3229193125052918,
    0.6614596562526459, 0.33072982812632296, 0.16536491406316148, 0.08268245703158074,
];

export const maxZoomBaseLayer = 14;
export const createGeodataSource = (config: Config): source.XYZ => {
    return new source.XYZ({
        crossOrigin: 'Anonymous',
        projection: EPSG25833,
        tileGrid: new TileGrid({
            extent: projectionExtent,
            origin: [-2500000, 9045984],
            resolutions: geodataResolutions,
        }),
        url: config.url_basemap,
    });
};

export const createNiBLayerSource = (config: Config, getBaatToken: () => string): source.WMTS => {
    /* NiB layer WMTS does not support zoom levels greater than 17.
     */
    const nibLevels = 18;
    return new source.WMTS({
        crossOrigin: 'Anonymous',
        layer: config.nib_layer,
        matrixSet: config.nib_matrixset,
        projection: EPSG25833,
        style: config.nib_style,
        tileGrid: new WMTS({
            extent: EPSG25833.getExtent(),
            matrixIds: getMatrixIds(nibLevels),
            origin: [-2500000, 9045984],
            resolutions: getResolutions(nibLevels, size),
        }),
        tileLoadFunction: (tile: ImageTile, url: string) => {
            const image = tile.getImage() as HTMLImageElement;
            image.src = `${url}&gkt=${getBaatToken()}`;
        },
        urls: config.url_nib,
    });
};

export const createTopo4LayerSource = (config: Config, getBaatToken: () => string): source.WMTS => {
    return new source.WMTS({
        crossOrigin: 'Anonymous',
        layer: config.kartdata2_layer,
        matrixSet: config.kartdata2_matrixset,
        projection: config.kartdata2_projection,
        style: config.kartdata2_style,
        tileGrid: new WMTS({
            extent: EPSG25833.getExtent(),
            matrixIds: matrixIds.map(id => `${id}`),
            origin: [-2500000, 9045984],
            resolutions,
        }),
        tileLoadFunction: (tile: ImageTile, url: string) => {
            const image = tile.getImage() as HTMLImageElement;
            image.src = `${url}&gkt=${getBaatToken()}`;
        },
        urls: config.url_kartdata2,
    });
};

export const createGrayscaleLayerSource = (config: Config, getBaatToken: () => string): source.WMTS => {
    return new source.WMTS({
        crossOrigin: 'Anonymous',
        layer: config.grayscale_layer,
        matrixSet: config.grayscale_matrixset,
        projection: config.grayscale_projection,
        style: config.grayscale_style,
        tileGrid: new WMTS({
            extent: EPSG25833.getExtent(),
            matrixIds: matrixIds.map(id => `${id}`),
            origin: [-2500000, 9045984],
            resolutions,
        }),
        tileLoadFunction: (tile: ImageTile, url: string) => {
            const image = tile.getImage() as HTMLImageElement;
            image.src = `${url}&gkt=${getBaatToken()}`;
        },
        urls: config.url_grayscale,
    });
};

export const createPropertyBoundariesLayerSource = (config: Config): source.ImageWMS => {
    return new source.ImageWMS({
        crossOrigin: 'Anonymous',
        url: config.url_matrikkelen,
        projection: EPSG25833,
        params: { LAYERS: 'matrikkelkart' },
    });
};

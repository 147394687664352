import { TileService } from '@/components/map/map-utils/WebMapTileServices';
import { RootState } from '@/state';
import { createSelector } from 'reselect';
// Meta
export const stateErrors = (state: RootState) => state.metaState.errors;
// Map
export const stateMap = (state: RootState) => state.mapState;
export const stateBaatToken = (state: RootState) => state.mapState.baatToken;
export const stateMapLayer = (state: RootState) => state.mapState.mapLayer;
export const stateKartutsnitt = (state: RootState) => state.mapState.kartutsnitt;
export const stateZoom = (state: RootState) => state.mapState.zoom;
export const stateZoomToExtent = (state: RootState) => state.mapState.zoomToExtent;
// Search
export const stateFilterPanelOpen = (state: RootState) => state.searchState.filterPanelOpen;
export const statePlacenames = (state: RootState) => state.searchState.placeNames;
export const stateSearchDropdownState = (state: RootState) => state.searchState.searchDropdownState;
export const stateSearchTimestamp = createSelector(stateSearchDropdownState, i => i.timestamp);
export const stateSearchPanelOpen = (state: RootState) => state.searchState.searchPanelOpen;
export const stateDatakatalogVegobjekttypeSelected = (state: RootState) =>
    state.searchState.selectedVegobjekttypeDatakatalog;
// Omrader
export const stateOmrader = (state: RootState) => state.omraderState;
export const stateLand = (state: RootState) => state.omraderState.land;
export const stateFylker = (state: RootState) => state.omraderState.fylke;
export const stateKommuner = (state: RootState) => state.omraderState.kommune;
export const statePolygon = (state: RootState) => state.omraderState.polygon;
export const stateKontraktsomrader = (state: RootState) => state.omraderState.kontraktsomrade;
export const stateRiksvegruter = (state: RootState) => state.omraderState.riksvegrute;
export const stateVegsystemreferanser = (state: RootState) => state.omraderState.vegsystemreferanse;
// Vegobjekter
export const stateVegobjekter = (state: RootState) => state.vegobjekterState;
export const stateSelectedRoadObjectTypes = (state: RootState) => state.vegobjekterState.vegobjekttypeStates;
export const stateIsCategorizing = (state: RootState) => state.vegobjekterState.categorizing;
// Vegnett
export const stateVegnett = (state: RootState) => state.vegnettState;
export const stateVegnettlenker = (state: RootState) => state.vegnettState.vegnettlenker;
export const stateVegnettVisible = (state: RootState) => state.vegnettState.visible;
export const stateVegnettFilter = (state: RootState) => state.vegnettState.filter;
export const stateVegnettStatistics = (state: RootState) => state.vegnettState.statistics;
export const stateVegnettLoading = (state: RootState) => state.vegnettState.loading;
export const stateVegnettDirection = (state: RootState) => state.vegnettState.direction;
// Valgt
export const stateValgt = (state: RootState) => state.valgtState;
export const stateLinkQueries = (state: RootState) => state.valgtState.linkQueries;
// Overlay
export const stateGeolocationState = (state: RootState) => state.overlayState.geolocationState;
export const statePlacenameMarker = (state: RootState) => state.overlayState.placenameMarker;
export const stateVegsystemreferanseSok = (state: RootState) => state.overlayState.vegsystemreferanseLookup;
export const stateSplash = (state: RootState) => state.splashState;
export const stateLoginWindow = (state: RootState) => state.loginWindowState;
export const stateUser = (state: RootState) => state.userState;
// Config
export const stateConfig = (state: RootState) => state.config;
export const aerialViewActive = createSelector([stateMapLayer], mapLayer => {
    return mapLayer === TileService.NIB;
});
export const stateMeasurement = (state: RootState) => state.measurementState;
export const measurementActive = createSelector([stateMeasurement], measurement => measurement.active);

export const statePreferences = (state: RootState) => state.preferences;

export const stateDrawingPolygon = (state: RootState) => state.drawingPolygon;
export const stateIsDrawing = createSelector(
    [measurementActive, stateDrawingPolygon],
    (measuring, drawingPolygon) => measuring && drawingPolygon
);

import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '@/reducers/rootReducer';
import { hashChanger, logger } from '@/middleware/middleware';
import { RootState } from '@/state/RootState';
import {
    TypedUseSelectorHook,
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector,
} from 'react-redux';
import { Integration } from '@/middleware/integration';

export const initStore = (integration: Integration) =>
    configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ thunk: { extraArgument: integration }, serializableCheck: false }).concat(
                logger,
                hashChanger
            ),
        preloadedState: new RootState().withConfig(integration.config),
    });

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export type Dispatch = ReturnType<typeof initStore>['dispatch'];
export const useDispatch = () => useReduxDispatch<Dispatch>();

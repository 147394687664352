import { Egenskapsfilter, Operator, OperatorE, VegobjekterQueries } from '@/middleware/queries';
import { ColorIndex } from '@/utils/ColorHelper';
import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';
import { VegobjekterResult } from '@/domain/vegobjekter/VegobjekterResult';
import { v4 as uuidv4 } from 'uuid';
import { uppercaseFirstLetter } from '@/utils/Utils';

export class VegobjekterState {
    constructor(
        readonly vegobjekttypeStates: VegobjekttypeState[] = [],
        readonly categorizing: boolean = false,
        readonly error: 'auth' | undefined = undefined
    ) {}

    withTypes(types: VegobjekttypeState[]): VegobjekterState {
        return new VegobjekterState(types, this.categorizing, this.error);
    }
    withType(type: VegobjekttypeState): VegobjekterState {
        return new VegobjekterState(this.vegobjekttypeStates.concat(type), this.categorizing, this.error);
    }
    withCategorizing(categorizing: boolean): VegobjekterState {
        return new VegobjekterState(this.vegobjekttypeStates, categorizing, this.error);
    }

    typeToQueries(typeId: number): VegobjekterQueries {
        return this.vegobjekttypeStates
            .find(vegobjekttypeState => vegobjekttypeState.typeId === typeId)
            ?.toQueries();
    }
}
export type VegobjekttypeStateError = 'auth' | 'network' | 'api' | 'unknown' | 'not-indexed' | undefined;

export class VegobjekttypeState {
    constructor(
        readonly typeId: number = null,
        readonly color: ColorIndex = null,
        readonly category: Egenskapstype = null,
        readonly intervals: number[] = [],
        readonly categoryStates: VegobjekttypeCategoryState[] = [],
        readonly filters: Egenskapsfilter[] = [],
        readonly result: VegobjekterResult = null,
        readonly loading: boolean = true,
        readonly visible: boolean = true,
        readonly absoluteIntervals: boolean = false,
        readonly error: VegobjekttypeStateError = undefined
    ) {}

    override(modifyObject: { [P in keyof VegobjekttypeState]?: VegobjekttypeState[P] }): VegobjekttypeState {
        return Object.assign(Object.create(VegobjekttypeState.prototype), { ...this, ...modifyObject });
    }

    withTypeId(typeId: number): VegobjekttypeState {
        return this.override({ typeId });
    }

    withError(error: VegobjekttypeStateError) {
        return this.override({ error });
    }

    withColor(color: ColorIndex) {
        return this.override({ color });
    }

    withCategory(category: Egenskapstype): VegobjekttypeState {
        return this.override({ category });
    }

    withInterval(intervals: number[], absoluteIntervals: boolean): VegobjekttypeState {
        return this.override({ intervals, absoluteIntervals });
    }

    withCategoryStates(categoryStates: VegobjekttypeCategoryState[]) {
        return this.override({ categoryStates });
    }

    withFilters(filters: Egenskapsfilter[]): VegobjekttypeState {
        return this.override({ filters });
    }

    withResults(result: VegobjekterResult): VegobjekttypeState {
        return this.override({ result });
    }

    withLoading(loading: boolean): VegobjekttypeState {
        return this.override({ loading });
    }

    withVisible(visible: boolean): VegobjekttypeState {
        return this.override({ visible });
    }

    withAbsoluteIntervals(absoluteIntervals: boolean) {
        return this.override({ absoluteIntervals });
    }

    categoryIsValid(): boolean {
        return this.category !== null && (this.category.erEnum() || this.intervals !== null);
    }

    toQueries(): VegobjekterQueries {
        return new VegobjekterQueries(true, this.filters).withAbsoluteIntervals(this.absoluteIntervals);
    }
}

export enum CategoryType {
    ENUM,
    INTERVAL,
    NAME,
}

export class VegobjekttypeCategoryState {
    readonly id: string;

    public static comparator(a: VegobjekttypeCategoryState, b: VegobjekttypeCategoryState): number {
        if (a.type != b.type) return 0;

        if (a.type == CategoryType.ENUM) {
            const a_verdi = a.filters[0].verdi as EgenskapTillattVerdi;
            const b_verdi = b.filters[0].verdi as EgenskapTillattVerdi;

            return a_verdi.sorteringsnummer - b_verdi.sorteringsnummer;
        }

        return 0;
    }

    constructor(
        readonly type: CategoryType,
        readonly filters: Egenskapsfilter[],
        readonly color: ColorIndex = null,
        readonly result: VegobjekterResult = null,
        readonly loading: boolean = false,
        readonly visible: boolean = true
    ) {
        this.id = uuidv4();
    }

    override(modifyObject: {
        [P in keyof VegobjekttypeCategoryState]?: VegobjekttypeCategoryState[P];
    }): VegobjekttypeCategoryState {
        return Object.assign(Object.create(VegobjekttypeCategoryState.prototype), {
            ...this,
            ...modifyObject,
        });
    }

    withFilters(filters: Egenskapsfilter[]) {
        return this.override({ filters });
    }

    withColor(color: ColorIndex) {
        return this.override({ color });
    }

    withResult(result: VegobjekterResult) {
        return this.override({ result });
    }

    withLoading(loading: boolean) {
        return this.override({ loading });
    }

    withVisible(visible: boolean) {
        return this.override({ visible });
    }

    toString() {
        if (this.filters.length === 1 && this.filters[0].operator === OperatorE.NO_VALUE)
            return uppercaseFirstLetter(Operator.toStringText(OperatorE.NO_VALUE));
        else if (this.type === CategoryType.ENUM) {
            return this.filters.map(filter => filter.verdiTypeToString()).join();
        } else if (this.type === CategoryType.INTERVAL) {
            return this.filters
                .map(filter => Operator.asString(filter.operator) + filter.verdiTypeToString())
                .join('');
        }
    }
}

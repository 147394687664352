import useMapLayer from '@/components/map/layers/useMapLayer';
import { useDispatch } from '@/state/store';
import { useMap } from '@/state/context/MapContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import Draw from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable';
import { ActionType, TogglePolygonDrawAction } from '@/actions/actiontypes';
import { Polygon } from '@/domain/omrader/Polygon';
import { addPolygonToSearch } from '@/actions/omraderActions';
import { HelpTooltip, HelpTooltipText } from '@/components/map/layers/MeasurementLayer/HelpTooltip';

export const PolygonLayer = ({ zIndex }: { zIndex: number }) => {
    const dispatch = useDispatch();
    const { map } = useMap();
    const [tooltip, setTooltip] = useState(HelpTooltipText.START_POLYGON);

    const layer = useMemo(
        () =>
            new VectorLayer({
                source: new VectorSource(),
            }),
        []
    );

    const draw = useMemo(
        () =>
            new Draw({
                source: layer.getSource(),
                type: 'Polygon',
                condition: e => e?.originalEvent?.button === 0,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineDash: [10, 10],
                        width: 2,
                    }),
                }),
            }),
        [layer]
    );
    useMapLayer({ layer, index: zIndex });

    const onDrawEnd = useCallback(
        e => {
            setTooltip(undefined);
            dispatch(addPolygonToSearch(new Polygon(e.feature)));
            dispatch({ type: ActionType.TOGGLE_POLYGON_DRAW, drawing: false } as TogglePolygonDrawAction);
        },
        [dispatch]
    );

    const onDrawStart = useCallback(() => {
        setTooltip(HelpTooltipText.END_POLYGON);
    }, []);

    useEffect(() => {
        const startKey = draw.on('drawstart', onDrawStart);
        const endKey = draw.on('drawend', onDrawEnd);
        return () => {
            unByKey(startKey);
            unByKey(endKey);
        };
    }, [draw, onDrawEnd, onDrawStart]);
    useEffect(() => {
        map.addInteraction(draw);
        return () => {
            map.removeInteraction(draw);
        };
    }, [map, draw]);

    return (
        <div id={'polygon-draw-layer'}>
            <HelpTooltip tip={tooltip} />
        </div>
    );
};

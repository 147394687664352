import { Position } from 'geojson';
import { Coordinate } from 'ol/coordinate';
import { getPointFromWKT } from './WKT';
import proj4 from 'proj4';

export function getVegBilderURL(wkt: string | Coordinate) {
    const from = '+proj=utm +zone=33 +ellps=GRS80 +units=m +wktext +no_defs';
    const to = '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
    const position = typeof wkt === 'string' ? getPointFromWKT(wkt) : (wkt as Position);
    const coords = proj4(from, to, position);
    const url = `https://vegbilder.atlas.vegvesen.no/?lat=${coords[1]}&lng=${coords[0]}&view=image&radius=15&requester=vegkart`;

    return url;
}

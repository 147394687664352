import { Stedsnavn } from '@/server/responses';
import { GeolocationState, SearchDropdownState } from '@/state';
import {
    ActionType,
    GetState,
    RemoveVSRLookupAction,
    SetGeolocationAction,
    SetStedsnavnMarkerAction,
} from './actiontypes';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { zoomToExtent } from './mapActions';
import { stateSearchPanelOpen, stateVegsystemreferanseSok } from '@/selectors/selectors';
import { lookupVSR } from '@/middleware/fetchMisc';
import { setDropdownState, setSearchPanelOpen } from './searchActions';

export type setGeolocationStateT = (geolocationState: GeolocationState) => (dispatch) => void;
export const setGeolocationState: setGeolocationStateT = (geolocationState: GeolocationState) => dispatch => {
    const setGeoLocationAction: SetGeolocationAction = {
        type: ActionType.OVERLAY_GEOLOCATION_SET,
        geolocationState,
    };
    dispatch(setGeoLocationAction);
};

export type getVSRForCoordinateT = (coordinate: [number, number]) => (dispatch, getState: GetState) => void;
export const getVSRForCoordinate: getVSRForCoordinateT =
    (coordinate: [number, number]) => (dispatch, getState: GetState) => {
        if (stateVegsystemreferanseSok(getState())) {
            dispatch(clearVSRLookup());
        }
        dispatch(lookupVSR(coordinate));
    };

export type clearVSRLookupT = () => (dispatch) => void;
export const clearVSRLookup: clearVSRLookupT = () => dispatch => {
    const removeVSRLookupAction: RemoveVSRLookupAction = {
        type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_REMOVED,
    };
    dispatch(removeVSRLookupAction);
};

export type setPlacenameMarkerT = (stedsnavn: Stedsnavn) => (dispatch, getState: GetState) => void;
export const setPlacenameMarker: setPlacenameMarkerT =
    (stedsnavn: Stedsnavn) => (dispatch, getState: GetState) => {
        const setStedsnavnMarkerAction: SetStedsnavnMarkerAction = {
            type: ActionType.OVERLAY_STEDSNAVN_ADDED,
            stedsnavn,
        };
        dispatch(setStedsnavnMarkerAction);

        // Clear marker if placenameMarker is null. Do not zoom and update state in this case.
        if (stedsnavn) {
            const x = parseInt(stedsnavn.xCoord);
            const y = parseInt(stedsnavn.yCoord);

            const kartutsnitt = Kartutsnitt.fromPoint(x, y);

            dispatch(zoomToExtent(kartutsnitt));
            dispatch(setDropdownState(new SearchDropdownState()));
            if (stateSearchPanelOpen(getState())) dispatch(setSearchPanelOpen(false));
        }
    };

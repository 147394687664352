import React from 'react';
import { Detaljniva, VegnettParameter } from '@/middleware/queries';
import TriStateCheckbox from '../../objects/TriStateCheckbox';
import { VegnettFilterOption } from './FilterOptions';

const DETALJNIVA_OPTIONS: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.DETALJNIVA, Detaljniva.VEGTRASE, 'Vegtrasé'),
    new VegnettFilterOption(VegnettParameter.DETALJNIVA, Detaljniva.KJOREBANE, 'Kjørebane'),
    new VegnettFilterOption(VegnettParameter.DETALJNIVA, Detaljniva.KJOREFELT, 'Kjørefelt'),
];

interface Props {
    detaljniva: Detaljniva[];
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) => void;
}

export const DetaljnivaOptions = ({ detaljniva, handleFilterChange }: Props) => {
    return (
        <div className="c-road-net__filter-group">
            <h3 className="c-road-net__filter-header">Detaljnivå</h3>
            {DETALJNIVA_OPTIONS.map(option => (
                <TriStateCheckbox
                    key={option.optionType}
                    checked={detaljniva.includes(option.optionType as Detaljniva)}
                    label={option.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, option)}
                />
            ))}
        </div>
    );
};

import { Feature } from 'ol';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import { addAlpha } from '@/utils/ColorHelper';
import { getFirstClusteredFeature } from '@/utils/SpatialHelper';
import { FeatureColorKey, FeatureLayerType, FeatureLayerTypeKey } from '../layers/FeatureLayerType';
import { Geometry } from 'ol/geom';
import Chart from 'ol-ext/style/Chart';

interface MarkerDimensionsProps {
    outerRadius: number;
    innerRadius: number;
    borderWidth: number;
    font: string;
}

function createMarkerDimensions({
    outerRadius,
    innerRadius,
    borderWidth,
    font,
}: MarkerDimensionsProps): MarkerDimensionsProps {
    return { outerRadius, innerRadius, borderWidth, font };
}

function highlightMarkerDimensions(dimensions: MarkerDimensionsProps): MarkerDimensionsProps {
    return {
        ...dimensions,
        outerRadius: dimensions.outerRadius + 3,
        innerRadius: dimensions.innerRadius + 3,
    };
}

function selectMarkerDimensions(dimensions: MarkerDimensionsProps): MarkerDimensionsProps {
    return {
        ...dimensions,
        innerRadius: dimensions.innerRadius + 1,
        borderWidth: dimensions.borderWidth + 0.5,
    };
}

const smallMarkerDimensions = createMarkerDimensions({
    outerRadius: 10,
    innerRadius: 7,
    borderWidth: 1,
    font: '12px Fira Sans,Helvetica,Sans-serif',
});

const extraLargeMarkerDimensions = createMarkerDimensions({
    outerRadius: 35,
    innerRadius: 29,
    borderWidth: 2,
    font: '14px Fira Sans,Helvetica,Sans-serif',
});

const largeMarkerDimensions = createMarkerDimensions({
    outerRadius: 20,
    innerRadius: 13,
    borderWidth: 2,
    font: '12px Fira Sans,Helvetica,Sans-serif',
});

const regularMarkerDimensions = createMarkerDimensions({
    outerRadius: 16,
    innerRadius: 12,
    borderWidth: 2,
    font: '12px Fira Sans,Helvetica,Sans-serif',
});

export function pointStyle(feature: Feature<Geometry>, highlight = false, selected = false): Style[] {
    const color = getFirstClusteredFeature(feature).get(FeatureColorKey);
    const layerType = feature.get(FeatureLayerTypeKey) as FeatureLayerType;
    let antall: number;
    let colors: string[] = [];
    if (
        layerType === FeatureLayerType.FEATURE_CLUSTER ||
        layerType == FeatureLayerType.FEATURE_VALGT_CLUSTER
    ) {
        antall = feature.get('features').length;
        colors = [
            ...new Set(feature.get('features').map((f: Feature) => f.get(FeatureColorKey))),
        ].sort() as string[];
    } else {
        antall = feature.get('antall');
    }
    if (layerType === FeatureLayerType.FEATURE_VALGT_NODE || layerType === FeatureLayerType.FEATURE_MAP_POINT)
        return markerStyle({ antall, color, highlight, selected, shape: squareShape });
    return markerStyle({ antall, color, highlight, selected, colors });
}

const circleShape = (fill, radius, stroke = undefined): RegularShape => new Circle({ fill, radius, stroke });
const squareShape = (fill, radius, stroke = undefined): RegularShape =>
    new RegularShape({ points: 4, fill, radius, stroke });

type markerStyleProps = {
    antall: number;
    color: string;
    highlight?: boolean;
    selected?: boolean;
    shape?: typeof circleShape | typeof squareShape;
    colors?: string[];
};

function markerStyle({
    antall,
    color,
    highlight = false,
    selected = false,
    shape = circleShape,
    colors = [],
}: markerStyleProps): Style[] {
    const isMultiType = colors.length > 1;
    const defaultLineColor = selected ? '#000000' : '#ffffff';
    const selectedFill = new Fill({ color: selected ? '#ffffff' : addAlpha(color, 0.5) });

    const innerFill = selected
        ? new Fill({ color: '#ffffff' })
        : new Fill({ color: isMultiType ? addAlpha('#888888', 0.0) : color });

    const dimensions = markerDimensions(antall, highlight, selected);

    const textValue = antall > 1 ? antall.toString() : '';

    const multiTypeOuterStyle = new Style({
        image: new Chart({
            type: 'pie',
            data: new Array(colors.length).fill(1),
            colors,
            radius: dimensions.innerRadius,
            stroke: new Stroke({ color: defaultLineColor, width: dimensions.borderWidth }),
        }),
    });

    const regularOuterStyle = new Style({
        image: shape(selectedFill, dimensions.outerRadius),
    });

    const colorFill = new Fill({ color: addAlpha(colors?.[0] || color, selected ? 1.0 : 0.5) });
    const combinedOuterStyle = new Style({
        image: shape(colorFill, dimensions.outerRadius),
    });

    const innerStyle = new Style({
        image: shape(
            innerFill,
            dimensions.innerRadius * (isMultiType ? 0.85 : 1),
            selected
                ? new Stroke({ color: '#000000', width: dimensions.borderWidth })
                : isMultiType
                ? null
                : new Stroke({ color: defaultLineColor, width: dimensions.borderWidth })
        ),
        text: new Text({
            fill: new Fill({ color: defaultLineColor }),
            stroke: isMultiType ? new Stroke({ color: '#000', width: 3 }) : null,
            font: dimensions.font,
            text: textValue,
            textAlign: 'center',
            textBaseline: 'middle',
        }),
    });

    return isMultiType
        ? [combinedOuterStyle, multiTypeOuterStyle, innerStyle]
        : [regularOuterStyle, innerStyle];
}

function markerDimensions(antall: number, hover = false, selected = false): MarkerDimensionsProps {
    let dimensions: MarkerDimensionsProps;

    if (antall >= 1000) {
        dimensions = extraLargeMarkerDimensions;
    } else if (antall >= 100) {
        dimensions = largeMarkerDimensions;
    } else if (antall > 1) {
        dimensions = regularMarkerDimensions;
    } else {
        dimensions = smallMarkerDimensions;
    }

    if (selected) {
        return selectMarkerDimensions(dimensions);
    }

    if (hover) {
        return highlightMarkerDimensions(dimensions);
    }

    return dimensions;
}
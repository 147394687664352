import * as React from 'react';

interface SpinnerProps {
    className: string;
}
export default class Spinner extends React.Component<SpinnerProps> {
    render() {
        const className = this.props.className;
        return <span className={'o-spinner o-icon-spinner ' + className} />;
    }
}

import * as React from 'react';
import { connect } from 'react-redux';

import { authorizeExport } from '@/actions/authActions';
import {
    MapState,
    OmraderState,
    PreferencesState,
    RootState,
    SearchDropdownState,
    UserState,
    VegnettState,
    VegobjekttypeCategoryState,
    VegobjekttypeState,
} from '@/state';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { download } from '@/utils/Utils';
import { memoize } from 'lodash-es';
import * as selectors from '../../../selectors/selectors';
import Toast from '../../objects/Toast';

interface TagProps {
    typeId: number;
    votState: VegobjekttypeState;
    color: string;
    catState?: VegobjekttypeCategoryState;
}

interface StateProps {
    userState: UserState;
    mapState: MapState;
    omraderState: OmraderState;
    vegnettState: VegnettState;
    searchDropdownState: SearchDropdownState;
    preferencesState: PreferencesState;
    authorizeExport: ({ url }: { url: string }) => { unwrap: () => Promise<string> };
}

interface LocalState {
    downloading: boolean;
}

type Props = TagProps & StateProps;

class DownloadResultsComponent extends React.PureComponent<Props, LocalState> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;

    state: LocalState = {
        downloading: false,
    };

    componentDidUpdate(prevProps: Props) {
        if (this.state.downloading) {
            setTimeout(() => {
                this.setState({ downloading: false });
            }, 5000);
        }
        if (this.props.typeId !== prevProps.typeId) {
            this.setState({});
        }
    }

    isSensitive = memoize(typeId => {
        const type = this.context.data.datakatalog.byId[typeId];
        return type.sensitiv || type.egenskapstyper.some(e => e.sensitivitet > 0);
    });

    render() {
        const isSensitiveAvailable = this.props.userState.isLoggedIn && this.isSensitive(this.props.typeId);
        return (
            <div className="c-results-summary t-hidden-mobile">
                <div className="c-results-summary__export c-results-summary-export">
                    <div className="c-results-summary-export__links c-results-summary-export-links t-clearfix">
                        <i className="o-icon-download c-results-summary__icon-download" />
                        <span className="c-results-summary__text">Last ned datasett:</span>
                        <a
                            id="csv-link"
                            key="csv-link"
                            className="c-results-summary-export-links__csv"
                            title={
                                isSensitiveAvailable
                                    ? 'Last ned som CSV med sensitive data'
                                    : 'Last ned som CSV'
                            }
                            href={this.csvLink()}
                            onClick={e => {
                                this.setState({ downloading: true });
                                if (isSensitiveAvailable) {
                                    e.preventDefault();
                                    this.props
                                        .authorizeExport({ url: this.csvLink(true) })
                                        .unwrap()
                                        .then(key => download(`${this.csvLink()}&key=${key}`))
                                        .catch(e => console.warn(e));
                                    return;
                                }
                                return true;
                            }}
                        >
                            CSV{' '}
                            {isSensitiveAvailable && (
                                <i className="o-icon-logout c-results-summary__icon-lock" />
                            )}
                        </a>
                        <a
                            id="sosi-link"
                            key="sosi-link"
                            className="c-results-summary-export-links__sosi"
                            title={
                                isSensitiveAvailable
                                    ? 'Last ned som SOSI med sensitive data'
                                    : 'Last ned som SOSI'
                            }
                            href={this.sosiLink()}
                            onClick={e => {
                                this.setState({ downloading: true });
                                if (isSensitiveAvailable) {
                                    e.preventDefault();
                                    this.props
                                        .authorizeExport({ url: this.sosiLink(true) })
                                        .unwrap()
                                        .then(key => download(`${this.sosiLink()}&key=${key}`))
                                        .catch(e => console.warn(e));
                                    return;
                                }
                                return true;
                            }}
                        >
                            SOSI{' '}
                            {isSensitiveAvailable && (
                                <i className="o-icon-logout c-results-summary__icon-lock" />
                            )}
                        </a>
                        <a
                            key="api-link"
                            className="c-results-summary-export-links__api"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={this.apiLink()}
                        >
                            API
                        </a>
                    </div>
                    {this.fileSizeWarning()}
                </div>
                <Toast text={'Nedlasting startet ...'} show={this.state.downloading} />
            </div>
        );
    }

    private getUrlsParams(apiLink: boolean): string {
        const params = this.getParams();
        const hasOmradeWithGeometry =
            !!params.fylke || !!params.kommune || !!params.kontraktsomrade || !!params.riksvegrute;
        return Object.keys(params)
            .filter(key => !hasOmradeWithGeometry || (hasOmradeWithGeometry && key !== 'kartutsnitt'))
            .reduce(
                (prev: string[], key: string) => prev.concat(`${key}=${encodeURIComponent(params[key])}`),
                []
            )
            .concat(`inkluder=${encodeURIComponent('alle')}`)
            .concat(
                apiLink
                    ? ''
                    : `eksportformat=${encodeURIComponent(this.props.preferencesState.exportProjection)}`
            )
            .join('&');
    }

    private getParams() {
        const {
            votState,
            catState,
            mapState,
            omraderState,
            vegnettState,
            searchDropdownState,
            preferencesState,
        } = this.props;

        const timestamp = searchDropdownState.timestamp;
        const votQueries = votState.toQueries().withTidspunkt(timestamp);
        const mapQueries = mapState.toQueries();
        const omraderQueries = omraderState.toQueries();
        const vegnettQueries = vegnettState.toQueries();
        const exportQueries = preferencesState.toExportQueries();
        if (!catState) {
            return {
                ...votQueries.toParams(),
                ...mapQueries.toParams(),
                ...omraderQueries.toParams(),
                ...vegnettQueries.toParams(),
                ...exportQueries.toParams(),
            };
        } else {
            return {
                ...votQueries.withKategorifilter(catState.filters).toParams(),
                ...mapQueries.toParams(),
                ...omraderQueries.toParams(),
                ...vegnettQueries.toParams(),
                ...exportQueries.toParams(),
            };
        }
    }

    private fileSizeWarning() {
        const { votState, catState } = this.props;
        if (votState?.loading || catState?.loading) return null;
        const filstoerrelse = DownloadResultsComponent.filstoerrelse(
            catState ? catState.result.vegobjekter.length : votState.result.extentStatistics.antall
        );
        return (
            filstoerrelse > 5 && (
                <p className="c-results-summary-export-links__file-size-warning">
                    <strong>Advarsel: </strong>
                    Stor fil (ca. {filstoerrelse} MB). Det kan ta opptil 1 minutt før filen begynner å laste
                    ned.
                </p>
            )
        );
    }

    private static filstoerrelse(antall) {
        return Math.floor(antall / 5000); // Dette gir ca filstørrelse i MB
    }

    private csvLink(preauth = false) {
        const { restapi_url_v3, eksport_url } = this.context.config;
        const { typeId } = this.props;
        const urlParams = `/vegobjekter/${typeId}.csv?${this.getUrlsParams()}`;
        const apiUrl = '&api=' + restapi_url_v3;
        return eksport_url + (preauth ? '/auth' : '') + urlParams + apiUrl;
    }

    private sosiLink(preauth = false) {
        const { restapi_url_v3, eksport_url } = this.context.config;
        const { typeId, omraderState, preferencesState } = this.props;
        const urlParams = `/vegobjekter/${typeId}.sos?${this.getUrlsParams()}`;
        let sosiKartutsnittParam = '';
        if (urlParams.indexOf('kartutsnitt') === -1 && !omraderState.loading) {
            sosiKartutsnittParam =
                '&sosiutsnitt=' +
                kartutsnittFromWktStrings(
                    ...omraderState
                        .withVegsystemreferanser([])
                        .getFullList()
                        .map(omrade => omrade?.vegobjekt?.geometri?.wkt)
                        .filter(e => !!e)
                ).toQueryString();
        }
        const apiParam = '&api=' + restapi_url_v3;
        const charsetParam = '&tegnsett=utf-8';
        const eksportEnumFormat = preferencesState.exportEnumFormat
            ? `&enumFormat=${preferencesState.exportEnumFormat}`
            : '';

        return (
            eksport_url +
            (preauth ? '/auth' : '') +
            urlParams +
            sosiKartutsnittParam +
            apiParam +
            charsetParam +
            eksportEnumFormat
        );
    }

    private apiLink() {
        const { restapi_url } = this.context.config;
        const { typeId } = this.props;
        const urlParams = `/vegobjekter/${typeId}?${this.getUrlsParams(true)}`;
        return restapi_url + urlParams;
    }
}

const mapStateToProps = (state: RootState) => ({
    userState: selectors.stateUser(state),
    mapState: selectors.stateMap(state),
    omraderState: selectors.stateOmrader(state),
    vegnettState: selectors.stateVegnett(state),
    searchDropdownState: selectors.stateSearchDropdownState(state),
    preferencesState: selectors.statePreferences(state),
});

export const DownloadResultsBox = connect(mapStateToProps, { authorizeExport })(DownloadResultsComponent);

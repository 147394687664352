import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { VegobjekttypeState } from '@/state';
import { useSelector } from '@/state/store';
import {
    featureTooSmall,
    isMultiFeatureCluster,
    isPointFeature,
    wktObjectToFeature,
} from '@/utils/SpatialHelper';
import { featureStyle } from '../style/Style';
import { FeatureLayerType, FeatureLayerTypeKey } from './FeatureLayerType';
import { useContext, useEffect, useMemo } from 'react';
import { MapContext } from '@/state/context/MapContext';
import { resolveColor } from '@/utils/ColorHelper';
import useMapLayer from './useMapLayer';
import { statePreferences } from '@/selectors/selectors';
import useClusterConfig from '../hooks/useClusterConfig';

interface FeaturesLayerProps {
    featureTypes: VegobjekttypeState[];
    zIndex: number;
}
export const FeaturesLayer = ({ zIndex, featureTypes }: FeaturesLayerProps) => {
    const { map } = useContext(MapContext);
    const prefs = useSelector(statePreferences);
    const source = useMemo(() => new VectorSource({}), []);
    const clusterConfig = useClusterConfig(source);

    /**
     * Test if a feature is rendered with a bounding box smaller than 50px (area)
     * @param {Feature} feature the feature to test
     * @returns {boolean} true if the rendered area at current zoomlevel is less than 50px
     */
    const resolution = map.getView().getResolution();
    const geometries = useMemo(
        () =>
            featureTypes
                .filter(r => !!r.result && r.visible)
                .flatMap(r => {
                    if (r.categoryStates?.length > 0) {
                        return r.categoryStates
                            .filter(category => !!category.result && category.visible)
                            .flatMap(category =>
                                category.result?.vegobjekter
                                    .filter(feature => !!feature?.geometri?.wkt)
                                    .map(feature => ({
                                        feature,
                                        geometry: feature.geometri.wkt,
                                        color: resolveColor(category.color),
                                    }))
                            );
                    } else {
                        return r.result?.vegobjekter
                            .filter(feature => !!feature?.geometri?.wkt)
                            .map(feature => {
                                if (!feature.geometri) {
                                    console.error('missing geometry');
                                    console.error(feature);
                                }
                                return {
                                    feature,
                                    geometry: feature.geometri?.egengeometri
                                        ? feature.geometri?.wkt
                                        : feature.lokasjon?.geometri?.wkt,
                                    color: resolveColor(r.color),
                                };
                            });
                    }
                }),
        [featureTypes]
    );

    const mapFeatures = useMemo(
        () =>
            geometries.map(({ feature, geometry, color }) =>
                wktObjectToFeature(
                    geometry,
                    feature.id,
                    feature.metadata.type.id,
                    FeatureLayerType.FEATURE_VEGOBJEKT,
                    color
                )
            ),
        [geometries]
    );

    const [pointSource, clusterLayer] = useMemo(() => {
        const clusters = new Cluster(clusterConfig);

        clusters.on('addfeature', e => {
            tagClusterFeatures(e.feature);
        });
        const layer = new VectorLayer({
            source: clusters,
            style: featureStyle,
        });

        return [source, layer];
    }, [clusterConfig, source]);

    const [nonPointSource, nonPointLayer] = useMemo(() => {
        const source = new VectorSource();
        const layer = new VectorLayer({
            source,
            style: featureStyle,
        });
        return [source, layer];
    }, []);

    useMapLayer({ index: zIndex + 1, layer: clusterLayer });
    useMapLayer({ index: zIndex, layer: nonPointLayer });

    useEffect(() => {
        const points = [];
        const nonPoints = [];
        mapFeatures.forEach(
            f => {
                // Split features into points and the rest.
                if (isPointFeature(f)) {
                    points.push(f);
                } else {
                    // Convert geometries to points if they are rendered too small..
                    // ..and road object type is not "full coverage"
                    if (prefs.transformTinyGeometries && featureTooSmall(f, resolution)) {
                        convertGeometryToPoint(f);
                        points.push(f);
                    } else {
                        nonPoints.push(f);
                    }
                }
                return [points, nonPoints];
            },
            [[], []]
        );
        pointSource.clear();
        pointSource.addFeatures(points);
        nonPointSource.clear();
        nonPointSource.addFeatures(nonPoints);
    }, [mapFeatures, nonPointSource, pointSource, prefs.transformTinyGeometries, resolution]);
    return null;
};

/**
 * Alter the geometry of a feature to the point at the centroid of the original geometry's extent.
 * @param {Feature} feature the feature to alter
 */
function convertGeometryToPoint(feature: Feature): void {
    const extent = feature.getGeometry().getExtent();
    const centroid = new Point(getCenter(extent));
    feature.setGeometry(centroid);
}

function tagClusterFeatures(feature: Feature) {
    feature.set(
        FeatureLayerTypeKey,
        isMultiFeatureCluster(feature) ? FeatureLayerType.FEATURE_CLUSTER : FeatureLayerType.FEATURE_VEGOBJEKT
    );
}

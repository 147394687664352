import { Layer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { StyleFunction, StyleLike } from 'ol/style/Style';
import { connect } from 'react-redux';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import * as selectors from '../../../selectors/selectors';
import { RootState, VegnettStatistics } from '@/state';
import { roadNetLinkToFeature, roadNetStatisticToFeature } from '@/utils/SpatialHelper';
import { lineStyle } from '../style/LineStyle';
import {
    arrowedLineStyle,
    directionalArrowedLineStyle,
    directionalLineStyle,
    featureStyle,
} from '../style/Style';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';

interface StateProps {
    roadNet: Vegnettlenke[];
    roadNetDirection: string;
    roadNetStatistics: VegnettStatistics[];
    visible: boolean;
    zoom: number;
}

type Props = StateProps;

class VegnettFeatureLayer extends React.Component<Props, {}> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    private roadNetLayer: Layer;

    componentDidUpdate() {
        this.context.map.removeLayer(this.roadNetLayer);

        if (this.props.roadNet !== null) {
            const features = this.props.roadNet
                .filter(o => o.geometri && o.geometri.wkt)
                .map(o => roadNetLinkToFeature(o, this.props.roadNetDirection));

            // Line source
            const lineSource = new VectorSource({
                features,
            });

            // Roadnet layer
            this.roadNetLayer = new VectorLayer({
                source: lineSource,
                style: (this.props.roadNetDirection === 'geometri'
                    ? this.props.zoom >= 14
                        ? arrowedLineStyle
                        : lineStyle
                    : this.props.zoom >= 14
                    ? directionalArrowedLineStyle
                    : directionalLineStyle) as StyleLike,
                visible: this.props.visible,
                zIndex: 100,
            });
        } else if (this.props.roadNetStatistics !== null) {
            const features = this.props.roadNetStatistics
                .filter(o => o.omrade.senterpunkt)
                .map(o => roadNetStatisticToFeature(o));

            // Statistics source
            const statisticsSource = new VectorSource({
                features,
            });

            // Roadnet layer
            this.roadNetLayer = new VectorLayer({
                source: statisticsSource,
                style: featureStyle as StyleFunction,
                visible: this.props.visible,
                zIndex: 101,
            });
        }

        this.roadNetLayer.set('name', 'roadnetLayer');
        this.context.map.addLayer(this.roadNetLayer);
        this.roadNetLayer.setVisible(this.props.visible);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.roadNetLayer);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState): StateProps => {
    return {
        roadNet: selectors.stateVegnettlenker(state),
        roadNetDirection: selectors.stateVegnettDirection(state),
        roadNetStatistics: selectors.stateVegnettStatistics(state),
        visible: selectors.stateVegnettVisible(state),
        zoom: selectors.stateZoom(state),
    };
};

export const VegnettLayer = connect(mapStateToProps)(VegnettFeatureLayer);

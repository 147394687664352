import { VegnettQueries } from '@/middleware/queries';
import { ActionType, RefreshAction, SetVegnettFilterAction, SetVegnettRetningAction } from './actiontypes';
import { executeAllSearches } from '@/middleware/fetchVegobjekter';
import { fetchVegnett } from '@/middleware/fetchVegnett';
import { stateVegnettVisible } from '@/selectors/selectors';
import { VegnettRetning } from '@/state';

export type refreshVegnettT = () => (dispatch, getState) => void;
export const refreshVegnett: refreshVegnettT = () => (dispatch, getState) => {
    const refreshAction: RefreshAction = {
        type: ActionType.TIMESTAMP_CHANGED,
    };
    dispatch(refreshAction);
    if (stateVegnettVisible(getState())) dispatch(fetchVegnett());
};
export type toggleRoadNetVisibilityT = () => (dispatch, getState) => void;
export const toggleRoadNetVisibility: toggleRoadNetVisibilityT = () => (dispatch, getState) => {
    const toggleVegnettVisibilityAction = {
        type: ActionType.VEGNETT_TOGGLED,
    };
    dispatch(toggleVegnettVisibilityAction);
    if (stateVegnettVisible(getState())) dispatch(fetchVegnett());
};
export type setRoadNetFilterT = (newFilter: VegnettQueries) => (dispatch) => void;
export const setRoadNetFilter: setRoadNetFilterT = (vegnettFilter: VegnettQueries) => dispatch => {
    const setVegnettFilterAction: SetVegnettFilterAction = {
        type: ActionType.VEGNETT_FILTER_UPDATED,
        vegnettFilter,
    };
    dispatch(setVegnettFilterAction);
    dispatch(executeAllSearches());
    dispatch(fetchVegnett());
};
export type resetRoadNetFilterT = () => (dispatch) => void;
export const resetRoadNetFilter: resetRoadNetFilterT = () => dispatch => {
    const vegnettFilter = VegnettQueries.AllFiltersOn();
    const resetVegnettFilterAction: SetVegnettFilterAction = {
        type: ActionType.VEGNETT_FILTER_UPDATED,
        vegnettFilter,
    };
    dispatch(resetVegnettFilterAction);
    dispatch(executeAllSearches());
    dispatch(fetchVegnett());
};
export type setRoadNetDirectionT = (retning: VegnettRetning) => (dispatch) => void;
export const setRoadNetDirection: setRoadNetDirectionT = (vegnettRetning: VegnettRetning) => dispatch => {
    const setVegnettRetningAction: SetVegnettRetningAction = {
        type: ActionType.VEGNETT_DIRECTION_SET,
        vegnettRetning,
    };
    dispatch(setVegnettRetningAction);
};

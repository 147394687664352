import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import { Fill, Stroke, Style } from 'ol/style';
import { addAlpha, setAlpha } from '@/utils/ColorHelper';
import { getFirstClusteredFeature } from '@/utils/SpatialHelper';
import {
    FeatureColorKey,
    FeatureDirection,
    FeatureRoadType,
    FeatureShowDirectionalArrows,
} from '../layers/FeatureLayerType';
import { selectedColor } from './Style';

function getLineStyle(highlight: boolean, selected: boolean, roadType: string, feature: Feature): Style[] {
    const glowColor = addAlpha('#ffffff', 0.5);
    const color = selected ? selectedColor : getFirstClusteredFeature(feature).get(FeatureColorKey);
    const weights = {
        outerWidth: highlight || selected ? 10 : 7,
        innerWidth: highlight || selected ? 7 : 4,
        hairWidth: 5,
    };

    const style = {
        outer: [
            new Style({
                fill: new Fill({
                    color: addAlpha(color, 0.3),
                }),
                stroke: new Stroke({
                    color: glowColor,
                    width: weights.outerWidth,
                }),
                zIndex: 1,
            }),
        ],
        inner: [
            new Style({
                stroke: new Stroke({
                    color,
                    width: weights.innerWidth,
                }),
                zIndex: 2,
            }),
        ],
        hair: [
            new Style({
                stroke: new Stroke({
                    color: '#ffffff',
                    width: weights.hairWidth,
                }),
                zIndex: 3,
            }),
        ],
    };

    switch (roadType) {
        case 'Gang- og sykkelveg':
        case 'Gågate':
        case 'Sykkelveg':
            style.outer[0].getStroke().setLineDash([4, 6]);
            style.outer[0].getStroke().setWidth(style.outer[0].getStroke().getWidth() / 2);
            style.inner[0].getStroke().setLineDash([4, 6]);
            style.inner[0].getStroke().setWidth(style.inner[0].getStroke().getWidth() / 2);
            style.hair[0].getStroke().setLineDash([4, 6]);
            style.hair[0].getStroke().setWidth(style.hair[0].getStroke().getWidth() / 2);
            break;
        case 'Gangfelt':
            style.outer[0].getStroke().setLineDash([5, 6]);
            style.outer[0].getStroke().setWidth(style.outer[0].getStroke().getWidth() / 2);
            style.inner[0].getStroke().setLineDash([5, 6]);
            style.inner[0].getStroke().setWidth(style.inner[0].getStroke().getWidth() / 2);
            style.inner[0].getStroke().setColor('#D3D3D3');
            style.hair[0].getStroke().setLineDash([5, 6]);
            style.hair[0].getStroke().setWidth(style.hair[0].getStroke().getWidth() / 2);
            break;
        case 'Gangveg':
        case 'Fortau':
            style.outer[0].getStroke().setLineDash([8, 6]);
            style.outer[0].getStroke().setWidth(style.outer[0].getStroke().getWidth() / 2);
            style.inner[0].getStroke().setLineDash([8, 6]);
            style.inner[0].getStroke().setWidth(style.inner[0].getStroke().getWidth() / 2);
            style.hair[0].getStroke().setLineDash([8, 6]);
            style.hair[0].getStroke().setWidth(style.hair[0].getStroke().getWidth() / 2);
            break;
        case 'Trapp':
            style.outer[0].getStroke().setLineDash([3, 4]);
            style.outer[0].getStroke().setLineCap('butt');
            style.outer[0].getStroke().setWidth(highlight || selected ? 10 : 7);
            style.inner[0].getStroke().setLineDash([3, 4]);
            style.inner[0].getStroke().setLineCap('butt');
            style.inner[0].getStroke().setWidth(highlight || selected ? 10 : 7);
            style.hair[0].getStroke().setLineDash([3, 4]);
            style.hair[0].getStroke().setLineCap('butt');
            style.hair[0].getStroke().setWidth(highlight || selected ? 10 : 7);
            break;
    }
    return [...style.outer, ...style.inner, ...(highlight || selected ? style.hair : [])];
}

export function lineStyle(
    feature: Feature,
    resolution: number,
    direction: string,
    highlight = false,
    selected = false,
    arrows = false
): Style[] {
    const lineStyle = getLineStyle(highlight, selected, feature.get(FeatureRoadType), feature);
    const showDirectionalArrows = feature.get(FeatureShowDirectionalArrows);
    const arrowStyles: Style[] = [];

    if (arrows && showDirectionalArrows) {
        const arrowStroke = new Stroke({
            color: setAlpha(feature.get(FeatureColorKey), 0.65),
            width: 2,
        });
        const highLightStroke = new Stroke({
            color: setAlpha('rgba(0,0,0,0)', 0.65),
            width: 1,
        });

        const arrowFill = new Fill({ color: 'rgba(0,0,0,0)' });
        const highLightArrowFill = new Fill({ color: '#ffffff' });
        const turn: boolean = direction !== 'geometri' && feature.get(FeatureDirection) === 'MOT';
        const geometry: LineString = feature.getGeometry() as LineString;
        const coordinates = geometry.getCoordinates();
        const totalLength = geometry.getLength();
        // Resolution is tied to zoom level. We use this to generate fewer arrows on high zoom levels.
        // Adding one to normalize results to some degree/less change per zoom level.
        const lengthBetweenArrows = 35 * (resolution + 1);
        let lengthSinceLastArrow = 0;
        // At the closest two zoom levels, we always put at least one arrow in each segment.
        if (resolution < 0.2 && lengthBetweenArrows > totalLength) {
            const start = coordinates[coordinates.length - 2];
            const end = coordinates[coordinates.length - 1];

            arrowStyles.push(
                new Style({
                    fill: highlight ? highLightArrowFill : arrowFill,
                    geometry: createArrow(start, end, turn),
                    stroke: highlight ? highLightStroke : arrowStroke,
                    zIndex: 4,
                })
            );
        } else {
            geometry.forEachSegment((start: number[], end: number[]) => {
                lengthSinceLastArrow += lengthBetweenPoints(start, end);
                if (lengthSinceLastArrow < lengthBetweenArrows) return;
                else lengthSinceLastArrow = 0;

                arrowStyles.push(
                    new Style({
                        fill: highlight ? highLightArrowFill : arrowFill,
                        geometry: createArrow(start, end, turn),
                        stroke: highlight ? highLightStroke : arrowStroke,
                        zIndex: 4,
                    })
                );
            });
        }
    }

    function createArrow(start: number[], end: number[], turn: boolean): Polygon {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx) - degreesToRadians(90) + degreesToRadians(turn ? 180 : 0);
        const newArrow = new Polygon([
            [
                [0, 0],
                [5, -5],
                [0, 10],
                [-5, -5],
                [0, 0],
            ],
        ]);

        newArrow.translate(start[0], start[1]);
        newArrow.rotate(rotation, [start[0], start[1]]);
        newArrow.scale(resolution, resolution, [start[0], start[1]]);

        return newArrow;
    }

    function degreesToRadians(degrees: number): number {
        return degrees * (Math.PI / 180);
    }

    function lengthBetweenPoints(point_1: number[], point_2: number[]): number {
        return Math.sqrt(Math.pow(point_2[0] - point_1[0], 2) + Math.pow(point_2[1] - point_1[1], 2));
    }

    return lineStyle.concat(arrowStyles);
}

import 'ol/ol.css';
import './stylesheets/vegkart.less';

import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { Bootstrapper } from './bootstrap/Bootstrapper';
import ErrorBoundary from './components/error/ErrorBoundary';
import { Vegkart } from './components/Vegkart';
import { Integration } from './middleware/integration';
import { ConfigAndDataProvider } from './state/context/ConfigAndDataContext';
import { StrictMode } from 'react';
import { config } from './config';
import { version } from './version';
import { parseConfig } from './bootstrap/config';
import { initStore } from '@/state/store';
import { initFlagsFromHash } from '@/featureFlags';

initFlagsFromHash();
const integration: Integration = new Integration();
integration.setConfig(parseConfig(version, config));
const store = initStore(integration);
if (localStorage.getItem('version') !== version.version) {
    localStorage.setItem('version', version.version);
    window.location.href =
        window.location.href.substr(-1) != '/'
            ? window.location.href + '/splash:changelog'
            : window.location.origin + '/#/splash:changelog';
}
const container = document.getElementById('root');
const loading = (
    <div style={{ paddingTop: '25%', textAlign: 'center', fontFamily: 'sans-serif' }}>
        Vent litt mens Vegkart laster…
    </div>
);
const root = createRoot(container);
root.render(
    <StrictMode>
        <Provider store={store} key="provider">
            <ErrorBoundary>
                <Bootstrapper fallback={loading} integration={integration}>
                    <ConfigAndDataProvider integration={integration}>
                        <Vegkart />
                    </ConfigAndDataProvider>
                </Bootstrapper>
            </ErrorBoundary>
        </Provider>
    </StrictMode>
);

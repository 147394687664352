export class ExportQueries {
    constructor(readonly srid: string = null) {}

    override(modifyObject: { [P in keyof ExportQueries]?: ExportQueries[P] }): ExportQueries {
        return Object.assign(Object.create(ExportQueries.prototype), { ...this, ...modifyObject });
    }

    withSrid(srid: string): ExportQueries {
        return this.override({ srid });
    }

    toParams(): Record<string, string> {
        const params: Record<string, string> = {};

        for (const [key, value] of Object.entries(this)) {
            if (value != null) {
                params[key] = value;
            }
        }

        return params;
    }
}

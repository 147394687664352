import * as React from 'react';
import { connect } from 'react-redux';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { VegobjekterResult } from '@/domain/vegobjekter/VegobjekterResult';
import { VegSysRefList } from './VegSysRefList';
import { selectFeature, selectFeatureT } from '@/actions/valgtActions';
import { AreaSummary } from './AreaSummary';

interface TagProps {
    color: string;
    results: VegobjekterResult;
}

interface DispatchProps {
    doSelectFeature: selectFeatureT;
}

const mapDispatchToProps = {
    doSelectFeature: selectFeature,
};

type Props = TagProps & DispatchProps;
class RoadRefOrAreaSummaryComponent extends React.PureComponent<Props> {
    render() {
        const { results } = this.props;
        if (results instanceof VegobjekterResult && results.vegobjekter.length > 0) {
            return (
                <VegSysRefList
                    vegobjekter={results.vegobjekter.filter(vegobjekt => !!vegobjekt?.geometri?.wkt)}
                    onObjectClick={(vegobjekt: Vegobjekt) => this.onRoadObjectClick(vegobjekt)}
                />
            );
        } else if (results instanceof VegobjekterResult && results.areaStatistics.length > 0) {
            return <AreaSummary statistics={results.areaStatistics} total={results.extentStatistics} />;
        } else {
            return null;
        }
    }

    private onRoadObjectClick(vegobjekt: Vegobjekt) {
        this.props.doSelectFeature([vegobjekt], [], [], this.props.color);
    }
}

export const RoadRefOrAreaSummary = connect(null, mapDispatchToProps)(RoadRefOrAreaSummaryComponent);

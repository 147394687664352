import { WKTObject } from '../WKTObject';

export interface Port {
    id: number;
    tilkobling: {
        portnummer: number;
        veglenkesekvensid: number;
        href: string;
    };
}

export class Node {
    constructor(
        readonly id: number,
        readonly geometri: WKTObject,
        readonly href: string,
        readonly startdato: string,
        readonly porter: Port[] = []
    ) {}
}

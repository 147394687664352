import { useDispatch, useSelector } from '@/state/store';
import { stateBaatToken } from '@/selectors/selectors';
import { useContext, useEffect, useState } from 'react';
import { VegkartError } from '@/state';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { handleError } from '@/actions/metaActions';
import { setBaatToken } from '@/actions/mapActions';

export interface BaatToken {
    expires: Date;
    token: string;
}

const useRefreshBaatToken = () => {
    const dispatch = useDispatch();
    const baatToken = useSelector(stateBaatToken);
    const { config } = useContext(ConfigAndDataContext);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (disabled) return;
        const delay = baatToken?.expires ? baatToken.expires.getTime() - Date.now() : 0;
        const timeout = setTimeout(() => {
            fetchToken(config.baatDispenser)
                .then(token => dispatch(setBaatToken(token)))
                .catch(e => {
                    setDisabled(true);
                    if (e instanceof VegkartError) dispatch(handleError(e));
                    else throw e;
                });
        }, delay);

        return () => clearTimeout(timeout);
    }, [baatToken, config.baatDispenser, dispatch, disabled, setDisabled]);
};

export default useRefreshBaatToken;

async function fetchToken(url: string): Promise<BaatToken> {
    return fetch(url)
        .then(async res => {
            if (res.ok) return res.json();
            else throw new Error(await res.text());
        })
        .then(response => ({ expires: new Date(response['expires']), token: response['token'] as string }))
        .catch((e: Error) => {
            throw new VegkartError(
                'Feil ved henting av BAAT Token. Last inn vegkart på nytt for å forsøke igjen.',
                {
                    exception: e.message,
                }
            );
        });
}

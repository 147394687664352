import * as React from 'react';

interface ToastProps {
    className?: string;
    text: string;
    show: boolean;
}

export default class Toast extends React.Component<ToastProps> {
    render() {
        const classes = `o-toast${this.props.show ? '--show' : ''} ${
            this.props.className ? this.props.className : ''
        }`;
        return (
            <div className={classes} aria-hidden={!this.props.show}>
                {this.props.text}
            </div>
        );
    }
}

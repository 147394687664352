import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Node } from '@/domain/vegnett/Node';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { defaultColor } from '@/utils/ColorHelper';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
export class ValgtState {
    static fromId(id: number): ValgtState {
        return new ValgtState([Vegobjekt.dummy(id, null)], []);
    }

    static fromIdAndType(id: number, typeId: number): ValgtState {
        return new ValgtState([Vegobjekt.dummy(id, typeId)], []);
    }

    static fromIdAndLenkeAndSegment(id: number, lenke: number, segment: number) {
        return new ValgtState([], [Vegnettlenke.dummy(id, lenke, segment)]);
    }

    static fromIdAndLenke(id: number, lenke: number) {
        return new ValgtState([], [Vegnettlenke.dummy(id, lenke)]);
    }

    static emptySelection(): ValgtState {
        return new ValgtState([], []);
    }

    static vegobjekterSelection(vegobjekt: Vegobjekt[]): ValgtState {
        return new ValgtState(vegobjekt, []);
    }

    static vegobjektSelection(vegobjekt: Vegobjekt): ValgtState {
        return new ValgtState([vegobjekt], []);
    }

    static veglenkeSelection(veglenke: Vegnettlenke): ValgtState {
        return new ValgtState([], [veglenke]);
    }

    constructor(
        readonly vegobjekter: Vegobjekt[] = [],
        readonly vegnettlenker: Vegnettlenke[] = [],
        readonly linkQueries: Record<string, Vegnettlenke[]> = {},
        readonly nodes: Node[] = [],
        readonly clusterObjekter: Vegobjekt[] = [],
        readonly fetching: boolean = false,
        readonly color: string = defaultColor
    ) {}

    override(modifyObject: { [P in keyof ValgtState]?: ValgtState[P] }): ValgtState {
        return Object.assign(Object.create(ValgtState.prototype), { ...this, ...modifyObject });
    }

    withVegobjekter(vegobjekter: Vegobjekt[]): ValgtState {
        return this.override({ vegobjekter });
    }

    withVegnettlenker(vegnettlenker: Vegnettlenke[]) {
        return this.override({ vegnettlenker });
    }

    withColor(color: string) {
        return this.override({ color });
    }

    withLinkQuery(queryString: string, links: Vegnettlenke[]) {
        return this.override({ linkQueries: { ...this.linkQueries, [queryString]: links } });
    }

    withLinkQueries(linkQueries: Record<string, Vegnettlenke[]>) {
        return this.override({ linkQueries });
    }

    withNodes(nodes: Node[]) {
        return this.override({ nodes });
    }

    withClusterObjekter(clusterObjekter: Vegobjekt[]) {
        return this.override({ clusterObjekter });
    }

    withFetching(fetching) {
        return this.override({ fetching });
    }

    isSingle(): boolean {
        let length = 0;

        if (this.vegobjekter) length += this.vegobjekter.length;
        if (this.vegnettlenker) length += this.vegnettlenker.length;

        return length === 1;
    }

    isCluster(): boolean {
        return this.clusterObjekter.length > 1;
    }

    singleVegobjekt(): Vegobjekt {
        return this.vegobjekter?.length > 0 ? this.vegobjekter[0] : null;
    }

    singleVegnettlenke(): Vegnettlenke {
        return this.vegnettlenker?.length > 0 ? this.vegnettlenker[0] : null;
    }

    isEmpty() {
        return (
            (this.vegobjekter === null || this.vegobjekter.length === 0) &&
            (this.vegnettlenker === null || this.vegnettlenker.length === 0)
        );
    }

    getKartutsnitt() {
        return this.singleVegobjekt()
            ? kartutsnittFromWktStrings(this.singleVegobjekt().geometri.wkt)
            : kartutsnittFromWktStrings(this.singleVegnettlenke().geometri.wkt);
    }
}

import { Config } from '@/bootstrap/config';
import { Server } from '@/server/server';
import { ColorHandler } from '@/utils/ColorHelper';
import { Data } from '@/bootstrap/data';
import { ConfigAndData } from '@/state/context/ConfigAndDataContext';

export class Integration {
    config: Config;
    server: Server;
    data: Data;
    colorHandler: ColorHandler = new ColorHandler([]);

    public setConfig(config: Config) {
        this.config = config;
        this.server = new Server(config);
    }

    public getConfig(): Config {
        return this.config;
    }

    public setData(data: Data) {
        this.data = data;
    }

    // Convenience method for testing.
    public resetColorHandler() {
        this.colorHandler = new ColorHandler([]);
    }

    public getConfigAndData(): ConfigAndData {
        return {
            config: this.config,
            data: this.data,
        };
    }
}

import * as React from 'react';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { vegSysRefComparator } from '@/domain/omrader';

interface Props {
    vegobjekter: Vegobjekt[];
    onObjectClick: (vegobjekt: Vegobjekt) => void;
}

export class VegSysRefList extends React.PureComponent<Props> {
    render() {
        const vegobjekter = this.props.vegobjekter;
        vegobjekter.map(vegobjekt => vegobjekt.lokasjon.vegsystemreferanser.sort(vegSysRefComparator));
        vegobjekter.sort(function (a: Vegobjekt, b: Vegobjekt) {
            return vegSysRefComparator(a.lokasjon.vegsystemreferanser[0], b.lokasjon.vegsystemreferanser[0]);
        });
        return (
            <ul className="c-roadref-list">
                {vegobjekter.map(vegobjekt => (
                    <button
                        key={vegobjekt.id + '_' + 'button'}
                        type="button"
                        className="c-roadref-list__button"
                        aria-label={`Vis vegobjekt med id: ${vegobjekt.id}`}
                        id={'vegobjekt_id_' + vegobjekt.id.toString()}
                        onClick={() => this.props.onObjectClick(vegobjekt)}
                    >
                        <li key={vegobjekt.id}>
                            <span className="c-roadref-list__button-vegobjekt">{vegobjekt.id}</span>
                            <span className="c-roadref-list__button-vsr">
                                {' ' + VegSysRefList.getShortVegSysRefs(vegobjekt)}
                            </span>
                        </li>
                    </button>
                ))}
            </ul>
        );
    }

    private static getShortVegSysRefs(vegobjekt: Vegobjekt): string {
        const vegSysRefs = vegobjekt.lokasjon.vegsystemreferanser;
        const lengthInfo = ' (' + vegSysRefs.length + ')';

        switch (vegSysRefs.length) {
            case 0:
                return 'Ingen vegsystemreferanser';
            case 1:
                return vegSysRefs[0].toVegkartKortform();
            default:
                return (
                    [
                        ...new Set(
                            vegSysRefs.map(vsr =>
                                vsr.vegsystem.vegkategori
                                    .concat(vsr.vegsystem.fase)
                                    .concat(vsr.vegsystem.nummer + '')
                                    .concat(
                                        vsr.strekning
                                            ? ' S' +
                                                  vsr.strekning.strekningsNummer.toString() +
                                                  'D' +
                                                  vsr.strekning.delstrekningsNummer?.toString()
                                            : ''
                                    )
                            )
                        ),
                    ].join(', ') + lengthInfo
                );
        }
    }
}

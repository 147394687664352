import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { isLinkQuery } from '@/utils/Utils';
import { useContext, useMemo } from 'react';
import { addKommuneToSearch, addPolygonToSearch, addVSRToSearch } from '@/actions/omraderActions';
import { addLinkQuery } from '@/actions/valgtActions';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { addVegobjektToSearch, addVegobjekttypeToSearch } from '@/actions/vegobjekterActions';
import { addPointToMap, zoomToExtent } from '@/actions/mapActions';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import * as uuidgen from 'uuid';
import { createVegrefParser } from '@/utils/VegrefParser';
import { Vegkategori } from '@/domain/Vegkategori';
import { ActionType, TogglePolygonDrawAction } from '@/actions/actiontypes';
import { Dispatch, useSelector } from '@/state/store';
import { Polygon } from '@/domain/omrader/Polygon';
import { WKTObject } from '@/domain/WKTObject';
import { vegobjekttypeToEntry } from '@/components/avgrensning/search/useSearchResult';
import { stateUser } from '@/selectors/selectors';
import { FeatureFlag, flags } from '@/featureFlags';

export type SearchSuggestion = {
    title: string;
    description: string;
    action: (dispatch: Dispatch) => void;
    disabled?: boolean;
};

export function parseCoordinates(searchText: string) {
    const coordinates = searchText
        .split(/,\s?|\s/)
        .map(s => s.trim())
        .map(s => Number(s));

    const [first, second] = coordinates;

    // Assuming UTM33, N should be much larger than E
    let north: number, east: number;
    if (first > 1000000 && second < 1000000) {
        north = first;
        east = second;
    } else if (second > 1000000 && first < 1000000) {
        east = first;
        north = second;
    } else {
        north = first;
        east = second;
    }

    return { north, east };
}
export const useSearchSuggestion = (searchText: string): SearchSuggestion[] => {
    const { data } = useContext(ConfigAndDataContext);
    const { isLoggedIn } = useSelector(stateUser);
    const kommuneByNummer = useMemo(
        () => data.kommuner.reduce((set, k) => ({ ...set, [k.nummer]: k }), {}),
        [data.kommuner]
    );
    const vegsystemreferanseParser = useMemo(
        () => createVegrefParser(data.datakatalog.getTypeById(915)),
        [data]
    );

    const vegsystemreferanse = vegsystemreferanseParser(searchText.trim());
    const result: SearchSuggestion[] = [];
    if (flags().has(FeatureFlag.POLYGONS)) {
        if (searchText.match(/POLYGON\s*\(/)) {
            try {
                const polygon = new Polygon(new WKTObject(6173, searchText));
                result.push({
                    title: 'Polygonsøk',
                    description: 'Bruk Polygon WKT for å filtrere',
                    action: dispatch => {
                        dispatch(addPolygonToSearch(polygon));
                    },
                });
            } catch (e) {}
        }
        if (searchText.length > 1 && 'polygon'.startsWith(searchText.toLowerCase())) {
            result.push({
                title: 'Polygonsøk',
                description: 'Tegn et polygon for å filtrere i kartet',
                action: dispatch => {
                    dispatch({
                        type: ActionType.TOGGLE_POLYGON_DRAW,
                        drawing: true,
                    } as TogglePolygonDrawAction);
                },
            });
        }
    }
    if (!!vegsystemreferanse) {
        if (vegsystemreferanse instanceof Vegkategori) {
            result.push({
                title: vegsystemreferanse.navn,
                description: 'Vegsystemreferanse',
                action: dispatch => {
                    dispatch(addVSRToSearch(vegsystemreferanse));
                },
            });
        } else {
            result.push({
                title: vegsystemreferanse.kommune
                    ? `${vegsystemreferanse.kommune} ${vegsystemreferanse.toVegkartKortform()}`
                    : vegsystemreferanse.toVegkartKortform(),
                description: 'Vegsystemreferanse',
                action: dispatch => {
                    dispatch(addVSRToSearch(vegsystemreferanse));
                },
            });
        }
    }
    if (isLinkQuery(searchText))
        result.push({
            title: searchText,
            description: 'Veglenkefilter',
            action: dispatch => {
                dispatch(addLinkQuery(searchText));
            },
        });

    if (isNumber(searchText)) {
        const kommune = kommuneByNummer[searchText];
        const vegobjekttype = data.datakatalog.getTypeById(Number.parseInt(searchText));
        if (vegobjekttype) {
            const { subtitle, disabled } = vegobjekttypeToEntry(vegobjekttype, undefined, isLoggedIn);
            result.push({
                title: `${vegobjekttype.navn} (${vegobjekttype.id})`,
                description: subtitle,
                disabled,
                action: dispatch => {
                    dispatch(addVegobjekttypeToSearch(vegobjekttype));
                },
            });
        }
        if (kommune) {
            result.push({
                title: `${kommune.navn} (${kommune.nummer})`,
                description: 'Kommunenummer',
                action: dispatch => {
                    dispatch(addKommuneToSearch(kommune));
                },
            });
        }
        result.push({
            title: searchText,
            description: 'Vegobjekt',
            action: dispatch => {
                const vegobjekt = Vegobjekt.dummy(parseInt(searchText.trim(), 10), null);
                dispatch(addVegobjektToSearch(vegobjekt));
            },
        });
    }
    if (isCoordinate(searchText)) {
        const { north, east } = parseCoordinates(searchText);

        result.push({
            title: searchText,
            description: 'Koordinater (Nord, Øst)',
            action: dispatch => {
                dispatch(zoomToExtent(kartutsnittFromWktStrings(`POINT(${east} ${north})`)));
                dispatch(addPointToMap({ id: uuidgen.v4(), geometry: [Number(east), Number(north)] }));
            },
        });
    }
    return result;
};

const isNumber = (n: string | number): boolean => !isNaN(parseFloat(String(n))) && isFinite(Number(n));

const isCoordinate = (s: string): boolean => {
    return s.match(/-?\d+\.?\d*(,\s?|\s)-?\d+\.?\d*/)?.length == 2;
};

export const SVVTOKENS_KEY = 'SVVTokens';
export const LAST_LOGIN_KEY = 'lastLogin';

export interface DecodedIdToken {
    email: string;
    name: string;
    uid: string;
    svvroles: string[];
    exp: number;
}

export const parseJwt = (idTokenRaw: string): DecodedIdToken => {
    const base64 = idTokenRaw.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const storageHasToken = (): boolean => {
    if (localStorage.getItem(LAST_LOGIN_KEY) != null && localStorage.getItem(SVVTOKENS_KEY)) {
        const lastLogin = new Date(localStorage.getItem(LAST_LOGIN_KEY));
        const now = new Date();
        const diff = Math.abs(lastLogin.getTime() - now.getTime()) / 3600000;
        if (diff > 7) {
            localStorage.removeItem(SVVTOKENS_KEY);
            return false;
        }
        return true;
    }
    return false;
};

import { transformVegobjekttypeStedfesting } from './stedfesting';
import { Relasjon, Relasjonstype, Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { transformEgenskapstype } from './egenskapstype';

export function transformVegobjekttype(data: unknown): Vegobjekttype {
    return new Vegobjekttype(
        data['id'],
        data['navn'],
        data['kortnavn'],
        data['beskrivelse'],
        transformVegobjekttypeStedfesting(data['stedfesting']),
        data['veiledning'],
        data['sosinvdbnavn'],
        data['sorteringsnummer'],
        data['tidsrom_relevant'],
        data['konnekteringslenke_ok'],
        data['abstrakt_type'],
        data['avledet'],
        data['må_ha_mor'],
        data['er_dataserie'],
        (data['egenskapstyper'] || []).map(e => transformEgenskapstype(e)),
        !data['relasjonstyper']
            ? null
            : new Relasjonstype(
                  data['relasjonstyper'].barn.map(transformRelasjon),
                  data['relasjonstyper'].foreldre.map(transformRelasjon)
              ),
        data['sensitiv']
    );
}

function transformRelasjon(relasjon: Relasjon): Relasjon {
    return new Relasjon(
        relasjon.id,
        relasjon.innenfor_mor,
        relasjon.type,
        relasjon.innhold ? relasjon.innhold : { type: relasjon.type },
        relasjon.relasjonstype
    );
}

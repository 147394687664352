import { VegobjektEgenskap } from '@/domain/vegobjekter/Vegobjekt';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';

export default function compareProperties(
    a: VegobjektEgenskap,
    b: VegobjektEgenskap,
    vegobjekttype: Vegobjekttype
): number {
    if (a === b) return 0;

    const vegobjektTypeA = vegobjekttype.egenskapstypeById(a.id);
    const vegobjektTypeB = vegobjekttype.egenskapstypeById(b.id);

    if (vegobjektTypeA && vegobjektTypeB) {
        return vegobjektTypeA.sorteringsnummer - vegobjektTypeB.sorteringsnummer;
    }

    return 0;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Medium, Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Node, Port } from '@/domain/vegnett/Node';
import { VegnettResponse, VegnettResponseMetadata } from '../responses';
import { transformGeometri, transformKvalitet } from './geometri';
import { transformSuperstedfesting } from './stedfesting';
import { transformVegsystemreferanse } from './vegsystemreferanse';
import { WKTObject } from '@/domain/WKTObject';

export function transformSegmentertVegnettResponse(data: any): VegnettResponse {
    return data
        ? new VegnettResponse(
              data.objekter.map(transformVegnettlenkeSegment),
              new VegnettResponseMetadata(data.metadata.returnert, data.metadata.antall, data.metadata.neste)
          )
        : null;
}
export function transformPorter(data: any): Port[] {
    return data && Array.isArray(data)
        ? data.map(p => ({
              id: p.id,
              tilkobling: {
                  portnummer: p.tilkobling.portnummer,
                  veglenkesekvensid: p.tilkobling.veglenkesekvensid,
                  href: p.tilkobling.href,
              },
          }))
        : null;
}

export function transformVegsystemreferanseGeometri(data: unknown): WKTObject {
    return data['geometri'];
}

export function transformNode(data: any): Node {
    return data
        ? new Node(
              data.id,
              transformGeometri(data.geometri),
              data.href,
              data.startdato,
              transformPorter(data.porter)
          )
        : null;
}

export function transformVegnettlenkesekvens(data: any): Vegnettlenke[] {
    return data
        ? data.veglenkesekvensid
            ? [transformVegnettlenkeSegment(data)]
            : data.map(transformVegnettlenkeSegment)
        : null;
}

export function transformVegnettlenkeSegment(data: any) {
    const hasVegsysRef = Object.entries(data.vegsystemreferanse).length > 0;
    return data
        ? new Vegnettlenke(
              data.href,
              data.veglenkesekvensid,
              data.startposisjon,
              data.sluttposisjon,
              data.kortform,
              data.veglenkenummer,
              data.segmentnummer,
              data.startnode,
              data.sluttnode,
              data.referanse,
              data.type,
              data.topologinivå,
              data.detaljnivå,
              data.typeVeg,
              // data.typeVeg_sosi,
              data.målemetode,
              data.måledato,
              transformSuperstedfesting(data.superstedfesting),
              data.feltoversikt,
              transformGeometri(data.geometri),
              transformKvalitet(data.geometri.kvalitet),
              data.lengde,
              data.fylke,
              data.kommune,
              hasVegsysRef && transformVegsystemreferanse(data.vegsystemreferanse, data.kommune),
              data.geometri?.medium && Medium[data.geometri.medium],
              { startdato: data.metadata?.startdato, sluttdato: data.metadata?.sluttdato }
          )
        : null;
}

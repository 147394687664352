import * as React from 'react';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { clearLinkQuery } from '@/actions/valgtActions';
import { zoomToExtent } from '@/actions/mapActions';
import {
    VeglenkeAdministrativInndeling,
    VeglenkeLengdeInndeling,
    VeglenkeStedfestingInndeling,
    Vegnettlenke,
} from '@/domain/vegnett/Vegnettlenke';
import { ColorIndex, resolveColor } from '@/utils/ColorHelper';
import { useDispatch } from '@/state/store';

interface Props {
    colorIndex: number;
    links: Vegnettlenke[];
    queryString: string;
}

export const VeglenkerComponent = ({ colorIndex, links, queryString }: Props) => {
    const dispatch = useDispatch();
    return (
        links?.length > 0 && (
            <div>
                {/* Header */}
                <div
                    className="c-valgt-header"
                    style={{ backgroundColor: resolveColor(new ColorIndex(colorIndex, 0)) }}
                >
                    <h3 className="c-valgt-header__title">{queryString}</h3>
                    <div className="c-valgt-tools">
                        <button
                            className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                            onClick={() =>
                                dispatch(
                                    zoomToExtent(kartutsnittFromWktStrings(...links.map(s => s.geometri.wkt)))
                                )
                            }
                            title={'Viser veglenkesegmentet i kartet'}
                        >
                            Zoom til
                        </button>
                    </div>
                    <button
                        title="Fjern"
                        onClick={() => dispatch(clearLinkQuery(queryString))}
                        className="c-valgt-header__button"
                        aria-label="Lukk objektvisning"
                    >
                        <i className="o-icon-remove c-valgt-header__close-icon" />
                    </button>
                </div>
                {/* Body */}
                <dl className="c-valgt-features">
                    <h4 className="c-valgt-features__subheader">Veglenker</h4>
                    {renderDistinctListPropety('typeVeg')}
                    <hr />
                    <h4 className="c-valgt-features__subheader">Lengde</h4>
                    {renderRoadNetProperty(VeglenkeLengdeInndeling.LENGDE, (a, b) => a + b, 0)}
                    <hr />
                    <h4 className="c-valgt-features__subheader">Administrativ inndeling</h4>
                    {renderDistinctListPropety(VeglenkeAdministrativInndeling.KOMMUNE)}
                    {renderDistinctListPropety(VeglenkeAdministrativInndeling.FYLKE)}
                    <hr />
                    <h4 className="c-valgt-features__subheader">Stedfesting</h4>
                    {renderKeyValue(
                        VeglenkeStedfestingInndeling.VEGLENKESEKVENSID,
                        links[0].veglenkesekvensid
                    )}
                    {renderRoadNetProperty(
                        VeglenkeStedfestingInndeling.STARTPOSISJON,
                        (a, b) => Math.min(a, b),
                        Infinity
                    )}
                    {renderRoadNetProperty(
                        VeglenkeStedfestingInndeling.SLUTTPOSISJON,
                        (a, b) => Math.max(a, b),
                        -Infinity
                    )}
                    <hr />
                    {/* API-link */}
                    <dt className="c-valgt-features__dt">Objektid:</dt>
                    <dd className="c-valgt-features__dd">
                        <a href={links[0].href} target="_blank" rel="noopener noreferrer">
                            {links[0].veglenkesekvensid}
                        </a>
                    </dd>
                </dl>
            </div>
        )
    );

    function renderKeyValue(key: string, value: string | number) {
        if (value.toString().length === 0) return;
        const capitalized = key.charAt(0).toUpperCase().concat(key.substring(1)).concat(':');

        return [
            <dt key={`dtRn${queryString}`} className="c-valgt-features__dt" title={capitalized}>
                {capitalized}
            </dt>,
            <dd key={`ddRn${queryString}`} className="c-valgt-features__dd">
                {value}
            </dd>,
        ];
    }

    function renderDistinctListPropety(property: string) {
        const values = [...new Set(links.map(l => l[property]))].join(', ');
        return renderKeyValue(property, values);
    }

    function renderRoadNetProperty(property: string, reduce, init) {
        const values = links.map(l => l[property]).reduce(reduce, init);
        return renderKeyValue(property, values);
    }
};

import { Riksvegrute } from '@/domain/omrader';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export function transformRiksvegrute(data: unknown) {
    if (!data) return null;
    return new Riksvegrute(
        data['navn'],
        data['nummer'],
        data['beskrivelse'],
        data['periode'],
        data['vegobjekt'] ? Vegobjekt.dummy(data['vegobjekt'].id, data['vegobjekt'].type) : null
    );
}

export function transformRiksvegruter(riksvegruter: unknown): Riksvegrute[] {
    return (riksvegruter as []).map(rv => transformRiksvegrute(rv));
}

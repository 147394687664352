import * as React from 'react';

import { setRoadNetDirection } from '@/actions/vegnettActions';
import { VegnettRetning } from '@/state';
import { useDispatch } from '@/state/store';

interface StateProps {
    vegnettRetning: VegnettRetning;
}

export const Retningsgrunnlag = ({ vegnettRetning }: StateProps) => {
    const dispatch = useDispatch();

    return (
        <div className="c-direction-selector">
            <h3 className="c-direction-selector__header">Retningsgrunnlag</h3>
            <label className="c-direction-selector__option">
                <input
                    type="radio"
                    value={VegnettRetning.GEOMETRI}
                    checked={vegnettRetning === VegnettRetning.GEOMETRI}
                    onChange={() => dispatch(setRoadNetDirection(VegnettRetning.GEOMETRI))}
                />
                Veglenkens retning
            </label>
            <label className="c-direction-selector__option">
                <input
                    type="radio"
                    value={VegnettRetning.METRERING}
                    checked={vegnettRetning === VegnettRetning.METRERING}
                    onChange={() => dispatch(setRoadNetDirection(VegnettRetning.METRERING))}
                />
                Metreringsretning
            </label>
        </div>
    );
};

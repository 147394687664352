import React, { cloneElement, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

interface Props {
    className?: string;
    orientation: 'vertical' | 'horizontal';
    children: ReactElement[];
    id?: string;
    ariaLabel: string;
}

const useCounter = (initial = 0, { min, max }: { min?: number; max?: number } | undefined = undefined) => {
    const [value, setValue] = useState(initial);
    const decrement = useCallback(() => {
        if (min === undefined || value > min) setValue(value - 1);
    }, [min, value]);
    const increment = useCallback(() => {
        if (max === undefined || value < max - 1) setValue(value + 1);
    }, [max, value]);
    return { value, increment, decrement };
};
export const Toolbar = ({ orientation, children, className, id, ariaLabel }: Props) => {
    const nextEvent = orientation === 'vertical' ? 'ArrowDown' : 'ArrowRight';
    const previousEvent = orientation === 'vertical' ? 'ArrowUp' : 'ArrowLeft';

    const refs = useRef<HTMLElement[]>([]);
    const self = useRef<HTMLDivElement>(null);
    const { value: active, increment, decrement } = useCounter(0, { min: 0, max: children.length });

    useEffect(() => {
        const next = (e: KeyboardEvent) => {
            switch (e.key) {
                case nextEvent:
                    increment();
                    break;
                case previousEvent:
                    decrement();
                    break;
            }
        };
        const element = self.current;
        element.addEventListener('keydown', next);
        return () => {
            element.removeEventListener('keydown', next);
        };
    }, [active, decrement, increment, nextEvent, previousEvent, self]);

    useEffect(() => {
        refs.current?.forEach((ref, index) => {
            if (index === active) {
                ref.tabIndex = 0;
                if (
                    document.activeElement === self.current ||
                    refs.current.some(r => r === document.activeElement)
                ) {
                    ref.focus();
                }
            } else {
                ref.tabIndex = -1;
            }
        });
    }, [active, refs]);
    return (
        <div
            aria-label={ariaLabel}
            id={id}
            aria-orientation={orientation}
            role={'toolbar'}
            ref={self}
            className={className}
        >
            {children
                .filter(c => !!c)
                .map((child, index) =>
                    cloneElement(child, {
                        key: index,
                        ref: r => {
                            refs.current[index] = r;
                        },
                    })
                )}
        </div>
    );
};

/* tslint:disable:no-submodule-imports */
import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from '@/state/store';
import { ActionType } from '@/actions/actiontypes';
import { measurementActive } from '@/selectors/selectors';
import { Toolbar } from '@/components/Toolbar';
import { MeasurementType } from '@/state/MeasurementState';

const Measure = forwardRef<HTMLButtonElement>((_, ref) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState<MeasurementType>();

    const onClick = useCallback(
        (measurementType: MeasurementType, active: boolean) => {
            dispatch({
                type: ActionType.MEASURE_TOGGLE_ACTIVE,
                measurementType: measurementType,
                active: active,
            });
        },
        [dispatch]
    );
    const measuring = useSelector(measurementActive);

    const handleMeasurementTypeOnClick = (measurementType: MeasurementType | undefined) => {
        const newSelected = selected !== measurementType ? measurementType : undefined;
        setSelected(newSelected);
        const isSelected = newSelected !== undefined && newSelected === measurementType;
        onClick(measurementType, isSelected);
    };

    return (
        <div className={'c-map-toggle-measure c-map-controls__button'}>
            <button
                ref={ref}
                type="button"
                title={measuring ? 'Avslutt måling' : 'Start måling'}
                className={`o-btn-square`}
                onClick={() => {
                    handleMeasurementTypeOnClick(!expanded ? MeasurementType.LINESTRING : undefined);
                    setExpanded(!expanded);
                }}
            >
                <i className={`o-icon-ruler c-current-position__gps-icon`} />
            </button>
            {expanded && (
                <Toolbar
                    orientation={'horizontal'}
                    className={'c-map-select-measure-container'}
                    ariaLabel={'Måling'}
                >
                    <button
                        ref={ref}
                        type="button"
                        title={measuring ? 'Avslutt måling' : 'Start måling'}
                        className={`o-btn-square`}
                        onClick={() => handleMeasurementTypeOnClick(MeasurementType.LINESTRING)}
                    >
                        <i
                            className={`o-icon-ruler-straight${
                                selected === 'LineString' ? '--active' : ''
                            } c-current-position__gps-icon`}
                        />
                        <span
                            className={`o-btn-square__title
                        ${selected === 'LineString' ? 'c-map-select-layer__active-text' : ''}`}
                        >
                            Avstand
                        </span>
                    </button>
                    <button
                        ref={ref}
                        type="button"
                        title={measuring ? 'Avslutt måling' : 'Start måling'}
                        className={`o-btn-square`}
                        onClick={() => handleMeasurementTypeOnClick(MeasurementType.POLYGON)}
                    >
                        <i
                            className={`o-icon-ruler-sqm${
                                selected === 'Polygon' ? '--active' : ''
                            } c-current-position__gps-icon`}
                        />
                        <span
                            className={`o-btn-square__title
                        ${selected === 'Polygon' ? 'c-map-select-layer__active-text' : ''}`}
                        >
                            Areal
                        </span>
                    </button>
                </Toolbar>
            )}
        </div>
    );
});
Measure.displayName = 'Measure';
export { Measure };

import { Stedsnavn } from '@/server/responses';

export const getLocationIconName = (location: Stedsnavn) => {
    switch (location.category) {
        case 'userPosition':
            return 'icon-location-arrow';
        case 'place': {
            return 'icon-map-marker';
        }
        case 'public': {
            return 'icon-bank';
        }
        case 'tourism': {
            return 'icon-home';
        }
        case 'airport': {
            return 'icon-plane';
        }
        case 'helicopter': {
            return 'icon-plane';
        }
        case 'train': {
            return 'icon-train';
        }
        case 'boat': {
            return 'icon-ship';
        }
        case 'bus': {
            return 'icon-bus';
        }
        case 'car': {
            return 'icon-automobile';
        }
        case 'restingarea': {
            return 'icon-fjellovergang';
        }
        case 'health': {
            return 'icon-medkit';
        }
        case 'tunnel': {
            return 'icon-tunnel';
        }
        case 'road': {
            return 'icon-road';
        }
        case 'mountain': {
            return 'icon-fjellovergang';
        }
        default: {
            return '';
        }
    }
};

import * as React from 'react';
import { connect } from 'react-redux';
import { Egenskapsfilter, EgenskapsfilterVerdi, Operator, OperatorE } from '@/middleware/queries';
import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';
import { RootState, UserState, VegobjekttypeState } from '@/state';
import {
    egenskapsfilterChanged,
    egenskapsfilterChangedT,
    removeVOTFilter,
    removeVOTFilterT,
} from '@/actions/vegobjekterActions';
import * as selectors from '../../../../selectors/selectors';
import { hasAccess } from '@/utils/Utils';

interface FilterProps {
    vegobjekttype: VegobjekttypeState;
    filter: Egenskapsfilter;
    egenskapstyper: Egenskapstype[];
    egenskapsfilterChanged: egenskapsfilterChangedT;
    removeVOTFilter: removeVOTFilterT;
    user: UserState;
}

interface FilterState {
    validInput: boolean;
    verdi: EgenskapsfilterVerdi;
}

class EgenskapsfilterContainer extends React.PureComponent<FilterProps, FilterState> {
    constructor(props: FilterProps) {
        super(props);
        const { verdi } = this.props.filter;
        this.state = {
            validInput: true,
            verdi,
        };
    }

    render() {
        return (
            <li className="c-egenskapsfilter">
                <i className="o-icon-filter c-egenskapsfilter__filter-icon" />
                <label className="c-egenskapsfilter__label">Filtrer etter egenskapstype</label>
                <button
                    className="c-egenskapsfilter__remove"
                    onClick={() => this.props.removeVOTFilter(this.props.vegobjekttype, this.props.filter)}
                    aria-label={`Fjern ${this.props.filter.egenskapstype?.navn ?? 'tomt'} filter`}
                >
                    <i className="o-icon-remove c-egenskapsfilter__remove-icon" />
                </button>
                {this.getEgenskapsSelect()}
                <div className="c-egenskapsfilter-operator-verdi-container">
                    {this.getOperatorSelect()}
                    {this.getVerdiInput()}
                </div>
            </li>
        );
    }

    private getEgenskapsSelect = () => {
        const selectedEgenskap = this.props.filter.egenskapstype;
        return (
            <div className="c-egenskapsfilter__select-egenskap-wrapper">
                <select
                    className="c-egenskapsfilter-select c-egenskapsfilter-select__filter"
                    name="egenskap"
                    id="egenskapsfilter_select_filter"
                    value={selectedEgenskap ? selectedEgenskap.id : ''}
                    onChange={e => this.egenskapSelected(e.target.value)}
                    aria-label="Velg egenskapstype å filtrere med"
                >
                    {[
                        <option className="c-egenskapsfilter-select__option" key="null" value="">
                            Velg et filter:
                        </option>,
                    ].concat(
                        this.props.egenskapstyper
                            .filter(e => !e.erStruct())
                            .map(et => (
                                <option
                                    className="c-egenskapsfilter-select__option"
                                    key={et.id}
                                    value={et.id}
                                    disabled={this.isDisabled(et)}
                                >
                                    {et.navn} {this.isDisabled(et) ? '(Ikke tilgjengelig)' : ''}
                                </option>
                            ))
                    )}
                </select>
            </div>
        );
    };

    private isDisabled(egenskapstype: Egenskapstype): boolean {
        return !hasAccess(egenskapstype.sensitivitet, this.props.user.roles);
    }

    private getOperatorSelect() {
        const selectedOperator = this.props.filter.operator;
        const selectedEgenskap = this.props.filter.egenskapstype;
        return (
            <div className="c-egenskapsfilter__select-operator-wrapper">
                <i className="o-icon-operators c-egenskapsfilter__operators-icon" />
                <label className="c-egenskapsfilter__label">Operator</label>
                <select
                    className="c-egenskapsfilter-select c-egenskapsfilter-select__operator"
                    disabled={selectedEgenskap === null}
                    name="operator"
                    id="egenskapsfilter_select_operator"
                    onChange={e => this.operatorSelected(e.target.value)}
                    value={selectedOperator || ''}
                    aria-label={'Velg operator'}
                >
                    {[<option className="c-egenskapsfilter-select__option" key="null" value="" />].concat(
                        operatorsForEgenskapstype(selectedEgenskap).map(o => (
                            <option
                                className="c-egenskapsfilter-select__option"
                                key={o}
                                value={o}
                                aria-label={`${
                                    o === OperatorE.NEQ ? 'er ikke lik' : Operator.toStringText(o)
                                }`}
                            >
                                {Operator.toStringText(o)}
                            </option>
                        ))
                    )}
                </select>
            </div>
        );
    }

    private getVerdiInput() {
        const selectedOperator = this.props.filter.operator;
        const selectedEgenskap = this.props.filter.egenskapstype;
        if (
            selectedEgenskap === null ||
            selectedOperator === null ||
            selectedOperator === OperatorE.NO_VALUE ||
            selectedOperator === OperatorE.HAS_VALUE
        ) {
            return null;
        }
        if (selectedEgenskap.erEnum()) {
            const selectedVerdi = (this.props.filter.verdi as EgenskapTillattVerdi) || null;
            return (
                <div className="c-egenskapsfilter__select-verdi-wrapper">
                    <label className="c-egenskapsfilter__label">Sett verdi</label>
                    <select
                        className="c-egenskapsfilter-select c-egenskapsfilter-select__value"
                        id="egenskapsfilter_select_option"
                        onChange={e => this.enumverdiChanged(e.target.value)}
                        value={selectedVerdi ? selectedVerdi.id : ''}
                        aria-label="Velg verdi"
                    >
                        {[<option className="c-egenskapsfilter-select__option" key="null" value="" />].concat(
                            [...selectedEgenskap.tillatte_verdier]
                                .sort(EgenskapTillattVerdi.comparator)
                                .map(tv => (
                                    <option
                                        className="c-egenskapsfilter-select__option"
                                        key={tv.id}
                                        value={tv.id}
                                    >
                                        {tv.verdi}
                                    </option>
                                ))
                        )}
                    </select>
                </div>
            );
        } else {
            const inputValidationRegex = getValidationRegex(this.props.filter.egenskapstype);
            const invalidClassModifier = this.state.validInput
                ? ''
                : 'c-egenskapsfilter-verdi-container__verdi-input--invalid';
            return (
                <div className="c-egenskapsfilter-verdi-container">
                    <label className="c-egenskapsfilter__verdi-container__verdi-label">
                        {this.props.filter.egenskapstype.erTall() ? 'Tall' : 'Sett verdi'}
                    </label>
                    <input
                        type={valueInputType(selectedEgenskap)}
                        className={`c-egenskapsfilter-verdi-container__verdi-input ${invalidClassModifier}`}
                        pattern={selectedEgenskap.erDato() ? '[0-9]{4}-[0-9]{2}-[0-9]{2}' : ''}
                        placeholder={getPlaceholder(selectedEgenskap)}
                        name="verdi"
                        onChange={e => {
                            const validInput = inputValidationRegex.test(e.target.value);
                            this.setState({ validInput, verdi: e.target.value });
                        }}
                        onKeyUp={e => this.handleKeypressAndBlurEvents(e.key)}
                        onBlur={() => this.handleKeypressAndBlurEvents('Enter')}
                        value={(this.state.verdi as number | string) || ''}
                        aria-label="Verdi"
                        id="egenskapsfilter_verdi_container"
                    />
                    {!this.state.validInput && <p>Ugyldig inputverdi</p>}
                </div>
            );
        }
    }

    private egenskapSelected(value: string) {
        const egenskapsId = parseInt(value, 10);
        const selectedEgenskap = this.props.egenskapstyper.find(et => et.id === egenskapsId) || null;
        const newFilter = this.props.filter.withEgenskapstypeReset(selectedEgenskap);
        this.props.egenskapsfilterChanged(this.props.vegobjekttype, newFilter);
    }

    private operatorSelected(value: string) {
        const operator = parseInt(value, 10) as OperatorE;
        const newFilter = this.props.filter.withOperator(isNaN(operator) ? null : operator);
        this.props.egenskapsfilterChanged(this.props.vegobjekttype, newFilter);
    }

    private enumverdiChanged(enumIdString: string) {
        const enumId = parseInt(enumIdString, 10);
        const verdi = this.props.filter.egenskapstype.tillatte_verdier.find(tv => tv.id === enumId) || null;
        const newFilter = this.props.filter.withVerdi(verdi);
        this.props.egenskapsfilterChanged(this.props.vegobjekttype, newFilter);
    }

    private handleKeypressAndBlurEvents(key: string) {
        if (key === 'Enter' && this.state.validInput) {
            const rawValue = ((this.state.verdi as string) || '').replace(/['"]/g, '').trim();
            const value = this.getValueFromString(rawValue);
            if (value !== null && value !== '') {
                const newFilter = this.props.filter.withVerdi(value);
                this.props.egenskapsfilterChanged(this.props.vegobjekttype, newFilter);
            }
        }
    }

    private getValueFromString(rawValue: string) {
        if (this.props.filter.egenskapstype.erTall()) {
            rawValue = rawValue.replace(',', '.');
            const value: number = rawValue.includes('.') ? parseFloat(rawValue) : parseInt(rawValue, 10);
            return isNaN(value) ? null : value;
        }
        return rawValue;
    }
}

const mapDispatchToProps = {
    egenskapsfilterChanged,
    removeVOTFilter,
};
const mapStateToProps = (state: RootState) => ({
    user: selectors.stateUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EgenskapsfilterContainer);

function getPlaceholder(egenskapstype: Egenskapstype): string {
    if (egenskapstype.erTall()) {
        return 'eks: 1000';
    }
    if (egenskapstype.erTekst()) {
        return '(Tekst)';
    }
    if (egenskapstype.erDato()) {
        return 'yyyy-mm-dd';
    }
    if (egenskapstype.erKlokkeslett()) {
        return 'hhmm';
    }
    return 'Verdi';
}

function getValidationRegex(egenskapstype: Egenskapstype): RegExp {
    if (egenskapstype.erFlyttall()) {
        return /^-?\d+[,.]?\d*$/;
    }
    if (egenskapstype.erTall()) {
        return /^-?\d+$/;
    }
    if (egenskapstype.erDato()) {
        // This will match yyyy-mm-dd and also yyyy-m-d
        return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/;
    }
    return /.+$/;
}

function operatorsForEgenskapstype(egenskapstype: Egenskapstype): OperatorE[] {
    if (egenskapstype === null) return [];
    const operatorer = [];
    if (!egenskapstype.erGeometri()) {
        operatorer.push(OperatorE.EQ, OperatorE.NEQ);
    }
    if (egenskapstype.erTall() || egenskapstype.erDato() || egenskapstype.erKlokkeslett()) {
        operatorer.push(OperatorE.BEQ, OperatorE.LEQ, OperatorE.LT, OperatorE.GT);
    }
    operatorer.push(OperatorE.HAS_VALUE, OperatorE.NO_VALUE);
    return operatorer;
}

function valueInputType(egenskapstype: Egenskapstype): string {
    if (egenskapstype.erTall()) {
        return 'number';
    } else if (egenskapstype.erDato()) {
        return 'date';
    } else {
        return 'text';
    }
}

import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { Stedsnavn, StedsnavnResponse } from '@/server/responses';
import { Fylke, Kommune, Kontraktsomrade, Land, Riksvegrute } from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';

export type DropdownObject =
    | Land
    | Fylke
    | Kommune
    | Riksvegrute
    | Kontraktsomrade
    | Vegkategori
    | Vegobjekttype
    | Stedsnavn;

export class SearchState {
    constructor(
        readonly searchDropdownState: SearchDropdownState = new SearchDropdownState(),
        readonly selectedVegobjekttypeDatakatalog: Vegobjekttype[] = [],
        readonly placeNames: StedsnavnResponse = null,
        // Mobile
        readonly filterPanelOpen: boolean = false,
        readonly searchPanelOpen: boolean = false
    ) {}

    override(modifyObject: { [P in keyof SearchState]?: SearchState[P] }): SearchState {
        return Object.assign(Object.create(SearchState.prototype), { ...this, ...modifyObject });
    }

    withDropdownState(searchDropdownState: SearchDropdownState): SearchState {
        return this.override({ searchDropdownState });
    }

    withSelectedVegobjekttypeDatakatalog(selectedVegobjekttypeDatakatalog: Vegobjekttype[]): SearchState {
        return this.override({
            selectedVegobjekttypeDatakatalog:
                selectedVegobjekttypeDatakatalog === null ? [] : selectedVegobjekttypeDatakatalog,
        });
    }

    withPlaceNames(placeNames: StedsnavnResponse): SearchState {
        return this.override({ placeNames });
    }

    withFilterPanelOpen(filterPanelOpen: boolean): SearchState {
        return this.override({ filterPanelOpen });
    }

    withSearchPanelOpen(searchPanelOpen: boolean): SearchState {
        return this.override({ searchPanelOpen });
    }
}

export class SearchDropdownState {
    constructor(
        readonly datakatalogOpen: boolean = false,
        readonly searchText: string = '',
        readonly timestamp: Date = null
    ) {}

    override(modifyObject: {
        [P in keyof SearchDropdownState]?: SearchDropdownState[P];
    }): SearchDropdownState {
        return Object.assign(Object.create(SearchDropdownState.prototype), { ...this, ...modifyObject });
    }

    withDatakatalogOpen(datakatalogOpen: boolean) {
        return this.override({ datakatalogOpen });
    }

    withSearchText(searchText: string) {
        return this.override({ searchText });
    }

    withTimestamp(timestamp: Date) {
        return this.override({ timestamp });
    }
}

import useRefreshBaatToken from '@/components/map/layers/useRefreshBaatToken';
import { stateBaatToken, stateConfig, stateMapLayer } from '@/selectors/selectors';
import { useMap } from '@/state/context/MapContext';
import { useSelector } from '@/state/store';
import { applyStyle } from 'ol-mapbox-style';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import { VectorTile } from 'ol/source';
import TileGrid from 'ol/tilegrid/TileGrid';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    createGeodataSource,
    createGrayscaleLayerSource,
    createNiBLayerSource,
    createTopo4LayerSource,
    kanvasResolutions,
    TileService,
} from '../map-utils/WebMapTileServices';

const LAYER_TYPE = 'type';

const useBaatToken = () => {
    const baatToken = useSelector(stateBaatToken)?.token ?? '';
    const baatTokenRef = useRef(baatToken);

    useEffect(() => {
        baatTokenRef.current = baatToken;
    }, [baatToken]);

    return useCallback(() => {
        return baatTokenRef.current;
    }, []);
};

export const TileServiceLayers = () => {
    useRefreshBaatToken();
    const { map } = useMap();
    const getBaatToken = useBaatToken();
    const config = useSelector(stateConfig);
    const visibleLayer = useSelector(stateMapLayer);
    const geodataLayer = useMemo(() => {
        const layer = new TileLayer({ source: createGeodataSource(config) });
        layer.set(LAYER_TYPE, TileService.GEODATA);
        return layer;
    }, [config]);
    const nibLayer = useMemo(() => {
        const layer = new TileLayer({ source: createNiBLayerSource(config, getBaatToken) });
        layer.set(LAYER_TYPE, TileService.NIB);
        return layer;
    }, [config, getBaatToken]);
    const topoLayer = useMemo(() => {
        const layer = new TileLayer({ source: createTopo4LayerSource(config, getBaatToken) });
        layer.set(LAYER_TYPE, TileService.TOPO4);
        return layer;
    }, [config, getBaatToken]);
    const grayscaleLayer = useMemo(() => {
        const layer = new TileLayer({ source: createGrayscaleLayerSource(config, getBaatToken) });
        layer.set(LAYER_TYPE, TileService.GRAYSCALE);
        return layer;
    }, [config, getBaatToken]);

    const kanvas = useMemo(() => {
        const layer = new VectorTileLayer({
            declutter: true,
            properties: { [LAYER_TYPE]: TileService.KANVAS },
            source: new VectorTile({
                tileGrid: new TileGrid({
                    tileSize: [512, 512],
                    origin: [-2500000, 9045984],
                    resolutions: kanvasResolutions,
                }),
                projection: map.getView().getProjection(),
            }),
        });
        applyStyle(
            layer,
            'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheKanvas/VectorTileServer/resources/styles/root.json'
        );
        return layer;
    }, [map]);
    const kanvasDark = useMemo(() => {
        const layer = new VectorTileLayer({
            declutter: true,
            properties: { [LAYER_TYPE]: TileService.KANVAS_DARK },
            source: new VectorTile({
                tileGrid: new TileGrid({
                    tileSize: [512, 512],
                    origin: [-2500000, 9045984],
                    resolutions: kanvasResolutions,
                }),
                projection: map.getView().getProjection(),
            }),
        });
        applyStyle(
            layer,
            'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheKanvasMork/VectorTileServer/resources/styles/root.json'
        );
        return layer;
    }, [map]);

    const basis = useMemo(() => {
        const layer = new VectorTileLayer({
            declutter: true,
            properties: { [LAYER_TYPE]: TileService.BASIS },
            source: new VectorTile({
                tileGrid: new TileGrid({
                    tileSize: [512, 512],
                    origin: [-2500000, 9045984],
                    resolutions: kanvasResolutions,
                }),
                projection: map.getView().getProjection(),
            }),
        });
        applyStyle(
            layer,
            'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheNordenBasis/VectorTileServer/resources/styles/root.json'
        );
        return layer;
    }, [map]);

    const basisGray = useMemo(() => {
        const layer = new VectorTileLayer({
            declutter: true,
            properties: { [LAYER_TYPE]: TileService.BASIS_GRAY },
            source: new VectorTile({
                tileGrid: new TileGrid({
                    tileSize: [512, 512],
                    origin: [-2500000, 9045984],
                    resolutions: kanvasResolutions,
                }),
                projection: map.getView().getProjection(),
            }),
        });
        applyStyle(
            layer,
            'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheNordenGraatone/VectorTileServer/resources/styles/root.json'
        );
        return layer;
    }, [map]);

    const layers = useMemo(
        () => [geodataLayer, nibLayer, topoLayer, grayscaleLayer, kanvas, kanvasDark, basis, basisGray],
        [geodataLayer, nibLayer, topoLayer, grayscaleLayer, kanvas, kanvasDark, basis, basisGray]
    );
    useEffect(() => {
        layers.forEach(layer => layer.setVisible(visibleLayer === layer.get(LAYER_TYPE)));
    }, [layers, visibleLayer]);

    useEffect(() => {
        layers.forEach(layer => map.addLayer(layer));
        return () => {
            layers.forEach(layer => map.removeLayer(layer));
        };
    }, [layers, map]);
    return null;
};

import {
    Vegposisjon,
    Kryssystem,
    Sideanlegg,
    Strekning,
    Vegsystem,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';

export function createVegrefParser(
    vegobjekttypeVegsystem: Vegobjekttype
): (input: string) => Vegsystemreferanse {
    const vegkategorier = vegobjekttypeVegsystem.egenskapstyper.find(et => et.id === 11276).tillatte_verdier;
    const vegkategorierKortnavn = vegkategorier.map(tv => tv.kortnavn).join('');

    const faser = vegobjekttypeVegsystem.egenskapstyper
        .find(et => et.id === 11278)
        .tillatte_verdier.map(tv => tv.kortnavn)
        .join('');

    const regexpForLongRoadSysRefNames = new RegExp(
        '(^(europa|riks|fylkes|kommunal|privat|skogs)\\s?ve[ig](er)?\\s?\\d*$)',
        'i'
    );

    // Vegsystem
    const vegkategoriPattern = `^(?<kommune>\\d{4})?(?<kortnavn>[${vegkategorierKortnavn}])`;
    const fasePattern = `[${faser}]`;
    const nummerPattern = `\\d+`;
    const vegsystemCaptureAllPattern = `(${vegkategoriPattern})(?<fase>${fasePattern})?(?<nummer>${nummerPattern})?`;
    const vegsystemGroupPattern = `(?<vegsystem>${vegkategoriPattern}${fasePattern}?(?:${nummerPattern})?)`;

    // Strekning
    const trafikantgruppePattern = 'K|G';
    const strekningPattern = 'S\\d+(?:-\\d+)?';
    const delstrekningPattern = 'D\\d+(?:-\\d+)?';
    const intervalPattern = '(\\d+)(?:-)?(\\d+)?';
    const meterPattern = 'M\\d+(?:-\\d+)?';

    const strekningCaptureAllPattern = `(${trafikantgruppePattern})?(${strekningPattern})?(${delstrekningPattern})?(${meterPattern})?`;
    const strekningFullPattern = `(?<strekning>(?:${trafikantgruppePattern})?(?:${strekningPattern})?(?:${delstrekningPattern})?(?:${meterPattern})?)?`;

    const trafikantgruppeRegExp = new RegExp(trafikantgruppePattern, 'i');
    const strekningRegExp = new RegExp(strekningPattern, 'i');
    const delstrekningRegExp = new RegExp(delstrekningPattern, 'i');
    const meterRegExp = new RegExp(meterPattern, 'i');

    // Kryssystem
    const kryssystemPattern = 'KS\\d+(?:-\\d+)?';
    const kryssdelPattern = 'KD\\d+(?:-\\d+)?';
    const kryssystemCaptureAllPattern = `(${kryssystemPattern})?(${kryssdelPattern})?(${meterPattern})?`;
    const kryssystemFullPattern = `(?<kryssystem>(?:${kryssystemPattern})?(?:${kryssdelPattern})?(?:${meterPattern})?)?`;

    const kryssystemRegExp = new RegExp(kryssystemPattern, 'i');
    const kryssdelRegExp = new RegExp(kryssdelPattern, 'i');

    // Sideanlegg
    const sideanleggPattern = 'SA\\d+(?:-\\d+)?';
    const sidedelPattern = 'SD\\d+(?:-\\d+)?';
    const sideanleggCaptureAllPattern = `(${sideanleggPattern})?(${sidedelPattern})?(${meterPattern})?`;
    const sideanleggFullPattern = `(?<sideanlegg>(?:${sideanleggPattern})?(?:${sidedelPattern})?(?:${meterPattern})?)?`;

    const sideanleggRegExp = new RegExp(sideanleggPattern, 'i');
    const sidedelRegExp = new RegExp(sidedelPattern, 'i');

    // Vegsystemreferanse
    const fullPattern = `${vegsystemGroupPattern}${strekningFullPattern}${kryssystemFullPattern}${sideanleggFullPattern}$`;
    const fullRegExp = new RegExp(fullPattern, 'i');

    const roadcategoryFromShortName = word => {
        const vegkategori = vegkategorier.find(vk => vk.kortnavn === word);
        return new Vegkategori(vegkategori.verdi, vegkategori.kortnavn);
    };
    return (input: string) => {
        const word = input.toUpperCase().replace(/\s/g, '');
        if (word.length === 1 && vegkategorierKortnavn.includes(word)) {
            return roadcategoryFromShortName(word);
        }
        // Try first to match long roadsysref name
        if (regexpForLongRoadSysRefNames.test(word)) {
            const wordAsVegRef = word[0];
            if (/\d+/.test(word)) {
                // europaveg6
                //return new Vegsystemreferanse(wordAsVegRef + /\d+/.exec(word));
            }
            return roadcategoryFromShortName(wordAsVegRef);
        } else if (fullRegExp.test(word)) {
            return parseVegsystemReferanse(word);
        }
        return null;
    };

    function parseVegsystemReferanse(word: string): Vegsystemreferanse {
        const { vegsystem, strekning, kryssystem, sideanlegg } = word.match(fullPattern).groups;

        const kommune = vegsystem ? parseKommune(vegsystem) : null;
        const vegsystemParsed: Vegsystem = vegsystem ? parseVegsystem(vegsystem) : null;
        const strekningParsed: Strekning = strekning ? parseStrekning(strekning) : null;
        const kryssystemParsed: Kryssystem = kryssystem ? parseKryssystem(kryssystem) : null;
        const sideanleggParsed: Sideanlegg = sideanlegg ? parseSideanlegg(sideanlegg) : null;

        const v = new Vegsystemreferanse(
            vegsystemParsed,
            strekningParsed,
            kryssystemParsed,
            sideanleggParsed,
            word.replace(/^\d{4}/, ''),
            kommune
        );
        return v;
    }

    function parseKommune(word: string): number {
        const kommune = word.match(vegsystemCaptureAllPattern)?.groups['kommune'];
        if (kommune) {
            const number = parseInt(kommune);
            if (number && !isNaN(number)) return number;
        }
        return null;
    }

    function parseVegsystem(word: string): Vegsystem {
        const matches = word.match(vegsystemCaptureAllPattern);
        const { kortnavn, fase, nummer } = matches.groups;

        const parsedNummer = parseInt(nummer);
        return new Vegsystem(
            kortnavn ?? null,
            fase ?? null,
            !isNaN(parsedNummer) && !!parsedNummer ? parsedNummer : null
        );
    }

    function parseStrekning(word: string): Strekning {
        const matches = word.match(strekningCaptureAllPattern).filter((match, index) => index !== 0);

        let strekning: Vegposisjon = null;
        let delstrekning: Vegposisjon = null;
        let trafikantgruppe: string = null;
        let meter: Vegposisjon = null;

        for (const match of matches) {
            if (strekningRegExp.test(match || '')) {
                strekning = parsePosisjon(match);
            } else if (delstrekningRegExp.test(match || '')) {
                delstrekning = parsePosisjon(match);
            } else if (trafikantgruppeRegExp.test(match || '')) {
                trafikantgruppe = match;
            } else if (meterRegExp.test(match || '')) {
                meter = parsePosisjon(match);
            }
        }
        return new Strekning(strekning, delstrekning, null, null, null, trafikantgruppe, meter, null);
    }

    function parseKryssystem(word: string): Kryssystem {
        const matches = word.match(kryssystemCaptureAllPattern).filter((match, index) => index !== 0);

        let kryssystem: number = null;
        let kryssdel: Vegposisjon = null;
        let meter: Vegposisjon = null;

        for (const match of matches) {
            if (kryssystemRegExp.test(match || '')) {
                kryssystem = Number.parseInt(match.match(intervalPattern)[1]);
            } else if (kryssdelRegExp.test(match || '')) {
                kryssdel = parsePosisjon(match);
            } else if (meterRegExp.test(match || '')) {
                meter = parsePosisjon(match);
            }
        }
        return new Kryssystem(kryssystem, kryssdel, null, meter, null);
    }

    function parseSideanlegg(word: string): Sideanlegg {
        const matches = word.match(sideanleggCaptureAllPattern).filter((match, index) => index !== 0);

        let sideanlegg: number = null;
        let sideanleggsdel: Vegposisjon = null;
        let meter: Vegposisjon = null;

        for (const match of matches) {
            if (sideanleggRegExp.test(match || '')) {
                sideanlegg = Number.parseInt(match.match(intervalPattern)[1]);
            } else if (sidedelRegExp.test(match || '')) {
                sideanleggsdel = parsePosisjon(match);
            } else if (meterRegExp.test(match || '')) {
                meter = parsePosisjon(match);
            }
        }
        return new Sideanlegg(sideanlegg, sideanleggsdel, null, meter);
    }

    function parsePosisjon(match: string): Vegposisjon {
        const matches = match.match(intervalPattern).filter((match, index) => index !== 0);
        const parsed_fra = Number.parseInt(matches[0]);
        const parsed_til = Number.parseInt(matches[1]);
        return Number.isNaN(parsed_til)
            ? new Vegposisjon(parsed_fra)
            : new Vegposisjon(parsed_fra, parsed_til);
    }
}

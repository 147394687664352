import { ActionType, ErrorAction } from '@/actions/actiontypes';
import { handleLostFocus, handleRegainedFocus } from '@/actions/metaActions';
import { PREFERENCES_STORAGE_KEY, RootState, VegkartError } from '@/state';
import { stateToHash } from '@/state/hash/StateToHash';
import { Dispatch } from '@/state/store';
import { getHash } from '@/utils/Utils';
import { Integration } from './integration';

/**
 * Logs all actions and states after they are dispatched.
 */
/* tslint:disable:no-console */
export const logger = store => next => action => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment) {
        const actionType = ActionType[action.type] || action.type;
        console.group(actionType);
        console.info('dispatching', { ...action, type: actionType });
    }
    const result = next(action);
    if (isDevelopment) {
        console.log('next state', store.getState());
        console.groupEnd();
    }
    return result;
};

/**
 * Changes URL hash if necessary after each action.
 */

export const hashChanger = store => next => action => {
    const actionCall = next(action);
    const state: RootState = store.getState();
    const currentURI = getHash(window.location.hash);
    const hashState = stateToHash(state);
    if (currentURI !== hashState) {
        window.location.replace(
            window.location.pathname.replace('//', '/') + window.location.search + '#' + hashState
        );
    }

    localStorage.setItem(PREFERENCES_STORAGE_KEY, JSON.stringify(state.preferences));

    return actionCall;
};

export function bindUpdateOnHashChange(dispatch: Dispatch): void {
    window.addEventListener('blur', () => dispatch(handleLostFocus(window.location.hash)), false);
    window.addEventListener('focus', () => dispatch(handleRegainedFocus(window.location.hash)), false);
}

export function bindErrorCatcher(dispatch, integration: Integration): void {
    window.onerror = (message: string, filename?: string, lineno?: number, colno?: number, error?: Error) => {
        const vegkartError = new VegkartError('En feil har oppstått', {
            colno,
            error,
            filename,
            lineno,
            message,
        });
        const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error: vegkartError };
        dispatch(errorAction);
        integration.server.postErrorReport(vegkartError);
    };
}

import { ExportEnumFormat } from '@/domain/ExportEnumFormat';
import { Projeksjon, toSrid } from '@/domain/Projeksjon';
import { ExportQueries } from '@/middleware/queries/ExportQueries';

export const PREFERENCES_STORAGE_KEY = 'preferences';

export class PreferencesState {
    constructor(
        readonly includeTrafikantgruppe: boolean = true,
        readonly lesApiOverride: string | null = null,
        readonly transformTinyGeometries: boolean = false,
        readonly disableClusters: boolean = true,
        readonly showQualityInfo: boolean = false,
        readonly showMetadata: boolean = false,
        readonly showPropertyBoundaries: boolean = false,
        readonly fetchStatisticsLength: boolean = false,
        readonly exportProjection: Projeksjon = Projeksjon.UTM_33,
        readonly exportEnumFormat: ExportEnumFormat = ExportEnumFormat.StandardID
    ) {}

    override(modifyObject: { [P in keyof PreferencesState]?: PreferencesState[P] }): PreferencesState {
        return Object.assign(Object.create(PreferencesState.prototype), { ...this, ...modifyObject });
    }

    toExportQueries() {
        const srid = toSrid(this.exportProjection).toString();
        return new ExportQueries(srid);
    }
}

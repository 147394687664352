import { Stedsnavn } from '@/server/responses';
import { Vegsystemreferanse } from '@/domain/omrader';
import { Veglenkesekvensposisjon } from '@/domain/Veglenkesekvensposisjon';
import { WKTObject } from '@/domain/WKTObject';
import { round } from 'lodash-es';

export enum GeolocationState {
    Inactive,
    Active,
    Follow,
}

export class OverlayState {
    constructor(
        readonly vegsystemreferanseLookup: VegsystemreferanseLookup = null,
        readonly geolocationState: GeolocationState = GeolocationState.Inactive,
        readonly placenameMarker: Stedsnavn = null,
        readonly measuring = false
    ) {}

    override(modifyObject: { [P in keyof OverlayState]?: OverlayState[P] }): OverlayState {
        return Object.assign(Object.create(OverlayState.prototype), { ...this, ...modifyObject });
    }

    withVegsystemreferanseLookup(vegsystemreferanseLookup: VegsystemreferanseLookup): OverlayState {
        return this.override({ vegsystemreferanseLookup });
    }

    withGeolocationState(geolocationState: GeolocationState): OverlayState {
        return this.override({ geolocationState });
    }

    withMeasuring(measuring: boolean): OverlayState {
        return this.override({ measuring });
    }

    withPlacenameMarker(placenameMarker: Stedsnavn): OverlayState {
        return this.override({ placenameMarker });
    }
}

export class VegsystemreferanseLookup {
    readonly east: number;
    readonly north: number;
    readonly vegsystemreferanse: Vegsystemreferanse;
    readonly veglenke: Veglenkesekvensposisjon;
    readonly geometri: WKTObject;
    readonly avstand: number;
    readonly kommune?: number;

    constructor(
        east: number,
        north: number,
        vegsystemreferanse: Vegsystemreferanse = null,
        veglenke: Veglenkesekvensposisjon = null,
        geometri: WKTObject = null,
        avstand: number = null,
        kommune = null
    ) {
        this.east = round(east, 3);
        this.north = round(north, 3);
        this.vegsystemreferanse = vegsystemreferanse;
        this.veglenke = veglenke;
        this.geometri = geometri;
        this.avstand = avstand;
        this.kommune = kommune;
    }
}

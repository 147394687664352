import * as React from 'react';
import { connect } from 'react-redux';
import { Egenskapsfilter } from '@/middleware/queries';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { VegobjekttypeState } from '@/state';
import EgenskapsfilterContainer from './Egenskapsfilter';
import {
    addFilterToVegobjekttype,
    addFilterToVegobjekttypeT,
    removeVOTFilter,
    removeVOTFilterT,
} from '@/actions/vegobjekterActions';

interface TagProps {
    vegobjekttype: VegobjekttypeState;
    egenskapstyper: Egenskapstype[];
}

interface DispatchProps {
    doRemoveFilter: removeVOTFilterT;
    doAddFilter: addFilterToVegobjekttypeT;
}

const mapDispatchToProps = {
    doAddFilter: addFilterToVegobjekttype,
    doRemoveFilter: removeVOTFilter,
};

type Props = TagProps & DispatchProps;
export class FiltereComponent extends React.Component<Props, {}> {
    readonly state = { open: false };

    render() {
        return (
            <>
                <button className="c-filtere__button" id="filtere_button" onClick={() => this.addFilter()}>
                    <i className="o-icon-add c-filtere__add-icon" />
                    Legg til filter
                </button>
                <ul className="c-filtere__list" aria-label="Liste med egenskapsfiltre">
                    {this.props.vegobjekttype.filters.map(f => this.getEgenskapsfilterContainer(f))}
                </ul>
            </>
        );
    }

    private addFilter() {
        this.props.doAddFilter(this.props.vegobjekttype);
    }

    private getEgenskapsfilterContainer(filter: Egenskapsfilter) {
        return (
            <EgenskapsfilterContainer
                key={filter.uuid}
                vegobjekttype={this.props.vegobjekttype}
                filter={filter}
                egenskapstyper={this.props.egenskapstyper}
            />
        );
    }
}

export const Filtere = connect(null, mapDispatchToProps)(FiltereComponent);

import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { SplashState } from '@/state/SplashState';
import { fetchEksportChangelog, fetchVegkartChangelog } from '@/actions/splashActions';
import { Dispatch, useDispatch, useSelector } from '@/state/store';
import { stateConfig } from '@/selectors/selectors';
import { runtimeEnvironment } from '@/config';
import { FeatureFlag, flags } from '@/featureFlags';

interface ChangelogProps {
    handleWindowChange: (window: SplashState) => void;
}

const CHANGELOG_MAX_LINES = 50;

export const Changelog = forwardRef(
    ({ handleWindowChange }: ChangelogProps, ref: ForwardedRef<HTMLDialogElement>) => {
        const dispatch = useDispatch();
        Changelog.displayName = 'Changelog';

        const [eksportChangelog, setEksportChangelog] = useState<string>('');
        const [latestChange, setLatestChange] = useState<string>('');
        const [oldChanges, setOldChanges] = useState<string>('');

        const config = useSelector(stateConfig);
        useEffect(() => {
            getChangelog(dispatch)
                .then(changes => {
                    const { latest, old } = splitChangelog(changes);
                    setLatestChange(latest);
                    setOldChanges(old);
                })
                .catch(e => console.warn(e));
            getExportChangelog(dispatch)
                .then(latest => setEksportChangelog(latest))
                .catch(e => console.warn(e));
        }, [dispatch]);

        return (
            <dialog
                className={'c-changelog-container'}
                ref={ref}
                aria-labelledby={'changelog-header'}
                aria-modal="true"
            >
                <header className="c-changelog-header" role={'heading'}>
                    <div id={'changelog-header'} className="c-changelog-header__text" tabIndex={0}>
                        Versjonslogg
                    </div>
                    <div className="c-changelog-header__close">
                        <button
                            type="button"
                            className="o-btn o-icon-arrow-back--white c-about-header__back-image"
                            onClick={() => handleWindowChange(SplashState.MAIN)}
                            aria-label="Lukk versjonslogg"
                            id="close_changelog_header_button"
                        />
                    </div>
                </header>
                <main className="c-changelog-main" aria-label="Fullstendig endringslogg">
                    <ReactMarkdown className={'c-changelog-main__latest-changes'}>
                        {latestChange}
                    </ReactMarkdown>
                    {oldChanges ? (
                        <ReactMarkdown className={'c-changelog-main__old-changes'}>
                            {oldChanges}
                        </ReactMarkdown>
                    ) : null}
                    <ReactMarkdown>{eksportChangelog}</ReactMarkdown>
                    <h2>Fullstendig endringslogg: </h2>
                    <a
                        className="c-splash-footer-interactive__item"
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://github.com/nvdb-vegdata/endringslogg/blob/master/vegkart.md"
                    >
                        Vegkart
                    </a>
                    <br />
                    <a
                        className="c-splash-footer-interactive__item"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={new URL('/changelog', config.eksport_url).href}
                    >
                        Eksport
                    </a>
                </main>
            </dialog>
        );
    }
);
async function fetchProductionChangelog(dispatch: Dispatch): Promise<string> {
    const raw = await dispatch(fetchVegkartChangelog()).unwrap();
    // Cut off at CHANGELOG_MAX_LINES and trim trailing changeslog entries
    const split = raw
        .split('\n')
        .map(s => s.replace(/-/g, ''))
        .slice(0, CHANGELOG_MAX_LINES);
    const index = split.map(s => s.replace(/[-\s]/g, '')).lastIndexOf('');
    return split.slice(0, index === -1 ? undefined : index).join('\n');
}

/**
 * Retrieves the current changelog. In production (or with feature flag enabled) this is fetched from an external source.
 * Splits the changelog into two sections: the latest set of changes and older changes.
 */
async function getChangelog(dispatch: Dispatch) {
    const useRemoteChangelog =
        runtimeEnvironment() === 'prod' || flags().has(FeatureFlag.SHOW_PRODUCTION_CHANGELOG);
    return useRemoteChangelog ? await fetchProductionChangelog(dispatch) : process.env.changelog;
}

/**
 * Split the changelog into 'latest' and 'old' changes
 */
function splitChangelog(changelog: string): { latest: string; old: string } {
    const split = changelog
        .trim()
        .split(/^(?= *# +)/m)
        .map(s => s.trim());
    return { latest: split[0], old: split.slice(1).join('\n') };
}

async function getExportChangelog(dispatch: Dispatch): Promise<string> {
    const raw = await dispatch(fetchEksportChangelog()).unwrap();
    return `
# ${raw.version} ${raw.version !== '' ? '(Eksport)' : ''}
${raw.changes}`;
}

import { ActionType, UserLoginAction, UserLogoutAction } from './actiontypes';
import { Integration } from '@/middleware/integration';
import { LAST_LOGIN_KEY, SVVTOKENS_KEY } from '@/utils/AuthenticationUtils';
import { updateSelectedFeature } from './valgtActions';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const userLogin =
    (tokens: { idToken: string; accessToken: string }) => (dispatch, _, integration: Integration) => {
        const jsonTokens = JSON.stringify(tokens);
        localStorage.setItem(SVVTOKENS_KEY, jsonTokens);
        localStorage.setItem(LAST_LOGIN_KEY, new Date().toString());
        integration.server.setSVVToken(tokens.idToken);
        const userLoginAction: UserLoginAction = {
            type: ActionType.LOGIN,
            idToken: tokens.idToken,
        };
        dispatch(userLoginAction);
        dispatch(updateSelectedFeature());
    };

export const userLogout = () => (dispatch, _, integration: Integration) => {
    localStorage.removeItem(SVVTOKENS_KEY);
    localStorage.removeItem(LAST_LOGIN_KEY);
    integration.server.setSVVToken();
    const userLogoutAction: UserLogoutAction = {
        type: ActionType.LOGOUT,
    };
    dispatch(userLogoutAction);
    dispatch(updateSelectedFeature());
};

export const authorizeExport = createAsyncThunk<string, { url: string }, { extra: Integration }>(
    'eksport/preauthorize',
    async ({ url }, { extra }) => {
        return extra.server.preauthorizeExportRequest(url);
    }
);

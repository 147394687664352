import { Coordinate } from 'ol/coordinate';
import Overlay from 'ol/Overlay';
import React, { useEffect, useMemo } from 'react';
import { useMap } from '@/state/context/MapContext';

interface MeasureMarkerProps {
    coordinates: Coordinate;
    value: number;
    locked: boolean;
}

export const MeasureTooltip = ({ coordinates, value, locked }: MeasureMarkerProps) => {
    const { map } = useMap();
    const overlay = useMemo(() => {
        const o = new Overlay({
            offset: [0, -15],
            positioning: 'bottom-center',
            stopEvent: false,
            insertFirst: false,
        });
        map.addOverlay(o);
        return o;
    }, [map]);

    useEffect(() => {
        overlay.setPosition(coordinates);
    }, [coordinates, overlay]);
    return (
        <div
            ref={r => overlay.setElement(r)}
            className={`c-map-tooltip c-map-tooltip-${locked ? 'static' : 'measure'}`}
        >
            {value}
        </div>
    );
};

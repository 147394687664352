import React, { ReactNode } from 'react';
import { Discriminate } from '@/utils/Utils';

export type DropdownItemData = {
    title: string | ReactNode;
    icon?: string;
    subtitle?: string;
    onClick?: () => void;
    header?: boolean;
    category?: string;
    disabled?: boolean;
};

type DropdownItemProps = {
    focusIndex: number;
    index: number;
    buttonClassName: string;
    itemClassName?: string;
    clickHandler?: (DropdownItemData) => void;
} & Discriminate<
    { item: DropdownItemData },
    { icon?: string; title: string; subtitle: string; disabled?: boolean }
>;

const DropdownItem = ({
    clickHandler,
    focusIndex,
    index,
    itemClassName,
    buttonClassName,
    title,
    subtitle,
    icon,
    item,
    disabled,
}: DropdownItemProps) => {
    const isDisabled = item ? item.disabled : disabled;
    const onClick = clickHandler ? () => clickHandler(item) : item?.onClick;
    return (
        <li aria-hidden className={itemClassName}>
            <button
                disabled={isDisabled}
                tabIndex={-1}
                type="button"
                onClick={onClick}
                id={item.title?.toString()}
                className={`item  ${buttonClassName} ${focusIndex === index ? 'active' : ''}`}
            >
                <i role={'img'} className={`${item ? item.icon : icon} dropdown-icon`} aria-hidden={true} />
                {item.title && typeof title !== 'string' ? (
                    item.title
                ) : (
                    <span className="item-label">{item ? item.title : title}</span>
                )}
                <span className="item-info">{item ? item.subtitle : subtitle}</span>
            </button>
        </li>
    );
};

export default DropdownItem;

DropdownItem.defaultProps = {
    itemClassName: '',
    icon: '',
    title: '',
    subtitle: '',
};

import { ActionType, ValgtAction } from '@/actions/actiontypes';
import { ValgtState } from '@/state';

const initialState = new ValgtState();

export const valgtReducer = (valgtState: ValgtState = initialState, action: ValgtAction) => {
    switch (action.type) {
        case ActionType.VALGT_NODE_REMOVED:
            return valgtState.withNodes(valgtState.nodes.filter(n => n.id !== action.id));
        case ActionType.VALGT_NODE_RECEIVED:
            return valgtState.withNodes([...valgtState.nodes, action.node]);
        case ActionType.VALGT_LENKE_REMOVED: {
            const { [action.linkQueryString]: _, ...linkQueries } = valgtState.linkQueries;
            return valgtState.withLinkQueries(linkQueries);
        }
        case ActionType.VALGT_CLUSTER_REMOVED: {
            return valgtState.withClusterObjekter([]);
        }
        case ActionType.VALGT_LENKE_RECEIVED:
            return valgtState.withLinkQuery(action.linkQueryString, action.links);
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.valgtState;
        case ActionType.HASH_CHANGED:
            return action.state.valgtState;
        // Valgt
        case ActionType.VALGT_OBJEKT_SELECTED:
            return action.selection;
        case ActionType.VALGT_OBJEKT_RECEIVED:
            return action.selection;
        case ActionType.VALGT_COLOR_UPDATED:
            return valgtState.withColor(action.color);
        case ActionType.VALGT_OBJEKT_REMOVED:
            if (action.id)
                return valgtState.withVegobjekter(valgtState.vegobjekter.filter(v => v.id !== action.id));
            else return valgtState.withVegobjekter([]).withVegnettlenker([]).withClusterObjekter([]);
        default:
            return valgtState;
    }
};

export class Stedfesting {
    constructor(
        readonly veglenkesekvensid: number,
        readonly kortform: string,
        readonly startposisjon: number,
        readonly sluttposisjon: number,
        readonly retning: 'MED' | 'MOT',
        readonly sideposisjon: string = null,
        readonly kjørefelt: string[] = null,
        readonly type: string
    ) {}
}

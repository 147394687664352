import { Omrade } from '@/domain/omrader';

export class VegobjekterStatisticsResponse {
    constructor(
        readonly antall: number,
        readonly lengde: number,
        readonly area: Omrade = null,
        readonly subStatistics: VegobjekterStatisticsResponse[] = []
    ) {}

    withArea(area: Omrade): VegobjekterStatisticsResponse {
        return new VegobjekterStatisticsResponse(this.antall, this.lengde, area, this.subStatistics);
    }

    withSubStatistics(subStatistics: VegobjekterStatisticsResponse[]): VegobjekterStatisticsResponse {
        return new VegobjekterStatisticsResponse(this.antall, this.lengde, this.area, subStatistics);
    }
}

import { getHash } from '@/utils/Utils';
export enum FeatureFlag {
    POLYGONS,
    LOCAL_CATEGORIES,
    EXTRA_TILES,
    SHOW_PRODUCTION_CHANGELOG,
}

const windowKey = 'vegkart-features';
const defaultFlags = new Set<FeatureFlag>([FeatureFlag.POLYGONS]);
export function initFlagsFromHash(): void {
    const hash = getHash(window.location.hash);
    const pattern = /features:([^/]+)?/;
    if (pattern.test(hash)) {
        const match = hash.match(pattern)[1];
        console.log(match.split(','), FeatureFlag);
        window[windowKey] = new Set<FeatureFlag>(
            match
                .split(',')
                .map(s => FeatureFlag[s])
                .filter(v => v !== undefined)
        );
        console.log('Activated feature flags: ', flags());
    }
}
declare global {
    interface Window {
        [windowKey]: Set<FeatureFlag>;
    }
}
export function flags(): Set<FeatureFlag> {
    return window[windowKey] || defaultFlags;
}

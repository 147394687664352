import { Feature } from 'ol';
import { Extent, getSize, getCenter } from 'ol/extent';
import { WKT } from 'ol/format';
import { Geometry, Point } from 'ol/geom';
import GeometryCollection from 'ol/geom/GeometryCollection';
import {
    FeatureColorKey,
    FeatureCountKey,
    FeatureDirection,
    FeatureGlobalDirection,
    FeatureLayerType,
    FeatureLayerTypeKey,
    FeatureRoadType,
    FeatureShowDirectionalArrows,
    FeatureTypeKey,
} from '@/components/map/layers/FeatureLayerType';
import { EPSG25833, EPSG32633 } from '@/components/map/map-utils/Projections';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Severity, VegkartError, VegnettStatistics, VegsystemreferanseLookup } from '@/state';
import { defaultColor, halfBlack, red } from './ColorHelper';
import { createLogger } from './Logger';
import { preprocessWKT } from './WKT';
import { Node } from '@/domain/vegnett/Node';
import { WKTObject } from '@/domain/WKTObject';

const logger = createLogger('OpenLayersHelper');
const format = new WKT();

export function wktObjectToFeature(
    geometry: Geometry | string,
    id: string | number,
    featureTypeId: number,
    featureLayerType: FeatureLayerType,
    featureColor = '#888888'
): Feature<Geometry> {
    const feature = new Feature(geometry instanceof Geometry ? geometry : wktToGeometry(geometry));
    feature.setId(id);
    feature.set(FeatureLayerTypeKey, featureLayerType);
    feature.set(FeatureTypeKey, featureTypeId);
    feature.set(FeatureColorKey, featureColor);
    return feature;
}

export function roadRefCoordinates(roadReferenceLookup: VegsystemreferanseLookup): number[] {
    if (!roadReferenceLookup.geometri) return null;

    const getCoordinatesFromExtent = (geometri: Geometry): number[] => {
        const center = getCenter(geometri.getExtent());
        return geometri.getClosestPoint(center);
    };

    const geometry = wktToGeometry(roadReferenceLookup.geometri.wkt);
    return geometry.getType() === 'Point'
        ? (geometry as Point).getCoordinates()
        : getCoordinatesFromExtent(geometry);
}

export function roadNetNodeToFeature(node: Node): Feature<Point> {
    const geometry = wktToGeometry(node.geometri.wkt);
    if (geometry.getType() !== 'Point')
        throw new VegkartError('Node mangler punktgeometri!', { node }, Severity.WARN);
    const feature = new Feature(geometry as Point);
    feature.setId(node.id);
    feature.set(FeatureLayerTypeKey, FeatureLayerType.FEATURE_VALGT_NODE);
    feature.set(FeatureColorKey, defaultColor);
    feature.set(FeatureCountKey, 1);
    return feature;
}

export function roadNetLinkToFeature(
    incomingLink: Vegnettlenke,
    vegnettRetningsgrunnlag: string,
    customColor = null
): Feature<Geometry> {
    const feature = new Feature(wktToGeometry(incomingLink.geometri.wkt));
    const linkDirection = getLinkDirection(incomingLink);
    const metrert =
        incomingLink.vegsystemreferanse?.strekning?.meterAvgrensning?.slutt ||
        incomingLink.vegsystemreferanse?.kryssystem?.meterAvgrensning?.slutt ||
        incomingLink.vegsystemreferanse?.sideanlegg?.meterAvgrensning?.slutt;
    const showDirectionalArrows =
        vegnettRetningsgrunnlag === 'geometri' || (vegnettRetningsgrunnlag === 'metrering' && metrert);
    const color = (): string => {
        if (incomingLink.type === 'KONNEKTERING' || incomingLink.type === 'DETALJERT_KONNEKTERING') {
            return 'rgba(0, 0, 0, 0.2)';
        } else if (!metrert) {
            return halfBlack;
        } else if (incomingLink.typeVeg === 'Fortau') {
            return linkDirection === 'MOT' ? red : 'rgba(0, 0, 0, 0.5)';
        } else if (vegnettRetningsgrunnlag === 'geometri') {
            return halfBlack;
        } else if (linkDirection === 'MED') {
            return halfBlack;
        } else if (linkDirection === 'MOT') {
            return red;
        } else {
            return halfBlack;
        }
    };

    feature.setId(incomingLink.referanse);
    feature.set(FeatureLayerTypeKey, FeatureLayerType.FEATURE_VEGNETTLENKE);
    feature.set(FeatureColorKey, customColor || color());
    feature.set(FeatureRoadType, incomingLink.typeVeg);
    feature.set(FeatureGlobalDirection, vegnettRetningsgrunnlag);
    feature.set(FeatureDirection, linkDirection);
    feature.set(FeatureShowDirectionalArrows, showDirectionalArrows);

    return feature;
}

export function getLinkDirection(incomingLink: Vegnettlenke): string {
    if (incomingLink.vegsystemreferanse?.kryssystem?.retning) {
        return incomingLink.vegsystemreferanse.kryssystem.retning;
    } else if (incomingLink.vegsystemreferanse?.sideanlegg?.retning) {
        return incomingLink.vegsystemreferanse.sideanlegg.retning;
    } else if (incomingLink.vegsystemreferanse?.strekning?.retning) {
        return incomingLink.vegsystemreferanse.strekning.retning;
    } else return null;
}

export function roadNetStatisticToFeature(incomingStatistic: VegnettStatistics): Feature<Geometry> {
    const feature = new Feature(wktToGeometry(incomingStatistic.omrade.senterpunkt.wkt));
    feature.setId(incomingStatistic.omrade.id);
    feature.set(FeatureCountKey, incomingStatistic.antall);
    feature.set(FeatureLayerTypeKey, FeatureLayerType.FEATURE_COUNT);
    feature.set(FeatureColorKey, halfBlack);
    return feature;
}

export function featureTooSmall(feature: Feature, resolution: number): boolean {
    const bboxSize = getSize(feature.getGeometry().getExtent());
    const bboxArea = bboxSize[0] * bboxSize[1];
    const pixelArea = bboxArea / (resolution * resolution);
    return pixelArea < 50;
}
/**
 * Transforms a wkt string to an OpenLayers Geometry object.
 * @param {string} incomingWkt The wkt string to transform
 * @returns {Geometry} The converted Geometry object.
 */
export function wktToGeometry(incomingWkt: string): Geometry {
    try {
        const wkt = preprocessWKT(incomingWkt.replace(/[\u2013\u2014\u2212]/g, '-'));
        return format.readGeometry(wkt, {
            dataProjection: EPSG32633,
            featureProjection: EPSG25833,
        });
    } catch (e) {
        logger(`Could not parse ${incomingWkt}: ${e}`);
        throw e;
    }
}

/**
 * Determine whether a feature is a cluster of features.
 * @param {Feature} feature The feature to test
 * @returns {boolean} True if the feature is a cluster
 */
export function isMultiFeatureCluster(feature: Feature<Geometry>): boolean {
    const features = feature.get('features');
    return features != null && features.length > 1;
}

const allEqual = (list: unknown[]) => [...new Set(list)].length === 1;

/**
 * Determine whether a list of features contains a set of roadobjects of the same type
 * @param {Feature} list The feature to test
 * @returns {boolean} True if the feature has a features property.
 */
export function isMultiRoadObjectSelection(list: Feature<Geometry>[]): boolean {
    return (
        list.length > 1 &&
        list.map(f => f.get(FeatureLayerTypeKey)).every(f => f == FeatureLayerType.FEATURE_VEGOBJEKT) &&
        allEqual(list.map(f => f.get(FeatureTypeKey)))
    );
}

/**
 * Determine whether a feature is a feature wrapper (has a 'features' property)
 * @param {Feature} feature The feature to test
 * @returns {boolean} True if the feature has a features property.
 */
export function isWrappedFeature(feature: Feature<Geometry>): boolean {
    return typeof feature.get('features') !== 'undefined';
}

/**
 * Get the contained features from a cluster
 * @param {Feature} cluster The wrapping cluster feature
 * @returns {Feature[]} The unwrapped features. (Or the feature itself if it is not a cluster)
 */
export function getClusteredFeatures(cluster: Feature<Geometry>): Feature<Geometry>[] {
    if (isWrappedFeature(cluster)) {
        return cluster.get('features');
    } else {
        return [cluster];
    }
}

/**
 * Determine whether a feature is a Point geometry or not
 * @param {Feature} feature The feature to test
 * @returns {boolean} True if the feature has a Point geometry
 */
export function isPointFeature(feature: Feature<Geometry>): boolean {
    return feature.getGeometry().getType() === 'Point';
}

/**
 * Determine whether a feature is a MultiPoint geometry or not
 * @param {Feature} feature The feature to test
 * @returns {boolean} True if the feature has a MultiPoint geometry
 */
export function isMultiPointFeature(feature: Feature<Geometry>): boolean {
    return feature.getGeometry().getType() === 'MultiPoint';
}

/**
 * Determine whether a feature is a Polygon geometry or not
 * @param {Feature} feature The feature to test
 * @returns {boolean} True if the feature has a Polygon geometry
 */
export function isPolygonFeature(feature: Feature<Geometry>): boolean {
    const geometry = feature.getGeometry();
    return geometry && geometry.getType() === 'Polygon';
}

/**
 * Get the first contained feature from a cluster
 * @param {Feature} cluster The wrapping cluster feature
 * @returns {Feature} The first of the unwrapped features. (Or the feature itself if it is not a cluster)
 */
export function getFirstClusteredFeature(cluster: Feature<Geometry>): Feature<Geometry> {
    return getClusteredFeatures(cluster)[0];
}

/**
 * Generate a new Kartutsnitt from an ol.Extent
 * @param {Extent} extent The Extent to convert
 * @returns {Kartutsnitt} a Karutsnitt defining the same extent
 */
export function extentToKartutsnitt(extent: Extent): Kartutsnitt {
    return new Kartutsnitt(extent[0], extent[1], extent[2], extent[3]);
}

/**
 * Generate a new ol.Extent from a Kartutsnitt
 * @param {Kartutsnitt} kartutsnitt The kartutsnitt to convert
 * @returns {Extent} an ol.Extent defining the same extent
 */
export function kartutsnittToExtent(kartutsnitt: Kartutsnitt): Extent {
    return [kartutsnitt.xMin, kartutsnitt.yMin, kartutsnitt.xMax, kartutsnitt.yMax];
}

/**
 * Generate a new Extent from an unspecified number of wktStrings.
 * @param wktStrings to convert.
 * @returns {Extent} of the total geometry extent.
 */
export function extentFromWktStrings(wktStrings: string[]): Extent {
    const collection: GeometryCollection = new GeometryCollection(wktStrings.map(wkt => wktToGeometry(wkt)));
    return collection.getExtent();
}

/**
 * Generate a new Kartutsnitt from an unspecified number of wktStrings.
 * @param wktStrings to convert.
 * @returns {Kartutsnitt} of the total geometry extent.
 */
export function kartutsnittFromWktStrings(wktStrings: string | string[]): Kartutsnitt {
    const formattedString = !Array.isArray(wktStrings) ? Array.of(wktStrings) : wktStrings;
    return extentToKartutsnitt(extentFromWktStrings(formattedString));
}

/**
 * Group a series of WKTs to a single GeometryCollection WKT
 * @param wktStrings to convert.
 * @returns {Kartutsnitt} of the total geometry extent.
 */

export function groupWkts(wkts: WKTObject[]): WKTObject {
    if (!wkts || wkts.length === 0) return null;
    const collection: GeometryCollection = new GeometryCollection(wkts.map(wkt => wktToGeometry(wkt.wkt)));
    const wkt = new WKT().writeFeature(new Feature(collection));
    return { wkt, srid: wkts[0].srid, egengeometri: undefined, forenklet: undefined };
}

import { ActionType, VegnettAction } from '@/actions/actiontypes';
import { VegnettQueries } from '@/middleware/queries';
import { VegnettState } from '@/state';

const initialState = new VegnettState();

export const vegnettReducer = (
    vegnettState: VegnettState = initialState,
    action: VegnettAction
): VegnettState => {
    switch (action.type) {
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.vegnettState;
        case ActionType.HASH_CHANGED:
            return action.state.vegnettState;
        // Vegnett
        case ActionType.VEGNETT_FETCH_INITIATED:
            return vegnettState.withLoading(true);
        case ActionType.VEGNETT_FETCH_RECEIVED:
            return action.receivedVegnettState;
        case ActionType.VEGNETT_FETCH_ABORTED:
            return vegnettState;
        case ActionType.VEGNETT_TOGGLED:
            return vegnettState
                .withLoading(false)
                .withVisible(!vegnettState.visible)
                .withFilter(!vegnettState.visible ? VegnettQueries.AllFiltersOn() : new VegnettQueries());
        case ActionType.VEGNETT_FILTER_UPDATED:
            return vegnettState.withFilter(action.vegnettFilter);
        case ActionType.VEGNETT_DIRECTION_SET:
            return vegnettState.withDirection(action.vegnettRetning);
        default:
            return vegnettState;
    }
};

import React, { ForwardedRef, forwardRef } from 'react';
import { SplashState } from '@/state/SplashState';

interface SplashProps {
    handleWindowChange: (window: SplashState) => void;
}
export const Splash = forwardRef(
    ({ handleWindowChange }: SplashProps, ref: ForwardedRef<HTMLDialogElement>) => {
        Splash.displayName = 'Splash';

        return (
            <>
                <dialog
                    ref={ref}
                    className="c-splash c-splash-container"
                    aria-expanded="true"
                    aria-label={'Informasjon om vegsystemreferanse'}
                    aria-modal="true"
                >
                    <header className="c-splash-header">
                        <div className="o-img-exclamation c-splash-header__image" />
                        <div className="c-splash-header__text" id={'splash-header'}>
                            Veg
                            <span className="c-splash-header__text--highlighted">system</span>
                            referanse
                        </div>
                        <button
                            type="button"
                            title="lukk"
                            id="close_splash_header"
                            onClick={() => handleWindowChange(SplashState.NONE)}
                            className="o-btn o-img-text-close c-splash-header__close"
                            aria-label="Lukk dialog"
                        />
                    </header>
                    <div
                        className="o-img-systemreferanse c-splash-divider__roadsystemreference"
                        role="image"
                    />
                    <section className="c-splash-main">
                        <h1 className="c-splash-main__title">Nasjonal vegsystemreferanse</h1>
                        <p className="c-splash-main__paragraph">
                            Nasjonal vegsystemreferanse ble produksjonssatt i november 2019. Det er tatt i
                            bruk en vegsystemreferanse som benyttes til presentasjon og søk etter vegobjekter.
                        </p>
                        <p className="c-splash-main__paragraph">
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href="https://www.vegvesen.no/fag/teknologi/nasjonal+vegdatabank/vegreferansesystem"
                            >
                                Mer informasjon om referansesystemet finner du her.
                            </a>
                        </p>
                        <p className="c-splash-main__paragraph">
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href="https://www.vegvesen.no/fag/teknologi/nasjonal+vegdatabank/kart"
                            >
                                Mer om Vegkart og Nasjonal vegdatabank finner du her.
                            </a>
                        </p>
                        <p className="c-splash-main__paragraph">
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href="https://www.vegdata.no/vegkart/brukerveiledning/"
                            >
                                Brukerveiledning av Vegkart finner du her.
                            </a>
                        </p>
                    </section>
                    <footer className="c-splash-footer">
                        <hr />
                        <div className="c-splash-footer-interactive">
                            <button
                                className="c-splash-footer-interactive__item"
                                type="button"
                                title="Personvernerklæring"
                                aria-label="Personvernerklæring"
                                onClick={() => handleWindowChange(SplashState.POLICY)}
                            >
                                Personvernerklæring
                            </button>
                            <button
                                className="c-splash-footer-interactive__item"
                                type="button"
                                title="Om Vegkart"
                                aria-label="Om Vegkart"
                                onClick={() => handleWindowChange(SplashState.ABOUT)}
                            >
                                Om Vegkart
                            </button>
                            <button
                                className="c-splash-footer-interactive__item"
                                type="button"
                                title="Versjonslogg"
                                aria-label="Versjonslogg"
                                onClick={() => handleWindowChange(SplashState.CHANGELOG)}
                            >
                                Versjonslogg
                            </button>
                            <a
                                className="c-splash-footer-interactive__item"
                                target="_blank"
                                rel="noreferrer noopener"
                                href="https://www.vegdata.no/hva-du-finner-i-nvdb/data-i-nvdb-2/"
                            >
                                Bruk av data
                            </a>
                        </div>
                    </footer>
                </dialog>
            </>
        );
    }
);

export const uniqueByNummer = <T extends { nummer: number }>(items: T[]): T[] =>
    Array.from(items.reduce((acc, item) => acc.set(item.nummer, item), new Map<number, T>()).values());

export const uniqueByKortform = <T extends { kortform: string }>(items: T[]): T[] =>
    Array.from(items.reduce((acc, item) => acc.set(item.kortform, item), new Map<string, T>()).values());

export const uniqueByNavn = <T extends { navn: string }>(items: T[]): T[] =>
    Array.from(
        items.reduce((acc, item) => acc.set(item.navn.toLowerCase(), item), new Map<string, T>()).values()
    );

export const filterUnique = <T, K>(
    list: K[],
    data: T[],
    comparator: (a: T, b: K) => boolean,
    uniqueFunc: (items: T[]) => T[]
): T[] => {
    return uniqueFunc(list.map(l => data.find(d => comparator(d, l))));
};

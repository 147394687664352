import * as uuidgen from 'uuid';

export class MetaState {
    constructor(readonly errors: VegkartError[] = [], readonly lastHash: string = '') {}

    override(modifyObject: { [P in keyof MetaState]?: MetaState[P] }): MetaState {
        return Object.assign(Object.create(MetaState.prototype), { ...this, ...modifyObject });
    }

    withErrors(errors: VegkartError[]): MetaState {
        return this.override({ errors });
    }

    withLastHash(lastHash: string): MetaState {
        return this.override({ lastHash });
    }
}

export class VegkartError extends Error {
    private __proto__: VegkartError;
    readonly uuid = uuidgen.v4();
    public readonly url: string;

    constructor(
        readonly message: string,
        readonly params?: { [key: string]: unknown },
        readonly severity: Severity = Severity.ERROR
    ) {
        super(message);
        this.url = window.window.location.toString();
        this.__proto__ = VegkartError.prototype;
    }
}

export enum Severity {
    ERROR = 'ERROR',
    WARN = 'WARN',
    INFO = 'INFO',
}

import { Datakatalog } from '@/bootstrap/data';
import {
    Vegobjekt,
    VegobjektEgenskap,
    VegobjektEgenskapKvalitetAttributt,
} from '@/domain/vegobjekter/Vegobjekt';
import { statePreferences } from '@/selectors/selectors';
import { interpretQuality } from '@/utils/Utils';
import { isEmpty } from '@/components/avgrensning/valgt/VegobjektComponent';
import React from 'react';
import { useSelector } from 'react-redux';

type KvalitetProps = {
    vegobjekt: Vegobjekt;
    datakatalog: Datakatalog;
};

function Kvalitet({ vegobjekt, datakatalog }: KvalitetProps) {
    const preferences = useSelector(statePreferences);

    return (
        preferences.showQualityInfo &&
        vegobjekt?.geometri?.egengeometri && (
            <>
                <hr />
                <h4 key="quality__subheader" className="c-valgt-features__subheader">
                    Kvalitet
                </h4>
                {vegobjekt?.egenskaper.some(e => !!e.kvalitet) ? (
                    renderQuality(
                        vegobjekt.egenskaper.find(
                            egenskap =>
                                (!isEmpty(egenskap.verdi) || !!egenskap.href) &&
                                egenskap.erGeometri() &&
                                !!egenskap.kvalitet
                        ),
                        datakatalog
                    )
                ) : (
                    <dd key="quality__dd-p" className="c-valgt-features__dd">
                        Kvalitet ikke registrert
                    </dd>
                )}
            </>
        )
    );
}

function renderQuality(e: VegobjektEgenskap, datakatalog: Datakatalog) {
    const hiddenQualities = ['maksimaltAvvik'];
    return [...e.kvalitet]
        .filter(k => !hiddenQualities.includes(k.name))
        .sort(VegobjektEgenskapKvalitetAttributt.comparator)
        .map(entry => {
            return [
                <dt key={`dt${entry.name}`} className="c-valgt-features__dt" title={entry.displayName}>
                    {entry.displayName}
                </dt>,
                <dd key={`dd${entry.name}`} className="c-valgt-features__dd">
                    {interpretQuality(entry, datakatalog)}
                </dd>,
            ];
        });
}

export default Kvalitet;

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { StyleLike } from 'ol/style/Style';
import { connect } from 'react-redux';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import * as selectors from '../../../selectors/selectors';
import { RootState } from '@/state';
import { roadNetLinkToFeature, roadNetNodeToFeature } from '@/utils/SpatialHelper';
import { lineStyle } from '../style/LineStyle';
import {
    arrowedLineStyle,
    directionalArrowedLineStyle,
    directionalLineStyle,
    featureStyle,
} from '../style/Style';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';
import { Geometry, Point } from 'ol/geom';
import { ColorIndex, resolveColor } from '@/utils/ColorHelper';
import { Node } from '@/domain/vegnett/Node';

interface StateProps {
    links: Record<string, Vegnettlenke[]>;
    nodes: Node[];
    roadNetDirection: string;
    zoom: number;
}

type Props = StateProps;

class LinkQueryFeatureLayer extends React.Component<Props, {}> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    private layer: VectorLayer<VectorSource<Geometry>>;
    private nodeLayer: VectorLayer<VectorSource<Point>>;

    componentDidUpdate() {
        this.context.map.removeLayer(this.layer);
        this.context.map.removeLayer(this.nodeLayer);
        const features = Object.values(this.props.links)
            .reduce(
                (all, links, index) => [
                    ...all,
                    ...links.map(link => ({ link, color: new ColorIndex(index, index) })),
                ],
                [] as { link: Vegnettlenke; color: ColorIndex }[]
            )
            .filter(({ link }) => link.geometri && link.geometri.wkt)
            .map(({ link, color }) =>
                roadNetLinkToFeature(link, this.props.roadNetDirection, resolveColor(color))
            );

        // Line source
        const lineSource = new VectorSource({
            features,
        });

        this.nodeLayer = new VectorLayer<VectorSource<Point>>({
            source: new VectorSource<Point>({ features: this.props.nodes.map(n => roadNetNodeToFeature(n)) }),
            style: featureStyle,
            zIndex: 6,
        });
        // Layer
        this.layer = new VectorLayer({
            source: lineSource,
            style: (this.props.roadNetDirection === 'geometri'
                ? this.props.zoom >= 14
                    ? arrowedLineStyle
                    : lineStyle
                : this.props.zoom >= 14
                ? directionalArrowedLineStyle
                : directionalLineStyle) as StyleLike,
            zIndex: 5,
        });

        this.layer.set('name', 'linkQueryLayer');
        this.nodeLayer.set('name', 'nodeLayer');
        this.context.map.addLayer(this.layer);
        this.context.map.addLayer(this.nodeLayer);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.layer);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState): StateProps => {
    return {
        nodes: selectors.stateValgt(state).nodes,
        links: selectors.stateValgt(state).linkQueries,
        roadNetDirection: selectors.stateVegnettDirection(state),
        zoom: selectors.stateZoom(state),
    };
};

export const LinkQueryLayer = connect(mapStateToProps)(LinkQueryFeatureLayer);

import React, {
    PropsWithChildren,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import DropdownItems from './DropdownItems';

type DropdownContainerProps = PropsWithChildren<{
    containerClassName?: string;
    children: ReactNode;
    id: string;
    focusIndex: number;
    role?: string;
}>;

const DropdownContainer = ({
    containerClassName,
    children,
    id,
    role,
    focusIndex,
}: DropdownContainerProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [bottomVisible, setBottomVisible] = useState(true);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                const isBottomVisible = entry.boundingClientRect.bottom < window.innerHeight;
                const totalHeight =
                    entry.boundingClientRect.height +
                    (ref.current?.previousSibling as HTMLElement).clientHeight;
                const topWouldBeHidden =
                    totalHeight > (ref.current?.previousSibling as HTMLElement).getBoundingClientRect().top;
                if (!topWouldBeHidden) {
                    setBottomVisible(isBottomVisible);
                }
                observer.disconnect();
            }),
        []
    );

    useEffect(() => {
        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, [observer, ref]);

    useLayoutEffect(() => {
        let refValue: HTMLDivElement | null = null; // <-- variable to hold ref value

        if (ref.current)
            if (!bottomVisible && ref.current) {
                refValue = ref.current;
                (ref.current.previousSibling as HTMLElement).classList.add('dropdown-previous-sibling');
            }

        return () => {
            if (refValue?.previousSibling)
                (refValue.previousSibling as HTMLElement).classList.remove('dropdown-previous-sibling');
        };
    }, [bottomVisible]);

    return (
        <div
            className={`dropdown-outer ${!bottomVisible && 'open-upwards'} ${containerClassName}`}
            ref={ref}
            style={{
                bottom:
                    ref.current && !bottomVisible
                        ? (ref.current.previousSibling as HTMLElement).offsetHeight - 2 // - 2 for covering top border of prev sibling
                        : 'inherit',
            }}
        >
            <div className="dropdown-content">
                <DropdownItems id={id} role={role} focusIndex={focusIndex}>
                    {children}
                </DropdownItems>
            </div>
        </div>
    );
};

export default DropdownContainer;

DropdownContainer.defaultProps = {
    containerClassName: '',
};

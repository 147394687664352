import { TileService } from '@/components/map/map-utils/WebMapTileServices';
import { Operator } from '@/middleware/queries';
import { ValgtState } from '@/state';
import { MapState } from '@/state';
import { OmraderState } from '@/state';
import { RootState } from '@/state';
import { VegnettState } from '@/state';
import { VegobjekterState } from '@/state';
import { VegsystemreferanseLookup } from '@/state';
import {
    stateMap,
    stateMapLayer,
    stateOmrader,
    stateSearchDropdownState,
    stateSplash,
    stateValgt,
    stateVegnett,
    stateVegobjekter,
    stateVegsystemreferanseSok,
} from '@/selectors/selectors';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { SplashState } from '../SplashState';
import { format } from 'date-fns';
import qs from 'qs';

interface CategoryRepresentation {
    type: 'interval' | 'enum';
    id: number;
    intervals?: number[];
}

export function stateToHash(state: RootState): string {
    return [
        kartlag(stateMapLayer(state)),
        mapStateToHash(stateMap(state)),
        hvaToHash(stateVegobjekter(state)),
        hvorToHash(stateOmrader(state)),
        roadNetToHash(stateVegnett(state)),
        selection(stateValgt(state)),
        vegsystemreferanseToHash(stateVegsystemreferanseSok(state)),
        splashToHash(stateSplash(state)),
        timestampToHash(stateSearchDropdownState(state).timestamp),
    ]
        .filter(Boolean)
        .join('');
}

function timestampToHash(timestamp: Date): string {
    return timestamp ? `/når:${format(timestamp, 'yyyy-MM-dd')}` : '';
}

function kartlag(mapLayer: TileService): string {
    return `kartlag:${TileService[mapLayer].toLowerCase()}`;
}

function hvaToHash(hva: VegobjekterState): string {
    if (hva.vegobjekttypeStates.length === 0) return '';

    const hvaObject = {
        hva: hva.vegobjekttypeStates.map(type =>
            removeEmptyProperties({
                category: stringifyCategory(type.category),
                intervals: type.intervals,
                absoluteIntervals: type.absoluteIntervals,
                filter: type.filters
                    .filter(f => f.isValid())
                    .map(t => ({
                        operator: Operator.asString(t.operator),
                        type_id: t.egenskapstype.id,
                        verdi: t.verdiValue() === null ? null : [t.verdiValue()],
                    })),
                id: type.typeId,
            })
        ),
    };

    return `/hva:${qs.stringify(hvaObject, { arrayFormat: 'indices' })}`;
}

function hvorToHash(hvor: OmraderState): string {
    const { kommune, fylke, riksvegrute, kontraktsomrade, land, vegsystemreferanse, polygon } = hvor;

    if (
        [kommune, fylke, riksvegrute, kontraktsomrade, land, vegsystemreferanse].every(
            arr => arr.length === 0
        ) &&
        !polygon
    ) {
        return '';
    }

    return `/hvor:${qs.stringify(removeEmptyProperties(mapHvor(hvor)))}`;
}

function mapStateToHash(mapState: MapState): string {
    return `/@${mapState.center[0]},${mapState.center[1]},${mapState.zoom}`;
}

function selection(selected: ValgtState): string {
    let hash = '';

    if (Object.keys(selected.linkQueries).length > 0) {
        hash += `/lenker:${qs.stringify({ links: Object.keys(selected.linkQueries) })}`;
    }

    if (selected.isSingle()) {
        if (selected.singleVegobjekt()) {
            hash += `/valgt:${generateSelectedObjectHash(selected)}`;
        } else if (selected.singleVegnettlenke()) {
            hash += `/veglenke:${generateSelectedObjectHash(selected)}`;
        }
    }

    if (selected.nodes.length > 0) {
        hash += `/noder:${qs.stringify({ nodes: selected.nodes.map(n => n.id) })}`;
    }

    return hash;
}

function generateSelectedObjectHash(selected: ValgtState): string {
    const vo = selected.singleVegobjekt();
    const vnl = selected.singleVegnettlenke();

    if (vo) {
        return `${vo.id}:${vo.metadata.type.id}`;
    }
    if (vnl) {
        return `${vnl.veglenkesekvensid}:${vnl.veglenkenummer}:${vnl.segmentnummer}`;
    }
    return '';
}

function vegsystemreferanseToHash(vegsystemreferansesok: VegsystemreferanseLookup): string {
    if (!vegsystemreferansesok) return '';

    const vegsystemreferanse = vegsystemreferansesok.vegsystemreferanse;

    if (vegsystemreferanse?.senterpunkt || vegsystemreferanse?.geometri) {
        const params = vegsystemreferanse.toParams();
        const vegsystemPart = `${params.kortnavn ?? ''}${params.fase ?? ''}${params.nummer ?? ''}`;
        const strekningPart = `${params.trafikantgruppe ?? ''}${params.strekning ?? ''}${
            params.delstrekning ?? ''
        }${params.meter ?? ''}`;
        const kryssystemPart = `${params.kryssystem ?? ''}${params.kryssdel ?? ''}${params.kryssmeter ?? ''}`;
        const sideanleggPart = `${params.sideanlegg ?? ''}${params.sideanleggsdel ?? ''}${
            params.sidemeter ?? ''
        }`;

        return `/vegsystemreferanse:${vegsystemPart}${strekningPart}${kryssystemPart}${sideanleggPart}`;
    }

    if (vegsystemreferansesok.east && vegsystemreferansesok.north) {
        return `/vegsystemreferanse:${vegsystemreferansesok.east}:${vegsystemreferansesok.north}`;
    }

    return '';
}

function splashToHash(splash: SplashState): string {
    return splash ? `/splash:${SplashState[splash].toLowerCase()}` : '';
}

function roadNetToHash(roadNetState: VegnettState): string {
    return !roadNetState.visible
        ? ''
        : `/vegnett:${qs.stringify({
              visible: roadNetState.visible,
              direction: roadNetState.direction,
              filter: roadNetState.filter,
          })}`;
}

function mapHvor(omrade: OmraderState) {
    return {
        fylke: omrade.fylke.map(f => f.nummer),
        kommune: omrade.kommune.map(k => k.nummer),
        kontraktsomrade: omrade.kontraktsomrade.map(k => k.navn),
        land: omrade.land.map(l => l.navn),
        riksvegrute: omrade.riksvegrute.map(r => `${r.navn} ${r.periode}`),
        vegsystemreferanse: omrade.vegsystemreferanse.map(v => v?.toParams()),
        polygon: omrade.polygon?.wkt.wkt,
    };
}

function stringifyCategory(category: Egenskapstype): CategoryRepresentation | null {
    if (!category || !category.egenskapstype) return null;

    if (category.erEnum()) {
        return { type: 'enum', id: category.id };
    }

    if (category.erTall()) {
        return { type: 'interval', id: category.id };
    }

    return null;
}

function removeEmptyProperties<T>(original: T): T {
    return Object.entries(original)
        .filter(([_, value]) => !(value == null || (Array.isArray(value) && value.length === 0)))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as T;
}

export interface MeasurementPoint {
    coordinates: [number, number];
    value: number;
    locked: boolean;
}

export enum MeasurementType {
    LINESTRING = 'LineString',
    POLYGON = 'Polygon',
}

export class MeasurementState {
    constructor(
        readonly active = false,
        readonly points: MeasurementPoint[] = [],
        readonly measurementType: MeasurementType = undefined
    ) {}

    override(modifyObject: { [P in keyof MeasurementState]?: MeasurementState[P] }): MeasurementState {
        return Object.assign(Object.create(MeasurementState.prototype), { ...this, ...modifyObject });
    }

    withActive(active: boolean): MeasurementState {
        return this.override({ active });
    }
    withPoints(points: MeasurementPoint[]): MeasurementState {
        return this.override({ points });
    }
    withMeasurementType(measurementType: MeasurementType): MeasurementState {
        return this.override({ measurementType });
    }
}

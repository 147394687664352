import { Egenskapstype } from './Egenskapstype';

export class Vegobjekttype {
    // noinspection NonAsciiCharacters
    constructor(
        readonly id: number,
        readonly navn: string,
        readonly kortnavn: string,
        readonly beskrivelse: string,
        readonly stedfesting: VegobjekttypeStedfesting,
        readonly veiledning: string,
        readonly sosinvdbnavn: string,
        readonly sorteringsnummer: number,
        readonly tidsrom_relevant: boolean,
        readonly konnekteringslenke_ok: boolean,
        readonly abstrakt_type: boolean,
        readonly avledet: boolean,
        readonly må_ha_mor: boolean,
        readonly er_dataserie: boolean,
        readonly egenskapstyper: Egenskapstype[],
        readonly relasjonstyper: Relasjonstype,
        readonly sensitiv: boolean
    ) {}

    isMinimal(): boolean {
        return this.tidsrom_relevant === null;
    }

    isLine(): boolean {
        return (
            this?.stedfesting?.innhold?.geometritype === 'LINJE' ||
            this?.stedfesting?.datatype === 'GeomLinje eller Kurve'
        );
    }

    isPoint(): boolean {
        return (
            this?.stedfesting?.innhold?.geometritype === 'PUNKT' ||
            this?.stedfesting?.datatype === 'GeomPunkt'
        );
    }

    egenskapstypeById(id: number): Egenskapstype {
        return this.egenskapstyper.find(e => e.id === id);
    }
}

export class VegobjekttypeStedfesting {
    // noinspection NonAsciiCharacters
    constructor(
        readonly id: number,
        readonly navn: string,
        readonly kortnavn: string,
        readonly kategori: number,
        readonly egenskapstype: string,
        readonly datatype: string,
        readonly sensitivitet: number,
        readonly sorteringsnummer: number,
        readonly viktighet: string,
        readonly skrivebeskyttet: boolean,
        readonly nøyaktighetskrav_grunnriss: number,
        readonly høydereferanse_tall: number,
        readonly innhold: Innhold
    ) {}
}

export class Innhold {
    constructor(
        readonly id: number,
        readonly navn: string,
        readonly kortnavn: string,
        readonly kategori: number,
        // readonly egenskapstype
        // readonly datatype
        // readonly sensitivitet
        // readonly sorteringsnummer
        // readonly viktighet
        // readonly nøyaktighetskrav_grunnriss
        // readonly høydereferanse_tall
        // readonly referansegeometri_tilstrekkelig
        // readonly avledet
        // readonly obligatorisk_verdi
        // readonly gruppesorteringsnummer
        // readonly stedfestingstype
        readonly geometritype: string // readonly overlapp_ok // readonly kjørefelt_relevant // readonly sideposisjon_relevant // readonly innenfor_mor // readonly vegnettsajourhold_splitt // readonly overlappsautomatikk // readonly retning_relevant
    ) {}
}

export class Relasjonstype {
    constructor(readonly barn: Relasjon[], readonly foreldre: Relasjon[]) {}
}

export class Relasjon {
    constructor(
        readonly id: number,
        readonly innenfor_mor: string,
        readonly type: { id: number; navn: string; objektliste_dato?: string },
        readonly innhold: { type: { id: number; navn: string; objektliste_dato?: string } },
        readonly relasjonstype: string
    ) {}
}

import {
    ActionType,
    GetState,
    RemoveSelectedClusterAction,
    RemoveSelectedLinkAction,
    RemoveSelectedNodeAction,
    RemoveSelectedObjectAction,
    SelectedObjectAction,
    UpdateSelectedColorAction,
} from './actiontypes';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { ValgtState } from '@/state';
import { fetchSelectedVegobjekt } from '@/middleware/fetchVegobjekter';
import { stateSelectedRoadObjectTypes, stateValgt } from '@/selectors/selectors';
import { defaultColor, resolveColor } from '@/utils/ColorHelper';
import { fetchRoadNetLinks } from '@/middleware/fetchVegnett';
import { WKT } from 'ol/format';
import { LineString, MultiLineString, Point, Polygon } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import * as turf from '@turf/turf';

export type selectFeatureT = (
    vegobjekt?: Vegobjekt[],
    roadNetLink?: Vegnettlenke[],
    clusterObjekter?: Vegobjekt[],
    color?: string
) => (dispatch, getState) => void;

const wktFormat = new WKT();

const convertToCentroid = wkt => {
    const geometry = wktFormat.readGeometry(wkt);
    if (!geometry) return null;

    if (geometry instanceof Point) {
        return geometry;
    }

    let centroid = null;
    if (
        geometry instanceof Polygon ||
        geometry instanceof LineString ||
        geometry instanceof MultiLineString
    ) {
        const geojson = new GeoJSON().writeGeometryObject(geometry);
        const turfCentroid = turf.centroid(geojson);
        centroid = new Point(turfCentroid.geometry.coordinates);
    }

    return centroid;
};

export const selectFeature: selectFeatureT =
    (vegobjekt, roadNetLink, clusterObjekter, color = defaultColor) =>
    (dispatch, getState) => {
        const state = getState();
        const { linkQueries, nodes, clusterObjekter: existingClusterObjekter } = state.valgtState;

        const needsFetching = !!vegobjekt?.length;

        const currentClusterObjekter =
            clusterObjekter?.map(o => ({
                ...o,
                geometri: { wkt: wktFormat.writeGeometry(convertToCentroid(o.geometri.wkt)) },
            })) || existingClusterObjekter;

        let selection = new ValgtState(
            vegobjekt,
            roadNetLink,
            linkQueries,
            nodes,
            currentClusterObjekter,
            needsFetching,
            color
        );

        const vegobjekttyper = stateSelectedRoadObjectTypes(state);
        const matchingVegobjekttype = vegobjekttyper.find(
            type => type.typeId === selection.singleVegobjekt()?.metadata?.type?.id
        );

        if (matchingVegobjekttype) {
            selection = selection.withColor(resolveColor(matchingVegobjekttype.color));
        }

        if (needsFetching) {
            dispatch(fetchSelectedVegobjekt(selection));
        } else {
            console.log('selection', selection);
            const selectedObjectAction: SelectedObjectAction = {
                type: ActionType.VALGT_OBJEKT_SELECTED,
                selection,
            };
            dispatch(selectedObjectAction);
        }
    };

export const updateSelectedFeature = () => (dispatch, getState) => {
    const state = stateValgt(getState());
    dispatch(selectFeature(state.vegobjekter, state.vegnettlenker, state.clusterObjekter, state.color));
};

export type updateSelectedColorT = (color: string) => (dispatch) => void;
export const updateSelectedColor: updateSelectedColorT = (color: string) => dispatch => {
    const updateSelectedColorAction: UpdateSelectedColorAction = {
        type: ActionType.VALGT_COLOR_UPDATED,
        color,
    };
    dispatch(updateSelectedColorAction);
};
export type clearSelectedFeatureT = (id?: number) => (dispatch) => void;
export const clearSelectedFeature: clearSelectedFeatureT = id => dispatch => {
    const clearSelectionAction: RemoveSelectedObjectAction = {
        type: ActionType.VALGT_OBJEKT_REMOVED,
        id,
    };
    dispatch(clearSelectionAction);
};

export const clearSelectedCluster: clearSelectedFeatureT = () => dispatch => {
    const clearSelectionAction: RemoveSelectedClusterAction = {
        type: ActionType.VALGT_CLUSTER_REMOVED,
    };
    dispatch(clearSelectionAction);
};

export type clearLinkQueryT = (query: string) => (dispatch) => void;
export const clearLinkQuery: clearLinkQueryT = linkQueryString => dispatch => {
    const clearSelectionAction: RemoveSelectedLinkAction = {
        type: ActionType.VALGT_LENKE_REMOVED,
        linkQueryString,
    };
    dispatch(clearSelectionAction);
};

export type clearNodeQueryT = (id: number) => (dispatch) => void;
export const clearNodeQuery: clearNodeQueryT = id => dispatch => {
    const clearSelectionAction: RemoveSelectedNodeAction = {
        type: ActionType.VALGT_NODE_REMOVED,
        id,
    };
    dispatch(clearSelectionAction);
};

export type addLinkQueryT = (linkQuery: string) => (dispatch, getState: GetState) => void;
export type LinkQuery = { id: string; start?: number; end?: number };

export const addLinkQuery: addLinkQueryT = query => dispatch => {
    dispatch(fetchRoadNetLinks(query));
};

import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors/selectors';
import { RootState, ValgtState, VegobjekttypeState } from '@/state';
import { VegobjekttypeContainer } from './VegobjekttypeContainer';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';

interface StateProps {
    searchPanelOpen: boolean;
    selectedFeature: ValgtState;
    selectedRoadObjectTypes: VegobjekttypeState[];
    isLoggedIn: boolean;
}

type Props = StateProps;

class HvaComponent extends React.PureComponent<Props> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;

    render() {
        const searchPanelMobileClass =
            this.props.searchPanelOpen || !this.props.selectedFeature.isEmpty() ? ' t-hidden-mobile' : '';
        const empty = this.props.selectedRoadObjectTypes.length > 0 ? '' : ' t-hidden';
        return (
            <section className={'c-what' + searchPanelMobileClass + empty} aria-label={'søkeresultater'}>
                <ul className="c-what-list o-list" aria-label="Liste over objekttyper som søkes etter">
                    {this.props.selectedRoadObjectTypes.map(rot => this.getVegobjekttypeContainer(rot))}
                </ul>
            </section>
        );
    }

    private getVegobjekttypeContainer(rot: VegobjekttypeState) {
        const { datakatalog } = this.context.data;

        return (
            <VegobjekttypeContainer
                key={rot.typeId}
                vegobjekttypeData={rot}
                vegobjekttype={datakatalog.getTypeById(rot.typeId)}
                farge={rot.color}
                isLoggedIn={this.props.isLoggedIn}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    searchPanelOpen: selectors.stateSearchPanelOpen(state),
    selectedFeature: selectors.stateValgt(state),
    selectedRoadObjectTypes: selectors.stateSelectedRoadObjectTypes(state),
    isLoggedIn: selectors.stateUser(state).isLoggedIn,
});

export const Hva = connect<StateProps>(mapStateToProps)(HvaComponent);

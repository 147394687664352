import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors/selectors';
import { ValgtState, RootState } from '@/state';
import { FilterPanel } from '../FilterPanel';
import { setSearchPanelOpen, setSearchPanelOpenT } from '@/actions/searchActions';

interface Props {
    filterPanelOpen: boolean;
    searchPanelOpen: boolean;
    setSearchPanelOpen: setSearchPanelOpenT;
    selectedVegobjekt: ValgtState;
}

class SearchPanelOpenButtonComponent extends React.Component<Props, {}> {
    render() {
        const panelOpenClass = this.props.searchPanelOpen ? ' t-hidden' : '';
        return [
            <div key="search-open" className={'c-search-panel-open' + panelOpenClass + ' t-visible-mobile '}>
                <button
                    className="o-btn c-search-panel-open__button"
                    type="button"
                    onClick={() => this.showSearch()}
                >
                    Søk her <i className="o-icon-search c-search-panel-open__search-icon" />
                </button>
            </div>,
            <FilterPanel key="filter-open" />,
        ];
    }

    private showSearch() {
        this.props.setSearchPanelOpen(!this.props.searchPanelOpen);
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        filterPanelOpen: selectors.stateFilterPanelOpen(state),
        searchPanelOpen: selectors.stateSearchPanelOpen(state),
        selectedVegobjekt: selectors.stateValgt(state),
    };
};
const mapDispatchToProps = {
    setSearchPanelOpen,
};
export const SearchPanelOpenButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPanelOpenButtonComponent);

import { Omrade } from './Omrade';
import { Vegobjekt } from '../vegobjekter/Vegobjekt';

export class Kontraktsomrade extends Omrade {
    constructor(readonly navn: string, readonly type: string = null, readonly vegobjekt: Vegobjekt = null) {
        super(navn, null, null, null, vegobjekt);
    }

    override(modifyObject: { [P in keyof Kontraktsomrade]?: Kontraktsomrade[P] }): Kontraktsomrade {
        return Object.assign(Object.create(Kontraktsomrade.prototype), { ...this, ...modifyObject });
    }

    withVegobjekt(vegobjekt: Vegobjekt): Kontraktsomrade {
        return this.override({ vegobjekt });
    }

    withVisible(visible: boolean): Kontraktsomrade {
        return this.override({ visible });
    }

    toString(): string {
        return `${this.navn} ${this.type} ${this.nummer}`;
    }

    getAreaType(): string {
        return 'Kontraktsområde';
    }

    toParams(): string {
        return this.navn;
    }
}

import * as React from 'react';

interface ToggleProps {
    checked: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    className?: string;
    label?: string;
    contentList?: string[];
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TriStateToggle = ({
    checked,
    indeterminate,
    disabled = false,
    className = '',
    label = '',
    contentList = [],
    onChange,
}: ToggleProps) => {
    const content: string = contentList
        ? !checked && !indeterminate
            ? contentList[0]
            : !checked
            ? contentList[1]
            : contentList[2]
        : '';
    const ref = React.useRef<HTMLLabelElement>();
    React.useEffect(() => {
        const interactableElement = ref.current;
        interactableElement.addEventListener('keydown', listener);
        return () => {
            interactableElement.removeEventListener('keydown', listener);
        };
    });
    const listener = e => {
        if (e.key == 'Enter' || e.key == ' ') {
            onChange(new Event('input', { bubbles: true }) as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    return (
        <label
            className={`o-tri-toggle ${className}`}
            tabIndex={0}
            ref={ref}
            role={'checkbox'}
            aria-checked={indeterminate ? 'mixed' : checked}
            aria-label={label}
        >
            <input
                className="o-tri-toggle__input"
                type="checkbox"
                checked={checked}
                ref={input => {
                    if (input) {
                        input.checked = checked;
                        input.indeterminate = indeterminate as boolean;
                        input.disabled = disabled as boolean;
                    }
                }}
                onChange={onChange}
            />
            <span className="o-tri-toggle__slider" data-content={content} />
            <span className="o-tri-toggle__label">{label}</span>
        </label>
    );
};

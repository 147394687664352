import { Position } from 'geojson';
import { parseWKT } from '@/utils/WKT';
import { WKTObject } from '../WKTObject';
import { v4 as uuidv4 } from 'uuid';
import { Vegobjekt } from '../vegobjekter/Vegobjekt';

export abstract class Omrade {
    readonly id: string;

    protected constructor(
        readonly navn: string,
        readonly nummer: number,
        readonly kartutsnitt: WKTObject,
        readonly senterpunkt: WKTObject,
        readonly vegobjekt: Vegobjekt,
        readonly visible: boolean = true
    ) {
        this.id = uuidv4();
    }

    getSenterpunkt(): [number, number] {
        const parsedSenterpunkt = parseWKT(this.senterpunkt.wkt);
        if ('coordinates' in parsedSenterpunkt) {
            const coordinate = parsedSenterpunkt.coordinates as Position;
            return [coordinate[0], coordinate[1]];
        }
    }

    abstract toString(): string;

    abstract toParams(): string;

    abstract getAreaType(): string;

    abstract withVisible(isVisible: boolean): Omrade;

    equals(other: Omrade) {
        return this.id === other.id;
    }
}

function createMessage(msg: string, args: unknown[]): string {
    const parts = msg.split('{}');
    let result = '';

    for (let i = 0; i < parts.length; i++) {
        result += (parts[i] || '') + (args[i] || '');
    }

    return result;
}

export function createLogger(name: string): (msg: string, ...args: unknown[]) => void {
    return (msg: string, ...args: unknown[]) => {
        const isDevelopment = process.env.NODE_ENV === 'development';
        if (isDevelopment) {
            console.group(name);
            console.info(createMessage(msg, args));
            console.groupEnd();
        }
    };
}

import * as React from 'react';
import { Severity, VegkartError } from '@/state';
import { useEffect } from 'react';

interface ErrorProps {
    error: VegkartError;
    dismiss: (error: VegkartError) => void;
}
const classes: Record<Severity, string> = {
    ERROR: 'c-error-container--severity-error',
    WARN: 'c-error-container--severity-warn',
    INFO: 'c-error-container--severity-info',
};
export function ErrorContainer({ error, dismiss }: ErrorProps) {
    const severityClass = classes[error.severity] ?? classes[Severity.ERROR];

    useEffect(() => {
        const timeout = window.setTimeout(() => dismiss(error), 2500);
        return () => window.clearTimeout(timeout);
    }, [error, dismiss]);
    return (
        <div className={`c-errors__error c-error-container ${severityClass}`}>
            <p className="c-error-container__message">{error.message}</p>
            <button
                type="button"
                onClick={() => dismiss(error)}
                className="c-error-container__dismiss"
                title="Fjern feilmelding"
            >
                <i className="o-icon-remove c-error-container__close-icon" />
            </button>
        </div>
    );
}

import React from 'react';
import { forwardRef } from 'react';

interface TileServiceOptionProps {
    label: string;
    active: boolean;
    iconName: string;
    large?: boolean;
    onClick: () => void;
}
const TileServiceOption = forwardRef<HTMLButtonElement, TileServiceOptionProps>(
    ({ label, onClick, active, iconName, large }, ref) => (
        <button
            ref={ref}
            type="button"
            className={`${
                large ? 'c-map-select-layer__change-layer-large' : 'c-map-select-layer__change-layer'
            } o-btn-square
                    ${active ? 'c-map-select-layer__active' : ''}`}
            title={label}
            onClick={onClick}
        >
            <i
                className={`${iconName} c-map-select-layer__icon
                        ${active ? `${iconName}--active` : ''}`}
            />
            <span
                className={`o-btn-square__title
                        ${active ? 'c-map-select-layer__active-text' : ''}`}
            >
                {label}
            </span>
        </button>
    )
);
TileServiceOption.displayName = 'TileServiceOption';
export { TileServiceOption };

export class MapQueries {
    constructor(readonly kartutsnitt: string = null) {}

    override(modifyObject: { [P in keyof MapQueries]?: MapQueries[P] }): MapQueries {
        return Object.assign(Object.create(MapQueries.prototype), { ...this, ...modifyObject });
    }

    withKartutsnitt(kartutsnitt: string) {
        return this.override({ kartutsnitt });
    }

    toParams(): Record<string, string> {
        const params: Record<string, string> = {};

        Object.keys(this).forEach(key => {
            if (this[key] !== null) {
                params[key] = this[key];
            }
        });

        return params;
    }
}

import { ActionType, MeasurementAction } from '@/actions/actiontypes';
import { MeasurementState } from '@/state/MeasurementState';

const initialState = new MeasurementState();

export const measurementReducer = (state: MeasurementState = initialState, action: MeasurementAction) => {
    switch (action.type) {
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.measurementState;
        case ActionType.MEASURE_ADD_POINT:
            return state.withPoints([...state.points, action.point]);
        case ActionType.MEASURE_MODIFY_POINT:
            return state.withPoints([
                ...state.points.slice(0, state.points.length - 1),
                { ...state.points[state.points.length - 1], ...action.modify },
            ]);
        case ActionType.MEASURE_TOGGLE_ACTIVE:
            return state.withActive(action.active).withMeasurementType(action.measurementType).withPoints([]);
        case ActionType.MEASURE_SET_ACTIVE:
            return state.withActive(action.active);
        default:
            return state;
    }
};

import * as React from 'react';

import { connect } from 'react-redux';
import * as selectors from '../../selectors/selectors';
import { ValgtState, RootState } from '@/state';
import { Hva } from './hva/Hva';
import Hvor from './Hvor';
import { Search } from './Search';
import ValgtVegobjekt from './valgt/ValgtContainer';
import { Vegnett } from './vegnett/Vegnett';

interface AvgrensningProps {
    filterPanelOpen: boolean;
    searchPanelOpen: boolean;
    selectedFeature: ValgtState;
    isCategorizing: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        filterPanelOpen: selectors.stateFilterPanelOpen(state),
        isCategorizing: selectors.stateIsCategorizing(state),
        searchPanelOpen: selectors.stateSearchPanelOpen(state),
        selectedFeature: selectors.stateValgt(state),
    };
};

class Avgrensning extends React.Component<AvgrensningProps, undefined> {
    render() {
        const isCategorizingModifier = this.props.isCategorizing ? 'c-limiter--extended' : '';
        const mobileClass =
            this.props.searchPanelOpen || this.props.filterPanelOpen || !this.props.selectedFeature.isEmpty()
                ? ''
                : ' t-limiter-below-view-mobile';
        return (
            <div className={`c-limiter ${isCategorizingModifier} ${mobileClass}`}>
                <Search />
                <Vegnett />
                <Hvor />
                <Hva />
                <ValgtVegobjekt />
            </div>
        );
    }
}

export default connect(mapStateToProps)(Avgrensning);

import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Omrade } from '@/domain/omrader';
import { WKTObject } from '@/domain/WKTObject';
import { wktObjectToFeature } from '@/utils/SpatialHelper';
import { getAreaOutlineStyle } from '../style/OmraderStyle';
import { FeatureLayerType } from './FeatureLayerType';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';
import { defaultColor } from '@/utils/ColorHelper';
import { Geometry } from 'ol/geom';

interface Props {
    area: Omrade;
    areaGeometry: WKTObject;
    zoom: number;
    isNiBLayer: boolean;
}

export class OmraderFeatureLayer extends React.Component<Props, null> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    static IsAreaFeatureKey = 'IS_AREA_FEATURE';

    private layer: VectorLayer<VectorSource<Geometry>>;
    private areaFeature: Feature;

    componentDidMount() {
        const area = this.props.area;
        const featureId = `area-${this.props.area.nummer}`;
        this.areaFeature = wktObjectToFeature(
            this.props.areaGeometry.wkt,
            featureId,
            area.vegobjekt.metadata?.type.id,
            FeatureLayerType.AREA_OUTLINE,
            defaultColor
        );
        this.areaFeature.set(OmraderFeatureLayer.IsAreaFeatureKey, true);
        const features = [this.areaFeature];
        this.layer = new VectorLayer({
            source: new VectorSource({
                features,
            }),
            style: getAreaOutlineStyle(this.props.isNiBLayer, this.props.zoom),
            zIndex: 1,
        });

        this.context.map.addLayer(this.layer);
    }

    componentDidUpdate() {
        const style = getAreaOutlineStyle(this.props.isNiBLayer, this.props.zoom);
        this.layer.setStyle(style);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.layer);
    }

    render() {
        return null;
    }
}

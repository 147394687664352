import * as React from 'react';
import Spinner from './Spinner';

interface LoadingProps {
    type: 'spinner' | 'text';
    className: string;
}

export class Loading extends React.PureComponent<LoadingProps> {
    readonly title = 'Venter på data';
    readonly extraClasses = this.props.className;
    render() {
        if (this.props.type === 'spinner') {
            return (
                <div className={'o-loading-indicator ' + this.extraClasses} title={this.title}>
                    <Spinner className={this.props.className} />
                </div>
            );
        }
        if (this.props.type === 'text') {
            return (
                <span className={'o-loading-text ' + this.extraClasses} title={this.title}>
                    Laster
                </span>
            );
        }
    }
}

import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { ServerStatusResponse } from '@/server/responses';
import Spinner from '../objects/Spinner';
import { SplashState } from '@/state/SplashState';
import { useDispatch, useSelector } from '@/state/store';
import { stateConfig } from '@/selectors/selectors';
import { fetchLesStatus } from '@/actions/metaActions';

interface AboutProps {
    handleWindowChange: (window: SplashState) => void;
}

export const About = forwardRef(
    ({ handleWindowChange }: AboutProps, ref: ForwardedRef<HTMLDialogElement>) => {
        About.displayName = 'About';

        const { version } = useSelector(stateConfig);
        const [serverInfo, setServerInfo] = useState<ServerStatusResponse | null>(null);
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(fetchLesStatus())
                .unwrap()
                .then(i => setServerInfo(i))
                .catch(e => console.warn(e));
        }, [dispatch]);
        return (
            <dialog
                className={'c-about-container'}
                ref={ref}
                aria-labelledby="about-header"
                aria-modal="true"
            >
                <header className="c-about-header">
                    <div className="c-about-header__text" id="about-header">
                        Om Vegkart
                    </div>
                    <div className="c-about-header__close">
                        <button
                            type="button"
                            title="Tilbake"
                            className="o-btn o-icon-arrow-back--white c-about-header__back-image"
                            onClick={() => handleWindowChange(SplashState.MAIN)}
                            aria-label="Gå tilbake"
                        />
                    </div>
                </header>
                <main className="c-about-main">
                    <p className="t-hidden-mobile">
                        Alle data er fritt tilgjengelig etter Norsk lisens for offentlige data.
                        <br />
                        Stedsnavn hentes fra{' '}
                        <a href="https://www.kartverket.no/">Sentralt stedsnavnregister (SSR) ©Kartverket</a>.
                        <br />
                    </p>
                    <p className="t-hidden-mobile">
                        Vegkartversjon:&nbsp;
                        <span className="c-splash-footer__bold">
                            {version.version}&nbsp; ({version.hash.substring(0, 8)})
                        </span>
                    </p>
                    <p className="t-hidden-mobile">
                        Datagrunnlaget fra NVDB ble sist oppdatert:&nbsp;
                        <span className="c-splash-footer__bold">
                            {serverInfo ? (
                                serverInfo.datagrunnlag_sist_oppdatert
                            ) : (
                                <Spinner className="c-splash__spinner-icon" />
                            )}
                        </span>
                    </p>
                </main>
                <footer className="c-about-footer">
                    <hr />
                </footer>
            </dialog>
        );
    }
);

import { Stedsnavn } from '@/server/responses';

const formatLocationDetails = (locationObject: Stedsnavn) => {
    const { kommune, fylke, placeNameType } = locationObject;
    let result =
        (placeNameType ? `${placeNameType}, ` : '') +
        (kommune ? `${kommune}, ` : '') +
        (fylke ? `${fylke}, ` : '');
    result = result.slice(0, -2);
    return result;
};

export default formatLocationDetails;

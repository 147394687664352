import { useDispatch, useSelector } from '@/state/store';
import { ActionType, SplashChangedAction } from '@/actions/actiontypes';
import { SplashState } from '@/state/SplashState';
import React, { forwardRef, useCallback } from 'react';
import { stateSplash } from '@/selectors/selectors';

const OpenPreferencesButton = forwardRef<HTMLButtonElement>((_, ref) => {
    const dispatch = useDispatch();
    const splashState = useSelector(stateSplash);
    const onClick = useCallback(() => {
        const action: SplashChangedAction = {
            type: ActionType.SHOW_SPLASH,
            state: splashState === SplashState.PREFERENCES ? SplashState.NONE : SplashState.PREFERENCES,
        };
        dispatch(action);
    }, [dispatch, splashState]);
    return (
        <button
            ref={ref}
            type="button"
            aria-controls="preferences"
            aria-owns="preferences"
            aria-label="Åpne/lukk innstillinger"
            onClick={onClick}
            className="c-preferences-button o-btn-square"
            title="Åpne/lukk innstillinger"
        >
            <i
                className="material-icons"
                style={{ color: splashState === SplashState.PREFERENCES ? 'orange' : 'inherit' }}
            >
                settings
            </i>
        </button>
    );
});
OpenPreferencesButton.displayName = 'OpenPreferencesButton';
export { OpenPreferencesButton };

import * as React from 'react';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    Fylke,
    Kommune,
    Kontraktsomrade,
    Land,
    Riksvegrute,
    validateVegsystemreferanse,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';
import * as selectors from '../../selectors/selectors';
import { OmraderState, RootState, ValgtState, VegobjekterState } from '@/state';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { createVegrefParser } from '@/utils/VegrefParser';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { zoomToExtent, zoomToExtentT } from '@/actions/mapActions';
import {
    gotoVSR,
    gotoVSRT,
    removeOmrade,
    removeOmradeT,
    toggleOmradeVisibility,
    toggleOmradeVisibilityT,
    updateVSRInSearch,
    updateVSRInSearchT,
} from '@/actions/omraderActions';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import Spinner from '../objects/Spinner';
import Select from 'react-select';
import { Polygon } from '@/domain/omrader/Polygon';
import clipboardCopy from 'clipboard-copy';

interface HvorProps {
    hvor: OmraderState;
    hva: VegobjekterState;
    fjernLokasjon: removeOmradeT;
    searchPanelOpen: boolean;
    selectedFeature: ValgtState;
    zoomToExtent: zoomToExtentT;
}

interface DispatchProps {
    updateRoadref: updateVSRInSearchT;
    gotoVSR: gotoVSRT;
    toggleOmradeVisibility: toggleOmradeVisibilityT;
}

type Props = HvorProps & DispatchProps;

class Hvor extends React.PureComponent<Props, { vegrefParser: (input: string) => Vegsystemreferanse }> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;

    constructor(props) {
        super(props);
        this.state = { vegrefParser: null };
    }

    componentDidMount() {
        const vegobjekttypeVegsystem: Vegobjekttype = this.context.data.datakatalog.getTypeById(915);
        this.setState({ vegrefParser: createVegrefParser(vegobjekttypeVegsystem) });
    }

    render() {
        const hvor = this.props.hvor;
        const hiddenMobile =
            this.props.searchPanelOpen || !this.props.selectedFeature.isEmpty() ? ' c-where--hidden' : '';
        const empty =
            hvor.fylke.length > 0 ||
            hvor.kommune.length > 0 ||
            hvor.kontraktsomrade.length > 0 ||
            hvor.riksvegrute.length > 0 ||
            hvor.land.length > 0 ||
            hvor.vegsystemreferanse.length > 0 ||
            hvor.polygon !== null
                ? ''
                : ' t-hidden';
        return (
            <div className={'c-where' + hiddenMobile + empty}>
                <ul className={'o-list c-where-list'} aria-label="Liste over områder som begrenser søket">
                    {this.lokasjoner()}
                </ul>
            </div>
        );
    }

    private lokasjoner() {
        const { land, riksvegrute, kontraktsomrade, fylke, kommune, vegsystemreferanse, polygon } =
            this.props.hvor;
        return [
            land.map(l => this.lokasjon(l)),
            riksvegrute.map(r => this.riksvegrute(r)),
            kontraktsomrade.map(k => this.kontraktsomrade(k)),
            fylke.map(f => this.fylke(f)),
            kommune.filter(k => k.senterpunkt !== null).map(k => this.kommune(k)),
            vegsystemreferanse.map(v => this.vegsystemreferanse(v)),
            [polygon].filter(p => !!p).map(p => this.polygon(p)),
        ].reduce((acc, curr) => acc.concat(curr), []);
    }

    private kontraktsomrade(k: Kontraktsomrade) {
        return (
            <Lokasjon
                type={k.getAreaType()}
                key={k.navn}
                text={k.navn}
                fjernLokasjon={() => this.props.fjernLokasjon(k)}
                toggleOmrade={() => this.props.toggleOmradeVisibility(k)}
                visible={k.visible}
            >
                <span key="text" className="c-where-element__location">
                    {k.navn}
                </span>
                <span key="extra" className="c-where-element__extra">
                    {k.getAreaType()}
                </span>
            </Lokasjon>
        );
    }

    private lokasjon(l: Land | Fylke) {
        return (
            <LokasjonMedExtent
                type={l.getAreaType()}
                key={l.navn}
                text={l.navn}
                onZoomClick={() => {
                    this.props.zoomToExtent(kartutsnittFromWktStrings(l.kartutsnitt.wkt));
                }}
                fjernLokasjon={() => this.props.fjernLokasjon(l)}
                toggleOmrade={() => this.props.toggleOmradeVisibility(l)}
                visible={l.visible}
            >
                <span key="text" className="c-where-element__location">
                    {l.navn}
                </span>
                <span key="extra" className="c-where-element__extra">
                    {l.getAreaType()}
                </span>
            </LokasjonMedExtent>
        );
    }
    private polygon(p: Polygon) {
        return (
            <li
                tabIndex={0}
                className="o-list__element c-where-list__element c-where-element"
                aria-label={p.getAreaType()}
            >
                <i className="o-icon-area c-where-element__area-icon" />

                <h3 className="c-where-element__body">
                    <span key="text" className="c-where-element__location">
                        {p.navn}
                    </span>
                    <span key="extra" className="c-where-element__extra">
                        {p.getAreaType()}
                    </span>
                </h3>
                <button
                    title={'Kopier WKT til utklippstavlen'}
                    className={'o-btn o-btn--orange-bg o-btn--color-white c-where-element__zoom-button'}
                    onClick={() => clipboardCopy(p.wkt.wkt)}
                >
                    Kopier WKT
                </button>
                <button
                    title={'Klikk for å vise hele området'}
                    className={'o-btn o-btn--orange-bg o-btn--color-white c-where-element__zoom-button'}
                    onClick={() => {
                        this.props.zoomToExtent(kartutsnittFromWktStrings(p.kartutsnitt.wkt));
                    }}
                >
                    Zoom til
                </button>

                <button
                    title="Fjern"
                    onClick={() => this.props.fjernLokasjon(p)}
                    className="c-where-element__remove"
                    aria-label={`Fjern polygon fra søkebegrensning`}
                >
                    <i className="o-icon-remove c-where-element__x-icon" />
                </button>
            </li>
        );
    }

    private fylke(f: Fylke) {
        return (
            <LokasjonMedExtent
                type={f.getAreaType()}
                key={f.navn}
                text={f.navn}
                onZoomClick={() => {
                    this.props.zoomToExtent(kartutsnittFromWktStrings(f.kartutsnitt.wkt));
                }}
                fjernLokasjon={() => this.props.fjernLokasjon(f)}
                toggleOmrade={() => this.props.toggleOmradeVisibility(f)}
                visible={f.visible}
            >
                <span key="text" className="c-where-element__location">
                    {f.navn}
                </span>
                <span key="extra" className="c-where-element__extra">
                    {f.getAreaType()}
                </span>
            </LokasjonMedExtent>
        );
    }

    private riksvegrute(r: Riksvegrute) {
        return (
            <Lokasjon
                type="Riksvegrute"
                key={r.navn + r.periode + r.nummer}
                text={r.navn}
                fjernLokasjon={() => this.props.fjernLokasjon(r)}
                toggleOmrade={() => this.props.toggleOmradeVisibility(r)}
                visible={r.visible}
            >
                <span key="text" className="c-where-element__location">
                    {r.navn}
                </span>
                ,
                <span key="ekstra" className="c-where-element__extra">
                    {r.beskrivelse}
                </span>
                ,
                <span key="periode" className="c-where-element__period">
                    {r.periode}
                </span>
                ,
            </Lokasjon>
        );
    }

    private kommune(k: Kommune) {
        return (
            <LokasjonMedExtent
                type="Kommune"
                key={k.navn}
                text={k.navn}
                onZoomClick={() => {
                    this.props.zoomToExtent(kartutsnittFromWktStrings(k.kartutsnitt.wkt));
                }}
                fjernLokasjon={() => this.props.fjernLokasjon(k)}
                toggleOmrade={() => this.props.toggleOmradeVisibility(k)}
                visible={k.visible}
            >
                <span
                    key="text"
                    className="c-where-element__location"
                    id={'whereelementlocation_' + k.nummer}
                >
                    {k.navn}
                </span>
                <span key="extra" className="c-where-element__extra">
                    {this.context.data.fylker.find(a => a.nummer === k.fylke)?.navn}
                </span>
            </LokasjonMedExtent>
        );
    }

    private vegsystemreferanse(v: Vegsystemreferanse) {
        const fjernLokasjon = () => this.props.fjernLokasjon(v);
        if (v instanceof Vegkategori) {
            return (
                <Lokasjon
                    type="Vegkategori"
                    key={v.id}
                    text={v.navn}
                    fjernLokasjon={fjernLokasjon}
                    toggleOmrade={() => this.props.toggleOmradeVisibility(v)}
                    visible={v.visible}
                >
                    <span key="text" className="c-where-element__location">
                        {v.navn}
                    </span>
                </Lokasjon>
            );
        } else {
            return (
                <VegrefLokasjon
                    key={v.id}
                    vegsystemreferanse={v}
                    updateRoadref={this.props.updateRoadref}
                    fjernLokasjon={fjernLokasjon}
                    vegrefParser={this.state.vegrefParser}
                    vegrefDocUrl={this.context.config.vegref_format_url}
                    zoomToExtent={this.props.zoomToExtent}
                    gotoVSR={this.props.gotoVSR}
                    toggleOmradeVisibility={this.props.toggleOmradeVisibility}
                />
            );
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        hva: selectors.stateVegobjekter(state),
        hvor: selectors.stateOmrader(state),
        searchPanelOpen: selectors.stateSearchPanelOpen(state),
        selectedFeature: selectors.stateValgt(state),
    };
};
const mapDispatchToProps = {
    fjernLokasjon: removeOmrade,
    updateRoadref: updateVSRInSearch,
    zoomToExtent,
    gotoVSR,
    toggleOmradeVisibility: toggleOmradeVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hvor);

interface LokasjonProps {
    type: string;
    text: string;
    fjernLokasjon: () => void;
    toggleOmrade: () => void;
    children?: ReactNode;
    visible?: boolean;
}

interface LokasjonMedExtentProps extends LokasjonProps {
    onZoomClick: () => void;
    loading?: boolean;
    disabled?: boolean;
    zoomTooltip?: string;
}

const Lokasjon = ({ type, text, fjernLokasjon, toggleOmrade, children, visible = true }: LokasjonProps) => {
    const iconColor = visible ? 'inherit' : '#c6c6c6';
    return (
        <li
            tabIndex={0}
            className="o-list__element c-where-list__element c-where-element"
            aria-label={`${type} ${text}`}
            onKeyDown={e => {
                if (e.key === 'Backspace') {
                    fjernLokasjon();
                }
            }}
        >
            <button
                key="hvor-info-visibility"
                className="material-icons c-where-element__location-icon"
                type="button"
                style={{ color: iconColor }}
                onClick={toggleOmrade}
                aria-label={`Vis ${type} ${text} i kart`}
                aria-pressed={visible}
            >
                room
            </button>

            <h3 className="c-where-element__body">{children}</h3>

            <button
                title="Fjern"
                onClick={fjernLokasjon}
                className="c-where-element__remove"
                aria-label={`Fjern ${text} fra søkebegrensning`}
            >
                <i className="o-icon-remove c-where-element__x-icon" />
            </button>
        </li>
    );
};
const LokasjonMedExtent = ({
    type,
    text,
    fjernLokasjon,
    toggleOmrade,
    children,
    onZoomClick,
    loading = false,
    disabled = false,
    zoomTooltip = 'Klikk for å vise hele området',
    visible = true,
}: LokasjonMedExtentProps) => {
    const iconColor = visible ? 'inherit' : '#c6c6c6';

    return (
        <li
            tabIndex={0}
            className="o-list__element c-where-list__element c-where-element"
            aria-label={`${type} ${text}`}
        >
            <button
                key="hvor-info-visibility"
                className="material-icons c-where-element__location-icon"
                type="button"
                style={{ color: iconColor }}
                onClick={toggleOmrade}
                aria-label={`Vis ${type} ${text} i kart`}
                aria-pressed={visible}
            >
                pin_drop
            </button>
            <h3 className="c-where-element__body">{children}</h3>
            {loading && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Spinner className={'c-where-element__spinner'} />
                </div>
            )}

            <button
                title={zoomTooltip}
                className={'o-btn o-btn--orange-bg o-btn--color-white c-where-element__zoom-button'}
                disabled={loading || disabled}
                onClick={onZoomClick}
            >
                Zoom til
            </button>

            <button
                title="Fjern"
                onClick={e => {
                    e.stopPropagation();
                    fjernLokasjon();
                }}
                className="c-where-element__remove"
                aria-label={`Fjern ${text} fra søkebegrensning`}
            >
                <i className="o-icon-remove c-where-element__x-icon" />
            </button>
        </li>
    );
};

interface VegrefLokasjonProps {
    vegsystemreferanse: Vegsystemreferanse;
    fjernLokasjon: () => void;
    updateRoadref: updateVSRInSearchT;
    vegrefParser: (input: string) => Vegsystemreferanse;
    vegrefDocUrl: string;
    zoomToExtent: zoomToExtentT;
    gotoVSR: gotoVSRT;
    toggleOmradeVisibility: toggleOmradeVisibilityT;
}

const kommuneSelectStyle = {
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    valueContainer: base => ({ ...base, padding: 0 }),
    singleValue: (base, state) => ({ ...base, color: 'unset', opacity: state.data.value ? '1' : '0.5' }),
    control: base => ({
        ...base,
        border: 'none',
        fontSize: '1.2rem',
        color: 'var(--gray)',
        borderBottom: '1px solid transparent',
        minHeight: 'unset',
        cursor: 'text',
        boxShadow: 'none',
        borderRadius: 0,
        '&:focus-within': {
            borderBottom: '1px solid var(--gray)',
        },
    }),
};

const emptyKommune = { label: '(ingen kommune valgt)', value: null };
const VegrefLokasjon = ({
    vegsystemreferanse,
    gotoVSR,
    fjernLokasjon,
    vegrefDocUrl,
    vegrefParser,
    updateRoadref,
    toggleOmradeVisibility,
}: VegrefLokasjonProps) => {
    const kortform = vegsystemreferanse.toVegkartKortform();
    const { data } = useContext(ConfigAndDataContext);
    const [vegrefInvalid, setVegrefInvalid] = useState(false);
    const [typing, setTyping] = useState(false);
    const submit = useCallback(
        event => {
            setTyping(false);
            const value = event.currentTarget.value;
            if (value === kortform) return;
            const parsed = vegrefParser(value);
            if (parsed !== null) {
                updateRoadref(vegsystemreferanse, parsed.withKommune(vegsystemreferanse.kommune));
                setVegrefInvalid(false);
            } else {
                setVegrefInvalid(true);
            }
        },
        [updateRoadref, vegsystemreferanse, kortform, vegrefParser]
    );

    const onKeyDown = useCallback(
        e => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                submit(e);
            } else if (e.key === 'Backspace') {
                e.stopPropagation();
            }
        },
        [submit]
    );
    const kommuner = useMemo(
        () => [
            emptyKommune,
            ...data.kommuner
                .map(k => ({
                    label: k.navn,
                    value: k.nummer,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ],
        [data.kommuner]
    );
    const value = kommuner.find(k => k.value === vegsystemreferanse.kommune);
    let tooltip = undefined;
    if (!vegsystemreferanse.senterpunkt) {
        if (!vegsystemreferanse.senterpunkt) tooltip = 'Vegsystemreferansen finnes ikke';
        else if (vegsystemreferanse.isKPS() && !vegsystemreferanse.kommune)
            tooltip = 'Kommune må oppgis for å zoome';
        else if (!validateVegsystemreferanse(vegsystemreferanse))
            tooltip = 'Zoom til vegsystemreferanse krever vegnummer, strekning og delstrekning.';
    }
    return (
        <LokasjonMedExtent
            type="Vegsystemreferanse"
            key={vegsystemreferanse.id}
            loading={
                vegsystemreferanse.senterpunkt === undefined &&
                vegsystemreferanse.geometri === undefined &&
                validateVegsystemreferanse(vegsystemreferanse)
            }
            disabled={typing || !(vegsystemreferanse.senterpunkt || vegsystemreferanse.geometri)}
            text={kortform}
            onZoomClick={() => gotoVSR(vegsystemreferanse)}
            fjernLokasjon={fjernLokasjon}
            zoomTooltip={tooltip}
            toggleOmrade={() => toggleOmradeVisibility(vegsystemreferanse)}
            visible={vegsystemreferanse.visible}
        >
            <input
                type={'text'}
                className="c-where-element__input"
                defaultValue={kortform}
                onBlur={submit}
                onSubmit={submit}
                onFocus={() => setTyping(true)}
                onKeyDown={onKeyDown}
            />
            {vegsystemreferanse.isKPS() && (
                <Select
                    menuPortalTarget={document.body}
                    styles={kommuneSelectStyle}
                    value={value}
                    options={kommuner}
                    onChange={o => {
                        updateRoadref(vegsystemreferanse, vegsystemreferanse.withKommune(o.value));
                    }}
                />
            )}
            {vegrefInvalid && (
                <div key="error" className="o-error c-where-element__ref-error">
                    Feil vegsystemreferanse. Eksempel på format:{' '}
                    <a rel="noopener noreferrer" target="_blank" href={vegrefDocUrl}>
                        FV911S2D10M0-3
                    </a>
                </div>
            )}
        </LokasjonMedExtent>
    );
};

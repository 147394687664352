import React, { useState } from 'react';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    FloatingPortal,
} from '@floating-ui/react';

interface TooltipWrapperProps {
    content: string;
    children: React.ReactNode;
}

const TooltipWrapper = ({ content, children }: TooltipWrapperProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'top',
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
        ],
    });

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </div>
            <FloatingPortal>
                {isOpen && (
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            background: 'black',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            zIndex: 1000,
                            minWidth: '150px',
                            maxWidth: '300px',
                            whiteSpace: 'normal',
                        }}
                        {...getFloatingProps()}
                    >
                        {content}
                    </div>
                )}
            </FloatingPortal>
        </>
    );
};

export default TooltipWrapper;

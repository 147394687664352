import { Map, Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { click } from 'ol/events/condition';
import { createEmpty, extend, Extent, getCenter } from 'ol/extent';
import { Geometry, LineString, Point } from 'ol/geom';
import { Select } from 'ol/interaction';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import {
    stateLinkQueries,
    stateSelectedRoadObjectTypes,
    stateValgt,
    stateVegnettlenker,
} from '@/selectors/selectors';
import { Severity, VegkartError, VegobjekttypeState } from '@/state';
import {
    getClusteredFeatures,
    getFirstClusteredFeature,
    isMultiFeatureCluster,
    isMultiRoadObjectSelection,
} from '@/utils/SpatialHelper';
import { assertUnreachable } from '@/utils/Utils';
import {
    FeatureColorKey,
    FeatureLayerType,
    FeatureLayerTypeKey,
    FeatureTypeKey,
    isInteractible,
} from '../layers/FeatureLayerType';
import { useMap } from '@/state/context/MapContext';
import { selectFeature } from '@/actions/valgtActions';
import { handleError } from '@/actions/metaActions';
import { useEffect, useMemo } from 'react';
import { removePointFromMap } from '@/actions/mapActions';
import { Dispatch, useDispatch, useSelector } from '@/state/store';
import { WKT } from 'ol/format';
import { WKTObject } from '@/domain/WKTObject';

export const SelectionHandlerQualifierKey = 'ObjectSelectionHandler';

const flatten = <T,>(records: Record<string, T[]>) =>
    Object.values(records).reduce((all, links) => [...all, ...links], []);

export const FeatureSelectionHandler = () => {
    const dispatch = useDispatch();
    const { map } = useMap();
    const roadObjectTypes: VegobjekttypeState[] = useSelector(stateSelectedRoadObjectTypes);
    const linkQueries = useSelector(stateLinkQueries);
    const vegnettState = useSelector(stateVegnettlenker);
    const selectedFeature = useSelector(stateValgt);

    const vegnettLenker = useMemo(
        () => [...(vegnettState || []), ...flatten(linkQueries || {})],
        [linkQueries, vegnettState]
    );

    useEffect(() => {
        const selectInteraction = new Select({
            condition: click,
            filter: f => isSelectableOnClick(f),
            style: null,
            multi: true,
        });

        selectInteraction.on('select', e => {
            if (e.selected && e.selected.length > 0) {
                handleFeatureSelection(
                    e.selected,
                    roadObjectTypes,
                    vegnettLenker,
                    dispatch,
                    selectInteraction,
                    map
                );
            } else {
                dispatch(selectFeature([], null, [], null));
            }
        });
        // Tag the handler to retrieve it from other components
        selectInteraction.set(SelectionHandlerQualifierKey, true);
        map.addInteraction(selectInteraction);

        return function cleanup() {
            map.removeInteraction(selectInteraction);
        };
    }, [dispatch, map, roadObjectTypes, vegnettLenker]);

    useEffect(() => {
        if (selectedFeature.isEmpty()) {
            const selectHandler = map
                .getInteractions()
                .getArray()
                .find(i => i.get(SelectionHandlerQualifierKey) === true) as Select;
            selectHandler.getFeatures().clear();
        }
    }, [map, dispatch, selectedFeature]);

    return null;
};

const isSelectableOnClick = (feature: FeatureLike): boolean => {
    const featureLayerType = feature.get(FeatureLayerTypeKey) as FeatureLayerType;
    return isInteractible(featureLayerType);
};

const handleFeatureSelection = (
    list: Feature<Geometry>[],
    roadObjectTypes: VegobjekttypeState[],
    roadNetLinks: Vegnettlenke[],
    dispatch,
    selectInteraction,
    map: Map
) => {
    const features = [
        ...new Set(list.map(f => (isMultiFeatureCluster(f) ? f : getFirstClusteredFeature(f)))),
    ];
    if (isMultiRoadObjectSelection(features))
        return handleOverlappingFeatures(features, roadObjectTypes, dispatch);
    const [feature] = features;
    const featureLayerType = feature.get(FeatureLayerTypeKey) as FeatureLayerType;
    switch (featureLayerType) {
        case FeatureLayerType.FEATURE_MAP_POINT:
            dispatch(removePointFromMap(feature.get('id')));
            break;
        case FeatureLayerType.FEATURE_VEGOBJEKT:
            const roadObject = roadObjectFromFeature(feature, roadObjectTypes, dispatch);
            if (roadObject) {
                dispatch(selectFeature([roadObject], [], [], feature.get(FeatureColorKey)));
            }
            break;
        case FeatureLayerType.FEATURE_COUNT:
            selectInteraction.getFeatures().clear();
            handleFeatureCountSelected(feature, map);
            break;
        case FeatureLayerType.FEATURE_CLUSTER:
            selectInteraction.getFeatures().clear();
            handleClusterSelected(feature, roadObjectTypes, map, dispatch);
            break;
        case FeatureLayerType.FEATURE_VEGNETTLENKE:
            const roadNetLink = roadNetLinkFromFeature(feature, roadNetLinks, dispatch);
            if (roadNetLink) {
                dispatch(selectFeature([], [roadNetLink], [], feature.get(FeatureColorKey)));
            }
            break;
        case FeatureLayerType.FEATURE_ROADREF:
        case FeatureLayerType.AREA_OUTLINE:
        case FeatureLayerType.USER_POSITION:
        case FeatureLayerType.USER_POSITION_ACCURACY:
        case FeatureLayerType.FEATURE_VALGT:
        case FeatureLayerType.FEATURE_VALGT_CLUSTER:
        case FeatureLayerType.FEATURE_VALGT_NODE:
            break;
        default:
            assertUnreachable(featureLayerType);
    }
};

const roadObjectFromFeature = (
    feature: Feature<Geometry>,
    roadObjectTypes: VegobjekttypeState[],
    dispatch
): Vegobjekt => {
    const featureId = feature.getId();
    const featureTypeId = feature.get(FeatureTypeKey) as number;

    // Check for feature in all resultsets of correct type
    const featureTypes = roadObjectTypes.filter(ft => ft.typeId === featureTypeId);
    for (const featureType of featureTypes) {
        if (featureType.categoryStates?.length > 0) {
            for (const category of featureType.categoryStates) {
                const roadObject = category.result.vegobjekter.find(o => o.id === featureId);
                if (roadObject) {
                    return roadObject;
                }
            }
        } else {
            const roadObject = featureType.result.vegobjekter.find(o => o.id === featureId);
            if (roadObject) {
                return roadObject;
            }
        }
    }
    // No roadObject found - this is rather odd!
    dispatch(
        handleError(
            new VegkartError(
                'Could not find object in features when feature is selected in map',
                {},
                Severity.ERROR
            )
        )
    );
    return null;
};

const roadNetLinkFromFeature = (feature: Feature<Geometry>, roadNetLinks: Vegnettlenke[], dispatch) => {
    const featureId = feature.getId();
    const roadNetLink = roadNetLinks.find(o => o.referanse === featureId);
    if (roadNetLink) {
        return roadNetLink;
    }
    // No roadNetLink found - this is rather odd!
    dispatch(
        handleError(
            new VegkartError(
                'Could not find roadNetLink in features when feature is selected in map',
                {},
                Severity.ERROR
            )
        )
    );
    return null;
};

const handleFeatureCountSelected = (feature: Feature<Geometry>, map: Map) => {
    map.getView().setCenter((feature.getGeometry() as Point).getCoordinates());
    map.getView().setZoom(map.getView().getZoom() + 1);
};

const createVegobjektFromFeature = (
    feature: Feature<Geometry>,
    wktFormatter: WKT,
    roadObjectTypes: VegobjekttypeState[],
    dispatch: Dispatch
) => {
    const vegobjektData = roadObjectFromFeature(feature, roadObjectTypes, dispatch);
    if (!vegobjektData) return null;

    const geometry = wktFormatter.readGeometry(vegobjektData.geometri.wkt);
    const newGeometri =
        geometry instanceof LineString
            ? createWKTObjectFromLineString(geometry, vegobjektData, wktFormatter)
            : vegobjektData.geometri;

    return new Vegobjekt(
        vegobjektData.id,
        vegobjektData.href,
        vegobjektData.metadata,
        vegobjektData.egenskaper,
        newGeometri,
        vegobjektData.lokasjon,
        vegobjektData.relasjoner,
        vegobjektData.segmenter
    );
};

const createWKTObjectFromLineString = (
    lineString: LineString,
    vegobjektData: Vegobjekt,
    wktFormatter: WKT
) => {
    const extent = lineString.getExtent();
    const center = getCenter(extent);
    return new WKTObject(
        vegobjektData.geometri.srid,
        wktFormatter.writeGeometry(new Point(center)),
        vegobjektData.geometri.egengeometri,
        vegobjektData.geometri.forenklet
    );
};

const handleOverlappingFeatures = (
    selected: Feature<Geometry>[],
    roadObjectTypes: VegobjekttypeState[],
    dispatch: Dispatch
) => {
    const featureColor = selected[0]?.get(FeatureColorKey);
    const wktFormatter = new WKT();

    const vegobjekter = selected
        .map(feature => createVegobjektFromFeature(feature, wktFormatter, roadObjectTypes, dispatch))
        .filter(Boolean);

    dispatch(selectFeature([], [], vegobjekter, featureColor));
};

const handleClusterSelected = (
    cluster: Feature<Geometry>,
    roadObjectTypes: VegobjekttypeState[],
    map: Map,
    dispatch
) => {
    const mapView = map.getView();
    // Are we zoomed all the way in?
    const atMaxZoom = mapView.getMinResolution() === mapView.getResolution();
    const features = getClusteredFeatures(cluster);
    const boundingExtent = features.reduce((extent: Extent, feature: Feature<Geometry>) => {
        extend(extent, feature.getGeometry().getExtent());
        return extent;
    }, createEmpty());

    // When a cluster is clicked ..
    if (atMaxZoom || (boundingExtent[0] === boundingExtent[2] && boundingExtent[1] === boundingExtent[3])) {
        // .. if we have zoomed all the way in, or if the features are located at the exact same position,
        // show a disambiguation list. Zooming is not possible, or will not break up the cluster.
        const color = getFirstClusteredFeature(cluster).get(FeatureColorKey);
        const vegobjekter = features.map(feature => {
            return roadObjectFromFeature(feature, roadObjectTypes, dispatch);
        });
        dispatch(selectFeature([], [], vegobjekter, color));
    } else {
        // .. if not, just zoom a bit closer to the cluster - this will eventually break it up
        map.getView().fit(boundingExtent, {
            duration: 300,
        });
    }
};

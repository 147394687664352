import { isToday } from '@/utils/Utils';

export enum VegnettParameter {
    TRAFIKANTGRUPPE = 'trafikantgruppe',
    VEGLENKETYPE = 'veglenketype',
    TYPEVEG = 'typeveg',
    DETALJNIVA = 'detaljniva',
    ADSKILTELOP = 'adskiltelop',
    ARM = 'arm',
    KRYSSYSTEM = 'kryssystem',
    SIDEANLEGG = 'sideanlegg',
}
export enum Trafikantgruppe {
    KJORENDE = 'K',
    GAENDE = 'G',
    NOT_SET = '',
}
export enum Veglenketype {
    HOVED = 'hoved',
    KONNEKTERING = 'konnektering',
    DETALJERT = 'detaljert',
    DETALJERT_KONNEKTERING = 'detaljert_konnektering',
    NOT_SET = '',
}
export enum TypeVeg {
    KANALISERT_VEG = 'kanalisertVeg',
    ENKEL_BILVEG = 'enkelBilveg',
    RAMPE = 'rampe',
    RUNDKJORING = 'rundkjøring',
    BILFERJE = 'bilferje',
    PASSASJERFERJE = 'passasjerferje',
    SYKKELVEG = 'sykkelVeg',
    GANGVEG = 'gangveg',
    GAGATE = 'gågate',
    FORTAU = 'fortau',
    TRAPP = 'trapp',
    GANGFELT = 'gangfelt',
    GATETUN = 'gatetun',
    GANG_OG_SYKKELVEG = 'gangOgSykkelveg',
    TRAKTORVEG = 'traktorveg',
    STI = 'sti',
    ANNET = 'annet',
}

export enum TypeVegAdditional {
    GANG_OG_SYKKELVEG = 'gangOgSykkelveg',
}

export enum Detaljniva {
    VEGTRASE = 'VT',
    KJOREBANE = 'KB',
    KJOREFELT = 'KF',
}

export enum DetaljnivaAdditional {
    VEGTRASE_OG_KJOREBANE = 'VTKB',
}

export enum AdskilteLop {
    NEI = 'nei',
    MED = 'med',
    MOT = 'mot',
}

export enum TrinaryQueryParam {
    TRUE = 'true',
    FALSE = 'false',
    NOT_SET = '',
}

export class VegnettQueries {
    static AllFiltersOn() {
        return new VegnettQueries(
            Trafikantgruppe.NOT_SET,
            Veglenketype.NOT_SET,
            Object.values(TypeVeg) as TypeVeg[],
            Object.values(Detaljniva) as Detaljniva[],
            Object.values(AdskilteLop) as AdskilteLop[],
            TrinaryQueryParam.NOT_SET,
            TrinaryQueryParam.NOT_SET,
            TrinaryQueryParam.NOT_SET
        );
    }

    constructor(
        public trafikantgruppe: Trafikantgruppe = Trafikantgruppe.NOT_SET,
        public veglenketype: Veglenketype = Veglenketype.NOT_SET,
        public typeveg: TypeVeg[] = [],
        public detaljniva: Detaljniva[] = [],
        public adskiltelop: AdskilteLop[] = [],
        public arm: TrinaryQueryParam = TrinaryQueryParam.NOT_SET,
        public kryssystem: TrinaryQueryParam = TrinaryQueryParam.NOT_SET,
        public sideanlegg: TrinaryQueryParam = TrinaryQueryParam.NOT_SET,
        public tidspunkt: string = null
    ) {}

    override(modifyObject: { [P in keyof VegnettQueries]?: VegnettQueries[P] }): VegnettQueries {
        return Object.assign(Object.create(VegnettQueries.prototype), { ...this, ...modifyObject });
    }

    withTidspunkt(timestamp: Date): VegnettQueries {
        const tidspunkt = timestamp ? timestamp.toISOString().split('T')[0] : null;
        return this.override({ tidspunkt });
    }

    withTrafikantGruppe(trafikantgruppe: Trafikantgruppe) {
        return this.override({ trafikantgruppe });
    }

    withVeglenketype(veglenketype: Veglenketype) {
        return this.override({ veglenketype });
    }

    withTypeveg(typeveg: TypeVeg[]) {
        return this.override({ typeveg });
    }

    withDetaljniva(detaljniva: Detaljniva[]) {
        return this.override({ detaljniva });
    }

    withAdskiltelop(adskiltelop: AdskilteLop[]) {
        return this.override({ adskiltelop });
    }

    withArm(arm: TrinaryQueryParam) {
        return this.override({ arm });
    }

    withKryssystem(kryssystem: TrinaryQueryParam) {
        return this.override({ kryssystem });
    }

    withSideanlegg(sideanlegg: TrinaryQueryParam) {
        return this.override({ sideanlegg });
    }

    isEmpty() {
        return JSON.stringify(this) === JSON.stringify(new VegnettQueries());
    }

    isFull() {
        return JSON.stringify(this) === JSON.stringify(VegnettQueries.AllFiltersOn());
    }

    hasEmptyArrays() {
        return this.typeveg.length === 0 || this.adskiltelop.length === 0 || this.detaljniva.length === 0;
    }

    // Fills in empty arrays from hash to full ones in state, to correctly represent direct links.
    fromHash() {
        return new VegnettQueries(
            this.trafikantgruppe,
            this.veglenketype,
            this.typeveg.length > 0 ? this.typeveg : (Object.values(TypeVeg) as TypeVeg[]),
            this.detaljniva.length > 0 ? this.detaljniva : (Object.values(Detaljniva) as Detaljniva[]),
            this.adskiltelop.length > 0 ? this.adskiltelop : (Object.values(AdskilteLop) as AdskilteLop[]),
            this.arm,
            this.kryssystem,
            this.sideanlegg
        );
    }

    // Hash should not contain any parameters which are either empty or full,
    // as an empty filter is equal to a filled one and filters nothing from that particular parameter.
    toHash() {
        const hash: Record<string, string | string[]> = {};

        Object.keys(this).forEach(key => {
            if (this[key] instanceof Array && this[key].length > 0) {
                if (
                    (key === VegnettParameter.TYPEVEG && this[key].length < Object.keys(TypeVeg).length) ||
                    (key === VegnettParameter.DETALJNIVA &&
                        this[key].length < Object.keys(Detaljniva).length) ||
                    (key === VegnettParameter.ADSKILTELOP &&
                        this[key].length < Object.keys(AdskilteLop).length)
                ) {
                    hash[key] = this[key];
                }
            } else if (typeof this[key] === 'string' && this[key] !== '') {
                hash[key] = this[key];
            }
        });

        return hash;
    }

    // Params can only include stringified values, so we have to join the arrays.
    // We also push additional queries for TypeVeg, Detaljnivå and Veglenketype if some queries are included.
    toParams() {
        const hash: Record<string, string | string[]> = this.toHash();
        const params: Record<string, string> = {};

        if (!!this.tidspunkt && !isToday(new Date(this.tidspunkt))) {
            params.tidspunkt = this.tidspunkt;
        }

        Object.keys(hash).forEach(key => {
            if (hash[key] instanceof Array) {
                let hashArray = hash[key] as string[];

                if (hashArray.includes(Detaljniva.VEGTRASE) || hashArray.includes(Detaljniva.KJOREBANE)) {
                    hashArray = hashArray.concat(DetaljnivaAdditional.VEGTRASE_OG_KJOREBANE);
                }

                params[key] = hashArray.join();
            } else {
                if (key === VegnettParameter.VEGLENKETYPE && hash[key] == Veglenketype.KONNEKTERING) {
                    const veglenkeString = hash[key] as string;
                    params[key] = [veglenkeString, Veglenketype.DETALJERT_KONNEKTERING].join(',');
                } else if (key === VegnettParameter.VEGLENKETYPE && hash[key] == Veglenketype.HOVED) {
                    const veglenkeString = hash[key] as string;
                    params[key] = [veglenkeString, Veglenketype.DETALJERT].join(',');
                } else {
                    params[key] = hash[key] as string;
                }
            }
        });
        return params;
    }
}

export class Egenskapstype {
    private static readonly quotedeDatatyper: string[] = ['Tekst', 'Dato', 'Klokkeslett'];

    public static comparator(a: Egenskapstype, b: Egenskapstype): number {
        return a.sorteringsnummer - b.sorteringsnummer;
    }

    constructor(
        readonly id: number,
        readonly kategori: number,
        readonly navn: string,
        readonly kortnavn: string,
        readonly beskrivelse: string,
        readonly veiledning: string,
        readonly egenskapstype: string,
        readonly datatype: string,
        readonly sosinvdbnavn: string,
        readonly sosinavn: string,
        readonly sorteringsnummer: number,
        readonly feltlengde: number,
        readonly desimaler: number,
        readonly min_anbefalt: number,
        readonly maks_anbefalt: number,
        readonly min: number,
        readonly maks: number,
        readonly sensitivitet: number,
        readonly viktighet: number,
        readonly egenskapstyper: Egenskapstype[],
        readonly tillatte_verdier: EgenskapTillattVerdi[],
        readonly enhet: Enhet,
        readonly dimensjon: number,
        readonly geometritype: string,
        readonly stedfestingstype: string,
        readonly retning_relevant: boolean
    ) {}

    verdiById(id: number): EgenskapTillattVerdi {
        return this.tillatte_verdier.find(tv => tv.id === id);
    }

    verdiByKortnavn(kortnavn: string): EgenskapTillattVerdi {
        return this.tillatte_verdier.find(tv => tv.kortnavn === kortnavn);
    }

    egenskapById(id: number): Egenskapstype {
        return this.egenskapstyper.find(e => e.id === id);
    }

    erEnum(): boolean {
        return (
            (this.datatype === 'FlerverdiAttributt, Tekst' || this.datatype === 'Flerverdiattributt, Tall') &&
            this.egenskapstype.includes('enum')
        );
    }

    erTall(): boolean {
        return this.datatype === 'Tall' || this.datatype === 'Flerverdiattributt, Tall';
    }

    erFlyttall(): boolean {
        return this.egenskapstype === 'Flyttall';
    }

    erGeometri(): boolean {
        return (
            this.datatype === 'GeomPunkt' ||
            this.datatype === 'GeomLinje eller Kurve' ||
            this.datatype === 'GeomFlate'
        );
    }

    erDato(): boolean {
        return this.datatype === 'Dato' || this.datatype === 'Flerverdiattributt, Dato';
    }

    erKlokkeslett(): boolean {
        return this.datatype === 'Klokkeslett';
    }

    erTekst(): boolean {
        return this.datatype === 'Tekst';
    }

    erStruct(): boolean {
        return this.datatype === 'Struktur';
    }

    maaQuotes(): boolean {
        return Egenskapstype.quotedeDatatyper.includes(this.datatype);
    }
}

export class EgenskapTillattVerdi {
    public static comparator(a: EgenskapTillattVerdi, b: EgenskapTillattVerdi): number {
        return a.sorteringsnummer - b.sorteringsnummer;
    }

    constructor(
        readonly id: number,
        readonly kortnavn: string,
        readonly verdi: string,
        readonly type: string,
        readonly sorteringsnummer: number
    ) {}

    toString() {
        return this.verdi ? this.verdi : 'Ingen verdi';
    }
}

export class Enhet {
    constructor(readonly id: number, readonly navn: string, readonly kortnavn: string) {}
}

import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import React from 'react';

interface SuperstedfestingProps {
    vegnettlenke: Vegnettlenke;
}

function getSuperHref(childHref: string, superId: number): string {
    const lastSlashIndex = childHref.lastIndexOf('/');
    return `${childHref.slice(0, lastSlashIndex + 1)}${superId}`;
}

export default function Superstedfesting({ vegnettlenke }: SuperstedfestingProps): JSX.Element {
    const href: string = getSuperHref(vegnettlenke.href, vegnettlenke.superstedfesting.veglenkesekvensid);

    return (
        <React.Fragment key="rn-superplacement">
            <dt className="c-valgt-features__dt">Superstedfesting:</dt>
            <dd className="c-valgt-features__dd">
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {vegnettlenke.superstedfesting.veglenkesekvensid}
                </a>
            </dd>
        </React.Fragment>
    );
}

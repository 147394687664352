import { Integration } from '@/middleware/integration';
import { ServerStatusResponse } from '@/server/responses';
import { Severity, VegkartError } from '@/state';
import { stateToHash } from '@/state/hash/StateToHash';
import { Dispatch } from '@/state/store';
import { getHash } from '@/utils/Utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionType, DismissErrorAction, ErrorAction, GetState, WindowUnfocusedAction } from './actiontypes';

export type handleErrorT = (error: VegkartError) => (dispatch, getState, integration) => void;
export const handleError: handleErrorT =
    (error: VegkartError) => (dispatch, getState: GetState, integration: Integration) => {
        const errorAction: ErrorAction = {
            type: ActionType.ERROR_OCCURRED,
            error,
        };
        dispatch(errorAction);
        if (error.severity === Severity.ERROR) {
            integration.server.postErrorReport(error);
        }
    };

export const logInfo =
    (message: string, data?: Record<string, string | number>) =>
    (dispatch: Dispatch, getState: GetState, integration: Integration) => {
        integration.server.postInfoLogEntry(message, data)?.then();
    };
export type dismissErrorT = (error: VegkartError) => (dispatch) => void;
export const dismissError: dismissErrorT = (error: VegkartError) => dispatch => {
    const dismissErrorAction: DismissErrorAction = {
        type: ActionType.ERROR_DISMISSED,
        error,
    };
    dispatch(dismissErrorAction);
};

export type handleLostFocusT = (hash: string) => (dispatch: Dispatch) => void;
export const handleLostFocus: handleLostFocusT = hash => dispatch => {
    const windowUnfocusedAction: WindowUnfocusedAction = {
        type: ActionType.WINDOW_UNFOCUSED,
        hash,
    };
    dispatch(windowUnfocusedAction);
};

export type handleRegainedFocusT = (hash: string) => (dispatch: Dispatch, getState: GetState) => void;
export const handleRegainedFocus: handleRegainedFocusT = hash => (dispatch, getState) => {
    if (getState().metaState.lastHash === hash) return;

    const uri = getHash(hash);
    const stateHash = stateToHash(getState());
    if (!uri.includes(stateHash)) {
        window.location.reload();
    }
};

export const fetchLesStatus = createAsyncThunk<ServerStatusResponse, never, { extra: Integration }>(
    'les/fetchStatus',
    async (_, { extra }) => {
        return extra.server.getServerStatus();
    }
);

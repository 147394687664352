import * as React from 'react';
import { connect } from 'react-redux';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { ColorIndex, resolveColor } from '@/utils/ColorHelper';

import { VegobjekttypeState } from '@/state';
import {
    removeVegobjekttype,
    removeVegobjekttypeT,
    toggleVegobjekttypeVisibility,
    toggleVegobjekttypeVisibilityT,
} from '@/actions/vegobjekterActions';

interface TagProps {
    vegobjekttype: Vegobjekttype;
    vegobjekttypeData: VegobjekttypeState;
    farge: ColorIndex;
}

interface Props extends TagProps {
    doToggleVegobjekttypeVisibility: toggleVegobjekttypeVisibilityT;
    doRemoveRoadObjectType: removeVegobjekttypeT;
}

const mapDispatchToProps = {
    doRemoveRoadObjectType: removeVegobjekttype,
    doToggleVegobjekttypeVisibility: toggleVegobjekttypeVisibility,
};

class HvaInfoComponent extends React.Component<Props, undefined> {
    render() {
        const { visible } = this.props.vegobjekttypeData;
        const vegobjekttype = this.props.vegobjekttype;
        const color = this.props.farge;
        const visibilityIcon = vegobjekttype.isLine() ? 'timeline' : 'room';
        const iconColor = visible ? resolveColor(color) : '#c6c6c6';

        return [
            <button
                key="hva-info-visibility"
                className="material-icons c-hva-info__visible-toggle"
                type="button"
                onClick={() => this.props.doToggleVegobjekttypeVisibility(this.props.vegobjekttypeData)}
                style={{ color: iconColor }}
                aria-label={`Vis vegobjekttype ${this.props.vegobjekttype.navn} i kart`}
                aria-pressed={this.props.vegobjekttypeData.visible}
            >
                {visibilityIcon}
            </button>,
            <h3 key="hva-info-header" className="c-hva-info c-hva-info__title">
                {this.props.vegobjekttype.navn}
            </h3>,
            <button
                key="hva-info-remove"
                className="c-hva-info__remove-button"
                type="button"
                onClick={() => this.removeRoadObject()}
                aria-label={`Fjern vegobjekttype ${this.props.vegobjekttype.navn} fra søk`}
            >
                <i className="o-icon-remove c-hva-info__remove-icon" />
            </button>,
        ];
    }

    private removeRoadObject() {
        this.props.doRemoveRoadObjectType(this.props.vegobjekttypeData);
    }
}

export const HvaInfo = connect(null, mapDispatchToProps)(HvaInfoComponent);

import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';

export class VegnettResponse {
    constructor(readonly objekter: Vegnettlenke[], readonly metadata: VegnettResponseMetadata) {}
}

export class VegnettResponseMetadata {
    constructor(
        readonly returnert: number,
        readonly antall: number,
        readonly neste: { start: string; href: string }
    ) {}
}

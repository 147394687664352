import React, { useEffect, useRef } from 'react';
import Login from './auth/Login';
import Avgrensning from './avgrensning/Avgrensning';
import { SearchPanelOpenButton } from './avgrensning/search/SearchPanelOpenButton';
import Errors from './error/Errors';
import LogoAndSplash from './info/LogoAndSplash';
import OLMapContainer from './map/OLMapContainer';
import { OpenPreferencesButton } from './preferences/OpenPreferencesButton';
import { LoginButton } from './auth/LoginButton';
import { InfoButton } from './info/InfoButton';
import { Toolbar } from './Toolbar';
import { useDispatch } from '@/state/store';
import { logInfo } from '@/actions/metaActions';

export const Vegkart = () => {
    const hasLoggedVisit = useRef(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!hasLoggedVisit.current) {
            hasLoggedVisit.current = true;
            dispatch(logInfo('Opened Vegkart'));
        }
    }, [dispatch]);
    useEffect(() => {
        // Set class to determine navigation by mouse or keyboard, used for styling in body.less-file.
        const mouseListener = () => {
            document.body.classList.add('using-mouse');
        };
        const keyListener = () => {
            document.body.classList.remove('using-mouse');
        };
        document.body.addEventListener('mousedown', mouseListener);
        document.body.addEventListener('keydown', keyListener);
        return () => {
            document.body.removeEventListener('mousedown', mouseListener);
            document.body.removeEventListener('keydown', keyListener);
        };
    }, []);
    return (
        <div>
            <OLMapContainer />
            <LogoAndSplash />
            <Login />
            <Toolbar ariaLabel={'Diverse valg'} id={'app-controls'} orientation={'vertical'}>
                <InfoButton />
                <OpenPreferencesButton />
                <LoginButton />
            </Toolbar>
            <Avgrensning />
            <Errors />
            <SearchPanelOpenButton />
        </div>
    );
};

import { round } from 'lodash-es';

export class Kartutsnitt {
    static fromArray(bounds: number[]): Kartutsnitt {
        return new Kartutsnitt(bounds[0], bounds[1], bounds[2], bounds[3]);
    }

    static fromPoint(x: number, y: number): Kartutsnitt {
        // Magic numbers achieved through trial and error.
        return new Kartutsnitt(x - 1000, y - 10000, x + 1000, y + 10000);
    }

    readonly xMin;
    readonly yMin;
    readonly xMax;
    readonly yMax;

    constructor(xMin: number, yMin: number, xMax: number, yMax: number) {
        this.xMin = round(xMin, 3);
        this.yMin = round(yMin, 3);
        this.xMax = round(xMax, 3);
        this.yMax = round(yMax, 3);
    }

    contains(coordinate: [number, number]): boolean {
        const x = coordinate[0];
        const y = coordinate[1];
        return x >= this.xMin && x <= this.xMax && y >= this.yMin && y <= this.yMax;
    }

    getCenter(): [number, number] {
        const easting = (this.xMin + this.xMax) / 2;
        const northing = (this.yMin + this.yMax) / 2;
        return [easting, northing];
    }

    toQueryString(): string {
        return `${this.xMin},${this.yMin},${this.xMax},${this.yMax}`;
    }
}

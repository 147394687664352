import { ChangeEvent, FormEvent, KeyboardEvent, RefObject, useCallback, useEffect } from 'react';
import { mod } from '@/utils/Utils';
import { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';

const useSearchBarFunctions = (
    setArrowPress: (state: boolean) => void,
    cursor: number,
    setCursor: (state: number) => void,
    dropdownVisible: boolean,
    setDropdownVisible: (state: boolean) => void,
    dropdownData: DropdownItemData[],
    noInput: () => boolean,
    onSelectedLocation: (selectedData?: DropdownItemData) => void,
    dropdownWrapperRef: RefObject<HTMLElement>,
    setInputValue: (state: string) => void
) => {
    const hasResults = useCallback(() => dropdownData.length > 0, [dropdownData.length]);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setArrowPress(false);
        if (cursor !== -1) {
            setCursor(dropdownData.findIndex(i => !i.header) || 0);
        }
        setInputValue(e.target.value);
        if (e.target.value.length > 0 && !dropdownVisible) setDropdownVisible(true);
    };

    const handleOnFocus = () => {
        setDropdownVisible(true);
        setCursor(-1);
        setArrowPress(false);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
            if (!dropdownVisible) {
                setDropdownVisible(true);
                setCursor(-1);
                // setInputValue('');
            } else {
                e.preventDefault();
                setArrowPress(true);
                let newCursor = cursor + 1;
                while (dropdownData[mod(newCursor, dropdownData.length)].header) newCursor++;
                newCursor = mod(newCursor, dropdownData.length);
                setCursor(newCursor);
            }
        } else if (e.key === 'ArrowUp') {
            if (!dropdownVisible) {
                setDropdownVisible(true);
                setCursor(-1);
                // setInputValue('');
            } else {
                e.preventDefault();
                setArrowPress(true);
                let newCursor = cursor - 1;
                while (dropdownData[mod(newCursor, dropdownData.length)].header) newCursor--;
                newCursor = mod(newCursor, dropdownData.length);
                // setInputValue(dropdownData[newCursor].title);
                setCursor(newCursor);
            }
        } else if (e.key === 'Tab') {
            if (!noInput()) onSelectedLocation(dropdownData[cursor || 0]);
            setDropdownVisible(false);
        } else if (e.key === 'Escape') {
            setDropdownVisible(false);
            setInputValue('');
        } else if (e.key === 'Alt') {
            e.preventDefault();
        }
    };

    const handleClickOutside = useCallback(
        (e: MouseEvent) => {
            const target = e.target as Node;
            if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(target)) {
                setDropdownVisible(false);
            }
        },
        [dropdownWrapperRef, setDropdownVisible]
    );
    const handleSubmit = useCallback(
        (e?: FormEvent) => {
            if (e) e.preventDefault();
            if (noInput() || !hasResults()) {
                setDropdownVisible(false);
                onSelectedLocation(null);
            } else if (hasResults()) {
                setDropdownVisible(false);
                onSelectedLocation(dropdownData.slice(cursor === -1 ? 0 : cursor).find(i => !i.header));
            }
        },
        [cursor, dropdownData, hasResults, noInput, onSelectedLocation, setDropdownVisible]
    );

    useEffect(() => {
        const listener = handleClickOutside;
        window.addEventListener('mouseup', listener);
        return () => {
            window.removeEventListener('mouseup', listener);
        };
    }, [handleClickOutside]);

    return { handleClickOutside, handleKeyDown, handleOnFocus, handleChange, handleSubmit };
};

export default useSearchBarFunctions;

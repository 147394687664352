import copy from 'clipboard-copy';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import EventType from 'ol/events/EventType';
import { Geometry, Point } from 'ol/geom';
import React, { useMemo } from 'react';
import { Severity, VegkartError, VegsystemreferanseLookup } from '@/state';
import { roadRefCoordinates } from '@/utils/SpatialHelper';
import { OmraderFeatureLayer } from '../layers/OmraderFeatureLayer';
import {
    stateConfig,
    stateIsDrawing,
    statePreferences,
    stateVegsystemreferanseSok,
} from '@/selectors/selectors';
import { useMap } from '@/state/context/MapContext';
import { handleError } from '@/actions/metaActions';
import { clearVSRLookup, getVSRForCoordinate } from '@/actions/overlayActions';
import { getVegBilderURL } from '@/utils/VegbilderHelper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/state/store';
import { unByKey } from 'ol/Observable';
import { getRoadReferenceFiksURL } from '@/utils/FiksVegDataHelper';
import { Vegposisjon } from '@/domain/omrader';

export const RoadReferencePopup = () => {
    const { map } = useMap();
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();
    const preferences = useSelector(statePreferences);
    const isDrawing = useSelector(stateIsDrawing);
    const vegsystemreferansesoek: VegsystemreferanseLookup = useSelector(stateVegsystemreferanseSok);
    const config = useSelector(stateConfig);
    const disabled = useMemo(() => {
        const meterAvgrensning: Vegposisjon =
            vegsystemreferansesoek?.vegsystemreferanse?.strekning?.meterAvgrensning;

        return !(meterAvgrensning && !meterAvgrensning.slutt);
    }, [vegsystemreferansesoek]);

    useEffect(() => {
        if (!isDrawing) {
            const key = addMapEventListener(map, setCopied, dispatch);
            return () => {
                unByKey(key);
            };
        }
    }, [map, dispatch, isDrawing]);
    if (!vegsystemreferansesoek) {
        return <div />;
    }

    const vegsystemreferanse: string = vegsystemreferansesoek.vegsystemreferanse
        ? selectText(preferences, vegsystemreferansesoek)
        : null;
    const coordinates = roadRefCoordinates(vegsystemreferansesoek);

    const popup = new Overlay({
        position: coordinates,
        positioning: 'top-left',
        stopEvent: true,
    });

    map.addOverlay(popup);

    return (
        <div className="o-popup" ref={popupElement => popup.setElement(popupElement)}>
            <div className="o-popup__arrow" />
            <header className="o-popup__header">
                <h3 className="o-popup__header-text">Vegsystemreferanse</h3>
                <button
                    type="button"
                    // we use onMouseUp because of quirkiness in openLayers
                    // https://github.com/openlayers/openlayers/issues/6948
                    onMouseUp={() => {
                        dispatch(clearVSRLookup());
                        map.removeOverlay(popup);
                    }}
                    title="Ta bort vegsystemreferansemarkør"
                    className="o-popup__close-button"
                >
                    <i className="o-icon-remove o-popup__remove-icon" />
                </button>
            </header>

            <p className="o-popup__body-text">
                {vegsystemreferanse ? (
                    <>
                        {vegsystemreferanse}
                        <button
                            type="button"
                            onClick={async () => {
                                try {
                                    await copy(vegsystemreferanse);
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, 2_000);
                                } catch (e) {
                                    dispatch(
                                        handleError(
                                            new VegkartError('Could not copy to clipboard', {}, Severity.WARN)
                                        )
                                    );
                                }
                            }}
                            title="Kopier vegsystemreferanse til utklippstavle"
                        >
                            <i className="o-icon-copy o-popup__copy-icon" />
                        </button>
                        <a
                            href={getVegBilderURL(coordinates)}
                            title="Naviger til vegbilder"
                            id="naviger_til_vegbilder"
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <i className="o-icon-camera o-popup__camera-icon" />
                        </a>
                        <button
                            onClick={() => getRoadReferenceFiksURL(vegsystemreferansesoek, config)}
                            disabled={disabled}
                            title={`${
                                disabled ? 'Melding av feil krever punktreferanse' : 'Meld feil på veg'
                            }`}
                            aria-label="Naviger til fiksvegdata"
                            className="c-valgt-header__report-button"
                        >
                            <i
                                className={`${
                                    disabled ? 'o-icon-report--disabled' : 'o-icon-report'
                                } c-valgt-header__report-icon`}
                            />
                        </button>
                        <br />
                        {copied && 'Kopiert!'}
                    </>
                ) : (
                    'Vegsystemreferanse mangler'
                )}
            </p>
        </div>
    );
};

const addMapEventListener = (map, setCopied, dispatch) => {
    return map.on(EventType.CLICK, (event: MapBrowserEvent<UIEvent>) => {
        const selectableFeatureAtPixel = map.forEachFeatureAtPixel(event.pixel, f => {
            return !(f as Feature<Geometry>).get(OmraderFeatureLayer.IsAreaFeatureKey);
        });
        if (!selectableFeatureAtPixel) {
            setCopied(false);
            dispatch(getVSRForCoordinate(event.coordinate as [number, number]));
        }
    });
};

const selectText = (preferences, vegsystemreferansesoek) => {
    if (preferences.includeTrafikantgruppe) return transformRoadSysRef(vegsystemreferansesoek);
    else return transformRoadSysRefNoGroup(vegsystemreferansesoek);
};

const transformRoadSysRef = ({ kommune, vegsystemreferanse }: VegsystemreferanseLookup) => {
    if (['K', 'P', 'S'].includes(vegsystemreferanse.vegsystem.vegkategori))
        return kommune + ' ' + vegsystemreferanse.toVegkartKortform();
    else return vegsystemreferanse.toVegkartKortform();
};

const transformRoadSysRefNoGroup = ({ kommune, vegsystemreferanse }: VegsystemreferanseLookup) => {
    if (['K', 'P', 'S'].includes(vegsystemreferanse.vegsystem.vegkategori))
        return kommune + ' ' + vegsystemreferanse.toVegkartRedusertKortform();
    else return vegsystemreferanse.toVegkartRedusertKortform();
};

import chroma from 'chroma-js';

const colorList = [
    '#e81a57',
    '#008b7d',
    '#547281',
    '#a4b611',
    '#0abbd3',
    '#6739b6',
    '#1f78b4',
    '#b2df8a',
    '#fb9a99',
    '#fdbf6f',
    '#cab2d6',
    '#4daf4a',
    '#984ea3',
    '#f781bf',
    '#999999',
    '#ae017e',
];

export function resolveColor(color: ColorIndex): string {
    return colorList[color.mainColorIndex % colorList.length];
}

export function addAlpha(color: string, alpha: number): string {
    const [r, g, b] = chroma(color).rgb();
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function setAlpha(color: string, alpha: number): string {
    return chroma(color).alpha(alpha).css();
}

export const defaultColor = '#7F898F';
export const halfBlack = 'rgba(0,0,0,0.5)';
export const red = 'rgba(255,0,0,0.5)';

export class ColorIndex {
    constructor(readonly mainColorIndex: number, readonly categoryColorIndex: number) {}
    toString(): string {
        return `${this.mainColorIndex}_${this.categoryColorIndex}`;
    }
}

export class ColorHandler {
    private colors = new Array(colorList.length).fill(0);
    private usedColorIndexes: Set<number> = new Set();

    constructor(usedColors: ColorIndex[]) {
        usedColors.forEach(c => {
            this.colors[c.mainColorIndex]++;
            this.usedColorIndexes.add(c.mainColorIndex);
        });
    }

    getNextColorIndex(): ColorIndex {
        const distinctColorIndex = this.getDistinctColorIndex();
        this.colors[distinctColorIndex]++;
        return new ColorIndex(distinctColorIndex, this.findIndexOfLowest(this.colors));
    }

    decrementColor(colorIndex: ColorIndex): void {
        this.colors[colorIndex.mainColorIndex]--;
        this.usedColorIndexes.delete(colorIndex.mainColorIndex);
    }

    private getDistinctColorIndex(): number {
        let maxDifference = 0;
        let mostDistinctIndex = 0;
        for (let i = 0; i < colorList.length; i++) {
            if (!this.usedColorIndexes.has(i)) {
                let difference = this.usedColorIndexes.size > 0 ? 0 : Infinity;
                this.usedColorIndexes.forEach(usedIndex => {
                    difference += chroma.deltaE(colorList[i], colorList[usedIndex]);
                });
                if (difference > maxDifference) {
                    maxDifference = difference;
                    mostDistinctIndex = i;
                }
            }
        }
        this.usedColorIndexes.add(mostDistinctIndex);
        return mostDistinctIndex;
    }

    private findIndexOfLowest(array: number[]): number {
        let lowestValue = Infinity;
        let lowestIndex = 0;
        array.forEach((value, index) => {
            if (value < lowestValue) {
                lowestValue = value;
                lowestIndex = index;
            }
        });
        return lowestIndex;
    }
}

import {
    AbortedVegnettAction,
    ActionType,
    ErrorAction,
    FetchVegnettAction,
    GetState,
    ReceivedVegnettAction,
    ReceiveSelectedLinkAction,
} from '@/actions/actiontypes';
import { handleError } from '@/actions/metaActions';
import { LinkQuery } from '@/actions/valgtActions';
import { Data } from '@/bootstrap/data';
import { Fylke } from '@/domain/omrader';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { stateMap, stateOmrader, stateVegnett, stateVegnettFilter } from '@/selectors/selectors';
import {
    MapState,
    OmraderState,
    RootState,
    Severity,
    VegkartError,
    VegnettState,
    VegnettStatistics,
} from '@/state';
import { getFylkerForQuery } from './fetchHelpers';
import { Integration } from './integration';
import { CommonQueries, MapQueries, OmraderQueries, VegnettQueries } from './queries';

export const parseLinkQuery: (query: string) => LinkQuery = queryString => {
    const { position, id } = queryString.match(/(?<position>[\d.-]+)?@(?<id>\d+)/).groups;
    if (position) {
        const [start, end] = position.split('-').map(v => parseFloat(v));
        return { id, start, end };
    } else {
        return { id };
    }
};

export const matchesLinkQuery: (filter: LinkQuery, link: Vegnettlenke) => boolean = (filter, link) => {
    if (link.veglenkesekvensid != parseInt(filter.id)) return false;
    else if (filter.start && filter.end)
        return link.startposisjon >= filter.start && link.startposisjon <= filter.end;
    else if (filter.start && !filter.end)
        return link.startposisjon <= filter.start && link.sluttposisjon >= filter.start;
    else return true;
};
const isNodeQuery = (linkQuery: LinkQuery) => linkQuery.id && !linkQuery.end && !linkQuery.start;

export const fetchRoadNetLinks =
    linkQueryString => async (dispatch, getState: GetState, integration: Integration) => {
        const linkQuery = parseLinkQuery(linkQueryString);
        integration.server
            .getVeglenkesekvens(linkQuery.id)
            .then(links => {
                links = links.filter(s => matchesLinkQuery(linkQuery, s));
                const receivedSelectedAction: ReceiveSelectedLinkAction = {
                    type: ActionType.VALGT_LENKE_RECEIVED,
                    linkQueryString,
                    links,
                };
                dispatch(receivedSelectedAction);
            })
            .catch(err => {
                if (err === 404 && isNodeQuery(linkQuery))
                    return integration.server.getNode(parseInt(linkQuery.id)).then(node => {
                        dispatch({ type: ActionType.VALGT_NODE_RECEIVED, node });
                    });
                else throw err;
            })
            .catch(err => {
                console.warn(err);
                let message = err === 404 ? `Fant ikke veglenke` : `Kunne ikke hente veglenke`;
                if (isNodeQuery(linkQuery)) message += ' eller node';
                message += ` ${linkQueryString}.`;
                const severity = err === 404 ? Severity.INFO : Severity.ERROR;
                dispatch(
                    handleError(
                        new VegkartError(message, { err, linkQueryString: linkQueryString }, severity)
                    )
                );
            });
    };

const geometryTolerance = (featureCount: number) => {
    if (featureCount > 3000) return 30;
    else if (featureCount > 2000) return 20;
    else if (featureCount > 1000) return 10;
    else return null;
};

export const fetchVegnett = () => async (dispatch, getState: () => RootState, integration: Integration) => {
    // States
    const mapState: MapState = stateMap(getState());
    const omraderState: OmraderState = stateOmrader(getState());
    const vegnettState: VegnettState = stateVegnett(getState());
    const staticData: Data = integration.data;

    // Timestamp
    const timestamp = getState().searchState.searchDropdownState.timestamp;

    // Query classes
    const commonQueries: CommonQueries = new CommonQueries();
    const mapQueries: MapQueries = mapState.toQueries();
    const omraderQueries: OmraderQueries = omraderState.toQueries();
    const vegnettQueries: VegnettQueries = stateVegnettFilter(getState());

    // If filter contains empty arrays, don't fetch and reset road net results.
    if (vegnettQueries.hasEmptyArrays()) {
        const resetVegnettAction: ReceivedVegnettAction = {
            type: ActionType.VEGNETT_FETCH_RECEIVED,
            receivedVegnettState: vegnettState.withRoadNetLinks([]).withRoadNetStatistics([]),
        };
        dispatch(resetVegnettAction);
        return;
    }

    // Dispatch loading state.
    const fetchVegnettAction: FetchVegnettAction = { type: ActionType.VEGNETT_FETCH_INITIATED };
    dispatch(fetchVegnettAction);

    // Begin fetch.
    findRoadSysRefAndTotalsFromProbeResults(
        commonQueries,
        mapQueries,
        omraderQueries,
        vegnettQueries,
        integration,
        timestamp
    )
        .then(response => {
            const adjustedOmraderQueries = response.roadRef
                ? omraderQueries.withVegsystemreferanser(response.roadRef)
                : omraderQueries;
            const antall = response.total;
            const geometritoleranse = geometryTolerance(antall);
            const commonQueries = new CommonQueries().withGeometritoleranse(geometritoleranse);
            const timeBeforeFetch = new Date().getTime();

            if (antall > 5000) {
                fetchVegnettStatisticsByFylke(
                    commonQueries,
                    mapQueries,
                    omraderQueries,
                    vegnettQueries,
                    integration,
                    staticData.fylker,
                    timestamp
                ).then(statisticsResponse => {
                    const timeAfterFetch = new Date().getTime();
                    let actionType;

                    if (hasSearchStateChanged(mapQueries, omraderQueries, vegnettQueries, getState)) {
                        actionType = ActionType.VEGNETT_FETCH_ABORTED;
                        const abortedVegnettAction: AbortedVegnettAction = {
                            type: actionType,
                        };
                        dispatch(abortedVegnettAction);
                    } else {
                        actionType = ActionType.VEGNETT_FETCH_RECEIVED;
                        const receivedVegnettState = vegnettState
                            .withRoadNetLinks(null)
                            .withRoadNetStatistics(statisticsResponse)
                            .withVisible(true)
                            .withFilter(vegnettQueries)
                            .withLoading(false);
                        const receivedVegnettAction: ReceivedVegnettAction = {
                            type: actionType,
                            receivedVegnettState,
                        };
                        dispatch(receivedVegnettAction);
                    }
                    integration.server.postPerformanceLogEntry(actionType, timeAfterFetch - timeBeforeFetch);
                });
            } else {
                fetchVegnettByOmradeType(
                    commonQueries,
                    mapQueries,
                    omraderQueries,
                    vegnettQueries,
                    integration,
                    getState,
                    adjustedOmraderQueries,
                    timestamp
                ).then(roadNetResponse => {
                    const timeAfterFetch = new Date().getTime();
                    let actionType;

                    if (hasSearchStateChanged(mapQueries, omraderQueries, vegnettQueries, getState)) {
                        actionType = ActionType.VEGNETT_FETCH_ABORTED;
                        const abortedVegnettAction: AbortedVegnettAction = {
                            type: actionType,
                        };
                        dispatch(abortedVegnettAction);
                    } else {
                        actionType = ActionType.VEGNETT_FETCH_RECEIVED;
                        const receivedVegnettState = vegnettState
                            .withRoadNetLinks(roadNetResponse)
                            .withRoadNetStatistics(null)
                            .withVisible(true)
                            .withFilter(vegnettQueries)
                            .withLoading(false);
                        const receivedVegnettAction: ReceivedVegnettAction = {
                            type: actionType,
                            receivedVegnettState,
                        };
                        dispatch(receivedVegnettAction);
                    }
                    integration.server.postPerformanceLogEntry(actionType, timeAfterFetch - timeBeforeFetch);
                });
            }
        })
        .catch(rejection => {
            console.warn('rejecthi on your honbor');
            const error = new VegkartError(
                'Feil ved henting av vegnett.',
                { response: rejection.response },
                Severity.ERROR
            );
            const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error };
            dispatch(errorAction);
        });
};

const fetchVegnettStatisticsByFylke = async (
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    integration: Integration,
    fylker: Fylke[],
    timestamp: Date
): Promise<VegnettStatistics[]> => {
    const results: VegnettStatistics[] = [];

    for (const fylke of getFylkerForQuery(omraderQueries, fylker)) {
        const fylkeQueries = omraderQueries.withFylker([fylke.toParams()]);

        await integration.server
            .getSegmentertVegnett(
                commonQueries.withAntall(0),
                mapQueries,
                fylkeQueries,
                vegnettQueries,
                timestamp
            )
            .then(response => {
                if (response.metadata.antall > 0) {
                    results.push(new VegnettStatistics(fylke, response.metadata.antall));
                }
            });
    }

    return results;
};

const fetchVegnettByOmradeType = async (
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    integration: Integration,
    getState,
    adjustedOmraderQueries: OmraderQueries,
    timestamp: Date
): Promise<Vegnettlenke[]> => {
    let uniqueVegnettlenker: Set<Vegnettlenke> = new Set<Vegnettlenke>();

    if (adjustedOmraderQueries.areasLength() > 0) {
        for (const adjustedOmradeTypeQueries of adjustedOmraderQueries.split()) {
            const omradeTypeLenker: Vegnettlenke[] = await fetchVegnettRecursively(
                commonQueries,
                mapQueries,
                omraderQueries,
                vegnettQueries,
                integration,
                getState,
                adjustedOmradeTypeQueries,
                timestamp
            );
            uniqueVegnettlenker = new Set<Vegnettlenke>([...uniqueVegnettlenker, ...omradeTypeLenker]);
        }
        return Array.from(uniqueVegnettlenker);
    } else {
        return await fetchVegnettRecursively(
            commonQueries,
            mapQueries,
            omraderQueries,
            vegnettQueries,
            integration,
            getState,
            adjustedOmraderQueries,
            timestamp
        );
    }
};

const fetchVegnettRecursively = async (
    genericQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    integration: Integration,
    getState: GetState,
    adjustedOmraderQueries: OmraderQueries,
    timestamp: Date
): Promise<Vegnettlenke[]> => {
    if (hasSearchStateChanged(mapQueries, omraderQueries, vegnettQueries, getState)) {
        return [];
    }

    const fragment = await integration.server.getSegmentertVegnett(
        genericQueries,
        mapQueries,
        adjustedOmraderQueries,
        vegnettQueries,
        timestamp
    );

    if (fragment.metadata.returnert > 0) {
        return fragment.objekter.concat(
            await fetchVegnettRecursively(
                genericQueries.withStart(fragment.metadata.neste.start),
                mapQueries,
                omraderQueries,
                vegnettQueries,
                integration,
                getState,
                adjustedOmraderQueries,
                timestamp
            )
        );
    } else {
        return fragment.objekter;
    }
};

interface RoadRefAndTotalT {
    roadRef: string[];
    total: number;
}
async function countRoadSysRefs(
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    integration: Integration,
    timestamp: Date
): Promise<number> {
    let antall = 0;
    // Timestamp

    if (omraderQueries.areasLength() > 0) {
        for (const omradetypeQueries of omraderQueries.split()) {
            await integration.server
                .getSegmentertVegnett(
                    commonQueries.withAntall(0),
                    mapQueries,
                    omradetypeQueries,
                    vegnettQueries,
                    timestamp
                )
                .then(response => {
                    antall += response.metadata.antall;
                });
        }
    } else {
        await integration.server
            .getSegmentertVegnett(
                commonQueries.withAntall(0),
                mapQueries,
                omraderQueries,
                vegnettQueries,
                timestamp
            )
            .then(response => {
                antall += response.metadata.antall;
            });
    }
    return antall;
}

async function findRoadSysRefAndTotalsFromProbeResults(
    commonQueries: CommonQueries,
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    integration: Integration,
    timestamp: Date
): Promise<RoadRefAndTotalT> {
    const LIMIT = 5000;
    // If vegsystemreferanse already exists in search, override probing.
    if (omraderQueries.vegsystemreferanse?.length > 0) {
        let antall = 0;
        if (omraderQueries.areasLength() > 0) {
            for (const omradetypeQueries of omraderQueries.split()) {
                await integration.server
                    .getSegmentertVegnett(
                        commonQueries.withAntall(0),
                        mapQueries,
                        omradetypeQueries,
                        vegnettQueries,
                        timestamp
                    )
                    .then(response => {
                        antall += response.metadata.antall;
                    });
            }
        }
        return { roadRef: omraderQueries.vegsystemreferanse, total: antall };
    }
    // Probe each area for each vegkategori.
    const roadRefRequestAll = ['E', 'R', 'F', 'K', 'P', 'S'];
    const roadRefRequest = [...roadRefRequestAll];
    const roadRefResult = new Map<string, number>();

    const total = await countRoadSysRefs(
        commonQueries.withAntall(0),
        mapQueries,
        omraderQueries,
        vegnettQueries,
        integration,
        timestamp
    );
    if (total < LIMIT) return { roadRef: null, total };

    for (const roadRef of roadRefRequestAll) {
        const antall = await countRoadSysRefs(
            commonQueries.withAntall(0),
            mapQueries,
            omraderQueries.withVegsystemreferanser([roadRef]),
            vegnettQueries,
            integration,
            timestamp
        );
        roadRefResult.set(roadRef, antall);
    }

    const totalAll = getTotal(roadRefResult);
    let sum = totalAll;

    while (sum > LIMIT && roadRefResult.size > 0) {
        roadRefResult.delete(roadRefRequest.pop());
        sum = getTotal(roadRefResult);
    }

    return roadRefResult.size > 0
        ? { roadRef: roadRefRequest, total: sum }
        : { roadRef: roadRefRequestAll, total: totalAll };
}

function getTotal(map: Map<string, number>): number {
    let total = 0;

    for (const value of map.values()) {
        total += value;
    }

    return total;
}

const hasSearchStateChanged = (
    mapQueries: MapQueries,
    omraderQueries: OmraderQueries,
    vegnettQueries: VegnettQueries,
    getState: GetState
): boolean => {
    return (
        JSON.stringify(stateMap(getState()).toQueries()) !== JSON.stringify(mapQueries) ||
        JSON.stringify(stateOmrader(getState()).toQueries()) !== JSON.stringify(omraderQueries) ||
        JSON.stringify(stateVegnett(getState()).toQueries()) !== JSON.stringify(vegnettQueries)
    );
};

import * as React from 'react';
import { About } from './About';
import { PrivacyPolicy } from './PrivacyPolicy';
import { Splash } from './Splash';
import { Changelog } from './Changelog';
import { SplashState } from '@/state/SplashState';
import * as selectors from '../../selectors/selectors';
import { changeSplash as _changeSplash } from '../../actions/splashActions';
import { useDispatch, useSelector } from '@/state/store';
import { useCallback, useEffect } from 'react';
import { PreferencesDialog } from '../preferences/PreferencesDialog';
import { trapFocus } from '@/utils/Utils';

const LogoAndSplash = () => {
    const windowOpen = useSelector(selectors.stateSplash);
    const dispatch = useDispatch();
    const ref = React.createRef<HTMLDialogElement>();
    const changeSplash = useCallback(
        splash => {
            dispatch(_changeSplash(splash));
        },
        [dispatch]
    );

    useEffect(() => {
        const { type, listener } = {
            type: 'keydown',
            listener: e => {
                if (e.key === 'Escape' && windowOpen !== SplashState.NONE) {
                    changeSplash(SplashState.NONE);
                }
            },
        };
        document.addEventListener(type, listener);
        const dialog = ref.current;
        dialog && [dialog.showModal(), trapFocus(dialog)];
        return () => {
            document.removeEventListener(type, listener);
            dialog?.close();
        };
    }, [changeSplash, windowOpen, ref]);

    return (
        <div className="c-splash-and-logo">
            {windowOpen === SplashState.MAIN && <Splash handleWindowChange={changeSplash} ref={ref} />}
            {windowOpen === SplashState.POLICY && (
                <PrivacyPolicy handleWindowChange={changeSplash} ref={ref} />
            )}
            {windowOpen === SplashState.ABOUT && <About handleWindowChange={changeSplash} ref={ref} />}
            {windowOpen === SplashState.CHANGELOG && (
                <Changelog handleWindowChange={changeSplash} ref={ref} />
            )}
            {windowOpen === SplashState.PREFERENCES && (
                <PreferencesDialog handleWindowChange={changeSplash} />
            )}
        </div>
    );
};
export default LogoAndSplash;

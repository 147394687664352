import * as React from 'react';

import { connect } from 'react-redux';
import { VegnettQueries } from '@/middleware/queries';
import * as selectors from '../../../selectors/selectors';
import { RootState, VegnettRetning } from '@/state';
import SimpleToggle from '../../objects/SimpleToggle';
import Spinner from '../../objects/Spinner';
import { VegnettFilter } from './VegnettFilter';
import {
    resetRoadNetFilter,
    resetRoadNetFilterT,
    setRoadNetFilter,
    setRoadNetFilterT,
    toggleRoadNetVisibility,
    toggleRoadNetVisibilityT,
} from '@/actions/vegnettActions';
import { DownloadResultsBox } from './DownloadResultsBox';

interface StateProps {
    roadNetDirection: VegnettRetning;
    roadNetFilter: VegnettQueries;
    roadNetLoading: boolean;
    roadNetVisible: boolean;
    id: string;
}

interface DispatchProps {
    doToggleRoadNetVisibility: toggleRoadNetVisibilityT;
    doResetRoadNetFilter: resetRoadNetFilterT;
    doSetRoadNetFilter: setRoadNetFilterT;
}

type Props = StateProps & DispatchProps;
interface OwnState {
    open: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        roadNetDirection: selectors.stateVegnettDirection(state),
        roadNetFilter: selectors.stateVegnettFilter(state),
        roadNetLoading: selectors.stateVegnettLoading(state),
        roadNetVisible: selectors.stateVegnettVisible(state),
    };
};

const mapDispatchToProps = {
    doResetRoadNetFilter: resetRoadNetFilter,
    doSetRoadNetFilter: setRoadNetFilter,
    doToggleRoadNetVisibility: toggleRoadNetVisibility,
};

class RoadNetComponent extends React.Component<Props, OwnState> {
    readonly state = { open: false };

    handleToggleRoadnet() {
        this.props.doToggleRoadNetVisibility();
    }

    handleResetFilter() {
        this.props.doResetRoadNetFilter();
    }

    render() {
        const { roadNetDirection, roadNetVisible, roadNetFilter, roadNetLoading } = this.props;
        const { open } = this.state;
        const openStateClassModifier = open ? 'open' : 'closed';

        return (
            <div className="c-road-net t-hidden-mobile">
                <section className="c-road-net__header">
                    <button
                        className="c-road-net__filter-button"
                        onClick={() => {
                            this.setState({ open: !open });
                        }}
                        id="choose-roadnet"
                        aria-expanded={open}
                    >
                        <i
                            className={`o-icon-chevron-right
                                       c-road-net__chevron c-road-net__chevron--${openStateClassModifier}`}
                        />
                        <span className="c-road-net__chevron-text">Velg vegnett</span>
                    </button>
                    <SimpleToggle
                        className="c-road-net__toggle-switch"
                        checked={roadNetVisible}
                        label="Vis i kart"
                        id="show_roadnet_toggle"
                        ariaLabel="Vis vegnett i kart"
                        onChange={() => this.handleToggleRoadnet()}
                    />
                    {roadNetLoading && <Spinner className="c-road-net__spinner" />}
                    <button
                        className="c-road-net__nullify"
                        disabled={roadNetFilter.isEmpty() || roadNetFilter.isFull()}
                        onClick={e => {
                            e.stopPropagation();
                            this.handleResetFilter();
                        }}
                    >
                        Nullstill filter
                    </button>
                </section>
                {open && (
                    <>
                        <VegnettFilter
                            vegnettfilter={roadNetFilter}
                            vegnettRetning={roadNetDirection}
                            setVegnettfilter={this.props.doSetRoadNetFilter}
                        />
                        <DownloadResultsBox />
                    </>
                )}
            </div>
        );
    }
}

export const Vegnett = connect(mapStateToProps, mapDispatchToProps)(RoadNetComponent);

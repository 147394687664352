import { OverlayState, VegsystemreferanseLookup } from '@/state';
import { ActionType, OverlayAction } from '@/actions/actiontypes';

const initialState = new OverlayState();

export const overlayReducer = (overlayState: OverlayState = initialState, action: OverlayAction) => {
    switch (action.type) {
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.overlayState;
        case ActionType.HASH_CHANGED:
            return action.state.overlayState;
        // Overlays
        case ActionType.OVERLAY_GEOLOCATION_SET:
            return overlayState.withGeolocationState(action.geolocationState);
        case ActionType.OVERLAY_VEGSYSTEMREFERANSE_ADDED:
            const vsrLookup = action.vsrLookup;
            if (!!vsrLookup) {
                const geometri = vsrLookup.vegsystemreferanse?.geometri
                    ? vsrLookup.vegsystemreferanse.geometri
                    : vsrLookup.geometri;

                return overlayState.withVegsystemreferanseLookup(
                    new VegsystemreferanseLookup(
                        vsrLookup.east,
                        vsrLookup.north,
                        vsrLookup.vegsystemreferanse,
                        vsrLookup.veglenke,
                        geometri,
                        vsrLookup.avstand,
                        vsrLookup.kommune
                    )
                );
            } else {
                return overlayState.withVegsystemreferanseLookup(null);
            }
        case ActionType.OVERLAY_VEGSYSTEMREFERANSE_REMOVED:
            return overlayState.withVegsystemreferanseLookup(null);
        case ActionType.OVERLAY_STEDSNAVN_ADDED:
            return overlayState.withPlacenameMarker(action.stedsnavn);
        default:
            return overlayState;
    }
};

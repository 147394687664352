import { GeometryObject, Position } from 'geojson';
import { wktToGeoJSON } from '@terraformer/wkt';

export function parseWKT(wkt: string): GeometryObject {
    return wktToGeoJSON(wkt);
}

// TODO Remove when the api returns correct geometry
export function preprocessWKT(wkt: string): string {
    // remove Z-coordinates from WKT-string.
    return (wkt || '')
        .replace(/[Z][ ]?/g, '')
        .replace(/((-?\d+(\.\d+)?) (-?\d+(\.\d+)?)) (-?\d+(\.\d+)?)/g, '$1');
}

export function getPointFromWKT(wkt: string): Position {
    const geoJson = parseWKT(wkt);
    if (geoJson.type == 'Point') {
        return geoJson.coordinates;
    }
    if (geoJson.type == 'LineString' || geoJson.type == 'MultiPoint') {
        return geoJson.coordinates[0];
    }
    if (geoJson.type == 'Polygon' || geoJson.type == 'MultiLineString') {
        return geoJson.coordinates[0][0];
    }
}

import { useDispatch, Dispatch } from '@/state/store';
import React, { useContext, useMemo } from 'react';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { navnFilter } from '@/components/avgrensning/search/useSearchResult';
import { vegobjekttypeSelectedInDatakatalog } from '@/actions/searchActions';
import { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';

export function useDatakatalogResult(searchText): DropdownItemData[] {
    const dispatch = useDispatch();
    const { data } = useContext(ConfigAndDataContext);
    const groupedByFirstLetter = useMemo(
        () =>
            Object.entries(
                data.datakatalog.vegobjekttyper.filter(navnFilter(searchText)).reduce(
                    (result, item) => ({
                        ...result,
                        [item.navn[0]]: [...(result[item.navn[0]] ?? []), item],
                    }),
                    {} as Record<string, Vegobjekttype[]>
                )
            )
                .sort(([a], [b]) => a[0].localeCompare(b[0]))
                .map(([letter, types]) => [letter, [...types].sort((a, b) => a.navn.localeCompare(b.navn))])
                .reduce(
                    (result, [key, types]: [string, Vegobjekttype[]]) => ({ ...result, [key]: types }),
                    {} as Record<string, Vegobjekttype[]>
                ),
        [data.datakatalog.vegobjekttyper, searchText]
    );

    return Object.entries(groupedByFirstLetter)
        .map(([letter, types]) => [letter, types.filter(navnFilter(searchText))])
        .filter(([_, types]) => types.length >= 1)
        .flatMap(([letter, types]: [string, Vegobjekttype[]]) => [
            { title: letter, header: true, disabled: true },
            ...types.map(v => vegobjekttypeToDatakatalogEntry(v, dispatch)),
        ]);
}

function vegobjekttypeToDatakatalogEntry(vegobjekttype: Vegobjekttype, dispatch: Dispatch): DropdownItemData {
    return {
        title: (
            <>
                <span style={{ minWidth: '3rem', display: 'inline-block', marginRight: '1rem' }}>
                    {vegobjekttype.id}
                </span>
                <span>{vegobjekttype.navn}</span>
            </>
        ),
        category: vegobjekttype.navn[0],
        onClick: () => {
            dispatch(vegobjekttypeSelectedInDatakatalog(vegobjekttype));
        },
    };
}

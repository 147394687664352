import { ValgtState } from './ValgtState';
import { MapState } from './MapState';
import { OmraderState } from './OmraderState';
import { VegnettState } from './VegnettState';
import { VegobjekterState } from './VegobjekterState';
import { OverlayState } from './OverlayState';
import { SearchState } from './SearchState';
import { MetaState } from './MetaState';
import { SplashState } from './SplashState';
import { UserState } from './UserState';
import { LoginWindowState } from './LoginWindowState';
import { Config, parseConfig } from '@/bootstrap/config';
import { MeasurementState } from './MeasurementState';
import { PreferencesState } from './PreferencesState';

export class RootState {
    constructor(
        readonly metaState: MetaState = new MetaState(),
        readonly mapState: MapState = new MapState(),
        readonly searchState: SearchState = new SearchState(),
        readonly omraderState: OmraderState = new OmraderState(),
        readonly vegobjekterState: VegobjekterState = new VegobjekterState(),
        readonly vegnettState: VegnettState = new VegnettState(),
        readonly valgtState: ValgtState = new ValgtState(),
        readonly overlayState: OverlayState = new OverlayState(),
        readonly splashState: SplashState = null,
        readonly userState: UserState = new UserState(),
        readonly config: Config = parseConfig({}, {}),
        readonly measurementState: MeasurementState = new MeasurementState(),
        readonly loginWindowState: LoginWindowState = null,
        readonly bootstrapped = false,
        readonly preferences = new PreferencesState(),
        readonly drawingPolygon = false
    ) {}

    override(modifyObject: { [P in keyof RootState]?: RootState[P] }): RootState {
        return Object.assign(Object.create(RootState.prototype), { ...this, ...modifyObject });
    }

    withMeta(metaState: MetaState): RootState {
        return this.override({ metaState });
    }

    withMap(mapState: MapState): RootState {
        return this.override({ mapState });
    }

    withSearch(searchState: SearchState): RootState {
        return this.override({ searchState });
    }

    withOmrader(omraderState: OmraderState): RootState {
        return this.override({ omraderState });
    }

    withVegobjekter(vegobjekterState: VegobjekterState): RootState {
        return this.override({ vegobjekterState });
    }

    withVegnett(vegnettState: VegnettState): RootState {
        return this.override({ vegnettState });
    }

    withValgt(valgtState: ValgtState): RootState {
        return this.override({ valgtState });
    }

    withOverlay(overlayState: OverlayState): RootState {
        return this.override({ overlayState });
    }

    withSplash(splashState: SplashState): RootState {
        return this.override({ splashState });
    }

    withUser(userState: UserState): RootState {
        return this.override({ userState });
    }

    withLogin(loginWindowState: LoginWindowState): RootState {
        return this.override({ loginWindowState });
    }

    withMeasurement(measurementState: MeasurementState): RootState {
        return this.override({ measurementState });
    }

    withConfig(config: Config): RootState {
        return this.override({ config });
    }

    withBootstrapped(bootstrapped: boolean): RootState {
        return this.override({ bootstrapped });
    }

    withPreferences(preferences: PreferencesState): RootState {
        return this.override({ preferences });
    }
    withDrawingPolygon(drawingPolygon: boolean): RootState {
        return this.override({ drawingPolygon });
    }
}

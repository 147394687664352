import { Kontraktsomrade } from '@/domain/omrader';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export function transformKontraktsomrade(data: unknown) {
    if (!data) return null;
    return new Kontraktsomrade(
        data['navn'],
        data['type'],
        data['id'] ? Vegobjekt.dummy(data['vegobjekt'].id, data['vegobjekt'].type) : null
    );
}

export function transformKontraktsomrader(kontraktsomrade: unknown): Kontraktsomrade[] {
    return (kontraktsomrade as []).map(ko => transformKontraktsomrade(ko));
}

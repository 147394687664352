import { assertUnreachable } from '@/utils/Utils';

export type OperatorAsString = '=' | '!=' | '>=' | '<=' | '<' | '>';

export enum OperatorE {
    EQ = 1, // Forcing values to start at 1, some things act strange when given value 0.
    NEQ,
    BEQ,
    LEQ,
    LT,
    GT,
    HAS_VALUE,
    NO_VALUE,
}

export class Operator {
    public static operators(): OperatorE[] {
        return [
            OperatorE.EQ,
            OperatorE.NEQ,
            OperatorE.BEQ,
            OperatorE.LEQ,
            OperatorE.LT,
            OperatorE.GT,
            OperatorE.HAS_VALUE,
            OperatorE.NO_VALUE,
        ];
    }

    public static from(operator: OperatorAsString, verdi: string): OperatorE {
        const isNullValue = verdi === null;
        switch (operator) {
            case '=':
                return isNullValue ? OperatorE.NO_VALUE : OperatorE.EQ;
            case '!=':
                return isNullValue ? OperatorE.HAS_VALUE : OperatorE.NEQ;
            case '>=':
                return OperatorE.BEQ;
            case '<=':
                return OperatorE.LEQ;
            case '<':
                return OperatorE.LT;
            case '>':
                return OperatorE.GT;
            default:
                assertUnreachable(operator);
        }
    }

    public static asString(operator: OperatorE): OperatorAsString {
        switch (operator) {
            case OperatorE.EQ:
            case OperatorE.NO_VALUE:
                return '=';
            case OperatorE.NEQ:
            case OperatorE.HAS_VALUE:
                return '!=';
            case OperatorE.BEQ:
                return '>=';
            case OperatorE.LEQ:
                return '<=';
            case OperatorE.LT:
                return '<';
            case OperatorE.GT:
                return '>';
            default:
                assertUnreachable(operator);
        }
    }

    public static toStringText(operator: OperatorE) {
        switch (operator) {
            case OperatorE.EQ:
                return '=';
            case OperatorE.NO_VALUE:
                return 'har ikke verdi';
            case OperatorE.NEQ:
                return '!=';
            case OperatorE.HAS_VALUE:
                return 'har verdi';
            case OperatorE.BEQ:
                return '>=';
            case OperatorE.LEQ:
                return '<=';
            case OperatorE.LT:
                return '<';
            case OperatorE.GT:
                return '>';
            default:
                assertUnreachable(operator);
        }
    }
}

/* tslint:disable:no-submodule-imports */
import { MapEvent } from 'ol';
import { getBottomLeft, getTopRight } from 'ol/extent';
import { connect } from 'react-redux';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';
import { mapMoved, mapMovedT } from '@/actions/mapActions';
import { debounce } from 'lodash-es';

interface DispatchProps {
    mapMoved: mapMovedT;
}

type Props = DispatchProps;

class MapMovedHandler extends React.Component<Props, {}, typeof MapContext> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    componentDidMount() {
        this.context.map.on('moveend', this.debounce);
    }

    componentWillUnmount() {
        this.context.map.un('moveend', this.debounce);
    }

    render() {
        return null;
    }

    private mapMovedHandler = (event: MapEvent) => {
        const map = event.map;
        const view = map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
        const extent = view.calculateExtent(map.getSize());
        const bottomLeft = getBottomLeft(extent);
        const topRight = getTopRight(extent);
        this.props.mapMoved(
            [Math.round(center[0]), Math.round(center[1])],
            Math.round(zoom),
            new Kartutsnitt(bottomLeft[0], bottomLeft[1], topRight[0], topRight[1])
        );
    };

    private debounce = debounce(this.mapMovedHandler, 500);
}

const mapDispatchToProps = {
    mapMoved,
};

export const MapMoved = connect(null, mapDispatchToProps)(MapMovedHandler);

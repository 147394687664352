import * as React from 'react';
import { connect } from 'react-redux';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import * as selectors from '../../../../selectors/selectors';
import { RootState, UserState, VegobjekttypeState } from '@/state';
import {
    addCategorizationToVegobjekttype,
    addCategorizationToVegobjekttypeT,
    isCategorizing,
    isCategorizingT,
    removeVOTCategory,
    removeVOTCategoryT,
    setVOTCategory,
    setVOTCategoryT,
} from '@/actions/vegobjekterActions';
import { hasAccess } from '@/utils/Utils';
import CategoryInterval from './CategoryInterval';

interface TagProps {
    vegobjekttype: Vegobjekttype;
    vegobjekttypeData: VegobjekttypeState;
}

interface DispatchProps {
    doRemoveCategorization: removeVOTCategoryT;
    doAddCategorization: addCategorizationToVegobjekttypeT;
    doSetCategorizationOnProperty: setVOTCategoryT;
    doSetIsCategorizing: isCategorizingT;
}

interface StateProps {
    isCategorizing: boolean;
    user: UserState;
}

const mapDispatchToProps = {
    doAddCategorization: addCategorizationToVegobjekttype,
    doRemoveCategorization: removeVOTCategory,
    doSetCategorizationOnProperty: setVOTCategory,
    doSetIsCategorizing: isCategorizing,
};

const mapStateToProps = (state: RootState): StateProps => ({
    isCategorizing: selectors.stateIsCategorizing(state),
    user: selectors.stateUser(state),
});

type Props = TagProps & StateProps & DispatchProps;

class CategorizeComponent extends React.Component<Props, {}> {
    readonly state = { open: false };
    private wrapperRef: HTMLSpanElement;
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('click', this.handleClickOutside, { capture: true });
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleClickOutside);
    }

    setWrapperRef(node: HTMLSpanElement) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            event.target.id !== 'categorize_button' &&
            event.target.id !== 'c-category-selector__button'
        ) {
            this.setState({ open: false });
            if (this.props.isCategorizing) {
                this.props.doSetIsCategorizing(false);
            }
        }
    }

    render() {
        const { vegobjekttypeData, vegobjekttype } = this.props;
        const category = vegobjekttypeData.category;
        const { open } = this.state;
        const selectedEgenskap = category ? category : null;
        const buttonText = category && category.egenskapstype ? category.navn : 'Kategoriser';
        return (
            <span ref={this.setWrapperRef}>
                <button
                    value="categorize"
                    id="categorize_button"
                    className="c-categorize__button"
                    onClick={() => {
                        this.setState({ open: !open });
                        this.props.doSetIsCategorizing(!open);
                    }}
                    aria-label={category?.egenskapstype ? `Kategorisert etter ${buttonText}` : buttonText}
                    aria-description={'Trykk for å endre kategorisering'}
                    aria-expanded={open}
                >
                    <i className="o-icon-category c-categorize__icon" />
                    {buttonText}
                </button>
                {open && (
                    <div className="c-category-selector">
                        <label className="c-category-selector__label">Kategoriser etter egenskapstype</label>
                        {selectedEgenskap && selectedEgenskap.erTall() && !selectedEgenskap.erEnum() && (
                            <CategoryInterval
                                interval={vegobjekttypeData.intervals}
                                egenskapstype={selectedEgenskap}
                                absoluteIntervals={vegobjekttypeData.absoluteIntervals}
                                setInterval={(i, abs) => this.setInterval(selectedEgenskap, i, abs)}
                            />
                        )}
                        <ul aria-label="Kategoriserbare egenskaper">
                            <li key={'null'}>
                                <button
                                    className="c-category-selector__button"
                                    title="Ingen kategorisering"
                                    value=" "
                                    onClick={event => {
                                        this.chooseAttributeType(parseInt(event.currentTarget.value, 10));
                                    }}
                                >
                                    Ingen kategorisering
                                </button>
                            </li>
                            {vegobjekttype.egenskapstyper
                                .filter(e => e.erEnum() || e.erTall())
                                .sort(Egenskapstype.comparator)
                                .map(e => (
                                    <li key={e.id}>
                                        <button
                                            disabled={this.isNotAvailable(e.sensitivitet, e.id)}
                                            onClick={event =>
                                                this.chooseAttributeType(
                                                    parseInt(event.currentTarget.value, 10)
                                                )
                                            }
                                            className={`c-category-selector__button${
                                                this.isNotAvailable(e.sensitivitet, e.id) ? ' disabled' : ''
                                            }`}
                                            value={e.id}
                                            id={'category_selector_button_' + e.id}
                                        >
                                            {`${e.navn}${
                                                this.isNotAvailable(e.sensitivitet, e.id)
                                                    ? ' (Ikke tilgjengelig)'
                                                    : ''
                                            }`}
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </span>
        );
    }
    private chooseAttributeType(value: number) {
        const {
            vegobjekttype,
            vegobjekttypeData,
            doSetCategorizationOnProperty,
            doSetIsCategorizing,
            doRemoveCategorization,
        } = this.props;
        const egenskapstype = vegobjekttype.egenskapstyper.find(e => e.id === value) || null;
        if (
            egenskapstype === null ||
            !egenskapstype.erTall() ||
            (egenskapstype.erTall() && egenskapstype.erEnum())
        ) {
            this.setState({ open: false });
        }
        if (egenskapstype === null) {
            doRemoveCategorization(vegobjekttypeData);
        } else {
            doSetCategorizationOnProperty(vegobjekttypeData, egenskapstype);
        }
        doSetIsCategorizing(true);
    }

    private setInterval(egenskapstype: Egenskapstype, interval: number[], absoluteIntervals: boolean) {
        const { vegobjekttypeData, doSetCategorizationOnProperty } = this.props;
        this.setState({ open: false });
        doSetCategorizationOnProperty(vegobjekttypeData, egenskapstype, interval, absoluteIntervals);
    }

    private isNotAvailable(sensitivity: number, id: number) {
        return (
            !hasAccess(sensitivity, this.props.user.roles) ||
            [521, 524, 525, 526, 562, 641, 644, 645, 793].includes(id)
        );
    }
}

export const Categorize = connect(mapStateToProps, mapDispatchToProps)(CategorizeComponent);

import * as idb from 'idb-keyval';
import { ReactNode } from 'react';
import * as React from 'react';
import parseErrorMessage from './ErrorParser';
import { connect } from 'react-redux';
import { PREFERENCES_STORAGE_KEY, RootState } from '@/state';
import { stateConfig } from '@/selectors/selectors';
import { Config } from '@/bootstrap/config';

interface Props {
    children: ReactNode;
    statusConfig: Config['status'];
}
type Feilmelding = {
    id: string;
    innhold: string;
    tittel: string;
    pavirkedeSystemer: { systemId: string; navn: string }[];
};
type StatusCheck = {
    healthCondition: string;
};
enum HealthCheckStatus {
    LOADING,
    HEALTHY,
    UNHEALTHY,
    UNKNOWN,
}

interface ErrorPageProps {
    sign: 'maintenance' | 'crash';
    header: string;
    children: ReactNode;
}
function getSignClass(sign: ErrorPageProps['sign']): string {
    switch (sign) {
        case 'crash':
            return 'c-error-boundary__image';
        case 'maintenance':
            return 'c-error-boundary__maintenance_sign';
        default:
            return 'c-error-boundary__maintenance_sign';
    }
}
const ErrorPage = ({ sign, header, children }: ErrorPageProps) => {
    const signClass = getSignClass(sign);
    return (
        <div className="c-error-boundary">
            <div className={signClass} />
            <div className="c-error-boundary__text-box" style={{ maxWidth: 'unset' }}>
                <h1 className="c-error-boundary__heading" tabIndex={1} aria-label="Det har skjedd en ulykke">
                    {header}
                </h1>
            </div>
            <br />
            <div className="c-error-boundary__text-box" style={{ maxWidth: 'unset' }}>
                {children}
            </div>
        </div>
    );
};
class ErrorBoundary extends React.Component<Props> {
    readonly state = {
        nvdbStatusIssue: HealthCheckStatus.LOADING,
        hasError: false,
        eventId: null,
        errorStackTrace: null,
        bootstrapError: 'bootstrap' in window && window['bootstrap'] === false,
    };

    async getUnresolvedIssue(): Promise<Feilmelding> {
        const { api, les, vegkart } = this.props.statusConfig;
        const unresolvedIssues: Feilmelding[] = await fetch(`${api}/api/v1/feilmeldinger/ulost`)
            .then(r => r.json())
            .then(r => r['feilmeldinger']);
        return unresolvedIssues.find(issue =>
            issue.pavirkedeSystemer.some(system => [les, vegkart].includes(system.systemId))
        );
    }
    async getHealthySystem(systemId: string): Promise<boolean> {
        const { api } = this.props.statusConfig;
        const status: StatusCheck = await fetch(`${api}/api/v1/systemer/${systemId}`).then(r => r.json());
        return status.healthCondition === 'HEALTHY';
    }

    async ongoingIssues(): Promise<HealthCheckStatus> {
        const { les, vegkart } = this.props.statusConfig;
        try {
            const [issue, lesHealthy, vegkartHealthy] = await Promise.all([
                this.getUnresolvedIssue(),
                this.getHealthySystem(les),
                this.getHealthySystem(vegkart),
            ]);

            const isHealthy = lesHealthy && vegkartHealthy && issue === undefined;
            return isHealthy ? HealthCheckStatus.HEALTHY : HealthCheckStatus.UNHEALTHY;
        } catch (e) {
            console.warn('Failed to retrieve status check from nvdb-status', e);
            return HealthCheckStatus.UNKNOWN;
        }
    }

    componentDidCatch(error) {
        this.setState({ nvdbStatusCheck: HealthCheckStatus.LOADING });
        this.ongoingIssues()
            .then(nvdbStatusIssue => this.setState({ nvdbStatusIssue }))
            .catch(() => {
                if (this.state.nvdbStatusIssue === HealthCheckStatus.LOADING) {
                    this.setState({ nvdbStatusIssue: HealthCheckStatus.UNKNOWN });
                }
            });
        if ('bootstrap' in window && window['bootstrap'] === false) {
            console.warn('Bootstrapping crashed. Terribly.');
        }
        this.setState({
            hasError: true,
            errorStackTrace: error,
        });

        const preferencesState = localStorage.getItem(PREFERENCES_STORAGE_KEY);
        const version = localStorage.getItem('version');

        localStorage.clear();
        localStorage.setItem(PREFERENCES_STORAGE_KEY, preferencesState);
        localStorage.setItem('version', version);

        sessionStorage.clear();
        idb.clear().then(() => console.log('Cleared Indexed DB to prevent preserving corrupt data.'));
    }

    renderBootstrapError() {
        return (
            <ErrorPage sign={'maintenance'} header={'NVDB er ikke tilgjengelig'}>
                <p className="c-error-boundary__paragraph" tabIndex={3}>
                    Vegkart kan ikke vise vegdata for øyeblikket. Data kan være tilgjengelig i{' '}
                    <a href={'https://vegkart-2019.atlas.vegvesen.no/#kartlag:geodata/@600000,7225000,3'}>
                        Vegkart 2019
                    </a>
                    , men med gamle vegreferanser, fylker og kommuner.
                </p>
                Mer informasjon på <a href={'https://www.vegdata.no/'}> Vegdata.no</a>
            </ErrorPage>
        );
    }
    renderGenericError() {
        return (
            <div className="c-error-boundary">
                <div className="c-error-boundary__image" />
                <div
                    className="c-error-boundary__text-box"
                    aria-label="Felt for informasjon om sideoppdatering"
                >
                    <h1
                        className="c-error-boundary__heading"
                        tabIndex={1}
                        aria-label="Det har skjedd en ulykke"
                    >
                        Noe har gått galt!
                        <br />
                        Prøv å oppdatere siden:
                    </h1>
                    <h2
                        className="c-error-boundary__tip"
                        tabIndex={1}
                        aria-label="CTRL + F5 for å oppdatere siden"
                    >
                        CTRL + F5
                    </h2>
                </div>
                <br />
                <div className="c-error-boundary__text-box" aria-label="Felt for feilmelding">
                    <p className="c-error-boundary__paragraph" tabIndex={3}>{`${parseErrorMessage(
                        this.state.errorStackTrace
                    )}`}</p>
                    <h2>
                        Sjekk status her:{' '}
                        <a href={this.props.statusConfig.url} aria-label="Knapp for NVDB Status">
                            NVDB Status | Statens vegvesen
                        </a>
                    </h2>
                </div>
            </div>
        );
    }

    renderHealthCheckFailed() {
        return (
            <ErrorPage sign={'maintenance'} header={'Vegkart er utilgjengelig'}>
                <p className="c-error-boundary__paragraph" tabIndex={3}>
                    Vegkart kan ikke vise vegdata for øyeblikket.
                </p>
                Mer informasjon på <a href={this.props.statusConfig.url}> NVDB Status</a>
            </ErrorPage>
        );
    }
    render() {
        const { bootstrapError, hasError, nvdbStatusIssue } = this.state;
        if (nvdbStatusIssue == HealthCheckStatus.LOADING && (hasError || bootstrapError)) return null;
        else if (nvdbStatusIssue === HealthCheckStatus.UNHEALTHY) return this.renderHealthCheckFailed();
        else if (bootstrapError) return this.renderBootstrapError();
        else if (hasError) return this.renderGenericError();
        else return this.props.children;
    }
}
export default connect((state: RootState) => ({
    statusConfig: stateConfig(state)?.status,
}))(ErrorBoundary);

import React, { useEffect, useRef } from 'react';

interface ToggleProps {
    className?: string;
    checked: boolean;
    ariaLabel?: string;
    label: string;
    id?: string;
    onChange: () => void;
}

const SimpleToggle: React.FC<ToggleProps> = ({ className, checked, ariaLabel, label, id, onChange }) => {
    const labelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        const labelElement = labelRef.current;
        if (labelElement) {
            labelElement.addEventListener('keydown', listener);
        }

        return () => {
            if (labelElement) {
                labelElement.removeEventListener('keydown', listener);
            }
        };
    }, []);

    const listener = (e: { key: string }) => {
        if (e.key === 'Enter' || e.key === ' ') {
            onChange();
        }
    };

    return (
        <label
            className={`o-toggle-switch ${className || ''}`}
            aria-label={ariaLabel ?? label}
            tabIndex={0}
            id={id}
            ref={labelRef}
        >
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="o-toggle-switch__slider round" />
            <p className="c-road-net__toggle-text">{label}</p>
        </label>
    );
};

export default SimpleToggle;

import { Vegsystemreferanse } from '@/domain/omrader';
import React from 'react';

interface LenkeretningProps {
    vegsystemreferanse: Vegsystemreferanse;
}

export default function Lenkeretning({ vegsystemreferanse }: LenkeretningProps): JSX.Element | null {
    const retning =
        vegsystemreferanse.sideanlegg?.retning ??
        vegsystemreferanse.kryssystem?.retning ??
        vegsystemreferanse.strekning?.retning;

    if (retning) {
        return (
            <React.Fragment key="rn-direction">
                <dt className="c-valgt-features__dt">Lenkeretning:</dt>
                <dd className="c-valgt-features__dd">{retning}</dd>
            </React.Fragment>
        );
    }

    return null;
}

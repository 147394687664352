import * as uuidgen from 'uuid';

export class StedsnavnResponse {
    constructor(readonly hits: number, readonly placeNames: Stedsnavn[]) {}
}

export class Stedsnavn {
    constructor(
        readonly ssrID: number,
        readonly placeNameType: string,
        readonly category: string,
        readonly name: string,
        readonly kommune: string,
        readonly fylke: string,
        readonly xCoord: string,
        readonly yCoord: string,
        readonly uuid: string = uuidgen.v4()
    ) {}
}

import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { VegnettQueries } from '@/middleware/queries';
import { Omrade } from '@/domain/omrader';

export enum VegnettRetning {
    GEOMETRI = 'geometri',
    METRERING = 'metrering',
}

export class VegnettState {
    constructor(
        readonly vegnettlenker: Vegnettlenke[] = [],
        readonly statistics: VegnettStatistics[] = [],
        readonly visible: boolean = false,
        readonly filter: VegnettQueries = new VegnettQueries(),
        readonly loading: boolean = false,
        readonly direction: VegnettRetning = VegnettRetning.METRERING
    ) {}

    override(modifyObject: { [P in keyof VegnettState]?: VegnettState[P] }): VegnettState {
        return Object.assign(Object.create(VegnettState.prototype), { ...this, ...modifyObject });
    }

    withRoadNetLinks(vegnettlenker: Vegnettlenke[]) {
        return this.override({ vegnettlenker });
    }

    withRoadNetStatistics(statistics: VegnettStatistics[]) {
        return this.override({ statistics });
    }

    withVisible(visible: boolean) {
        return this.override({ visible });
    }

    withFilter(filter: VegnettQueries) {
        return this.override({ filter });
    }

    withLoading(loading: boolean) {
        return this.override({ loading });
    }

    withDirection(direction: VegnettRetning) {
        return this.override({ direction });
    }

    toQueries() {
        return this.filter;
    }
}

export class VegnettStatistics {
    constructor(readonly omrade: Omrade, readonly antall: number) {}
}

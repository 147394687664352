import { VegsystemreferanseLookup } from '@/state';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { Config } from '@/bootstrap/config';

export function getRoadReferenceFiksURL(reference: VegsystemreferanseLookup, config: Config): void {
    window.open(
        `${config.fiksvegdata_url}?vegsystemreferanse=${reference.vegsystemreferanse.kortform}`,
        '_blank'
    );
}

export function getVegObjektFiksURL(vegobjekt: Vegobjekt, config: Config): string {
    return `${config.fiksvegdata_url}?vegobjekt=${vegobjekt?.id}&vegsystemreferanse=${vegobjekt?.lokasjon?.vegsystemreferanser}`;
}

import { linear } from 'ol/easing';
import * as React from 'react';
import { forwardRef, useCallback } from 'react';
import { useMap } from '@/state/context/MapContext';

interface Props {
    zoomType: 'in' | 'out';
}
const ZoomControls = forwardRef<HTMLButtonElement, Props>(({ zoomType }, ref) => {
    const { map } = useMap();
    const onClick = useCallback(() => {
        map.getView().animate({
            easing: linear,
            duration: 100,
            zoom: map.getView().getZoom() + (zoomType === 'in' ? 1 : -1),
        });
    }, [map, zoomType]);
    return (
        <button
            ref={ref}
            className={'o-btn-square c-zoom-controls__buttons c-map-controls__button'}
            title={`Zoom ${zoomType === 'in' ? 'inn' : 'ut'}`}
            type="button"
            onClick={onClick}
        >
            <i className={`c-zoom-controls__zoom-icon o-icon-zoom-${zoomType}`} />
        </button>
    );
});
ZoomControls.displayName = 'ZoomControl';
export { ZoomControls };

import { ActionType, LoginWindowAction } from '@/actions/actiontypes';
import { LoginWindowState } from '@/state';

const initialState: LoginWindowState = null;

export const loginWindowReducer = (
    loginWindowState: LoginWindowState = initialState,
    action: LoginWindowAction
) => {
    switch (action.type) {
        case ActionType.SHOW_LOGIN:
            return action.state;
        default:
            return loginWindowState;
    }
};

import { Feature } from 'ol';
import { add, Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Layer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import * as React from 'react';
import { Omrade } from '@/domain/omrader';
import { wktObjectToFeature } from '@/utils/SpatialHelper';
import { featureStyle } from '../style/Style';
import { FeatureLayerType } from './FeatureLayerType';
import { MapContext } from '@/state/context/MapContext';

interface OmradeantallProps {
    antall: number;
    omrade: Omrade;
    colorString: string;
    displacement: number;
    vegobjektTypeId: number;
    visible: boolean;
}

export class Omradeantall extends React.Component<OmradeantallProps, null> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    public static BOUNDS_KEY = 'areaBoundsWKT';

    private layer: Layer;
    private areaFeature: Feature;

    componentDidMount() {
        const omrade = this.props.omrade;

        const featureId = `${this.props.vegobjektTypeId}-${this.props.omrade.nummer}`;
        this.areaFeature = wktObjectToFeature(
            omrade.senterpunkt.wkt,
            featureId,
            omrade.vegobjekt.metadata.type.id,
            FeatureLayerType.FEATURE_COUNT,
            this.props.colorString
        );

        // Unclutter features by moving features along radials around correct position
        this.moveFeature(this.areaFeature, this.props.displacement);

        this.areaFeature.set('antall', this.props.antall);
        this.areaFeature.set(Omradeantall.BOUNDS_KEY, omrade.kartutsnitt.wkt);
        const features = [this.areaFeature];
        this.layer = new VectorLayer({
            source: new VectorSource({
                features,
            }),
            style: featureStyle,
            visible: this.props.visible,
        });

        this.context.map.addLayer(this.layer);
    }

    componentDidUpdate() {
        this.layer.setVisible(this.props.visible);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.layer);
    }

    render() {
        return null;
    }

    private moveFeature(feature: Feature, displacement: number): void {
        // Skip center position - displace even the first feature
        displacement++;

        if (displacement < 1) {
            return; // Displacement of 0 and less should not be moved
        }

        const resolution = this.context.map.getView().getResolution();
        const translation = Omradeantall.getTranslationFactors(displacement).map(factor => {
            return resolution * factor;
        });

        const coord = (feature.getGeometry() as Point).getCoordinates();

        feature.setGeometry(new Point(add(coord, translation as Coordinate)));
        feature.set('isMoved', true);
    }

    private static getTranslationFactors(displacement: number): [number, number] {
        const level = Math.ceil(displacement / 4);
        const sector = Math.max((displacement + 1) % 4, 0);

        if (level === 0) {
            return [0, 0]; // No translation for level 0
        }

        const factor = level * 40; // Magic number is displacement factor for markers.

        // TODO: Implement some better algo to spread markers more evenly
        // odd and even level should be 45˚ offset or the displacement could follow a spiral?
        switch (sector) {
            case 0:
                return [0, factor];
            case 1:
                return [factor, 0];
            case 2:
                return [0, -factor];
            case 3:
                return [-factor, 0];
        }

        return [0, 0]; // Should never happen
    }
}

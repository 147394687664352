import { ActionType, OmraderAction, ReceivedOmradeGeometryAction } from '@/actions/actiontypes';
import {
    Fylke,
    Kommune,
    Kontraktsomrade,
    Land,
    Omrade,
    Riksvegrute,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { OmraderState } from '@/state';
import { Polygon } from '@/domain/omrader/Polygon';

const initialState = new OmraderState();

export const omraderReducer = (omraderState: OmraderState = initialState, action: OmraderAction) => {
    switch (action.type) {
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.omraderState;
        case ActionType.HASH_CHANGED:
            return action.state.omraderState;
        // Omrader
        case ActionType.OMRADE_LAND_ADDED:
            return omraderState.withLand(omraderState.land.concat(action.land));
        case ActionType.OMRADE_FYLKE_ADDED:
            return omraderState.withLoading(true).withFylker(omraderState.fylke.concat(action.fylke));
        case ActionType.OMRADE_KOMMUNE_ADDED:
            return omraderState.withLoading(true).withKommuner(omraderState.kommune.concat(action.kommune));
        case ActionType.OMRADE_KONTRAKTSOMRADE_ADDED:
            return omraderState
                .withLoading(true)
                .withKontraktsomrader(omraderState.kontraktsomrade.concat(action.kontraktsomrade));
        case ActionType.OMRADE_RIKSVEGRUTE_ADDED:
            return omraderState
                .withLoading(true)
                .withRiksvegruter(omraderState.riksvegrute.concat(action.riksvegrute));
        case ActionType.OMRADE_VEGSYSTEMREFERANSE_ADDED:
            return omraderState.withVegsystemreferanser(
                omraderState.vegsystemreferanse.concat(action.vegsystemreferanse)
            );
        case ActionType.OMRADE_POLYGON_ADDED:
            return omraderState.withPolygon(action.polygon);
        case ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED:
            return omraderState.withVegsystemreferanser(
                omraderState.vegsystemreferanse.map(currentVsr =>
                    currentVsr.id === action.previousVsr.id ? action.newVsr : currentVsr
                )
            );
        case ActionType.OMRADE_GEOMETRI_RECEIVED:
            const previousOmrade = action.omrade;
            const filterFunc = (inSearch: Omrade) =>
                inSearch.nummer && action.omrade.nummer
                    ? inSearch.nummer !== action.omrade.nummer
                    : inSearch.navn !== action.omrade.navn;

            if (previousOmrade instanceof Fylke) {
                return omraderState
                    .withLoading(false)
                    .withFylker(
                        omraderState.fylke
                            .filter(filterFunc)
                            .concat((previousOmrade as Fylke).withVegobjekt(action.vegobjekt))
                    );
            } else if (previousOmrade instanceof Kommune) {
                return omraderState.withLoading(false).withKommuner(
                    omraderState.kommune
                        .filter(filterFunc)
                        .filter(f => f.nummer !== previousOmrade.nummer)
                        .concat((previousOmrade as Kommune).withVegobjekt(action.vegobjekt))
                );
            } else if (previousOmrade instanceof Kontraktsomrade) {
                return omraderState
                    .withLoading(false)
                    .withKontraktsomrader(
                        omraderState.kontraktsomrade
                            .filter(filterFunc)
                            .concat((previousOmrade as Kontraktsomrade).withVegobjekt(action.vegobjekt))
                    );
            } else if (previousOmrade instanceof Riksvegrute) {
                return omraderState
                    .withLoading(false)
                    .withRiksvegruter(
                        omraderState.riksvegrute
                            .filter(filterFunc)
                            .concat((previousOmrade as Riksvegrute).withVegobjekt(action.vegobjekt))
                    );
            } else {
                return omraderState;
            }
        case ActionType.OMRADE_TOGGLED: {
            const omrade: Omrade = action.omrade;
            function toggleVisible<T extends Omrade>(o: T): T {
                return o === omrade ? (o.withVisible(!o.visible) as T) : (o as T);
            }
            if (omrade instanceof Land) {
                return omraderState.withLand(omraderState.land.map(l => toggleVisible<Land>(l)));
            } else if (omrade instanceof Fylke) {
                return omraderState.withFylker(omraderState.fylke.map(f => toggleVisible<Fylke>(f)));
            } else if (omrade instanceof Kommune) {
                return omraderState.withKommuner(omraderState.kommune.map(k => toggleVisible<Kommune>(k)));
            } else if (omrade instanceof Kontraktsomrade) {
                return omraderState.withKontraktsomrader(
                    omraderState.kontraktsomrade.map(ko => toggleVisible<Kontraktsomrade>(ko))
                );
            } else if (omrade instanceof Riksvegrute) {
                return omraderState.withRiksvegruter(
                    omraderState.riksvegrute.map(r => toggleVisible<Riksvegrute>(r))
                );
            } else if (omrade instanceof Vegsystemreferanse) {
                return omraderState.withVegsystemreferanser(
                    omraderState.vegsystemreferanse.map(v => toggleVisible<Vegsystemreferanse>(v))
                );
            } /*else if (omrade instanceof Polygon) {
                omraderState.polygon.withVisible(toggleVisible)
            }*/
        }
        case ActionType.OMRADE_REMOVED:
            const omrade: Omrade = action.omrade;
            const f = (inSearch: Omrade) => !inSearch.equals(omrade);
            if (omrade instanceof Land) {
                return omraderState.withLand(omraderState.land.filter(f));
            } else if (omrade instanceof Fylke) {
                return omraderState.withFylker(omraderState.fylke.filter(f));
            } else if (omrade instanceof Kommune) {
                return omraderState.withKommuner(omraderState.kommune.filter(f));
            } else if (omrade instanceof Kontraktsomrade) {
                return omraderState.withKontraktsomrader(omraderState.kontraktsomrade.filter(f));
            } else if (omrade instanceof Riksvegrute) {
                return omraderState.withRiksvegruter(omraderState.riksvegrute.filter(f));
            } else if (omrade instanceof Vegsystemreferanse) {
                return omraderState.withVegsystemreferanser(omraderState.vegsystemreferanse.filter(f));
            } else if (omrade instanceof Polygon) {
                return omraderState.withPolygon(null);
            } else {
                return omraderState;
            }
        default:
            return omraderState;
    }
};

import { WKTObject } from '../WKTObject';
import { Omrade } from './Omrade';
import { Vegobjekt } from '../vegobjekter/Vegobjekt';

export class Fylke extends Omrade {
    constructor(
        navn: string,
        nummer: number,
        kartutsnitt: WKTObject,
        senterpunkt: WKTObject,
        vegobjekt: Vegobjekt
    ) {
        super(navn, nummer, kartutsnitt, senterpunkt, vegobjekt);
    }

    override(modifyObject: { [P in keyof Fylke]?: Fylke[P] }): Fylke {
        return Object.assign(Object.create(Fylke.prototype), { ...this, ...modifyObject });
    }

    withVegobjekt(vegobjekt: Vegobjekt): Fylke {
        return this.override({ vegobjekt });
    }

    withVisible(visible: boolean): Fylke {
        return this.override({ visible });
    }

    toString(): string {
        return `${this.navn} ${this.nummer}`;
    }

    getAreaType(): string {
        return 'Fylke';
    }

    toParams(): string {
        return this.nummer.toString();
    }
}

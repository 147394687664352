import { Fylke, Kommune, Kontraktsomrade, Land, Riksvegrute } from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { WKTObject } from '@/domain/WKTObject';

export class Data {
    constructor(
        readonly datakatalog: Datakatalog,
        readonly fylker: Fylke[],
        readonly kommuner: Kommune[],
        readonly kontraktsomrader: Kontraktsomrade[],
        readonly riksvegruter: Riksvegrute[],
        readonly land: Land[] = norge(),
        readonly vegkategorier: Vegkategori[] = alleVegkategorier()
    ) {}
}

export class Datakatalog {
    readonly byId: { [index: number]: Vegobjekttype };

    constructor(readonly vegobjekttyper: Vegobjekttype[]) {
        this.byId = vegobjekttyper.reduce((acc, val: Vegobjekttype) => {
            acc[val.id] = val;
            return acc;
        }, {});
    }

    public getTypeById(typeId: number): Vegobjekttype {
        return this.byId[typeId];
    }
}

export function norge(): Land[] {
    const wkt = new WKTObject(
        32633,
        'POLYGON ((-130186 6436712, 1275961 6320210, 1368078 8013547, -254815 8005419, -130186 6436712))'
    );
    return [new Land('Norge', wkt), new Land('Noreg', wkt)];
}

export function alleVegkategorier(): Vegkategori[] {
    return [
        new Vegkategori('Europaveg', 'E'),
        new Vegkategori('Riksveg', 'R'),
        new Vegkategori('Fylkesveg', 'F'),
        new Vegkategori('Kommunalveg', 'K'),
        new Vegkategori('Privatveg', 'P'),
        new Vegkategori('Skogsveg', 'S'),
    ];
}

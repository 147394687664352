import { WKTObject } from '../WKTObject';
import { Omrade } from './Omrade';

export class Land extends Omrade {
    constructor(readonly navn: string, readonly kartutsnitt: WKTObject) {
        super(navn, null, kartutsnitt, null, null);
    }

    override(modifyObject: { [P in keyof Land]?: Land[P] }): Land {
        return Object.assign(Object.create(Land.prototype), { ...this, ...modifyObject });
    }

    toString(): string {
        return this.navn;
    }

    getAreaType(): string {
        return 'Land';
    }

    toParams(): string {
        return undefined;
    }

    withVisible(visible: boolean): Land {
        return this.override({ visible });
    }
}

export class SuperStedfesting {
    constructor(
        readonly veglenkesekvensid: number,
        readonly startposisjon: number,
        readonly sluttposisjon: number,
        readonly relativPosisjon: number,
        readonly retning: 'MED' | 'MOT',
        readonly sideposisjon: number,
        readonly kjørefelt: string[],
        readonly kortform: string
    ) {}
}

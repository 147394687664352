import { useEffect, useState } from 'react';

export const useDebounceAndCleanValue = (value: string, delay: number, skipUpdate = false) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        if (skipUpdate) return () => undefined;
        // Update value
        const timer = setTimeout(() => {
            const cleanInput = value.replace(/[^a-zA-ZæøåÆØÅ0-9 ]/g, '');
            setDebouncedValue(cleanInput);
        }, delay);

        // Cancel timeout if value or delay changes, or on unmount
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay, skipUpdate]);
    return debouncedValue;
};

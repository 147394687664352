import { Vegobjekt } from './Vegobjekt';
import { Omrade } from '../omrader';

export class VegobjekterResult {
    constructor(
        readonly vegobjekter: Vegobjekt[] = [],
        readonly totalStatistics: VegobjekterStatistic = null,
        readonly extentStatistics: VegobjekterStatistic = null,
        readonly areaStatistics: VegobjekterStatistic[] = []
    ) {}

    areaStatisticsAntall(): number {
        return this.areaStatistics
            .map(statistic => statistic.antall)
            .reduce((total: number, current: number) => total + current);
    }

    areaStatisticsLengde(): number {
        return this.areaStatistics
            .map(statistic => statistic.lengde)
            .reduce((total: number, current: number) => total + current);
    }
}

export class VegobjekterStatistic {
    constructor(readonly antall: number, readonly lengde: number, readonly omrade?: Omrade) {}
}

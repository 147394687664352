import * as React from 'react';
import { connect } from 'react-redux';
import { Datakatalog } from '@/bootstrap/data';
import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';
import { Relasjon, Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import * as selectors from '../../../selectors/selectors';
import { RootState, VegobjekttypeState } from '@/state';
import Accordion from '../../objects/Accordion';
import Spinner from '../../objects/Spinner';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { addVegobjekttypeToSearch, addVegobjekttypeToSearchT } from '@/actions/vegobjekterActions';
import {
    backtrackVegobjekttypeSelectedInDatakatalog,
    backtrackVegobjekttypeSelectedInDatakatalogT,
    setSearchPanelOpen,
    setSearchPanelOpenT,
    vegobjekttypeSelectedInDatakatalog,
    vegobjekttypeSelectedInDatakatalogT,
} from '@/actions/searchActions';

interface StateProps {
    valgtVegobjekttype: Vegobjekttype[];
    vegobjekttyperInSearch: VegobjekttypeState[];
}

interface DispatchProps {
    doSelectVegobjekttypeInDatakatalog: vegobjekttypeSelectedInDatakatalogT;
    doBacktrackSelectedVegobjekttypeInDatakatalog: backtrackVegobjekttypeSelectedInDatakatalogT;
    doAddVegobjekttypeToSearch: addVegobjekttypeToSearchT;
    doSetSearchPanelOpen: setSearchPanelOpenT;
}

type Props = StateProps & DispatchProps;

class VegobjekttypeContainer extends React.Component<Props, {}> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;

    render() {
        return (
            this.props.valgtVegobjekttype.length > 0 && (
                <ShowVegobjekttype
                    valgtVegobjekttype={this.props.valgtVegobjekttype}
                    vegobjekttyperInSearch={this.props.vegobjekttyperInSearch}
                    datakatalog={this.context.data.datakatalog}
                    doSelectVegobjekttypeInDatakatalog={this.props.doSelectVegobjekttypeInDatakatalog}
                    doBacktrackSelectedVegobjekttypeInDatakatalog={
                        this.props.doBacktrackSelectedVegobjekttypeInDatakatalog
                    }
                    doAddVegobjekttypeToSearch={this.props.doAddVegobjekttypeToSearch}
                    doSetSearchPanelOpen={this.props.doSetSearchPanelOpen}
                />
            )
        );
    }
}

function getTitle(
    vegobjekttypeIsInSearch: boolean,
    vegobjekttypeIsSensitiv: boolean,
    vegobjekttypeIsNotIndexed: boolean
): string {
    if (vegobjekttypeIsSensitiv || vegobjekttypeIsNotIndexed) {
        return 'Vegobjekttype er ikke tilgjengelig i Vegkart';
    } else if (vegobjekttypeIsInSearch) {
        return 'Vegobjekttype er allerede i søk';
    } else {
        return 'Legg vegobjekttype til i søk';
    }
}

interface ContextProps {
    datakatalog: Datakatalog;
}

type ShowVegobjekttypeProps = Props & ContextProps;

class ShowVegobjekttype extends React.Component<ShowVegobjekttypeProps, {}> {
    render() {
        const type: Vegobjekttype = this.props.valgtVegobjekttype[this.props.valgtVegobjekttype.length - 1];
        const vegobjekttypeIsInSearch = this.props.vegobjekttyperInSearch.some(
            vegobjekttype => vegobjekttype.typeId === type.id
        );
        const isExludedFromIndexing = [521, 524, 525, 526, 562, 641, 644, 645, 793].includes(type.id);
        return (
            <div className="c-datakatalog-object">
                <article>
                    <header className="c-datakatalog-object__header c-datakatalog-object-header">
                        <p className="c-datakatalog-object-header__type-id">{type.id}</p>
                        <h1 className="c-datakatalog-object-header__type-name">{type.navn}</h1>
                        <p className="c-datakatalog-object-header__type-description">{type.beskrivelse}</p>
                        {type.sensitiv ||
                            (isExludedFromIndexing && <p>Vegobjekttype er ikke tilgjengelig i Vegkart</p>)}
                    </header>
                    {type.isMinimal() ? (
                        <Spinner className="" />
                    ) : (
                        <VegobjekttypeDetaljer
                            valgtVegobjekttype={type}
                            datakatalog={this.props.datakatalog}
                            onVegobjektTypeClick={this.props.doSelectVegobjekttypeInDatakatalog}
                        />
                    )}
                </article>
                <div className="c-show-vegobjekttype__buttons c-datakatalog-object-buttons">
                    <button
                        type="button"
                        className="c-datakatalog-object-buttons__back c-datakatalog-object-buttons-back o-btn o-btn--orange-bg o-btn--color-black"
                        title="Tilbake til datakataloglisten"
                        onClick={() => this.props.doBacktrackSelectedVegobjekttypeInDatakatalog()}
                    >
                        <i
                            role={'img'}
                            className={`icon-navigate-left dropdown-icon`}
                            aria-label="dropdown icon"
                        />
                        Tilbake
                    </button>
                    <button
                        disabled={vegobjekttypeIsInSearch || type.sensitiv || isExludedFromIndexing}
                        type="button"
                        className="c-datakatalog-object-buttons__add-to-search c-datakatalog-object-buttons-add-to-search o-btn o-btn--orange-bg o-btn--color-black"
                        title={getTitle(vegobjekttypeIsInSearch, type.sensitiv, isExludedFromIndexing)}
                        onClick={() => this.addVegobjekttypeToSearch()}
                    >
                        Legg i søk
                        <i role={'img'} className={`icon-plus dropdown-icon`} aria-label="dropdown icon" />
                    </button>
                </div>
            </div>
        );
    }

    addVegobjekttypeToSearch() {
        this.props.doAddVegobjekttypeToSearch(
            this.props.valgtVegobjekttype[this.props.valgtVegobjekttype.length - 1]
        );
        this.props.doSetSearchPanelOpen(false);
    }
}

interface VegobjekttypeDetaljerProps {
    valgtVegobjekttype: Vegobjekttype;
    datakatalog: Datakatalog;
    onVegobjektTypeClick: (vegobjekttype: Vegobjekttype) => void;
}

interface VegobjekttypeDetaljerStates {
    propertyTypesOpen: boolean;
    relationsOpen: boolean;
    controlParameters: boolean;
}

class VegobjekttypeDetaljer extends React.Component<VegobjekttypeDetaljerProps, VegobjekttypeDetaljerStates> {
    private static egenskapstyper(egenskapstyper: Egenskapstype[]) {
        return [...egenskapstyper].sort(Egenskapstype.comparator).map(e => {
            const definition = [
                <dt
                    key={`dt${e.id}`}
                    className="o-dl-list__dt"
                    title={'id: ' + e.id + (e.beskrivelse ? ' ' + e.beskrivelse : '')}
                >
                    {e.navn}
                </dt>,
            ];
            if (VegobjekttypeDetaljer.ifNotEnumAndHasValue(e)) {
                definition.push(
                    <dd key={`dd${e.id}`} className="o-dl-list__dd">
                        &lt;{e.datatype}&gt; {!e.enhet ? '' : '[' + e.enhet.kortnavn + ']'}
                    </dd>
                );
            }

            const verdier = [...e.tillatte_verdier].sort(EgenskapTillattVerdi.comparator).map(tv => (
                <dd
                    key={`dd${tv.id}`}
                    className="o-dl-list__dd"
                    title={tv.id + (!tv.verdi ? '' : ' Beskrivelse: ' + tv.verdi)}
                >
                    {tv.verdi}
                </dd>
            ));

            return definition.concat(verdier);
        });
    }

    private static ifNotEnumAndHasValue(egenskapstype: Egenskapstype) {
        return !egenskapstype.tillatte_verdier || egenskapstype.tillatte_verdier.length === 0;
    }

    constructor(props: VegobjekttypeDetaljerProps) {
        super(props);
        this.state = {
            controlParameters: false,
            propertyTypesOpen: false,
            relationsOpen: false,
        };
    }

    render() {
        const type: Vegobjekttype = this.props.valgtVegobjekttype;
        const { abstrakt_type, avledet, er_dataserie, konnekteringslenke_ok, må_ha_mor, tidsrom_relevant } =
            type;
        const styringsparametere = {
            abstrakt_type,
            avledet,
            er_dataserie,
            konnekteringslenke_ok,
            må_ha_mor,
            tidsrom_relevant,
        };
        return (
            <div className="c-vegobjekttype-detaljer">
                <Accordion header="Egenskapstyper">
                    <dl className="o-dl-list c-vegobjekttype-liste-egenskaper">
                        {VegobjekttypeDetaljer.egenskapstyper(type.egenskapstyper)}
                    </dl>
                </Accordion>
                <Accordion header="Relasjoner">
                    <div className="c-vegobjekttype-detaljer__relations c-vegobjekttype-detaljer-relations">
                        <h2 className="c-vegobjekttype-detaljer-relations__category">Foreldreobjekttyper</h2>
                        <ul className="o-list">{this.listParents(type)}</ul>
                        <h2 className="c-vegobjekttype-detaljer-relations__category">Barnobjekttyper</h2>
                        <ul className="o-list">{this.listChildren(type)}</ul>
                    </div>
                </Accordion>
                <Accordion header="Styringsparametere">{this.renderObject(styringsparametere)}</Accordion>
                <Accordion header="Stedfesting">{this.renderObject(type.stedfesting)}</Accordion>
            </div>
        );
    }

    private listChildren(vegobjektType: Vegobjekttype) {
        if (vegobjektType.relasjonstyper) {
            if (vegobjektType.relasjonstyper.barn && vegobjektType.relasjonstyper.barn.length > 0) {
                return this.relasjoner(vegobjektType.relasjonstyper.barn);
            }
            return (
                <li
                    className="o-list__element c-vegobjekttype-detaljer-relations__related-type-name"
                    key={'ingen datterobjekter'}
                >
                    <span className="o-btn-block">Ingen datterobjekter</span>
                </li>
            );
        }
    }

    private listParents(vegobjektType: Vegobjekttype) {
        if (vegobjektType.relasjonstyper) {
            if (vegobjektType.relasjonstyper.foreldre && vegobjektType.relasjonstyper.foreldre.length > 0) {
                return this.relasjoner(vegobjektType.relasjonstyper.foreldre);
            }
            return (
                <li
                    className="o-list__element c-vegobjekttype-detaljer-relations__related-type-name"
                    key={'inga Foreldreobjekter'}
                >
                    <span className="o-btn-block">Ingen Foreldreobjekter</span>
                </li>
            );
        }
    }

    private renderObject(object: object) {
        return (
            <dl className="c-egenskapsobjekt">
                {Object.keys(object).map((key, index) => {
                    const value = object[key];
                    if (typeof value === 'object' && value !== null) {
                        return (
                            <Accordion key={key} className="c-egenskapsobjekt__nested" header={key}>
                                {this.renderObject(value)}
                            </Accordion>
                        );
                    }
                    return (
                        <div key={`${key}${index}`}>
                            <dt className="c-egenskapsobjekt__heading">
                                {`${key.charAt(0).toUpperCase()}${key.slice(1)}:`.replace(/_/g, ' ')}
                            </dt>
                            <dd className="c-egenskapsobjekt__content">{JSON.stringify(value)}</dd>
                        </div>
                    );
                })}
            </dl>
        );
    }

    private relasjoner(relasjon: Relasjon[]) {
        return [...relasjon]
            .sort((a, b) => a.innhold.type.navn.localeCompare(b.innhold.type.navn))
            .map((f: Relasjon) => (
                <li
                    className="o-list__element c-vegobjekttype-detaljer-relations__related-type-name"
                    key={f.innhold.type.id}
                >
                    <a
                        className="o-btn-block"
                        onClick={e => {
                            e.preventDefault();
                            this.props.onVegobjektTypeClick(
                                this.props.datakatalog.getTypeById(f.innhold.type.id)
                            );
                        }}
                    >
                        {f.innhold.type.navn}
                    </a>
                </li>
            ));
    }
}

const mapStateToProps = (state: RootState) => ({
    valgtVegobjekttype: selectors.stateDatakatalogVegobjekttypeSelected(state),
    vegobjekttyperInSearch: selectors.stateSelectedRoadObjectTypes(state),
});

const mapDispatchToProps = {
    doAddVegobjekttypeToSearch: addVegobjekttypeToSearch,
    doBacktrackSelectedVegobjekttypeInDatakatalog: backtrackVegobjekttypeSelectedInDatakatalog,
    doSelectVegobjekttypeInDatakatalog: vegobjekttypeSelectedInDatakatalog,
    doSetSearchPanelOpen: setSearchPanelOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(VegobjekttypeContainer);

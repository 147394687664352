import * as React from 'react';

export interface CheckboxProps {
    checked: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    value?: string;
    className?: string;
    label?: string;
    bold?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, option_1?, option_2?, option_3?) => void;
}

const TriStateCheckbox = ({
    checked,
    indeterminate = false,
    disabled = false,
    value = '',
    className = '',
    label,
    bold,
    onChange,
}: CheckboxProps) => {
    const boldModifier = bold ? '--bold' : '';
    return (
        <label className={'o-checkbox ' + className}>
            <input
                type="checkbox"
                ref={input => {
                    if (input) {
                        input.checked = checked;
                        input.indeterminate = indeterminate as boolean;
                        input.disabled = disabled as boolean;
                    }
                }}
                value={value}
                onChange={e => {
                    if (onChange) {
                        onChange(e);
                    }
                }}
            />
            <span className={'o-checkbox__label' + boldModifier}>{label}</span>
        </label>
    );
};

export default TriStateCheckbox;

import { ActionType, LoginWindowAction, SplashAction } from '@/actions/actiontypes';
import { SplashState } from '@/state/SplashState';

const initialState: SplashState = null;

export const splashReducer = (
    splashState: SplashState = initialState,
    action: SplashAction | LoginWindowAction
) => {
    switch (action.type) {
        // Start-up
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.splashState;
        case ActionType.SHOW_SPLASH:
            return action.state;
        case ActionType.SHOW_LOGIN:
            return SplashState.NONE;
        default:
            return splashState;
    }
};

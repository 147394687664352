import React, { ReactNode, useEffect, useRef } from 'react';

type DropdownItemsProps = { id: string; role?: string; focusIndex: number; children: ReactNode };

const DropdownItems = ({ id, role, focusIndex, children }: DropdownItemsProps) => {
    const dropdownRef = useRef<HTMLUListElement>(null);
    const dataLength = dropdownRef?.current?.children.length;
    const ariaLabel = 'søkeresultater, liste med ' + dataLength + ' elementer';

    const scrollIntoView = (position: number) => {
        if (dropdownRef.current) {
            dropdownRef.current.scrollTo({
                top: position,
                behavior: 'smooth',
            });
        }
    };
    useEffect(() => {
        if (dropdownRef.current && focusIndex >= 0) {
            const listItems = Array.from(dropdownRef.current.children) as HTMLElement[];
            if (!listItems[focusIndex]) {
                console.error('failed to locate index', focusIndex, 'in', listItems);
            }
            scrollIntoView(listItems[focusIndex].offsetTop - dropdownRef.current.clientHeight + 61);
        }
    }, [focusIndex]);

    return (
        <ul
            id={id}
            role={role}
            aria-live="assertive"
            aria-atomic
            aria-label={ariaLabel}
            className="dropdown-list"
            ref={dropdownRef}
        >
            {children}
        </ul>
    );
};

export default DropdownItems;

import * as React from 'react';
import {
    VeglenkeAdministrativInndeling,
    VeglenkeBasisInndeling,
    VeglenkeLengdeInndeling,
    VeglenkeStedfestingInndeling,
    Vegnettlenke,
} from '@/domain/vegnett/Vegnettlenke';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { clearSelectedFeature } from '@/actions/valgtActions';
import { zoomToExtent } from '@/actions/mapActions';
import { highlightRoadsystemReference } from '@/actions/searchActions';
import { useDispatch, useSelector } from '@/state/store';
import { statePreferences } from '@/selectors/selectors';
import { Datakatalog } from '@/bootstrap/data';
import renderKvalitet from './components/veglenke/renderKvalitet';
import Lenkeretning from './components/veglenke/Lenkeretning';
import Superstedfesting from './components/veglenke/Superstedfesting';
import Lenkeegenskap from './components/veglenke/Lenkeegenskap';

interface Props {
    color: string;
    vegnettlenke: Vegnettlenke;
    datakatalog: Datakatalog;
}

export const VeglenkeComponent = ({ color, vegnettlenke, datakatalog }: Props) => {
    const dispatch = useDispatch();
    const preferences = useSelector(statePreferences);

    return (
        <div className="c-valgt" tabIndex={-1} aria-live="assertive" aria-atomic="true">
            {/* Header */}
            <div className="c-valgt-header" style={{ backgroundColor: color }}>
                <h3 className="c-valgt-header__title">{vegnettlenke.typeVeg}</h3>
                <div className="c-valgt-tools">
                    <button
                        className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                        onClick={() =>
                            dispatch(zoomToExtent(kartutsnittFromWktStrings(vegnettlenke.geometri.wkt)))
                        }
                        title={'Viser veglenkesegmentet i kartet'}
                    >
                        Zoom til
                    </button>
                </div>
                <button
                    title="Fjern"
                    onClick={() => dispatch(clearSelectedFeature())}
                    className="c-valgt-header__button"
                    aria-label="Lukk objektvisning"
                >
                    <i className="o-icon-remove c-valgt-header__close-icon" />
                </button>
            </div>
            {/* Body */}
            <dl className="c-valgt-features">
                {/* Vegsystemreferanse */}
                {vegnettlenke.vegsystemreferanse && [
                    <dt key="rn-vsr" className="c-valgt-features__dt">
                        Vegsystemreferanse:
                    </dt>,
                    <dd key={vegnettlenke.kortform} className="c-valgt-features__dd">
                        <a className="c-valgt-features__link" onClick={() => handleVSRClick()}>
                            {vegnettlenke.vegsystemreferanse.kortform}
                        </a>
                    </dd>,
                ]}
                {vegnettlenke.vegsystemreferanse && <hr />}
                {/* Properties */}
                <h4 className="c-valgt-features__subheader">Veglenke</h4>
                {Object.values(VeglenkeBasisInndeling).map((egenskap, index) => (
                    <Lenkeegenskap property={egenskap} vegnettlenke={vegnettlenke} key={index} />
                ))}
                <hr />
                <h4 className="c-valgt-features__subheader">Lengde</h4>
                {Object.values(VeglenkeLengdeInndeling).map((egenskap, index) => (
                    <Lenkeegenskap property={egenskap} vegnettlenke={vegnettlenke} key={index} />
                ))}
                <hr />
                <h4 className="c-valgt-features__subheader">Administrativ inndeling</h4>
                {Object.values(VeglenkeAdministrativInndeling).map((egenskap, index) => (
                    <Lenkeegenskap property={egenskap} vegnettlenke={vegnettlenke} key={index} />
                ))}
                <hr />
                <h4 className="c-valgt-features__subheader">Stedfesting</h4>
                {Object.values(VeglenkeStedfestingInndeling).map((egenskap, index) => (
                    <Lenkeegenskap property={egenskap} vegnettlenke={vegnettlenke} key={index} />
                ))}
                {vegnettlenke.vegsystemreferanse && (
                    <Lenkeretning vegsystemreferanse={vegnettlenke.vegsystemreferanse} />
                )}
                {!!vegnettlenke.superstedfesting?.veglenkesekvensid && (
                    <Superstedfesting vegnettlenke={vegnettlenke} />
                )}
                {vegnettlenke.feltoversikt || vegnettlenke.superstedfesting?.kjørefelt ? (
                    <Lenkeegenskap
                        property={'Feltoversikt (veglenkeretning)'}
                        value={
                            vegnettlenke.feltoversikt?.join('#') ||
                            vegnettlenke.superstedfesting.kjørefelt?.join('#')
                        }
                        vegnettlenke={vegnettlenke}
                    />
                ) : null}
                <hr />
                {preferences.showQualityInfo && (
                    <>
                        <h4 key="quality__subheader" className="c-valgt-features__subheader">
                            Kvalitet
                        </h4>
                        {vegnettlenke.kvalitet ? (
                            renderKvalitet(vegnettlenke.kvalitet, datakatalog)
                        ) : (
                            <>
                                <dd key="quality__dd-p" className="c-valgt-features__dd">
                                    Kvalitet ikke registrert
                                </dd>
                                <hr key="quality__separator" />
                            </>
                        )}
                    </>
                )}
                {preferences.showMetadata && (
                    <>
                        <h4 className="c-valgt-features__subheader">Metadata</h4>
                        {/* Start date */}
                        {vegnettlenke?.metadata?.startdato && (
                            <Lenkeegenskap
                                property="Startdato"
                                value={vegnettlenke.metadata.startdato}
                                vegnettlenke={vegnettlenke}
                            />
                        )}
                        {vegnettlenke?.metadata?.sluttdato && (
                            <Lenkeegenskap
                                property="Sluttdato"
                                value={vegnettlenke.metadata.sluttdato}
                                vegnettlenke={vegnettlenke}
                            />
                        )}
                        {/* API-link */}
                        <Lenkeegenskap
                            property="Objektid"
                            value={
                                <a href={vegnettlenke.href} target="_blank" rel="noopener noreferrer">
                                    {vegnettlenke.veglenkesekvensid}
                                </a>
                            }
                            vegnettlenke={vegnettlenke}
                        />
                    </>
                )}
            </dl>
        </div>
    );

    function handleVSRClick() {
        dispatch(highlightRoadsystemReference(vegnettlenke.vegsystemreferanse, vegnettlenke.kommune));
        dispatch(zoomToExtent(kartutsnittFromWktStrings(vegnettlenke.geometri.wkt)));
    }
};

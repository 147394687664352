export const FeatureLayerTypeKey = 'FeatureLayerType';
export const FeatureTypeKey = 'FeatureType';
export const FeatureColorKey = 'FeatureColor';
export const FeatureCountKey = 'antall';
export const FeatureRoadType = 'roadType';
export const FeatureDirection = 'linkDirection';
export const FeatureGlobalDirection = 'roadNetDirection';
export const FeatureShowDirectionalArrows = 'showDirectionalArrows';

export enum FeatureLayerType {
    FEATURE_VEGOBJEKT,
    FEATURE_COUNT,
    FEATURE_CLUSTER,
    FEATURE_ROADREF,
    FEATURE_VEGNETTLENKE,
    FEATURE_VALGT,
    FEATURE_VALGT_CLUSTER,
    FEATURE_VALGT_NODE,
    AREA_OUTLINE,
    USER_POSITION,
    USER_POSITION_ACCURACY,
    FEATURE_MAP_POINT,
}

export const isInteractible = (type: FeatureLayerType) => {
    return [
        FeatureLayerType.FEATURE_VEGOBJEKT,
        FeatureLayerType.FEATURE_COUNT,
        FeatureLayerType.FEATURE_CLUSTER,
        FeatureLayerType.FEATURE_VEGNETTLENKE,
        FeatureLayerType.FEATURE_VALGT_NODE,
        FeatureLayerType.FEATURE_MAP_POINT,
    ].includes(type);
};

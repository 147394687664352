import { ActionType, BootstrapAction } from '@/actions/actiontypes';
import { fetchAllOmradeGeometries } from '@/middleware/fetchMisc';
import { Integration } from '@/middleware/integration';
import { bindErrorCatcher, bindUpdateOnHashChange } from '@/middleware/middleware';
import { stateConfig } from '@/selectors/selectors';
import { useDispatch, useSelector } from '@/state/store';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { bootstrap } from './bootstrap';
import { useDataLoader } from './useDataLoader';
import React from 'react';

interface BootstrapperProps {
    children: ReactNode;
    fallback: ReactNode;
    integration: Integration;
}

export const Bootstrapper = ({ children, fallback, integration: integration }: BootstrapperProps) => {
    const dispatch = useDispatch();
    const store = useRef(useSelector(state => state));
    const config = useSelector(stateConfig);
    const data = useDataLoader(config.restapi_url);
    const [ready, setReady] = useState(store.current.bootstrapped);
    useEffect(() => {
        const timeBeforeFetch = new Date().getTime();
        if (data) {
            bootstrap(integration, store.current, data)
                .then(bootstrappedState => {
                    const timeAfterFetch = new Date().getTime();
                    const bootstrappedAction: BootstrapAction = {
                        type: ActionType.BOOTSTRAP,
                        bootstrappedState: bootstrappedState.withConfig(integration.config),
                    };
                    integration.server.postPerformanceLogEntry(
                        ActionType.BOOTSTRAP,
                        timeAfterFetch - timeBeforeFetch
                    );
                    dispatch(bootstrappedAction);
                    fetchAllOmradeGeometries()(dispatch, bootstrappedState);
                    bindErrorCatcher(dispatch, integration);
                    bindUpdateOnHashChange(dispatch);
                })
                .finally(() => setReady(true));
        }
    }, [dispatch, integration, store, data, config]);
    return useMemo(() => (ready ? <>{children}</> : <>{fallback}</>), [children, fallback, ready]);
};

import React, { useState } from 'react';

interface AccordionProps {
    className?: string;
    header: React.ReactNode;
    children: React.ReactNode;
    ariaLabel?: string;
    iconStyle?: string;
    id?: string;
}

function Accordion({ className, header, children, ariaLabel, iconStyle, id }: AccordionProps) {
    const [open, setOpen] = useState(false);
    return (
        <section className={className}>
            <header className="o-accordion__header" id={id} onClick={() => setOpen(!open)}>
                <button onClick={() => setOpen(!open)} aria-expanded={open} aria-label={ariaLabel ?? ''}>
                    <i
                        className={`
                        o-accordion__chevron
                        o-accordion__chevron--${open ? 'open' : 'closed'}
                        o-icon-chevron-right
                        c-what-accordion__icon ${iconStyle}`}
                    />
                </button>
                {header}
            </header>
            {open && children}
        </section>
    );
}

export default Accordion;

import { Veglenkesekvensposisjon } from '@/domain/Veglenkesekvensposisjon';
import { VegkartError, VegsystemreferanseLookup } from '@/state';
import { transformGeometri } from './geometri';
import {
    Vegposisjon,
    Kryssystem,
    Sideanlegg,
    Strekning,
    Vegsystem,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { getPointFromWKT } from '@/utils/WKT';

export function transformVegsystemreferanse(vegsysrefData: unknown, kommune: string): Vegsystemreferanse {
    return vegsysrefData && Object.keys(vegsysrefData).length > 0
        ? new Vegsystemreferanse(
              transformVegsystem(vegsysrefData['vegsystem']),
              transformStrekning(vegsysrefData['strekning']),
              transformKryssystem(vegsysrefData['kryssystem']),
              transformSideanlegg(vegsysrefData['sideanlegg']),
              transformKortform(vegsysrefData['kortform'], vegsysrefData['vegsystem'].vegkategori, kommune)
          )
        : null;
}

function transformKortform(kortform: string, vegkategori: string, kommune: string): string {
    return ['K', 'P', 'S'].includes(vegkategori) ? kommune + ' ' + kortform : kortform;
}

function transformVegsystem(vegsystemData: unknown): Vegsystem {
    return vegsystemData
        ? new Vegsystem(vegsystemData['vegkategori'], vegsystemData['fase'], vegsystemData['nummer'])
        : null;
}

function transformStrekning(strekningData: unknown): Strekning {
    return strekningData
        ? new Strekning(
              new Vegposisjon(strekningData['strekning']),
              strekningData['delstrekning'] ? new Vegposisjon(strekningData['delstrekning']) : null,
              strekningData['arm'],
              strekningData['adskilte_løp'],
              strekningData['adskilte_løp_nummer'],
              strekningData['trafikantgruppe'],
              strekningData['meter'] != null
                  ? new Vegposisjon(strekningData['meter'])
                  : new Vegposisjon(strekningData['fra_meter'], strekningData['til_meter']),
              strekningData['retning']
          )
        : null;
}

function transformKryssystem(kryssData: unknown): Kryssystem {
    return kryssData
        ? new Kryssystem(
              kryssData['krysssystem'],
              kryssData['kryssdel'] ? new Vegposisjon(kryssData['kryssdel']) : null,
              kryssData['trafikantgruppe'],
              kryssData['meter'] != null
                  ? new Vegposisjon(kryssData['meter'])
                  : new Vegposisjon(kryssData['fra_meter'], kryssData['til_meter']),
              kryssData['retning']
          )
        : null;
}

function transformSideanlegg(sideData: unknown): Sideanlegg {
    return sideData
        ? new Sideanlegg(
              sideData['sideanlegg'],
              sideData['sideanleggsdel'] ? new Vegposisjon(sideData['sideanleggsdel']) : null,
              sideData['trafikantgruppe'],
              sideData['meter'] != null
                  ? new Vegposisjon(sideData['meter'])
                  : new Vegposisjon(sideData['fra_meter'], sideData['til_meter']),
              sideData['retning']
          )
        : null;
}

export function transformRoadReferenceLookup(data: unknown): VegsystemreferanseLookup | VegkartError {
    const responseData = data instanceof Array ? data[0] : data;
    const { vegsystemreferanse, veglenkesekvens, geometri, avstand, code } = responseData;
    const kommune = responseData.kommune;
    if (!!code) {
        // error code
        return null;
    }

    const { wkt } = geometri;
    const geo = getPointFromWKT(wkt);
    return new VegsystemreferanseLookup(
        geo.at(0) ?? null,
        geo.at(1) ?? null,
        transformVegsystemreferanse(vegsystemreferanse, kommune),
        new Veglenkesekvensposisjon(
            veglenkesekvens.veglenkesekvensid,
            veglenkesekvens.relativPosisjon,
            veglenkesekvens.kortform
        ),
        transformGeometri(geometri),
        avstand,
        kommune
    );
}

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SearchContainer } from './search/SearchContainer';
import { searchFieldUpdated, setSearchPanelOpen } from '@/actions/searchActions';
import { useDebounceAndCleanValue } from '@/utils/useDebounceValue';
import { useDispatch, useSelector } from '@/state/store';
import useSearchBarFunctions from '@/components/avgrensning/search/dropdown/useSearchBarFunctions';
import { useSearchResult } from '@/components/avgrensning/search/useSearchResult';
import { SearchBar } from '@/components/avgrensning/search/SearchBar';
import { DateFilter } from '@/components/avgrensning/search/DateFilter';
import { stateSearchPanelOpen } from '@/selectors/selectors';
import { useDatakatalogResult } from '@/components/avgrensning/datakatalog/useDatakatalogResult';
import { DatakatalogContainer } from '@/components/avgrensning/datakatalog/DatakatalogContainer';
import { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';

export const Search = () => {
    const dispatch = useDispatch();
    const dropdownWrapperRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState<string>('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownCursor, setDropdownCursor] = useState(-1);
    const [lastInputWasArrowPress, setLastInputWasArrowPress] = useState(false);
    const [datakatalogOpen, setDatakatalogOpen] = useState(false);

    const showPanel = useSelector(stateSearchPanelOpen);

    const searchText = useDebounceAndCleanValue(inputValue, 500, lastInputWasArrowPress);
    const searchResult = useSearchResult(inputValue);
    const datakatalogResult = useDatakatalogResult(inputValue);

    useEffect(() => {
        if (!datakatalogOpen) dispatch(searchFieldUpdated(searchText));
    }, [datakatalogOpen, dispatch, searchText]);

    const noInput = useCallback(() => inputValue.length === 0, [inputValue.length]);
    const onDropdownItemSelected = useCallback(
        (i: DropdownItemData) => {
            if (i.disabled) return;
            setInputValue('');
            i.onClick();
            if (!datakatalogOpen) setDropdownVisible(false);
        },
        [datakatalogOpen, setDropdownVisible]
    );
    const { handleKeyDown, handleOnFocus, handleChange, handleSubmit } = useSearchBarFunctions(
        setLastInputWasArrowPress,
        dropdownCursor,
        setDropdownCursor,
        dropdownVisible,
        setDropdownVisible,
        datakatalogOpen ? datakatalogResult : searchResult,
        noInput,
        onDropdownItemSelected,
        dropdownWrapperRef,
        setInputValue
    );

    const closedClass = showPanel ? '' : ' c-search__closed';
    return (
        <div>
            <div className={'c-search' + closedClass} ref={dropdownWrapperRef}>
                <SearchBar
                    handleSubmit={handleSubmit}
                    handleKeyDown={handleKeyDown}
                    handleOnFocus={handleOnFocus}
                    handleChange={handleChange}
                    datakatalogOpen={datakatalogOpen}
                    setDatakatalogOpen={setDatakatalogOpen}
                    inputValue={inputValue}
                />
                {datakatalogOpen && (
                    <DatakatalogContainer focusIndex={dropdownCursor} items={datakatalogResult} />
                )}
                {dropdownVisible && !datakatalogOpen && (
                    <SearchContainer
                        items={searchResult}
                        focusIndex={dropdownCursor}
                        onDropdownItemSelected={onDropdownItemSelected}
                    />
                )}
                <div className="t-visible-mobile">
                    <button
                        type="button"
                        onClick={() => {
                            setDropdownVisible(false);
                            setDatakatalogOpen(false);
                            dispatch(setSearchPanelOpen(false));
                        }}
                        className="o-btn o-btn--orange-bg"
                    >
                        <i className="o-icon-chevron-left c-search-interact" /> Tilbake
                    </button>
                </div>
            </div>
            <DateFilter className={showPanel ? '' : 'c-date-selector__closed'} />
        </div>
    );
};

import {
    Fylke,
    Kommune,
    Kontraktsomrade,
    Land,
    Omrade,
    Riksvegrute,
    validateVegsystemreferanse,
    Vegsystemreferanse,
} from '@/domain/omrader';
import {
    stateFylker,
    stateKommuner,
    stateKontraktsomrader,
    stateLand,
    stateRiksvegruter,
    stateVegnett,
    stateVegnettVisible,
    stateVegsystemreferanser,
} from '@/selectors/selectors';
import {
    ActionType,
    AddFylkeAction,
    AddKommuneAction,
    AddKontraktsomradeAction,
    AddLandAction,
    AddPolygonAction,
    AddRiksvegruteAction,
    AddVSRAction,
    AddVSRLookupAction,
    ErrorAction,
    GetState,
    RemoveOmradeAction,
    ToggleOmradeAction,
    UpdateVSRAction,
} from './actiontypes';
import { executeAllSearches } from '@/middleware/fetchVegobjekter';
import { fetchAreaGeometry, fetchVegsystemreferansePoint } from '@/middleware/fetchMisc';
import { fetchVegnett } from '@/middleware/fetchVegnett';
import { Severity, VegkartError, VegsystemreferanseLookup } from '@/state';
import { zoomToExtent } from './mapActions';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { Dispatch } from '@/state/store';
import { Polygon } from '@/domain/omrader/Polygon';

export type addLandToSearchT = (land: Land) => (dispatch, getState: GetState) => void;
export const addLandToSearch: addLandToSearchT = (land: Land) => (dispatch, getState: GetState) => {
    if (hasArea(land, stateLand(getState()))) return;
    const addLandAction: AddLandAction = {
        type: ActionType.OMRADE_LAND_ADDED,
        land,
    };
    dispatch(addLandAction);
    dispatch(executeAllSearches());
    // Land-search currently does not trigger fetchVegnett().
};

export type addFylkeToSearchT = (fylke: Fylke) => (dispatch, getState: GetState) => void;
export const addFylkeToSearch: addFylkeToSearchT = (fylke: Fylke) => (dispatch, getState: GetState) => {
    if (hasArea(fylke, stateFylker(getState()))) return;
    const addFylkeAction: AddFylkeAction = {
        type: ActionType.OMRADE_FYLKE_ADDED,
        fylke,
    };
    dispatch(addFylkeAction);
    dispatch(executeAllSearches());
    dispatch(fetchAreaGeometry(fylke));
    // Fylke-search automatically moves the map, which triggers fetchVegnett().
};

export type addPolygonToSearchT = (polygon: Polygon) => (dispatch: Dispatch, getState: GetState) => void;
export const addPolygonToSearch: addPolygonToSearchT = polygon => dispatch => {
    const addPolygonAction: AddPolygonAction = {
        type: ActionType.OMRADE_POLYGON_ADDED,
        polygon,
    };
    dispatch(addPolygonAction);
    dispatch(executeAllSearches());
};
export type addKommuneToSearchT = (kommune: Kommune) => (dispatch, getState: GetState) => void;
export const addKommuneToSearch: addKommuneToSearchT =
    (kommune: Kommune) => (dispatch, getState: GetState) => {
        if (hasArea(kommune, stateKommuner(getState()))) return;
        const addKommuneAction: AddKommuneAction = {
            type: ActionType.OMRADE_KOMMUNE_ADDED,
            kommune,
        };
        dispatch(addKommuneAction);
        dispatch(executeAllSearches());
        dispatch(fetchAreaGeometry(kommune));
        // Kommune-search automatically moves the map, which triggers fetchVegnett().
    };

export type addKommuneAndVegrefToSearchT = (kommune: Kommune) => (dispatch, getState: GetState) => void;
export const addKommuneAndVegrefToSearch: addKommuneAndVegrefToSearchT =
    (kommune: Kommune) => (dispatch, getState: GetState) => {
        if (hasArea(kommune, stateKommuner(getState()))) return;
        dispatch(executeAllSearches());
        dispatch(fetchAreaGeometry(kommune));
        // Kommune-search automatically moves the map, which triggers fetchVegnett().
    };

export type addKontraktsomradeToSearchT = (
    kontraktsomrade: Kontraktsomrade
) => (dispatch, getState: GetState) => void;
export const addKontraktsomradeToSearch: addKontraktsomradeToSearchT =
    (kontraktsomrade: Kontraktsomrade) => (dispatch, getState: GetState) => {
        if (hasArea(kontraktsomrade, stateKontraktsomrader(getState()))) return;
        const addKontraktsomradeAction: AddKontraktsomradeAction = {
            type: ActionType.OMRADE_KONTRAKTSOMRADE_ADDED,
            kontraktsomrade,
        };
        dispatch(addKontraktsomradeAction);
        dispatch(executeAllSearches());
        dispatch(fetchAreaGeometry(kontraktsomrade));
        if (stateVegnett(getState()).visible) {
            dispatch(fetchVegnett());
        }
    };

export type addRiksvegruteToSearchT = (riksvegrute: Riksvegrute) => (dispatch, getState: GetState) => void;
export const addRiksvegruteToSearch: addRiksvegruteToSearchT =
    (riksvegrute: Riksvegrute) => (dispatch, getState: GetState) => {
        if (hasArea(riksvegrute, stateRiksvegruter(getState()))) return;
        const addRiksvegruteAction: AddRiksvegruteAction = {
            type: ActionType.OMRADE_RIKSVEGRUTE_ADDED,
            riksvegrute,
        };
        dispatch(addRiksvegruteAction);
        dispatch(executeAllSearches());
        dispatch(fetchAreaGeometry(riksvegrute));
        if (stateVegnettVisible(getState())) {
            dispatch(fetchVegnett());
        }
    };

export type addVSRToSearchT = (
    vegsystemreferanse: Vegsystemreferanse
) => (dispatch, getState: GetState) => void;
export const addVSRToSearch: addVSRToSearchT =
    (vegsystemreferanse: Vegsystemreferanse) => (dispatch, getState: GetState) => {
        if (hasArea(vegsystemreferanse, stateVegsystemreferanser(getState()))) return;
        const addVSRAction: AddVSRAction = {
            type: ActionType.OMRADE_VEGSYSTEMREFERANSE_ADDED,
            vegsystemreferanse,
        };
        dispatch(addVSRAction);
        dispatch(executeAllSearches());
        if (validateVegsystemreferanse(vegsystemreferanse))
            dispatch(fetchVegsystemreferansePoint(vegsystemreferanse));
        if (stateVegnettVisible(getState())) {
            dispatch(fetchVegnett());
        }
    };

export type gotoVSRT = (vegsystemreferanse: Vegsystemreferanse) => (dispatch) => void;
export const gotoVSR = (vegsystemreferanse: Vegsystemreferanse) => dispatch => {
    if (!validateVegsystemreferanse(vegsystemreferanse)) {
        let message = 'Zoom til vegsystemreferanse krever vegnummer, strekning og delstrekning.';
        if (vegsystemreferanse.isKPS() && !vegsystemreferanse.kommune)
            message = 'Zoom til vegsystemreferanse krever kommune';
        const vegkartError = new VegkartError(message, {}, Severity.INFO);
        const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error: vegkartError };
        dispatch(errorAction);
        const updateAction: UpdateVSRAction = {
            type: ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED,
            previousVsr: vegsystemreferanse,
            newVsr: vegsystemreferanse.withKommune(null),
        };
        dispatch(updateAction);
    } else {
        const addVSRAction: AddVSRLookupAction = {
            type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_ADDED,
            vsrLookup: new VegsystemreferanseLookup(
                0,
                0,
                vegsystemreferanse,
                null,
                vegsystemreferanse.senterpunkt,
                null,
                vegsystemreferanse.kommune
            ),
        };
        dispatch(addVSRAction);
        dispatch(
            zoomToExtent(
                kartutsnittFromWktStrings(
                    vegsystemreferanse.geometri
                        ? vegsystemreferanse.geometri.wkt
                        : vegsystemreferanse.senterpunkt.wkt
                )
            )
        );
    }
};

export type updateVSRInSearchT = (
    previousVsr: Vegsystemreferanse,
    newVsr: Vegsystemreferanse
) => (dispatch, getState: GetState) => void;
export const updateVSRInSearch: updateVSRInSearchT =
    (previousVsr: Vegsystemreferanse, newVsr: Vegsystemreferanse) => (dispatch, getState: GetState) => {
        const updateVSRAction: UpdateVSRAction = {
            type: ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED,
            previousVsr,
            newVsr,
        };
        dispatch(updateVSRAction);
        dispatch(executeAllSearches());
        if (validateVegsystemreferanse(newVsr)) dispatch(fetchVegsystemreferansePoint(newVsr));
        if (stateVegnettVisible(getState())) dispatch(fetchVegnett());
    };
export type toggleOmradeVisibilityT = (omrade: Omrade) => (dispatch, getState) => void;
export const toggleOmradeVisibility: toggleOmradeVisibilityT = (omrade: Omrade) => (dispatch, getState) => {
    const toggleOmradeAction: ToggleOmradeAction = {
        type: ActionType.OMRADE_TOGGLED,
        omrade: omrade,
    };
    dispatch(toggleOmradeAction);
    dispatch(executeAllSearches());
    if (stateVegnettVisible(getState())) dispatch(fetchVegnett());
};
export type removeOmradeT = (omrade: Omrade) => (dispatch, getState) => void;
export const removeOmrade: removeOmradeT = (omrade: Omrade) => (dispatch, getState) => {
    const removeOmradeAction: RemoveOmradeAction = {
        type: ActionType.OMRADE_REMOVED,
        omrade: omrade,
    };
    dispatch(removeOmradeAction);
    dispatch(executeAllSearches());
    if (stateVegnettVisible(getState())) dispatch(fetchVegnett());
};

function hasArea(omrade: Omrade, currentAreas: Omrade[]): boolean {
    if (omrade instanceof Polygon) return currentAreas.some(v => v instanceof Polygon);
    else if (omrade instanceof Vegsystemreferanse)
        return currentAreas
            .filter(a => a instanceof Vegsystemreferanse && a.kommune === omrade.kommune)
            .map(a => a.toString())
            .includes(omrade.toString());
    return currentAreas.map(a => a.toString()).indexOf(omrade.toString()) !== -1;
}

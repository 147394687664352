import buildJson from './.environment.json';

interface Git {
    commit: { time: string; id: string };
}
interface BuildEnvironment {
    git: Git;
    build: { version: string };
}

const build = buildJson as BuildEnvironment;
export const version = {
    hash: build.git.commit.id,
    version: build.build.version,
};

import { selectFeature } from '@/actions/valgtActions';
import Accordion from '@/components/objects/Accordion';
import { Vegobjekt, VegobjektRelasjon } from '@/domain/vegobjekter/Vegobjekt';
import { Dispatch } from '@/state/store';
import { defaultColor } from '@/utils/ColorHelper';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

type RelasjonerProps = {
    vegobjekt: Vegobjekt;
};

function Relasjoner({ vegobjekt }: RelasjonerProps) {
    const morRelasjoner = vegobjekt?.relasjoner?.foreldre?.length > 0;
    const datterRelasjoner = vegobjekt?.relasjoner?.barn?.length > 0;
    const dispatch = useDispatch();

    const children = useMemo(
        () => [...(vegobjekt.relasjoner?.barn || [])].sort(sortRelations),
        [vegobjekt.relasjoner?.barn]
    );
    const parents = useMemo(
        () => [...(vegobjekt.relasjoner?.foreldre || [])].sort(sortRelations),
        [vegobjekt.relasjoner?.foreldre]
    );

    return (
        <>
            {(morRelasjoner || datterRelasjoner) && [
                <hr key={'relationsDivider'} />,
                <h4 key="relationsSubheader" className="c-valgt-features__subheader">
                    Relasjoner
                </h4>,
            ]}
            <div className="c-relaterte-objekter">
                {morRelasjoner && [
                    <h4 key="foreldreobjekter" className="c-relaterte-objekter__relasjon">
                        Foreldreobjekter
                    </h4>,
                    parents.map(parentRelation => renderRelation(parentRelation, vegobjekt, dispatch)),
                ]}
                {datterRelasjoner && [
                    <h4 key="barnobjekter" className="c-relaterte-objekter__relasjon">
                        Barnobjekter
                    </h4>,
                    children.map(childRelation => renderRelation(childRelation, vegobjekt, dispatch, true)),
                ]}
            </div>
        </>
    );
}

function renderRelation(
    relasjon: VegobjektRelasjon,
    vegobjekt: Vegobjekt,
    dispatch: Dispatch,
    showCount = false
) {
    return (
        <Accordion
            key={relasjon.type.id + '_' + relasjon.type.navn}
            className="c-relaterte-list__header"
            ariaLabel={`Vis ${relasjon.relasjonstype == 1 ? 'barn' : 'forelder'}objekt ${relasjon.type.navn}`}
            iconStyle="c-relaterte-list__accordionIcon"
            header={
                <>
                    <div>{relasjon.type.navn}</div>
                    {showCount && (
                        <small className={'c-relaterte-list__count'}>
                            {relasjon.vegobjekter.length} vegobjekt
                            {relasjon.vegobjekter.length > 1 ? 'er' : ''}
                        </small>
                    )}
                </>
            }
        >
            <ul className="c-relaterte-list">
                {relasjon.vegobjekter.map(relatertVegobjekt => (
                    <li key={relatertVegobjekt.id}>
                        <button
                            type="button"
                            className="c-relaterte-list__button"
                            aria-label={`Vis ${relasjon.type.navn} med id ${relatertVegobjekt.id}`}
                            onClick={() =>
                                dispatch(
                                    selectFeature(
                                        [Vegobjekt.dummy(relatertVegobjekt.id, relasjon.type.id), vegobjekt],
                                        [],
                                        undefined,
                                        defaultColor
                                    )
                                )
                            }
                        >
                            {relatertVegobjekt.id}
                        </button>
                    </li>
                ))}
            </ul>
        </Accordion>
    );
}

function sortRelations(a: VegobjektRelasjon, b: VegobjektRelasjon) {
    return a.type.navn.localeCompare(b.type.navn);
}
export default Relasjoner;

import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';

const useDebounce = <T extends (...args: never[]) => unknown>(func: T, delay: number) => {
    const debouncedCall = useMemo(() => debounce(func, delay), [func, delay]);

    useEffect(() => {
        return () => {
            debouncedCall.cancel();
        };
    }, [debouncedCall]);

    return debouncedCall;
};

export default useDebounce;

import { RootState, VegobjekttypeCategoryState, VegobjekttypeState } from '@/state';
import {
    ActionType,
    AddVegobjektAction,
    GetState,
    IsAddingCatToVOAction,
    RefreshAction,
    VOAddAction,
    VOAddCategoryAction,
    VOAddFilterAction,
    VORemoveAction,
    VORemoveCategoryAction,
    VORemoveFilterAction,
    VOSetCategoryAction,
    VOToggleAction,
    VOToggleCategoryAction,
    VOUpdateFilterAction,
} from './actiontypes';
import { Integration } from '@/middleware/integration';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { stateSelectedRoadObjectTypes, stateValgt } from '@/selectors/selectors';
import { updateSelectedColor } from './valgtActions';
import { defaultColor, resolveColor } from '@/utils/ColorHelper';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';
import { Egenskapsfilter } from '@/middleware/queries';
import { executeAllSearches, fetchVegobjekt, fetchVegobjekter } from '@/middleware/fetchVegobjekter';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export type addVegobjektToSearchT = (vegobjekt: Vegobjekt) => (dispatch, getState: GetState) => void;
export const addVegobjektToSearch: addVegobjektToSearchT =
    (vegobjekt: Vegobjekt) => (dispatch, _: GetState) => {
        const addVegobjektAction: AddVegobjektAction = {
            type: ActionType.VEGOBJEKT_ADDED,
            vegobjekt,
        };
        dispatch(addVegobjektAction);
        dispatch(fetchVegobjekt(vegobjekt.id));
    };

export type refreshVegobjektSearchT = () => (dispatch) => void;
export const refreshVegobjektSearch: refreshVegobjektSearchT = () => dispatch => {
    const refreshAction: RefreshAction = {
        type: ActionType.TIMESTAMP_CHANGED,
    };
    dispatch(refreshAction);
    dispatch(executeAllSearches());
};

export type addVegobjekttypeToSearchT = (
    vegobjekttype: Vegobjekttype
) => (dispatch, getState: GetState, integration: Integration) => void;
export const addVegobjekttypeToSearch: addVegobjekttypeToSearchT =
    (vegobjekttypeRaw: Vegobjekttype) => (dispatch, getState: () => RootState, integration: Integration) => {
        // Don't add type to search if it is already added. Add-buttons are disabled already,
        // but this is a failsafe which also catches attempts to add from search field.
        if (
            stateSelectedRoadObjectTypes(getState()).find(votState => votState.typeId === vegobjekttypeRaw.id)
        ) {
            return;
        }

        const votState = new VegobjekttypeState(
            vegobjekttypeRaw.id,
            integration.colorHandler.getNextColorIndex()
        );
        const addVegobjekttypeAction: VOAddAction = {
            type: ActionType.VEGOBJEKTER_TYPE_ADDED,
            vegobjekttype: votState,
        };
        dispatch(addVegobjekttypeAction);
        dispatch(fetchVegobjekter(votState));

        // Change selected object color if new vegobjektttype matches selection's type.
        const mapSelection = stateValgt(getState());
        const selectedIsOfType = mapSelection.singleVegobjekt()?.metadata?.type?.id === vegobjekttypeRaw.id;
        if (selectedIsOfType) {
            dispatch(updateSelectedColor(resolveColor(votState.color)));
        }
    };

export type toggleVegobjekttypeVisibilityT = (votState: VegobjekttypeState) => (dispatch) => void;
export const toggleVegobjekttypeVisibility: toggleVegobjekttypeVisibilityT =
    (votState: VegobjekttypeState) => dispatch => {
        const toggleVOTAction: VOToggleAction = {
            type: ActionType.VEGOBJEKTER_TYPE_TOGGLED,
            votTypeId: votState.typeId,
        };
        dispatch(toggleVOTAction);
    };
export type removeVegobjekttypeT = (
    votState: VegobjekttypeState
) => (dispatch, getState: GetState, integration: Integration) => void;
export const removeVegobjekttype: removeVegobjekttypeT =
    (votState: VegobjekttypeState) => (dispatch, getState: () => RootState, integration: Integration) => {
        const removeVOTAction: VORemoveAction = {
            type: ActionType.VEGOBJEKTER_TYPE_REMOVED,
            votTypeId: votState.typeId,
        };
        dispatch(removeVOTAction);
        integration.colorHandler.decrementColor(votState.color);

        // Change selected object color if old vegobjekttype matches selection's type.
        const mapSelection = stateValgt(getState());
        const selectedIsOfType = mapSelection.singleVegobjekt()?.metadata?.type?.id === votState.typeId;
        if (selectedIsOfType) {
            dispatch(updateSelectedColor(defaultColor));
        }
    };

export type addFilterToVegobjekttypeT = (votState: VegobjekttypeState) => (dispatch) => void;
export const addFilterToVegobjekttype: addFilterToVegobjekttypeT =
    (votState: VegobjekttypeState) => dispatch => {
        const addFilterToVOTAction: VOAddFilterAction = {
            type: ActionType.VEGOBJEKTER_FILTER_ADDED,
            votTypeId: votState.typeId,
        };
        dispatch(addFilterToVOTAction);
    };

export type addCategorizationToVegobjekttypeT = (votState: VegobjekttypeState) => (dispatch) => void;
export const addCategorizationToVegobjekttype: addCategorizationToVegobjekttypeT =
    (votState: VegobjekttypeState) => dispatch => {
        const addCatToVOTAction: VOAddCategoryAction = {
            type: ActionType.VEGOBJEKTER_CATEGORY_ADDED,
            votTypeId: votState.typeId,
            egenskapstype: votState.category,
        };
        dispatch(addCatToVOTAction);
    };

export type isCategorizingT = (categorizing: boolean) => (dispatch, getState) => void;
export const isCategorizing: isCategorizingT = (categorizing: boolean) => dispatch => {
    const isAddingCatToVOTAction: IsAddingCatToVOAction = {
        type: ActionType.IS_ADDING_CAT_TO_VOT,
        categorizing,
    };
    dispatch(isAddingCatToVOTAction);
};

export type setVOTCategoryT = (
    votState: VegobjekttypeState,
    egenskapstype: Egenskapstype,
    interval?: number[],
    absoluteIntervals?: boolean
) => (dispatch) => void;
export const setVOTCategory: setVOTCategoryT =
    (
        votState: VegobjekttypeState,
        egenskapstype: Egenskapstype,
        interval: number[] = null,
        absoluteIntervals = false
    ) =>
    dispatch => {
        const setVOTCatAction: VOSetCategoryAction = {
            type: ActionType.VEGOBJEKTER_CATEGORY_UPDATED,
            votTypeId: votState.typeId,
            egenskapstype,
            interval,
            absoluteIntervals,
        };
        dispatch(setVOTCatAction);
        dispatch(
            fetchVegobjekter(votState.withCategory(egenskapstype).withInterval(interval, absoluteIntervals))
        );
    };

export type toggleCategoryT = (
    votTypeId: number,
    votCategoryState: VegobjekttypeCategoryState
) => (dispatch) => void;
export const toggleCategory: toggleCategoryT =
    (votTypeId: number, votCategoryState: VegobjekttypeCategoryState) => dispatch => {
        const toggleCatAction: VOToggleCategoryAction = {
            type: ActionType.VEGOBJEKTER_CATEGORY_TOGGLED,
            votTypeId,
            votCategoryState,
        };
        dispatch(toggleCatAction);
    };

export type removeVOTCategoryT = (votState: VegobjekttypeState) => (dispatch) => void;
export const removeVOTCategory: removeVOTCategoryT = (votState: VegobjekttypeState) => dispatch => {
    const removeVOTCategoryAction: VORemoveCategoryAction = {
        type: ActionType.VEGOBJEKTER_CATEGORY_REMOVED,
        votTypeId: votState.typeId,
    };
    dispatch(removeVOTCategoryAction);

    // If the category was not valid (no categorisation was specified) there is no point in executing the search.
    if (votState.categoryIsValid()) {
        dispatch(fetchVegobjekter(votState.withCategory(null).withCategoryStates([])));
    }
};

export type egenskapsfilterChangedT = (
    votState: VegobjekttypeState,
    filter: Egenskapsfilter
) => (dispatch) => void;
export const egenskapsfilterChanged: egenskapsfilterChangedT =
    (votState: VegobjekttypeState, filter: Egenskapsfilter) => dispatch => {
        const updateVOTFilterAction: VOUpdateFilterAction = {
            type: ActionType.VEGOBJEKTER_FILTER_UPDATED,
            votTypeId: votState.typeId,
            filter,
        };
        dispatch(updateVOTFilterAction);
        if (filter.isValid()) {
            dispatch(fetchVegobjekter(votState));
        }
    };

export type removeVOTFilterT = (votState: VegobjekttypeState, filter: Egenskapsfilter) => (dispatch) => void;
export const removeVOTFilter: removeVOTFilterT =
    (votState: VegobjekttypeState, filter: Egenskapsfilter) => dispatch => {
        const removeVOTFilterAction: VORemoveFilterAction = {
            type: ActionType.VEGOBJEKTER_FILTER_REMOVED,
            votTypeId: votState.typeId,
            filter,
        };
        dispatch(removeVOTFilterAction);
        if (!isOnlyEmptyFilter(votState)) {
            dispatch(fetchVegobjekter(votState));
        }
    };

// We do not want to execute a search with invalid/empty filter
function isOnlyEmptyFilter(vegobjekttype: VegobjekttypeState): boolean {
    const egenskapsfilters = vegobjekttype.filters;
    return egenskapsfilters.length === 1 && !egenskapsfilters[0].isValid();
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Vegobjekt, vegobjektVersionsComparator } from '@/domain/vegobjekter/Vegobjekt';
import { Integration } from '@/middleware/integration';
import { stateValgt, stateVegsystemreferanser, stateVegsystemreferanseSok } from '@/selectors/selectors';
import {
    MapState,
    PreferencesState,
    PREFERENCES_STORAGE_KEY,
    RootState,
    UserState,
    ValgtState,
    VegkartError,
    VegsystemreferanseLookup,
} from '@/state';
import { hashToState } from '@/state/hash/HashToState';
import { defaultColor, resolveColor } from '@/utils/ColorHelper';
import { getHash } from '@/utils/Utils';
import { Data } from './data';
import { SplashState } from '@/state/SplashState';
import { storageHasToken, SVVTOKENS_KEY } from '@/utils/AuthenticationUtils';
import { matchesLinkQuery, parseLinkQuery } from '@/middleware/fetchVegnett';
import { padVegsystemreferanse, validateVegsystemreferanse, Vegsystemreferanse } from '@/domain/omrader';
import { createVegrefParser } from '@/utils/VegrefParser';
import { isEqual } from 'lodash-es';
import { fetchVegSystemReferanse } from '@/middleware/fetchMisc';

export async function bootstrap(
    integration: Integration,
    shellState: RootState,
    data: Data
): Promise<RootState> {
    integration.setData(data);

    if (storageHasToken())
        integration.server.setSVVToken(JSON.parse(localStorage.getItem(SVVTOKENS_KEY)).idToken);

    const storedPreferences = JSON.parse(localStorage.getItem(PREFERENCES_STORAGE_KEY));

    const preferences = new PreferencesState().override(storedPreferences);
    const stateFromUrl: RootState = hashToState(
        integration,
        shellState.withPreferences(preferences),
        getHash(window.location.hash)
    );
    return bootstrapState(integration, stateFromUrl);
}

/*
 * Show main splash first time a user opens Vegkart
 */
function bootstrapSplash(splashState: SplashState) {
    const showOnStartup = localStorage.getItem('ShowSplashOnStartup') !== 'false';

    if (showOnStartup === null && (splashState === null || splashState === SplashState.NONE)) {
        localStorage.setItem('ShowSplashOnStartup', 'false');
    }
    if (showOnStartup === null && splashState === null) {
        return SplashState.MAIN;
    }
    return splashState;
}

async function bootstrapState(integration: Integration, stateFromUrl: RootState): Promise<RootState> {
    // Starts all calls asynchronously
    const selectionPromise: Promise<ValgtState> = bootstrapMapSelection(integration, stateFromUrl);
    const vsrSokPromise: Promise<VegsystemreferanseLookup> = bootstrapPopups(integration, stateFromUrl);
    const kartutsnittPromise: Promise<Kartutsnitt> = selectionPromise.then(mapSelection =>
        bootstrapZoomToExtent(integration, stateFromUrl.withValgt(mapSelection))
    );
    const vegsystemReferanser = bootstrapVegsystemreferanser(integration, stateFromUrl);
    const userState = bootstrapUserState();
    // Awaits calls to enable concurrent send-off.
    return stateFromUrl
        .withOmrader(stateFromUrl.omraderState.withVegsystemreferanser(await vegsystemReferanser))
        .withMap(stateFromUrl.mapState.withZoomToExtent(await kartutsnittPromise))
        .withValgt(await selectionPromise)
        .withOverlay(stateFromUrl.overlayState.withVegsystemreferanseLookup(await vsrSokPromise))
        .withSplash(bootstrapSplash(stateFromUrl.splashState))
        .withUser(userState);
}

function bootstrapUserState() {
    return storageHasToken() ? UserState.fromIdToken(localStorage.getItem(SVVTOKENS_KEY)) : new UserState();
}

async function bootstrapVegsystemreferanser(
    integration: Integration,
    stateFromUrl: RootState
): Promise<Vegsystemreferanse[]> {
    const urlState = stateVegsystemreferanser(stateFromUrl);
    const validVegsystemreferanser = urlState.filter(validateVegsystemreferanse);

    if (!validVegsystemreferanser.length) return urlState;

    const promises = urlState.map(vegsystemreferanse =>
        fetchVegSystemReferanse(vegsystemreferanse, integration)
    );

    return Promise.all(promises);
}

async function bootstrapMapSelection(integration: Integration, stateFromUrl: RootState): Promise<ValgtState> {
    const urlState = stateValgt(stateFromUrl);
    const sparseVegobjekt: Vegobjekt = urlState.singleVegobjekt();
    const sparseVeglenke: Vegnettlenke = urlState.singleVegnettlenke();
    const sparseLinkQueries: string[] = Object.keys(urlState.linkQueries);
    const sparseNodes = urlState.nodes.map(n => n.id);
    const timestamp = stateFromUrl.searchState.searchDropdownState.timestamp || new Date();
    let vegobjekt: any = sparseVegobjekt;
    if (sparseVegobjekt?.metadata.type.id != null) {
        vegobjekt = sparseVegobjekt
            ? [
                  await integration.server
                      .getVegobjektVersionsMinimal(sparseVegobjekt.id, sparseVegobjekt.metadata.type.id)
                      .then((vegobjekterFromServer: Vegobjekt[]) => {
                          return integration.server.getVegobjekt(
                              sparseVegobjekt.id,
                              sparseVegobjekt.metadata.type.id,
                              vegobjekterFromServer
                                  .filter(v => new Date(v.metadata.startdato) <= timestamp)
                                  .sort(vegobjektVersionsComparator)
                                  .pop()?.metadata.version
                          );
                      })
                      .then(vegobjektFromServer => {
                          return vegobjektFromServer;
                      })
                      .catch(() => {
                          return null;
                      }),
              ]
            : null;
    } else {
        vegobjekt = sparseVegobjekt
            ? [
                  await integration.server
                      .getVegobjekt(sparseVegobjekt.id)
                      .then(vegobjektFromServer => {
                          return vegobjektFromServer;
                      })
                      .catch(() => {
                          return null;
                      }),
              ]
            : null;
    }

    const veglenkesekvens: Vegnettlenke[] = sparseVeglenke
        ? await integration.server.getVeglenkesekvens(sparseVeglenke.veglenkesekvensid, true)
        : null;

    // Extra step to get specific segment of the sequence, direct API request not supported yet.
    const veglenkesegment: Vegnettlenke[] | null = veglenkesekvens
        ? veglenkesekvens
              .filter(
                  segment =>
                      segment.veglenkenummer === sparseVeglenke.veglenkenummer &&
                      (!sparseVeglenke.segmentnummer ||
                          segment.segmentnummer === sparseVeglenke.segmentnummer)
              )
              .reduce(
                  (segmentArray, currentObject) =>
                      !segmentArray.length || currentObject.segmentnummer > segmentArray[0].segmentnummer
                          ? [currentObject]
                          : segmentArray,
                  []
              )
        : null;

    const linkQueryResultSet = await Promise.all(
        sparseLinkQueries.map(async queryString => {
            const query = parseLinkQuery(queryString);
            let links = await integration.server.getVeglenkesekvens(query.id);
            links = links.filter(s => matchesLinkQuery(query, s));
            return { queryString, links };
        })
    );

    const nodeResultSet = await Promise.all(sparseNodes.map(async id => integration.server.getNode(id)));

    function resolveSelectedColor(): string {
        // if selected vegobject matches a selected vegobjekttype, use the color for that type for the objcet
        // if not, use default color
        if (!sparseVegobjekt) return defaultColor;
        const matchingType = stateFromUrl.vegobjekterState.vegobjekttypeStates.find(
            x => x.typeId === vegobjekt[0]?.metadata?.type.id
        );
        return matchingType ? resolveColor(matchingType.color) : defaultColor;
    }

    let state = ValgtState.emptySelection();
    if (vegobjekt) state = state.withVegobjekter(vegobjekt);
    if (veglenkesegment) state = state.withVegnettlenker(veglenkesegment);
    if (vegobjekt || veglenkesegment) state = state.withColor(resolveSelectedColor());
    if (nodeResultSet.length > 0) state = state.withNodes(nodeResultSet);
    if (linkQueryResultSet.length > 0) {
        const linkQueries = linkQueryResultSet.reduce(
            (queries, { queryString, links }) => ({
                ...queries,
                [queryString]: links,
            }),
            {}
        );
        state = state.withLinkQueries(linkQueries);
    }
    return state;
}

async function bootstrapPopups(
    integration: Integration,
    stateFromUrl: RootState
): Promise<VegsystemreferanseLookup> {
    const sparseVegSysRefSok: VegsystemreferanseLookup = stateVegsystemreferanseSok(stateFromUrl);
    const ref = sparseVegSysRefSok?.vegsystemreferanse;

    if (ref?.kortform) {
        const parser = createVegrefParser(integration.data.datakatalog.getTypeById(915));
        const parsed = parser(ref.kortform).withKortform(ref.kortform).withKommune(ref.kommune);
        const center = await integration.server.getVeg(padVegsystemreferanse(parsed));
        return new VegsystemreferanseLookup(
            0,
            0,
            parsed.withSenterpunkt(center),
            null,
            center,
            0,
            parsed.kommune
        );
    }
    const coordsToVegsysref: VegsystemreferanseLookup | VegkartError = sparseVegSysRefSok
        ? await integration.server.getVSRFromCoordinate([sparseVegSysRefSok.east, sparseVegSysRefSok.north])
        : null;

    return coordsToVegsysref instanceof VegsystemreferanseLookup ? coordsToVegsysref : null;
}

async function bootstrapZoomToExtent(
    integration: Integration,
    stateWithSelection: RootState
): Promise<Kartutsnitt> {
    const sparseMapState: MapState = stateWithSelection.mapState;
    const selection: ValgtState = stateWithSelection.valgtState;
    // Direct link with modified map state -> do nothing.
    if (!isEqual(sparseMapState, new MapState())) {
        return null;
    }
    // Direct link with selection -> return kartutsnitt.
    if (!selection.isEmpty()) {
        return selection.getKartutsnitt();
    }
    // Safety net. Note that the areas from url might set their own zoomToExtent in components. This is fine.
    return null;
}

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Datakatalog } from '@/bootstrap/data';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import { clearSelectedFeature } from '@/actions/valgtActions';
import { zoomToExtent } from '@/actions/mapActions';
import { addVegobjekttypeToSearch } from '@/actions/vegobjekterActions';
import { UserState, VegobjekttypeState } from '@/state';
import { getVegBilderURL } from '@/utils/VegbilderHelper';
import { stateConfig } from '@/selectors/selectors';
import { useDispatch, useSelector } from '@/state/store';
import { getVegObjektFiksURL } from '@/utils/FiksVegDataHelper';
import Vegsystemreferanser from './components/vegobjekt/Vegsystemreferanser';
import Egenskaper from './components/vegobjekt/Egenskaper';
import Kvalitet from './components/vegobjekt/Kvalitet';
import Metadata from './components/vegobjekt/Metadata';
import Relasjoner from './components/vegobjekt/Relasjoner';

interface Props {
    color: string;
    vegobjekt: Vegobjekt;
    vegobjekttyperInSearch: VegobjekttypeState[];
    datakatalog: Datakatalog;
    user: UserState;
}

export function isEmpty(value) {
    return value == null || value.length === 0;
}
function getTitle(
    vegobjekttypeIsInSearch: boolean,
    vegobjekttypeIsSensitiv: boolean,
    vegobjekttypeIsNotIndexed: boolean
): string {
    if (vegobjekttypeIsSensitiv || vegobjekttypeIsNotIndexed) {
        return 'Vegobjekttype er ikke tilgjengelig i Vegkart';
    }

    if (vegobjekttypeIsInSearch) {
        return 'Vegobjekttype er allerede i søk';
    }

    return 'Legg vegobjekttype til i søk';
}

export const VegobjektComponent = ({
    color,
    vegobjekt,
    vegobjekttyperInSearch,
    datakatalog,
    user: { roles, isLoggedIn },
}: Props) => {
    const dispatch = useDispatch();
    const config = useSelector(stateConfig);

    const vegobjekttype = useMemo(() => {
        return vegobjekt?.metadata?.type?.id ? datakatalog.getTypeById(vegobjekt.metadata.type.id) : null;
    }, [datakatalog, vegobjekt?.metadata?.type?.id]);

    const isExludedFromIndexing = useMemo(() => {
        return vegobjekttype && [521, 524, 525, 526, 562, 641, 644, 645, 793].includes(vegobjekttype.id);
    }, [vegobjekttype]);

    const vegobjekttypeIsInSearch = useCallback(() => {
        return vegobjekttyperInSearch.some(type => type.typeId === vegobjekt?.metadata?.type?.id);
    }, [vegobjekttyperInSearch, vegobjekt?.metadata?.type?.id]);

    const [error, setError] = useState(false);

    const geometriDispatch = dispatchFunction => {
        if (!vegobjekt.geometri?.wkt) {
            setError(true);
            return;
        }

        setError(false);
        dispatchFunction();
    };

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    return (
        <div className="c-valgt" tabIndex={-1} aria-live="assertive" aria-atomic="true">
            <div className="c-valgt-header" style={{ backgroundColor: color }}>
                <h3 className="c-valgt-header__title">{vegobjekt.metadata.type.navn}</h3>
                {vegobjekttype && (
                    <div className="c-valgt-tools">
                        <button
                            disabled={
                                vegobjekttypeIsInSearch() || vegobjekttype.sensitiv || isExludedFromIndexing
                            }
                            className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                            onClick={() => dispatch(addVegobjekttypeToSearch(vegobjekttype))}
                            title={getTitle(
                                vegobjekttypeIsInSearch(),
                                vegobjekttype.sensitiv,
                                isExludedFromIndexing
                            )}
                        >
                            Legg til i søk
                        </button>
                        <button
                            className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                            onClick={() =>
                                geometriDispatch(() =>
                                    dispatch(zoomToExtent(kartutsnittFromWktStrings(vegobjekt.geometri?.wkt)))
                                )
                            }
                            title={'Viser vegobjektet i kartet'}
                        >
                            Zoom til
                        </button>
                    </div>
                )}
                <button
                    onClick={() =>
                        geometriDispatch(() =>
                            window.open(getVegBilderURL(vegobjekt.geometri?.wkt), '_blank')
                        )
                    }
                    title="Vegbilder"
                    aria-label="Naviger til vegbilder"
                    className="c-valgt-header__button"
                >
                    <i className="o-icon-camera c-valgt-header__camera-icon" />
                </button>
                <button
                    onClick={() => window.open(getVegObjektFiksURL(vegobjekt, config), '_blank')}
                    title="Meld feil på vegobjekt"
                    aria-label="Naviger til fiksvegdata"
                    className="c-valgt-header__button"
                >
                    <i className="o-icon-report c-valgt-header__report-icon" />
                </button>
                <button
                    onClick={() => dispatch(clearSelectedFeature(vegobjekt?.id))}
                    title="Fjern"
                    aria-label="Lukk objektvisning"
                    className="c-valgt-header__button"
                >
                    <i className="o-icon-remove c-valgt-header__close-icon" />
                </button>
            </div>
            {error && (
                <div className="c-valgt-header__error">
                    <span>Vegobjektet mangler geometri</span>
                </div>
            )}
            <dl className="c-valgt-features">
                <Vegsystemreferanser vegobjekt={vegobjekt} />
                <Egenskaper
                    vegobjekt={vegobjekt}
                    isLoggedIn={isLoggedIn}
                    roles={roles}
                    vegobjekttype={vegobjekttype}
                />
                <Kvalitet vegobjekt={vegobjekt} datakatalog={datakatalog} />
                <Metadata vegobjekt={vegobjekt} />
                <Relasjoner vegobjekt={vegobjekt} />
            </dl>
        </div>
    );
};

import * as React from 'react';
import { connect } from 'react-redux';
import { ConfigAndDataContext } from '@/state/context/ConfigAndDataContext';
import { LoginWindowState } from '@/state';
import { RootState } from '@/state';
import * as selectors from '../../selectors/selectors';
import { changeLoginWindow } from '@/actions/loginActions';
import { LoginForm } from './LoginForm';

interface Props {
    windowOpen: LoginWindowState;
    changeLoginWindow: typeof changeLoginWindow;
    isLoggedIn: boolean;
}
class Login extends React.Component<Props, {}> {
    static contextType = ConfigAndDataContext;
    declare context: React.ContextType<typeof ConfigAndDataContext>;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="c-splash-and-logo">
                {this.props.windowOpen === LoginWindowState.LOGIN && (
                    <LoginForm
                        isLoggedIn={this.props.isLoggedIn}
                        handleWindowChange={this.props.changeLoginWindow}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: RootState) => {
    return {
        windowOpen: selectors.stateLoginWindow(state),
        isLoggedIn: selectors.stateUser(state).isLoggedIn,
    };
};
export default connect(mapStateToProps, { changeLoginWindow })(Login);

import { Map, View } from 'ol';
import { createContext, useContext } from 'react';
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import * as control from 'ol/control';
import { EPSG25833 } from '@/components/map/map-utils/Projections';
import { geodataResolutions } from '@/components/map/map-utils/WebMapTileServices';
import { MapOptions } from 'ol/Map';

export interface MapContextValues {
    map: Map;
}

export const MapContext = createContext<MapContextValues>(undefined);
export const MapProvider = MapContext.Provider;
export const MapConsumer = MapContext.Consumer;

export function createMap(center: [number, number], zoom: number): Map {
    // Empty layer to make a single static map data attribution possible
    const attributionLayer = new layer.Vector({
        source: new source.Vector({
            attributions: '© NVDB, Geovekst, kommunene og Open Street Map contributors (utenfor Norge)',
        }),
    });

    const attribution = new control.Attribution({ collapsible: false, collapsed: false });
    const scaleLineControl = new control.ScaleLine({
        units: 'metric',
        bar: true,
        text: true,
        minWidth: 140,
    });

    const options: MapOptions = {
        controls: control.defaults({ attribution: false }).extend([attribution, scaleLineControl]),
        layers: [attributionLayer],
        view: new View({
            center: [center[0], center[1]],
            extent: [-2200000, 5250000, 3300000, 8500000],
            maxZoom: 18,
            minZoom: 3,
            projection: EPSG25833,
            resolutions: geodataResolutions,
            zoom: zoom,
            constrainResolution: true,
        }),
    };

    return new Map(options);
}

export const useMap = () => useContext(MapContext);

import {
    Fylke,
    Kommune,
    Kontraktsomrade,
    Land,
    Omrade,
    Riksvegrute,
    Vegsystemreferanse,
} from '@/domain/omrader';
import { OmraderQueries } from '@/middleware/queries';
import { Polygon } from '@/domain/omrader/Polygon';

export class OmraderState {
    constructor(
        readonly land: Land[] = [],
        readonly fylke: Fylke[] = [],
        readonly kommune: Kommune[] = [],
        readonly kontraktsomrade: Kontraktsomrade[] = [],
        readonly riksvegrute: Riksvegrute[] = [],
        readonly vegsystemreferanse: Vegsystemreferanse[] = [],
        readonly polygon: Polygon = null,
        readonly loading: boolean = false
    ) {}

    override(modifyObject: { [P in keyof OmraderState]?: OmraderState[P] }): OmraderState {
        return Object.assign(Object.create(OmraderState.prototype), { ...this, ...modifyObject });
    }

    withLand(land: Land[]): OmraderState {
        return this.override({ land });
    }

    withFylker(fylke: Fylke[]): OmraderState {
        return this.override({ fylke });
    }

    withKommuner(kommune: Kommune[]): OmraderState {
        return this.override({ kommune });
    }

    withRiksvegruter(riksvegrute: Riksvegrute[]): OmraderState {
        return this.override({ riksvegrute });
    }

    withKontraktsomrader(kontraktsomrade: Kontraktsomrade[]): OmraderState {
        return this.override({ kontraktsomrade });
    }

    withVegsystemreferanser(vegsystemreferanse: Vegsystemreferanse[]): OmraderState {
        return this.override({ vegsystemreferanse });
    }

    withLoading(loading: boolean) {
        return this.override({ loading });
    }

    withPolygon(polygon: Polygon) {
        return this.override({ polygon });
    }

    getFullList(): Omrade[] {
        return this.land
            .concat(this.fylke)
            .concat(this.kommune)
            .concat(this.kontraktsomrade)
            .concat(this.riksvegrute)
            .concat(this.vegsystemreferanse)
            .concat([this.polygon]);
    }

    getGeometryFetchables(): Omrade[] {
        return this.fylke.concat(this.kommune).concat(this.kontraktsomrade).concat(this.riksvegrute);
    }

    toQueries(): OmraderQueries {
        let queries: OmraderQueries = new OmraderQueries();

        Object.keys(this)
            .filter(key => key !== 'loading' && key !== 'land' && key !== 'polygon' && key !== 'riksvegrute')
            .forEach(key => {
                queries[key] = this[key].filter(omrade => omrade.visible).map(omrade => omrade.toParams());
            });
        if (this.polygon) queries = queries.withPolygon(this.polygon.toParams());
        if (this.riksvegrute.length > 0)
            queries = queries.withRiksvegruter(this.riksvegrute.map(r => r.navn));
        return queries;
    }
}

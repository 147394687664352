import { ValgtState } from '@/state';
import { useDispatch } from '@/state/store';
import { clearSelectedCluster, selectFeature } from '@/actions/valgtActions';
import { zoomToExtent } from '@/actions/mapActions';
import { useMemo } from 'react';
import { defaultColor } from '@/utils/ColorHelper';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';

interface Props {
    colors: Record<number, string>;
    selectedFeatures: ValgtState;
}

export const ClusterComponent = ({ colors, selectedFeatures }: Props) => {
    const dispatch = useDispatch();

    const vegobjekter = useMemo(
        () => [...selectedFeatures.clusterObjekter].sort((a, b) => a.id - b.id),
        [selectedFeatures]
    );
    const isMultiTypeCluster = useMemo(
        () => [...new Set(vegobjekter.map(v => v.metadata.type.id))].length > 1,
        [vegobjekter]
    );
    const vegsystemreferanser = useMemo(
        () =>
            vegobjekter
                .map(v => ({ id: v.id, refs: v.lokasjon.vegsystemreferanser }))
                .reduce(
                    (map, { id, refs }) => ({
                        ...map,
                        [id]: [...refs].sort((a, b) => a.id.localeCompare(b.id)),
                    }),
                    {}
                ),
        [vegobjekter]
    );
    return (
        <div className={'c-valgt'}>
            {/* Header */}
            <div
                className="c-valgt-header"
                style={{ backgroundColor: colors[vegobjekter[0]?.metadata.type.id] ?? defaultColor }}
            >
                <h3 className="c-valgt-header__title">
                    {isMultiTypeCluster ? 'Vegobjekter' : vegobjekter?.[0]?.metadata.type.navn}
                </h3>
                {selectedFeatures && (
                    <div className="c-valgt-tools">
                        <button
                            className="o-btn o-btn--orange-bg o-btn--color-white c-valgt-tools__button"
                            onClick={() =>
                                dispatch(
                                    zoomToExtent(
                                        kartutsnittFromWktStrings(
                                            selectedFeatures.clusterObjekter[0]?.geometri?.wkt
                                        )
                                    )
                                )
                            }
                            title={'Zoom til objekt'}
                        >
                            Zoom til
                        </button>
                    </div>
                )}
                <button
                    title="Fjern"
                    onClick={() => dispatch(clearSelectedCluster())}
                    className="c-valgt-header__button"
                    aria-label="Lukk objektvisning"
                >
                    <i className="o-icon-remove c-valgt-header__close-icon" />
                </button>
            </div>
            <div>
                <ul className="o-list">
                    {vegobjekter.map(vegobjekt => {
                        if (vegobjekt.lokasjon.vegsystemreferanser === null) {
                            return <li key={vegobjekt.id}>Ingen vegsystemreferanser</li>;
                        } else
                            return (
                                <li
                                    className="o-list__element c-vegobjekt-cluster-list-element"
                                    key={vegobjekt.id}
                                >
                                    <span
                                        style={{ color: colors[vegobjekt.metadata.type.id] }}
                                        className={'material-icons c-vegobjekt-cluster-list-dot'}
                                    >
                                        circle
                                    </span>
                                    <a
                                        href={`#/objektid/${vegobjekt.id}`}
                                        className="o-btn-block c-vegobjekt-cluster-list-button"
                                        onClick={e => {
                                            e.preventDefault();
                                            dispatch(
                                                selectFeature(
                                                    [vegobjekt],
                                                    null,
                                                    vegobjekter,
                                                    selectedFeatures.color
                                                )
                                            );
                                        }}
                                    >
                                        <span>{vegobjekt.metadata.type.navn}</span>
                                        <span>{vegobjekt.id}</span>
                                        <div className="c-vegobjekt-cluster-nested-roadref ">
                                            {vegsystemreferanser[vegobjekt.id].map(vr => (
                                                <span key={vr.id}>{vr.toVegkartKortform()}</span>
                                            ))}
                                        </div>
                                    </a>
                                </li>
                            );
                    })}
                </ul>
            </div>
        </div>
    );
};

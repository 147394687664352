const environments = [
    { name: 'local', predicate: ({ hostname }: Location) => hostname === 'localhost' },
    { name: 'stm', predicate: ({ host }: Location) => host.includes(`-stm.utv.`) },
    { name: 'dev', predicate: ({ host }: Location) => host.includes(`.utv.`) },
    { name: 'test', predicate: ({ host }: Location) => host.includes(`.test.`) },
    { name: 'prod', predicate: () => true },
];
export function runtimeEnvironment(): string {
    const env = environments.find(e => e.predicate(window.location));
    if (!env) throw new Error('Unable to determine runtime environment');
    return env.name;
}
export type Config = { api: string; authority: string; name: string };

export const config: Config = {
    ...process.env.config?.['base'],
    ...process.env.config?.[runtimeEnvironment()],
    ...process.env.config?.['override'],
};

import * as React from 'react';
import { connect } from 'react-redux';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { VegobjekttypeCategoryState, VegobjekttypeState } from '@/state';
import { resolveColor } from '@/utils/ColorHelper';
import Accordion from '../../../objects/Accordion';
import { Loading } from '../../../objects/Loading';
import { DownloadResultsBox } from '../DownloadResultsBox';
import { RoadRefOrAreaSummary } from '../RoadRefOrAreaSummary';
import { toggleCategory, toggleCategoryT } from '@/actions/vegobjekterActions';
import { VegobjekterResult } from '@/domain/vegobjekter/VegobjekterResult';
import { HitStatistics } from '../HitStatistics';

interface TagProps {
    vegobjekttypeData: VegobjekttypeState;
    categoryState: VegobjekttypeCategoryState;
    vegobjekttype: Vegobjekttype;
    result: VegobjekterResult;
    color: string;
}

interface DispatchProps {
    doToggleCategoryVisibility: toggleCategoryT;
}

interface CategoryResultState {
    showSummary: boolean;
}

const mapDispatchToProps = {
    doToggleCategoryVisibility: toggleCategory,
};

type Props = TagProps & DispatchProps;
class CategoryResultContainer extends React.PureComponent<Props, CategoryResultState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showSummary: false,
        };
    }
    render() {
        const { vegobjekttype, categoryState, vegobjekttypeData, color, doToggleCategoryVisibility } =
            this.props;
        const isVisible = categoryState.visible && vegobjekttypeData.visible;
        const visibilityIcon = vegobjekttype.isLine() ? 'timeline' : 'room';
        return (
            <li className="c-category-result-list__element">
                <header className="c-hva-info__header">
                    <button
                        type="button"
                        className="material-icons c-category-result__visible-icon"
                        onClick={() => doToggleCategoryVisibility(vegobjekttypeData.typeId, categoryState)}
                        style={{ color: isVisible ? color : '#c6c6c6' }}
                        aria-label={`Vis ${
                            categoryState.filters[0].egenskapstype.navn
                        } ${categoryState.toString()} i kart`}
                        id={'toggle_attribute_value_' + categoryState.filters[0].verdi['id']}
                        aria-pressed={isVisible}
                    >
                        {visibilityIcon}
                    </button>
                    <h4 className="c-hva-info c-hva-info__subtitle">{categoryState.toString()}</h4>
                </header>
                <Accordion
                    className="c-what-accordion"
                    header={this.getResultHeader()}
                    ariaLabel={`Vis liste av ${vegobjekttype.navn} objekter med ${
                        categoryState.filters[0].egenskapstype.navn
                    } ${categoryState.toString()}`}
                >
                    {this.getResultContent()}
                </Accordion>
            </li>
        );
    }

    getResultHeader = () => {
        const { vegobjekttype, vegobjekttypeData, categoryState } = this.props;
        return (
            <>
                {vegobjekttypeData.loading && (
                    <Loading className="c-hva-info__category-statistics" type="text" />
                )}
                {!this.props.vegobjekttypeData.loading && (
                    <HitStatistics vegobjekttype={vegobjekttype} result={categoryState.result} />
                )}
            </>
        );
    };

    getResultContent = () => {
        const { vegobjekttype, vegobjekttypeData, categoryState } = this.props;
        const color = resolveColor(vegobjekttypeData.color);

        return (
            <div className={`c-hva-list-element__filter-and-category`}>
                <RoadRefOrAreaSummary color={color} results={this.props.result} />
                <DownloadResultsBox
                    typeId={vegobjekttype.id}
                    votState={vegobjekttypeData}
                    color={color}
                    catState={categoryState}
                />
            </div>
        );
    };
}

export const CategoryResult = connect(null, mapDispatchToProps)(CategoryResultContainer);

import { ActionType, VegobjekterAction } from '@/actions/actiontypes';
import { VegobjekterState } from '@/state';
import { Egenskapsfilter } from '@/middleware/queries';

const initialState = new VegobjekterState();

export const vegobjekterReducer = (
    vegobjekterState: VegobjekterState = initialState,
    action: VegobjekterAction
): VegobjekterState => {
    switch (action.type) {
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.vegobjekterState;
        case ActionType.HASH_CHANGED:
            return action.state.vegobjekterState;
        // Plain vegobjekttype
        case ActionType.VEGOBJEKTER_TYPE_ADDED:
            return vegobjekterState.withType(action.vegobjekttype);
        case ActionType.VEGOBJEKTER_TYPE_TOGGLED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId === action.votTypeId ? t.withVisible(!t.visible) : t
                )
            );
        case ActionType.VEGOBJEKTER_TYPE_REMOVED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.filter(t => t.typeId !== action.votTypeId)
            );

        // Categorization on vegobjekttype
        case ActionType.VEGOBJEKTER_CATEGORY_ADDED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId ? t : t.withCategory(action.egenskapstype)
                )
            );
        // TODO: Re-implement functionality in "Categorize.tsx" and remove this ActionType.
        case ActionType.IS_ADDING_CAT_TO_VOT:
            return vegobjekterState.withCategorizing(action.categorizing);
        case ActionType.VEGOBJEKTER_CATEGORY_UPDATED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId
                        ? t
                        : t
                              .withCategory(action.egenskapstype)
                              .withInterval(action.interval, action.absoluteIntervals)
                )
            );
        case ActionType.VEGOBJEKTER_CATEGORY_TOGGLED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId
                        ? t
                        : t.withCategoryStates(
                              t.categoryStates.map(c =>
                                  c.id !== action.votCategoryState.id ? c : c.withVisible(!c.visible)
                              )
                          )
                )
            );
        case ActionType.VEGOBJEKTER_CATEGORY_REMOVED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId
                        ? t
                        : t.withCategory(null).withCategoryStates([]).withLoading(true)
                )
            );

        // Filter on vegobjekttype
        case ActionType.VEGOBJEKTER_FILTER_ADDED:
            const emptyFilter = new Egenskapsfilter(null, null, null);
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId ? t : t.withFilters(t.filters.concat(emptyFilter))
                )
            );
        case ActionType.VEGOBJEKTER_FILTER_UPDATED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId
                        ? t
                        : t.withFilters(
                              t.filters.map(f => (f.uuid === action.filter.uuid ? action.filter : f))
                          )
                )
            );
        case ActionType.VEGOBJEKTER_FILTER_REMOVED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId !== action.votTypeId
                        ? t
                        : t.withFilters(t.filters.filter(f => f.uuid !== action.filter.uuid))
                )
            );

        // Fetching vegobjekter
        case ActionType.VEGOBJEKTER_FETCH_INITIATED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId === action.votTypeId ? t.withLoading(true).withError(undefined) : t
                )
            );
        case ActionType.VEGOBJEKTER_FETCH_RECEIVED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId === action.votTypeId
                        ? t
                              .withResults(action.result)
                              .withCategoryStates(action.categoryStates)
                              .withLoading(false)
                              .withError(undefined)
                        : t
                )
            );
        case ActionType.VEGOBJEKTER_FETCH_FAILED:
            return vegobjekterState.withTypes(
                vegobjekterState.vegobjekttypeStates.map(t =>
                    t.typeId == action.votTypeId ? t.withLoading(false).withError(action.error) : t
                )
            );
        case ActionType.VEGOBJEKTER_FETCH_ABORTED:
            return vegobjekterState;
        default:
            return vegobjekterState;
    }
};

import { Fylke } from '@/domain/omrader';
import { Kommune } from '@/domain/omrader';
import { Kontraktsomrade } from '@/domain/omrader';
import { Land } from '@/domain/omrader';
import { Riksvegrute } from '@/domain/omrader';
import { Vegkategori } from '@/domain/Vegkategori';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';

export class SearchResult {
    constructor(
        readonly vegobjekttyper: Vegobjekttype[] = [],
        readonly fylker: Fylke[] = [],
        readonly kommuner: Kommune[] = [],
        readonly kontraktsomrader: Kontraktsomrade[] = [],
        readonly riksvegruter: Riksvegrute[] = [],
        readonly land: Land[] = [],
        readonly vegkategorier: Vegkategori[] = []
    ) {}

    isNotEmpty(): boolean {
        return this.size() > 0;
    }

    size() {
        return (
            this.vegobjekttyper.length +
            this.fylker.length +
            this.kommuner.length +
            this.kontraktsomrader.length +
            this.riksvegruter.length +
            this.land.length +
            this.vegkategorier.length
        );
    }
}

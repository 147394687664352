import { Omrade } from './Omrade';
import { WKTObject } from '../WKTObject';

export class Vegsystemreferanse extends Omrade {
    constructor(
        readonly vegsystem: Vegsystem,
        readonly strekning: Strekning,
        readonly kryssystem: Kryssystem = null,
        readonly sideanlegg: Sideanlegg = null,
        readonly kortform: string,
        readonly kommune: number = null,
        readonly geometri: WKTObject = null
    ) {
        super(null, null, null, undefined, null);
    }

    toVegkartKortform(): string {
        return (
            (this.vegsystem ? this.vegsystem.toString() : '') +
            ' ' +
            (this.strekning ? this.strekning.toString() : '') +
            ' ' +
            (this.kryssystem ? this.kryssystem.toString() : '') +
            ' ' +
            (this.sideanlegg ? this.sideanlegg.toString() : '')
        )
            .replace(/\s+/, ' ')
            .trim();
    }

    toVegkartRedusertKortform(): string {
        return (
            (this.vegsystem ? this.vegsystem.toString() : '') +
            ' ' +
            (this.strekning ? this.strekning.toReducedString() : '') +
            ' ' +
            (this.kryssystem ? this.kryssystem.toString() : '') +
            ' ' +
            (this.sideanlegg ? this.sideanlegg.toString() : '')
        )
            .replace(/\s+/, ' ')
            .trim();
    }

    toString(): string {
        return this.kortform;
    }

    getAreaType(): string {
        return 'Vegsystemreferanse';
    }

    toParams(): string {
        return (
            (this.kommune ? this.kommune.toString().padStart(4, '0') : '') + this.kortform.replaceAll(' ', '')
        );
    }

    override(modifyObject: { [P in keyof Vegsystemreferanse]?: Vegsystemreferanse[P] }): Vegsystemreferanse {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), { ...this, ...modifyObject });
    }

    withStrekning(strekning: Strekning): Vegsystemreferanse {
        return this.override({ strekning });
    }

    withKortform(kortform: string): Vegsystemreferanse {
        return this.override({ kortform });
    }

    withSenterpunkt(senterpunkt: WKTObject): Vegsystemreferanse {
        return this.override({ senterpunkt });
    }

    withGeometri(geometri: WKTObject): Vegsystemreferanse {
        return this.override({ geometri });
    }

    withKryssystem(kryssystem: Kryssystem): Vegsystemreferanse {
        return this.override({ kryssystem });
    }

    withSideanlegg(sideanlegg: Sideanlegg): Vegsystemreferanse {
        return this.override({ sideanlegg });
    }

    withKommune(kommune: number): Vegsystemreferanse {
        return this.override({ kommune });
    }

    withVisible(visible: boolean): Vegsystemreferanse {
        return this.override({ visible });
    }

    isKPS(): boolean {
        return 'KPS'.includes(this.vegsystem?.vegkategori?.toUpperCase());
    }

    get meterAvgrensning(): Vegposisjon {
        if (this.sideanlegg?.sideanleggsdel?.startEllerPunkt) return this.sideanlegg.meterAvgrensning;
        if (this.kryssystem?.kryssdel?.startEllerPunkt) return this.kryssystem.meterAvgrensning;
        if (this.strekning) return this.strekning.meterAvgrensning;
        return null;
    }
}

export class Vegsystem {
    constructor(readonly vegkategori: string, readonly fase: string = null, readonly nummer: number = null) {}

    toString() {
        return (
            (this.vegkategori ? this.vegkategori : '') +
            (this.fase ? this.fase : '') +
            (this.nummer ? this.nummer : '')
        );
    }
}

export class Strekning {
    // noinspection NonAsciiCharacters,JSNonASCIINames
    constructor(
        readonly strekningsNummer: Vegposisjon,
        readonly delstrekningsNummer: Vegposisjon = null,
        readonly arm: boolean = null,
        readonly adskilte_løp: 'Med' | 'Mot' | 'Nei' = null,
        readonly adskilte_løp_nummer: number = null,
        readonly trafikantgruppe: string = null,
        readonly meterAvgrensning: Vegposisjon = null,
        readonly retning: 'MED' | 'MOT' = null
    ) {}

    toString(): string {
        const trafikantgruppeString = this.trafikantgruppe ? this.trafikantgruppe.toUpperCase() : '';
        const strekningString = this.strekningsNummer ? 'S' + this.strekningsNummer.toString() : '';
        const delstrekningString = this.delstrekningsNummer ? 'D' + this.delstrekningsNummer.toString() : '';
        const meterString = this.meterAvgrensning === null ? '' : 'm' + this.meterAvgrensning.toString();

        return trafikantgruppeString + ' ' + strekningString + delstrekningString + ' ' + meterString;
    }

    toReducedString(): string {
        const strekningString = this.strekningsNummer ? 'S' + this.strekningsNummer.toString() : '';
        const delstrekningString = this.delstrekningsNummer ? 'D' + this.delstrekningsNummer.toString() : '';
        const meterString = this.meterAvgrensning === null ? '' : 'm' + this.meterAvgrensning.toString();

        return strekningString + delstrekningString + ' ' + meterString;
    }
}

export class Kryssystem {
    constructor(
        readonly kryssystem: number,
        readonly kryssdel: Vegposisjon,
        readonly trafikantgruppe: string = null,
        readonly meterAvgrensning: Vegposisjon = null,
        readonly retning: 'MED' | 'MOT' = null
    ) {}

    toString(): string {
        const kryssdelString = this.kryssdel ? 'KD' + this.kryssdel : '';
        const meterString = this.meterAvgrensning === null ? '' : 'm' + this.meterAvgrensning.toString();

        return kryssdelString + ' ' + meterString;
    }
}

export class Sideanlegg {
    constructor(
        readonly sideanlegg: number,
        readonly sideanleggsdel: Vegposisjon,
        readonly trafikantgruppe: string = null,
        readonly meterAvgrensning: Vegposisjon = null,
        readonly retning: 'MED' | 'MOT' = null
    ) {}

    toString(): string {
        const sideanleggsdelString = this.sideanleggsdel ? 'SD' + this.sideanleggsdel : '';
        const meterString = this.meterAvgrensning === null ? '' : 'm' + this.meterAvgrensning.toString();

        return sideanleggsdelString + ' ' + meterString;
    }
}

export class Vegposisjon {
    constructor(readonly startEllerPunkt: number, readonly slutt: number = null) {}

    toString(): string {
        if (this.startEllerPunkt === undefined || this.startEllerPunkt === null) {
            return '';
        }

        if (this.slutt !== undefined && this.slutt !== null) {
            return this.startEllerPunkt.toFixed() + '-' + this.slutt.toFixed();
        } else {
            return this.startEllerPunkt.toFixed();
        }
    }

    compareTo(other: Vegposisjon): number {
        // Should always be set, but just in case
        if (this.startEllerPunkt === undefined || this.startEllerPunkt === null) {
            return Vegposisjon.isEmpty(other) ? 0 : -1;
        }

        if (Vegposisjon.isEmpty(other)) {
            return this.startEllerPunkt;
        } else if (this.startEllerPunkt !== other.startEllerPunkt) {
            return this.startEllerPunkt - other.startEllerPunkt;
        } else {
            if (this.slutt === null || this.slutt === undefined) {
                if (other.slutt === null || other.slutt === undefined) {
                    return 0;
                } else {
                    return other.slutt - other.startEllerPunkt;
                }
            } else {
                if (other.slutt === null || other.slutt === undefined) {
                    return this.slutt - this.startEllerPunkt;
                } else {
                    return this.slutt - other.slutt;
                }
            }
        }
    }

    private static isEmpty(pos: Vegposisjon): boolean {
        return (
            pos === undefined ||
            pos === null ||
            pos.startEllerPunkt === undefined ||
            pos.startEllerPunkt === null
        );
    }
}

// noinspection DuplicatedCode
export function vegSysRefComparator(vegSysRefA: Vegsystemreferanse, vegSysRefB: Vegsystemreferanse) {
    if (!vegSysRefA || !vegSysRefB || vegSysRefA === vegSysRefB) return 0;

    const vegkategoriCompared = vegSysRefA.vegsystem?.vegkategori?.localeCompare(
        vegSysRefB?.vegsystem?.vegkategori
    );
    const faseCompared = vegSysRefA.vegsystem?.fase?.localeCompare(vegSysRefB.vegsystem?.fase);
    const vegnummerCompared = vegSysRefA.vegsystem?.nummer - vegSysRefB.vegsystem?.nummer;
    const strekningCompared = vegSysRefA.strekning?.strekningsNummer?.compareTo(
        vegSysRefB.strekning?.strekningsNummer
    );
    const delstrekningCompared = vegSysRefA.strekning?.delstrekningsNummer?.compareTo(
        vegSysRefB.strekning?.delstrekningsNummer
    );
    const strekningAvgrensningCompared = vegSysRefA.strekning?.meterAvgrensning?.compareTo(
        vegSysRefB.strekning?.meterAvgrensning
    );
    const kryssystemCompared = vegSysRefA.kryssystem?.kryssystem - vegSysRefB.kryssystem?.kryssystem;
    const kryssdelCompared = vegSysRefA.kryssystem?.kryssdel?.compareTo(vegSysRefB.kryssystem?.kryssdel);
    const kryssystemAvgrensningCompared = vegSysRefA.kryssystem?.meterAvgrensning?.compareTo(
        vegSysRefB.kryssystem?.meterAvgrensning
    );
    const sideanleggCompared = vegSysRefA.sideanlegg?.sideanlegg - vegSysRefB.sideanlegg?.sideanlegg;
    const sideanleggsdelCompared = vegSysRefA.sideanlegg?.sideanleggsdel?.compareTo(
        vegSysRefB.sideanlegg?.sideanleggsdel
    );
    const sideanleggAvgrensningCompared = vegSysRefA.sideanlegg?.meterAvgrensning?.compareTo(
        vegSysRefB.sideanlegg?.meterAvgrensning
    );

    if (vegkategoriCompared && vegkategoriCompared !== 0) return vegkategoriCompared;
    if (faseCompared && faseCompared !== 0) return faseCompared;
    if (vegnummerCompared && vegnummerCompared !== 0) return vegnummerCompared;
    if (strekningCompared && strekningCompared !== 0) return strekningCompared;
    if (delstrekningCompared && delstrekningCompared !== 0) return delstrekningCompared;
    if (strekningAvgrensningCompared && strekningAvgrensningCompared !== 0)
        return strekningAvgrensningCompared;
    if (kryssystemCompared && kryssystemCompared !== 0) return kryssystemCompared;
    if (kryssdelCompared && kryssdelCompared !== 0) return kryssdelCompared;
    if (kryssystemAvgrensningCompared && kryssystemAvgrensningCompared !== 0)
        return kryssystemAvgrensningCompared;
    if (sideanleggCompared && sideanleggCompared !== 0) return sideanleggCompared;
    if (sideanleggsdelCompared && sideanleggsdelCompared !== 0) return sideanleggsdelCompared;
    if (sideanleggAvgrensningCompared && sideanleggAvgrensningCompared !== 0)
        return sideanleggAvgrensningCompared;

    return 0;
}

export const validateVegsystemreferanse = (vegsystemreferanse: Vegsystemreferanse): boolean => {
    // NVDB-9398: KSP Roads should pad S1 if needed.
    if (vegsystemreferanse?.isKPS())
        return Boolean(
            vegsystemreferanse.kommune &&
                vegsystemreferanse.vegsystem?.nummer &&
                vegsystemreferanse.vegsystem?.vegkategori
        );
    else return Boolean(vegsystemreferanse?.vegsystem?.nummer && vegsystemreferanse?.vegsystem?.vegkategori);
};

export const padVegsystemreferanse = (vegsystemreferanse: Vegsystemreferanse) => {
    const { strekning, sideanlegg, kryssystem } = vegsystemreferanse;
    let newVsr = vegsystemreferanse.withStrekning(
        new Strekning(
            new Vegposisjon(strekning?.strekningsNummer?.startEllerPunkt ?? 1),
            new Vegposisjon(strekning?.delstrekningsNummer?.startEllerPunkt ?? 1),
            strekning?.arm,
            strekning?.adskilte_løp,
            strekning?.adskilte_løp_nummer,
            strekning?.trafikantgruppe,
            new Vegposisjon(strekning?.meterAvgrensning?.startEllerPunkt ?? 0),
            strekning?.retning
        )
    );
    if (sideanlegg)
        newVsr = newVsr.withSideanlegg(
            new Sideanlegg(
                sideanlegg.sideanlegg,
                new Vegposisjon(sideanlegg.sideanleggsdel?.startEllerPunkt ?? 1),
                sideanlegg.trafikantgruppe,
                new Vegposisjon(sideanlegg.meterAvgrensning?.startEllerPunkt ?? 1),
                sideanlegg.retning
            )
        );
    if (kryssystem)
        newVsr = newVsr.withKryssystem(
            new Kryssystem(
                kryssystem.kryssystem,
                new Vegposisjon(kryssystem.kryssdel?.startEllerPunkt ?? 1),
                kryssystem.trafikantgruppe,
                new Vegposisjon(kryssystem.meterAvgrensning?.startEllerPunkt ?? 1),
                kryssystem.retning
            )
        );
    return newVsr.withKortform(newVsr.toVegkartKortform());
};

import React, { ForwardedRef, forwardRef } from 'react';
import { SplashState } from '@/state/SplashState';

interface PrivacyPolicyProps {
    handleWindowChange: (window: SplashState) => void;
}

export const PrivacyPolicy = forwardRef(
    ({ handleWindowChange }: PrivacyPolicyProps, ref: ForwardedRef<HTMLDialogElement>) => {
        PrivacyPolicy.displayName = 'PrivacyPolicy';
        return (
            <dialog
                className={'c-policy-container'}
                ref={ref}
                aria-labelledby="privacypolicy-header"
                aria-modal="true"
            >
                <header className="c-policy-header">
                    <div className="c-policy-header__text" id="privacypolicy-header">
                        Personvernerklæring
                    </div>
                    <div className="c-policy-header__close">
                        <button
                            type="button"
                            title="Tilbake"
                            className="o-btn o-icon-arrow-back--white c-policy-header__close-image"
                            onClick={() => handleWindowChange(SplashState.MAIN)}
                            aria-label="Gå tilbake"
                        />
                    </div>
                </header>
                <main className="c-policy-main">
                    <h2 className="c-policy-main__title">Hvilke brukerdata lagrer vi?</h2>
                    <h3 className="c-policy-main__title">Sensitive opplysninger</h3>
                    <p>Vegkart hverken lagrer eller sender sensitive opplysninger om brukeren.</p>
                    <h3 className="c-policy-main__title">Cookies</h3>
                    <ul>
                        <li className="c-policy-main__list-item">
                            For hver gang man bruker applikasjonen blir det opprettet en unik og tilfeldig
                            generert &nbsp;sesjons-id som benyttes i logging av Vegkart, og ved forespørsler
                            til NVDB-API-et. &nbsp;Denne slettes når brukeren lukker nettleseren.
                        </li>
                        <li className="c-policy-main__list-item">
                            Dersom brukeren huker av på velkomstdialogens &quot;Ikke vis ved
                            oppstart&quot;-felt&nbsp;vil dette valget lagres. Brukeren kan selv slette denne
                            innstillingen ved å avhuke valget.
                        </li>
                    </ul>
                    <h2 className="c-policy-main__title">Endringer</h2>
                    <p>
                        NVDB-teamet går gjennom denne personvernerklæringen jevnlig. Siste oppdatering skjedde
                        <span className="c-policy-main__bold"> 20.01.2020</span>.
                    </p>
                </main>
                <footer className="c-policy-footer">
                    <hr />
                    <p className="c-policy-footer__text">
                        Ved spørsmål angående personvern, ta kontakt på: &nbsp;
                        <a href="mailto:vegkart-support@vegvesen.no">vegkart-support@vegvesen.no</a>.
                    </p>
                </footer>
            </dialog>
        );
    }
);

import { JspVersion } from '@/state/context/ConfigAndDataContext';

export class Config {
    constructor(
        readonly fiksvegdata_url: string,
        readonly changelog_url: string,
        readonly eksport_url: string,
        readonly maximum_number_of_objects_line: number,
        readonly cluster_threshold_zoom_level_16: number,
        readonly kartdata2_layer: string,
        readonly kartdata2_style: string,
        readonly kartdata2_matrixset: string,
        readonly kartdata2_projection: string,
        readonly url_kartdata2: string[],
        readonly grayscale_layer: string,
        readonly grayscale_style: string,
        readonly grayscale_matrixset: string,
        readonly grayscale_projection: string,
        readonly url_grayscale: string[],
        readonly cluster_distance: number,
        readonly url_basemap: string,
        readonly restapi_url: string,
        readonly restapi_url_v3: string,
        readonly zoom_level_detail_break: number,
        readonly cluster_threshold: number,
        readonly url_nib: string[],
        readonly maximum_number_of_objects_point: number,
        readonly nib_layer: string,
        readonly nib_style: string,
        readonly nib_matrixset: string,
        readonly cluster_distance_zoom_level_16: number,
        readonly vegref_format_url,
        readonly version: JspVersion,
        readonly splunk_token: string = null,
        readonly splunk_url: string = null,
        readonly splunk_index: string = null,
        readonly baatDispenser: string = null,
        readonly url_matrikkelen = null,
        readonly status: { url: string; api: string; les: string; vegkart: string },
        readonly url_brukAvData: string = 'http://www.vegdata.no/bruk-av-data/',
        readonly url_vegdataVegkart: string = 'http://www.vegdata.no/vegkart/',
        readonly url_informasjon: string = 'http://www.vegdata.no/vegkart/',
        readonly url_brukerveiledning: string = 'http://www.vegdata.no/vegkart/brukerveiledning/',
        readonly url_varBlog: string = 'http://vegdata.no/',
        readonly url_SSRI: string = 'https://ws.geonorge.no/stedsnavn/v1/',
        readonly testMode: boolean = true,
        readonly doBlur: boolean = true,
        readonly logDebug: boolean = false,
        readonly ajaxTimeout: number = 30000,
        readonly ventetidImsForBrukerKanAvbryteForesporsler: number = 3000,
        readonly searchDelay: number = 500,
        readonly antallResultater: number = 1000,
        readonly minZoomLevel: number = 3,
        readonly maxZoomLevelRegion: number = 4,
        readonly maxZoomLevelCounty: number = 6,
        readonly maxNumberOfRoadrefsShown: number = 5,
        readonly geometritoleranse: number = 10
    ) {}
}

export function parseConfig(version, config): Config {
    return new Config(
        config.fiksvegdata_url,
        config.changelog_url,
        config.eksport_url,
        parseInt(config.maximum_number_of_objects_line, 10),
        parseInt(config.cluster_threshold_zoom_level_16, 10),
        config.kartdata2_layer,
        config.kartdata2_style,
        config.kartdata2_matrixset,
        config.kartdata2_projection,
        Array.isArray(config.url_kartdata2) ? config.url_kartdata2 : [config.url_kartdata2],
        config.grayscale_layer,
        config.grayscale_style,
        config.grayscale_matrixset,
        config.grayscale_projection,
        Array.isArray(config.url_grayscale) ? config.url_grayscale : [config.url_grayscale],
        parseInt(config.cluster_distance, 10),
        config.url_basemap,
        config.restapi_url,
        config.restapi_url_v3,
        parseInt(config.zoom_level_detail_break, 10),
        parseInt(config.cluster_threshold, 10),
        Array.isArray(config.url_nib) ? config.url_nib : [config.url_nib],
        parseInt(config.maximum_number_of_objects_point, 10),
        config.nib_layer,
        config.nib_style,
        config.nib_matrixset,
        parseInt(config.cluster_distance_zoom_level_16, 10),
        config.vegref_format_url,
        version,
        config.splunk_token,
        config.splunk_url,
        config.splunk_index,
        config.baat_dispenser_url,
        config.url_matrikkelen,
        config.status
    );
}

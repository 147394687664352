import { isArray } from 'lodash-es';
import React from 'react';
import { handleError } from '@/actions/metaActions';
import { LoginWindowState, Severity, VegkartError } from '@/state';
import { userLogin, userLogout } from '@/actions/authActions';
import { trapFocus } from '@/utils/Utils';
import { useDispatch, useSelector } from '@/state/store';
import { getClientSessionId, getHeaders } from '@/server/server';

interface LoginFormProps {
    handleWindowChange: (window: LoginWindowState) => void;
    isLoggedIn: boolean;
}

export const LoginForm = ({ handleWindowChange, isLoggedIn }: LoginFormProps) => {
    const usernameRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const dialogRef = React.useRef<HTMLDialogElement>(null);
    const dispatch = useDispatch();
    const config = useSelector(s => s.config);
    const listener = e => {
        if (e.key === 'Escape') {
            handleWindowChange(LoginWindowState.NONE);
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', listener);
        const dialog = dialogRef.current;
        dialog?.showModal();
        trapFocus(dialog);
        return () => {
            dialog?.close();
            document.removeEventListener('keydown', listener);
        };
    });

    React.useEffect(() => {
        usernameRef.current?.focus();
    }, []);

    const onLogin = e => {
        e.preventDefault();
        const headers = getHeaders(getClientSessionId());
        fetch(config.restapi_url + '/auth/login', {
            headers: { ...headers, 'Content-Type': 'application/json', Accept: 'application/json' },
            method: 'post',
            body: JSON.stringify({
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                user_type: 'external',
            }),
        })
            .then(response => response.json())
            .then(tokens => {
                if (!isArray(tokens)) {
                    handleWindowChange(LoginWindowState.NONE);
                    dispatch(userLogin(tokens));
                } else {
                    dispatch(
                        handleError(
                            new VegkartError(
                                'Feil ved innlogging. Brukernavn eller passord er feil',
                                {},
                                Severity.INFO
                            )
                        )
                    );
                }
            });
    };

    const onLogout = () => {
        handleWindowChange(LoginWindowState.NONE);
        dispatch(userLogout());
    };

    return (
        <dialog
            className="c-splash c-splash-container"
            aria-expanded="true"
            aria-label={'Skjema for innlogging'}
            aria-describedby={'login-header'}
            aria-modal="true"
            ref={dialogRef}
        >
            <header className="c-splash-header">
                <div id="login-header" className="c-splash-header__text">
                    Tilgang til
                    <span className="c-splash-header__text--highlighted"> sensitive data </span>
                </div>
                <button
                    type="button"
                    title="lukk"
                    onClick={() => handleWindowChange(LoginWindowState.NONE)}
                    className="o-btn o-img-text-close c-splash-header__close"
                    aria-label="Lukk dialog"
                />
            </header>
            {!isLoggedIn ? (
                <main className="c-splash-main">
                    <p className="c-splash-main__paragraph">
                        Logg inn for å se skjermede data i Vegkart. Tilgang til skjermede data gis bare til
                        personer med dokumentert faglig behov. Innlogget bruker er selv ansvarlig for at
                        sensitiv informasjon ikke havner på avveie. Logg inn med 6-bokstav/8-tegn brukerident
                        fra Statens vegvesen:
                    </p>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        onSubmit={onLogin}
                    >
                        <div className={'c-search'} style={{ marginLeft: '0' }}>
                            <div className={'c-search-interact'} role={'searchbox'}>
                                <i
                                    className="o-icon-user c-search-interact__search-icon"
                                    aria-hidden={true}
                                />
                                <input
                                    aria-label={'Brukernavn'}
                                    id="usernameText"
                                    ref={usernameRef}
                                    className="c-search-interact__input"
                                    placeholder="Brukernavn"
                                    autoComplete="off"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={'c-search'} style={{ marginLeft: '0' }}>
                            <div className={'c-search-interact'}>
                                <i
                                    className="o-icon-lock c-search-interact__search-icon"
                                    aria-hidden={true}
                                />
                                <input
                                    id="passwordText"
                                    ref={passwordRef}
                                    className="c-search-interact__input"
                                    placeholder="Passord"
                                    type="password"
                                />
                            </div>
                        </div>
                        <button id="loginButton" type={'submit'} onClick={onLogin} className={'dark-button'}>
                            Logg inn
                        </button>
                    </form>
                </main>
            ) : (
                <main className="c-splash-main">
                    <p className="c-splash-main__paragraph">
                        Du er allerede logget inn. Ønsker du å logge ut?
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button id="loginButton" type={'submit'} onClick={onLogout} className={'dark-button'}>
                            Logg ut
                        </button>
                    </div>
                </main>
            )}

            <footer className="c-splash-footer">
                <hr />
                <div className="c-splash-footer-interactive">
                    <a
                        className="c-splash-footer-interactive__item"
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://nvdb.atlas.vegvesen.no/docs/data-i-nvdb/skjerma-data"
                    >
                        Be om tilgang
                    </a>
                </div>
            </footer>
        </dialog>
    );
};

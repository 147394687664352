import * as React from 'react';
import { useDispatch, useSelector } from '@/state/store';
import { ErrorContainer } from './ErrorContainer';
import { dismissError } from '@/actions/metaActions';
import { stateErrors } from '@/selectors/selectors';
import { useCallback } from 'react';
import { VegkartError } from '@/state';

const Errors = () => {
    const dispatch = useDispatch();
    const errors = useSelector(stateErrors);
    const dismiss = useCallback((e: VegkartError) => dispatch(dismissError(e)), [dispatch]);
    return (
        <div className="c-errors">
            {errors.map(e => (
                <ErrorContainer error={e} key={e.uuid} dismiss={dismiss} />
            ))}
        </div>
    );
};
export default Errors;

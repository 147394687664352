import { Kontraktsomrade, Riksvegrute } from '@/domain/omrader';
import {
    VegobjektLokasjon,
    Relasjonstype,
    Vegobjekt,
    VegobjektEgenskap,
    VegobjektEgenskapEnhet,
    VegobjektMetadata,
    VegobjektRelasjon,
    VegobjektRelasjoner,
    Vegsegment,
} from '@/domain/vegobjekter/Vegobjekt';
import { VegobjektResponse, VegobjektResponseMetadata } from '../responses';
import { transformGeometri, transformKvalitet } from './geometri';
import { transformVegobjektStedfesting } from './stedfesting';
import { transformVegsystemreferanse } from './vegsystemreferanse';
import { Detaljniva, DetaljnivaAdditional } from '@/middleware/queries';

export function transformVegobjektResponse(data: unknown): VegobjektResponse {
    return new VegobjektResponse(
        data['objekter'].map(transformVegobjekt),
        new VegobjektResponseMetadata(data['metadata'].returnert, data['metadata'].neste)
    );
}

export function transformVegobjektArray(objekter: unknown): Vegobjekt[] {
    return (objekter as []).map(transformVegobjekt);
}

export function transformVegobjekt(data: unknown): Vegobjekt {
    if (typeof data === 'number') {
        return Vegobjekt.dummy(data, null);
    }

    return new Vegobjekt(
        data['id'],
        data['href'],
        transformMetadata(data['metadata']),
        transformEgenskaper(data['egenskaper']),
        transformGeometri(data['geometri']),
        transformLokasjon(data['lokasjon']),
        transformRelasjoner(data['relasjoner']),
        transformSegmenter(data['vegsegmenter'])
    );
}

function transformMetadata(metadata: unknown): VegobjektMetadata {
    return metadata
        ? new VegobjektMetadata(
              metadata['type'],
              metadata['versjon'],
              metadata['startdato'],
              metadata['sist_modifisert'],
              metadata['sluttdato']
          )
        : null;
}

function transformEgenskaper(egenskaper: unknown[]): VegobjektEgenskap[] {
    return egenskaper
        ? egenskaper.map(
              e =>
                  new VegobjektEgenskap(
                      e['id'],
                      e['navn'],
                      e['egenskapstype'],
                      e['datatype'],
                      e['stedfestingstype'],
                      e['verdi'],
                      e['enum_id'],
                      e['href'],
                      e['verifiseringsdato'],
                      e['datafangstdato'],
                      e['kvalitet'] ? transformKvalitet(e['kvalitet']) : null,
                      e['enhet']
                          ? new VegobjektEgenskapEnhet(e['enhet'].id, e['enhet'].navn, e['enhet'].kortnavn)
                          : null
                  )
          )
        : null;
}

function transformLokasjon(lokasjon: unknown): VegobjektLokasjon {
    return lokasjon
        ? new VegobjektLokasjon(
              lokasjon['kommuner'],
              lokasjon['fylker'],
              lokasjon['kontraktsområder']
                  ? lokasjon['kontraktsområder'].map(ko => new Kontraktsomrade(ko.navn, ko.type, ko.nummer))
                  : [],
              lokasjon['riksvegruter']
                  ? lokasjon['riksvegruter'].map(
                        r => new Riksvegrute(r.navn, r.beskrivelse, r.nummer, r.periode, r.vegobjekt)
                    )
                  : [],
              lokasjon['vegsystemreferanser']
                  ? lokasjon['vegsystemreferanser'].map(transformVegsystemreferanse)
                  : [],
              lokasjon['stedfestinger'] ? lokasjon['stedfestinger'].map(transformVegobjektStedfesting) : [],
              transformGeometri(lokasjon['geometri']),
              lokasjon['lengde']
          )
        : null;
}

function transformRelasjoner(relasjoner: unknown): VegobjektRelasjoner {
    return relasjoner
        ? new VegobjektRelasjoner(
              relasjoner['barn']
                  ? relasjoner['barn'].map(
                        b =>
                            new VegobjektRelasjon(
                                b.type,
                                b.vegobjekter.map(transformVegobjekt),
                                Relasjonstype.Datter
                            )
                    )
                  : [],
              relasjoner['foreldre']
                  ? relasjoner['foreldre'].map(
                        f =>
                            new VegobjektRelasjon(
                                f.type,
                                f.vegobjekter.map(transformVegobjekt),
                                Relasjonstype.Mor
                            )
                    )
                  : []
          )
        : null;
}
type SosiDetaljniva = 'Kjørebane' | 'Kjørefelt' | 'Vegtrase' | 'Vegtrase og kjørebane';

function transformDetaljniva(detaljniva: SosiDetaljniva): Detaljniva | DetaljnivaAdditional {
    switch (detaljniva) {
        case 'Kjørebane':
            return Detaljniva.KJOREBANE;
        case 'Kjørefelt':
            return Detaljniva.KJOREFELT;
        case 'Vegtrase':
            return Detaljniva.VEGTRASE;
        case 'Vegtrase og kjørebane':
            return DetaljnivaAdditional.VEGTRASE_OG_KJOREBANE;
        default:
            return null;
    }
}
function transformSegment(segment: unknown): Vegsegment {
    return segment
        ? new Vegsegment(
              segment['veglenkesekvensid'],
              segment['startposisjon'],
              segment['sluttposisjon'],
              transformDetaljniva(segment['detaljnivå']),
              transformGeometri(segment['geometri']),
              segment['feltoversikt'] ?? []
          )
        : null;
}

function transformSegmenter(segmenter: unknown[]): Vegsegment[] {
    if (!segmenter || segmenter.length == 0) return [];
    return segmenter.map(transformSegment);
}

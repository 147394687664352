import { RootState, SearchDropdownState } from '@/state';
import {
    ActionType,
    GetState,
    SelectedDataKatVOTAction,
    SetDropdownAction,
    SetFilterPanelAction,
    SetSearchPanelAction,
    SetSearchTimestampAction,
} from './actiontypes';
import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { stateDatakatalogVegobjekttypeSelected } from '@/selectors/selectors';
import { fetchVegobjektType } from '@/middleware/fetchVegobjekter';
import { Integration } from '@/middleware/integration';
import { clearPlaceNames, fetchPlaceNames, loopupCoordinateForRoadref } from '@/middleware/fetchMisc';
import { Vegsystemreferanse } from '@/domain/omrader';
import { AppDispatch } from '@/state/store';

// RETHINK: Check if the actions in this section are necessary.

export type searchFieldUpdatedT = (
    searchString: string
) => (dispatch: AppDispatch, getState: () => RootState) => void;
export const searchFieldUpdated: searchFieldUpdatedT = (searchString: string) => (dispatch, getState) => {
    const term = searchString.trim().replace(/[;?"']/g, '');
    const onlyNumbers = term.match(/^[\d.-@]+$/);
    if (!onlyNumbers) {
        if (term.length > 2) dispatch(fetchPlaceNames(term));
        else if (getState().searchState.placeNames?.hits > 0) dispatch(clearPlaceNames());
    }
};

export type setSearchTimestampT = (date: Date) => (dispatch) => void;
export const setSearchTimestamp: setSearchTimestampT = date => dispatch => {
    const setDropdownAction: SetSearchTimestampAction = {
        type: ActionType.SEARCH_TIMESTAMP_SET,
        date,
    };
    dispatch(setDropdownAction);
};
export type setDropdownStateT = (searchDropdownState: SearchDropdownState) => (dispatch) => void;
export const setDropdownState: setDropdownStateT = (searchDropdownState: SearchDropdownState) => dispatch => {
    const setDropdownAction: SetDropdownAction = {
        type: ActionType.SEARCH_DROPDOWN_SET,
        searchDropdownState,
    };
    dispatch(setDropdownAction);
};

export type highlightRoadsystemReferenceT = (
    vegsystemreferanse: Vegsystemreferanse,
    kommune: number
) => (dispatch) => void;
export const highlightRoadsystemReference: highlightRoadsystemReferenceT =
    (vegsystemreferanse: Vegsystemreferanse, kommune: number) => dispatch => {
        dispatch(loopupCoordinateForRoadref(vegsystemreferanse, kommune));
    };

export type vegobjekttypeSelectedInDatakatalogT = (
    vegobjekttype: Vegobjekttype
) => (dispatch, getState: () => RootState, integration: Integration) => void;
export const vegobjekttypeSelectedInDatakatalog: vegobjekttypeSelectedInDatakatalogT =
    (vegobjekttype: Vegobjekttype) => (dispatch, getState: GetState) => {
        const lastVOTSelections: Vegobjekttype[] = stateDatakatalogVegobjekttypeSelected(getState());

        if (lastVOTSelections && lastVOTSelections[lastVOTSelections.length - 1] !== vegobjekttype) {
            const selectedVOTHistory = lastVOTSelections.slice(0);
            if (vegobjekttype !== null) {
                selectedVOTHistory[selectedVOTHistory.length] = vegobjekttype;
                const selectedDataKatVOTAction: SelectedDataKatVOTAction = {
                    type: ActionType.DATAKATALOG_VEGOBJEKTTYPE_SELECTED,
                    selectedVOTHistory,
                };
                dispatch(selectedDataKatVOTAction);
            }
        }
        if (vegobjekttype !== null) {
            dispatch(fetchVegobjektType(vegobjekttype.id));
        }
    };

export type backtrackVegobjekttypeSelectedInDatakatalogT = (
    backtrackAll?: boolean
) => (dispatch, getState: () => RootState, integration: Integration) => void;
export const backtrackVegobjekttypeSelectedInDatakatalog: backtrackVegobjekttypeSelectedInDatakatalogT =
    (backtrackAll = false) =>
    (dispatch, getState: GetState) => {
        const lastVOTSelections: Vegobjekttype[] = stateDatakatalogVegobjekttypeSelected(getState());
        const selectedVOTHistory =
            backtrackAll && lastVOTSelections ? [] : lastVOTSelections.slice(0, lastVOTSelections.length - 1);
        const backtrackDataKatVOTAction: SelectedDataKatVOTAction = {
            type: ActionType.DATAKATALOG_VEGOBJEKTTYPE_SELECTED,
            selectedVOTHistory,
        };
        dispatch(backtrackDataKatVOTAction);
    };

/* Mobile navigation */

// RETHINK: Can these manage "open" in local state instead?
export type setFilterPanelOpenT = (open: boolean) => (dispatch) => void;
export const setFilterPanelOpen: setFilterPanelOpenT = (open: boolean) => dispatch => {
    const filterPanelAction: SetFilterPanelAction = {
        type: ActionType.MOBILE_FILTER_OPEN_SET,
        filterPanelOpen: open,
    };
    dispatch(filterPanelAction);
};
export type setSearchPanelOpenT = (open: boolean) => (dispatch) => void;
export const setSearchPanelOpen: setSearchPanelOpenT = (open: boolean) => dispatch => {
    const searchPanelAction: SetSearchPanelAction = {
        type: ActionType.MOBILE_SEARCH_OPEN_SET,
        searchPanelOpen: open,
    };
    dispatch(searchPanelAction);
};

import * as style from 'ol/style';

export const userPositionStyle = new style.Style({
    image: new style.Circle({
        fill: new style.Fill({
            color: '#3399CC',
        }),
        radius: 6,
        stroke: new style.Stroke({
            color: '#fff',
            width: 2,
        }),
    }),
});

export const userPositionDirectionalStyle = new style.Style({
    image: new style.Icon({
        // required for IE 11
        anchor: [0.5, 0.5],
        imgSize: [32, 58],
        scale: 0.5,
        src: 'img/gfx/svg/heading-icon.svg',
    }),
});

import { OmraderQueries } from './queries';
import { Fylke } from '@/domain/omrader';

/**
 * Get active fylker in a query. Defaults to all fylker if no fylke is set in query.
 *
 * @param omraderQueries Query for områder
 * @param alleFylker List of all valid fylker
 */
export function getFylkerForQuery(omraderQueries: OmraderQueries, alleFylker: Fylke[]): Fylke[] {
    if (omraderQueries.fylke?.length > 0) {
        // May return an empty array if there is non-valid fylker in omraderQueries, which corresponds
        // to no result if querying for an invalid fylke number
        return omraderQueries.fylke
            .map(fylkeNo => alleFylker.find(f => f.nummer.toString() === fylkeNo))
            .filter(f => f !== undefined);
    } else {
        return alleFylker;
    }
}

import React, { createContext, ReactNode, useMemo } from 'react';
import { Config } from '@/bootstrap/config';
import { Data } from '@/bootstrap/data';
import { Integration } from '@/middleware/integration';

export interface JspVersion {
    hash: string;
    version: string;
}

export interface ConfigAndData {
    config: Config;
    data: Data;
}

export const ConfigAndDataContext = createContext<ConfigAndData>(undefined);
// export const ConfigAndDataProvider = ConfigAndDataContext.Provider;

interface ConfigAndDataProviderProps {
    children: ReactNode;
    integration: Integration;
}

export const ConfigAndDataProvider = ({ integration, children }: ConfigAndDataProviderProps) => {
    const value = useMemo(() => integration.getConfigAndData(), [integration]);
    return <ConfigAndDataContext.Provider value={value}>{children}</ConfigAndDataContext.Provider>;
};

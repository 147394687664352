import { ActionType, UserAction } from '@/actions/actiontypes';
import { UserState } from '@/state';

const initialState: UserState = new UserState();

export const userReducer = (state: UserState = initialState, action: UserAction) => {
    switch (action.type) {
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.userState;
        case ActionType.LOGIN:
            return UserState.fromIdToken(action.idToken);
        case ActionType.LOGOUT:
            return new UserState();
        default:
            return state;
    }
};

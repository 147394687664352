import { SearchDropdownState, SearchState } from '@/state';
import { ActionType, SearchAction } from '@/actions/actiontypes';
import { StedsnavnResponse } from '@/server/responses';

const initialState = new SearchState();

export const searchReducer = (searchState: SearchState = initialState, action: SearchAction) => {
    switch (action.type) {
        // Start-up & hash
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.searchState;
        case ActionType.HASH_CHANGED:
            return action.state.searchState;
        // Search
        case ActionType.SEARCH_DROPDOWN_SET:
            if (action.searchDropdownState.searchText.length === 0) {
                return searchState
                    .withPlaceNames(new StedsnavnResponse(0, null))
                    .withDropdownState(action.searchDropdownState);
            }
            return searchState.withDropdownState(action.searchDropdownState);
        case ActionType.SEARCH_TIMESTAMP_SET:
            return searchState.withDropdownState(
                searchState.searchDropdownState?.withTimestamp(action.date) ?? new SearchDropdownState()
            );
        case ActionType.SEARCH_STEDSNAVN_RECEIVED:
            return searchState.withPlaceNames(action.stedsnavn);
        case ActionType.SEARCH_STEDSNAVN_CLEARED:
            return searchState.withPlaceNames({ placeNames: [], hits: 0 });
        case ActionType.DATAKATALOG_VEGOBJEKTTYPE_SELECTED:
            return searchState.withSelectedVegobjekttypeDatakatalog(action.selectedVOTHistory);
        case ActionType.DATAKATALOG_VEGOBJEKTTYPE_RECEIVED:
            // Test for current - if not discard
            return searchState.withSelectedVegobjekttypeDatakatalog(
                searchState.selectedVegobjekttypeDatakatalog.map(e =>
                    e.id === action.vegobjekttype.id ? action.vegobjekttype : e
                )
            );
        case ActionType.VEGOBJEKTER_TYPE_ADDED:
            return searchState.withSelectedVegobjekttypeDatakatalog(null);

        // Mobile
        case ActionType.MOBILE_FILTER_OPEN_SET:
            return searchState.withFilterPanelOpen(action.filterPanelOpen);
        case ActionType.MOBILE_SEARCH_OPEN_SET:
            return searchState.withSearchPanelOpen(action.searchPanelOpen);
        default:
            return searchState;
    }
};

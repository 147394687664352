import { WKTObject } from '../WKTObject';
import { Omrade } from './Omrade';
import { Vegobjekt } from '../vegobjekter/Vegobjekt';

export class Kommune extends Omrade {
    static dummy(nummer: number): Kommune {
        return new Kommune('', nummer, null, null, null, Vegobjekt.dummy(null, null));
    }
    constructor(
        navn: string,
        nummer: number,
        readonly fylke: number,
        kartutsnitt: WKTObject,
        senterpunkt: WKTObject,
        vegobjekt: Vegobjekt
    ) {
        super(navn, nummer, kartutsnitt, senterpunkt, vegobjekt);
    }

    override(modifyObject: { [P in keyof Kommune]?: Kommune[P] }): Kommune {
        return Object.assign(Object.create(Kommune.prototype), { ...this, ...modifyObject });
    }

    withVegobjekt(vegobjekt: Vegobjekt): Kommune {
        return this.override({ vegobjekt });
    }

    withVisible(visible: boolean): Kommune {
        return this.override({ visible });
    }

    toString(): string {
        return `${this.navn} ${this.nummer}`;
    }

    getAreaType(): string {
        return 'Kommune';
    }

    toParams(): string {
        return this.nummer.toString();
    }
}

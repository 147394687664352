import { useSelector } from '@/state/store';
import { stateConfig } from '@/selectors/selectors';
import useMapLayer from './useMapLayer';
import { createPropertyBoundariesLayerSource } from '@/components/map/map-utils/WebMapTileServices';
import ImageLayer from 'ol/layer/Image';

interface PropertyBoundariesLayerType {
    zIndex: number;
}
export const PropertyBoundariesLayer = ({ zIndex = 10000 }: PropertyBoundariesLayerType) => {
    const config = useSelector(stateConfig);

    const layer = new ImageLayer({ source: createPropertyBoundariesLayerSource(config) });

    useMapLayer({ layer, index: zIndex });

    return null;
};

import { ActionType, LoginWindowChangedAction } from './actiontypes';
import { LoginWindowState } from '@/state';

export const changeLoginWindow = (login: LoginWindowState) => dispatch => {
    const changeLoginWindowAction: LoginWindowChangedAction = {
        type: ActionType.SHOW_LOGIN,
        state: login,
    };
    dispatch(changeLoginWindowAction);
};

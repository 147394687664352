import React from 'react';
import { Trafikantgruppe, TypeVeg, VegnettParameter } from '@/middleware/queries';
import TriStateCheckbox from '../../objects/TriStateCheckbox';
import { VegnettFilterOption } from './FilterOptions';

const KJORENDE: VegnettFilterOption = new VegnettFilterOption(
    VegnettParameter.TRAFIKANTGRUPPE,
    Trafikantgruppe.KJORENDE,
    'Kjørende'
);

const GAENDE: VegnettFilterOption = new VegnettFilterOption(
    VegnettParameter.TRAFIKANTGRUPPE,
    Trafikantgruppe.GAENDE,
    'Gående og syklende'
);

export const ALLE_KJORENDE: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.KANALISERT_VEG, 'Kanalisert veg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.ENKEL_BILVEG, 'Enkel bilveg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.RAMPE, 'Rampe'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.RUNDKJORING, 'Rundkjøring'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.BILFERJE, 'Bilferje'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.GATETUN, 'Gatetun'),
];

export const ALLE_GAENDE: VegnettFilterOption[] = [
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.SYKKELVEG, 'Sykkelveg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.GANGVEG, 'Gangveg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.GANG_OG_SYKKELVEG, 'Gang- og sykkelveg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.GAGATE, 'Gågate'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.FORTAU, 'Fortau'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.TRAPP, 'Trapp'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.GANGFELT, 'Gangfelt'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.PASSASJERFERJE, 'Passasjerferje'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.TRAKTORVEG, 'Traktorveg'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.STI, 'Sti'),
    new VegnettFilterOption(VegnettParameter.TYPEVEG, TypeVeg.ANNET, 'Annet'),

];

interface Props {
    typeveg: TypeVeg[];
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, filterOption: VegnettFilterOption) => void;
}

export const VegutvalgOptions = ({ typeveg, handleFilterChange }: Props) => {
    const alle_kjorende_satt = containsAll(
        ALLE_KJORENDE.map(option => option.optionType),
        typeveg
    );
    const noen_kjorende_satt = containsOverlap(
        ALLE_KJORENDE.map(option => option.optionType),
        typeveg
    );
    const alle_gaende_satt = containsAll(
        ALLE_GAENDE.map(option => option.optionType),
        typeveg
    );
    const noen_gaende_satt = containsOverlap(
        ALLE_GAENDE.map(option => option.optionType),
        typeveg
    );

    return (
        <div className="c-road-net__filter-group">
            <h3 className="c-road-net__filter-header">Vegutvalg</h3>
            <TriStateCheckbox
                checked={alle_kjorende_satt}
                indeterminate={!alle_kjorende_satt && noen_kjorende_satt}
                value={KJORENDE.paramType}
                label={KJORENDE.label}
                bold={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, KJORENDE)}
            />
            {ALLE_KJORENDE.map(option => (
                <TriStateCheckbox
                    className="c-road-net__filter-subgroup"
                    key={option.optionType}
                    checked={typeveg.includes(option.optionType as TypeVeg)}
                    label={option.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, option)}
                />
            ))}
            <TriStateCheckbox
                checked={alle_gaende_satt}
                indeterminate={!alle_gaende_satt && noen_gaende_satt}
                value={GAENDE.paramType}
                label={GAENDE.label}
                bold={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, GAENDE)}
            />
            {ALLE_GAENDE.map(option => (
                <TriStateCheckbox
                    className="c-road-net__filter-subgroup"
                    key={option.optionType}
                    checked={typeveg.includes(option.optionType as TypeVeg)}
                    label={option.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, option)}
                />
            ))}
        </div>
    );

    // Returns true if currentArray contains (at least) all elements in baseArray, otherwise false.
    function containsAll(baseArray: string[], currentArray: string[]) {
        if (currentArray == null || baseArray == null) return false;
        if (currentArray.length < baseArray.length) return false;

        for (const element of baseArray) {
            if (!currentArray.includes(element)) return false;
        }
        return true;
    }

    // Returns true if at least one element exists in both arrays, otherwise false.
    function containsOverlap(array1: string[], array2: string[]) {
        for (const element1 of array1) {
            for (const element2 of array2) {
                if (element1 === element2) return true;
            }
        }
        return false;
    }
};

import { Stedsnavn, StedsnavnResponse } from '../responses';

export function transformStedsnavnResponse(data: unknown): StedsnavnResponse {
    if (data['metadata'].totaltAntallTreff === 0) {
        return new StedsnavnResponse(0, []);
    }
    return new StedsnavnResponse(
        data['metadata'].totaltAntallTreff,
        data['navn']
            // remove old (historical) names from results to avoid confusing order of results
            .filter(e => e.skrivemaatestatus !== 'Historisk')
            .map(
                e =>
                    new Stedsnavn(
                        e.stedsnummer,
                        e.navneobjekttype,
                        categoryMapping(e.navneobjekttype),
                        e.stedsnavn?.[0]?.skrivemåte || '',
                        e.kommuner?.[0].kommunenavn || '',
                        e.fylker?.[0].fylkesnavn || '',
                        e.representasjonspunkt.øst,
                        e.representasjonspunkt.nord
                    )
            )
    );
}
const categoryPlace = [
    'Mindre brukonstruksjon',
    'Del av vann',
    'Fjellvegg',
    'Sjømerke med indirekte belysning',
    'Støls-/setereiendom',
    'Eiendomsteig',
    'Tuft',
    'Reinbeitedistrikt',
    'Fjellovergang',
    'Sva',
    'Vannstandsmåler',
    'Overbygg',
    'Vannverk',
    'Boinstitusjon',
    'Sjøvarde',
    'Strand i sjø',
    'Øyr',
    'Eid i sjø',
    'Skredområde',
    'Våg i sjø',
    'Fjordmunning',
    'Sjøstykke',
    'Fiskeplass i sjø',
    'Holme i sjø',
    'Øygruppe i sjø',
    'Nes i sjø',
    'Holmegruppe i sjø',
    'Vik i sjø',
    'Halvøy i sjø',
    'Sund i sjø',
    'Soneinndeling til havs',
    'Verneområde',
    'Garasje/hangarbygg',
    'Sjødetalj',
    'Bru',
    'Våtmarksområde',
    'Jernbanebru',
    'Klopp',
    'Ur',
    'Stein',
    'Sand',
    'Elvemel',
    'Skar',
    'Skogholt',
    'Juv',
    'Skog',
    'Dal',
    'Botn',
    'Dalføre',
    'Krater',
    'Søkk',
    'Senkning',
    'Offersted',
    'Gass-/oljefelt i sjø',
    'Havn',
    'Lon',
    'Allmenning',
    'Tjern',
    'Del av innsjø',
    'Vann',
    'Dam',
    'Fjell i dagen',
    'Gruppe av tjern',
    'Pytt',
    'Matrikkeladressenavn',
    'Adressetilleggsnavn',
    'Adressenavn',
    'Traktorveg',
    'Havområde',
    'Havstrøm',
    'Banestrekning',
    'Oppdrettsanlegg',
    'Badeplass',
    'Strand',
    'Vik',
    'Halvøy',
    'Nes',
    'Holme',
    'Kontinentalsokkel',
    'Hylle i sjø',
    'Molo',
    'Undersjøisk vegg',
    'Havdyp',
    'Stø',
    'Høl',
    'Administrativ bydel',
    'Grunnkrets',
    'Fylke',
    'Landingsplass',
    'Kommune',
    'TV-/radio- eller mobiltelefontårn',
    'Nasjon',
    'Stryk',
    'Os',
    'Grøft',
    'Foss',
    'Elv',
    'Bekk',
    'Grustak/steinbrudd',
    'Torvtak',
    'Valgkrets',
    'Skolekrets',
    'Innsjø',
    'Gruppe av vann',
    'Boligfelt',
    'Stup',
    'Annen vanndetalj',
    'Hylle',
    'Forskningsstasjon',
    'Annen industri- og lagerbygning',
    'Fabrikk',
    'Forretningsbygg',
    'Rådhus',
    'Vaktstasjon/beredsskapsbygning',
    'Gammel bosettingsplass',
    'Bygg for jordbruk, fiske og fangst',
    'Eiendom',
    'Grotte',
    'Gravplass',
    'Heller',
    'Annen terrengdetalj',
    'Geologisk struktur',
    'Tettbebyggelse',
    'Annen bygning for religionsutøvelse',
    'Tettsteddel',
    'Grend',
    'Bygdelag (bygd)',
    'Hyttefelt',
    'Ås',
    'Gard',
    'Navnegard',
    'Slette',
    'Mo',
    'Enebolig/mindre boligbygg',
    'Fritidsbolig',
    'Boligblokk',
    'Borettslag',
    'Industriområde',
    'Historisk bosetting',
    'Torg',
    'Gjerde',
    'Bruk',
    'Seter/støl',
    'Korallrev',
    'Båe i sjø',
    'Klakk i sjø',
    'Grunne i sjø',
    'Skjær i sjø',
    'Fjellkant',
    'Hei',
    'Topp',
    'Bydel',
    'Tettsted',
    'By',
    'Fjell',
    'Berg',
    'Rygg',
    'Haug',
    'Høyde',
    'Øygruppe',
    'Øy',
    'Rev i sjø',
    'Kanal',
    'Landskapsområde',
    'Bakke i sjø',
    'Bakketopp i sjø',
    'Vidde',
    'Fjellområde',
    'Banke i sjø',
    'Rygg i sjø',
    'Skogområde',
    'Morenerygg i sjø',
    'Søkk i sjø',
    'Renne/Kløft i sjø',
    'Egg i sjø',
    'Sluse',
    'Ras i sjø',
    'Fjelltopp i sjø',
    'Utstikker',
    'Fjellkjede i sjø',
    'Sadel i sjø',
    'Sokkel i sjø',
    'Eid',
    'Vulkan i sjø',
    'Sund',
    'Platå i sjø',
    'Poststed',
    'Sokn',
    'Grensemerke',
    'Statistisk tettsted',
    'Annen administrativ inndeling',
    'Kilde',
    'Elvesving',
    'Nes ved elver',
    'Bakke',
    'Fjellside',
    'Li',
    'Parkeringsplass',
    'Banke ',
    'Grind',
    'Vad',
    'Eng',
    'Jorde',
    'Havnehage',
    'Setervoll',
    'Myr',
    'Melkeplass',
    'Utmark',
    'Isbre',
    'Fyllplass',
    'Bergverk',
    'Iskuppel',
    'Fonn',
    'Vegsving',
    'Bakke (Veg)',
    'Varde',
    'Jernstang',
    'Lanterne',
    'Fyrlykt',
    'Fyrstasjon',
    'Overett',
    'Båke',
    'Lysbøye',
    'Stake',
    'Skjær',
    'Båe ',
    'Tømmervelte',
    'Grunne',
    'Øy i sjø',
    'Kraftledning',
    'Kraftstasjon',
    'Oljeinstallasjon',
    'Kabel',
    'Rørledning',
    'Kraftgate (rørgate)',
    'Vegstrekning',
    'Hammar',
    'Friluftsområde',
    'Egg',
    'Sti',
    'Vegkryss',
    'Fjord',
];

const categoryAirport = ['Flyplass'];
const categoryTunnel = ['Tunnel'];
const categoryBoat = [
    'Småbåthavn',
    'Ankringsplass',
    'Ferjekai ',
    'Fløtningsanlegg',
    'Brygge',
    'Kai',
    'Ferjestrekning',
    'Farled/skipslei',
];
const categoryTrain = ['Holdeplass', 'Stasjon', 'Jernbanetunnel'];
const categoryBus = ['Busstopp', 'Busstasjon'];
const categoryCar = ['Vegbom', 'Bomstasjon'];
const categoryHelicopter = ['Helikopterlandingsplass'];
const categoryPublic = [
    'Militært bygg/anlegg',
    'Idrettshall',
    'Alpinanlegg',
    'Skiheis',
    'Skytebane',
    'Skytefelt',
    'Fjellheis',
    'Taubane',
    'Barnehage',
    'Skole',
    'Universitet/høgskole',
    'Idrettsanlegg',
    'Park',
    'Kirke',
    'Fengsel',
];
const categoryHealth = ['Sykehus', 'Helseinstitusjon'];
const categoryRestArea = ['Rasteplass'];
const categoryTourism = [
    'Fornøyelsespark',
    'Kultur-/messehall',
    'Severdighet',
    'Utsiktspunkt',
    'Annen kulturdetalj',
    'Turisthytte',
    'Campingplass',
    'Serveringssted',
    'Hotell',
    'Pensjonat',
    'Forsamlingshus/kulturhus',
    'Museum/galleri/bibliotek',
    'Basseng i sjø',
];

function categoryMapping(category: string): string {
    if (categoryPlace.includes(category)) {
        return 'place';
    }
    if (categoryPublic.includes(category)) {
        return 'public';
    }
    if (categoryTourism.includes(category)) {
        return 'tourism';
    }
    if (categoryAirport.includes(category)) {
        return 'airport';
    }
    if (categoryHelicopter.includes(category)) {
        return 'helicopter';
    }
    if (categoryTrain.includes(category)) {
        return 'train';
    }
    if (categoryBoat.includes(category)) {
        return 'boat';
    }
    if (categoryBus.includes(category)) {
        return 'bus';
    }
    if (categoryCar.includes(category)) {
        return 'car';
    }
    if (categoryRestArea.includes(category)) {
        return 'restingarea';
    }
    if (categoryHealth.includes(category)) {
        return 'health';
    }
    if (categoryTunnel.includes(category)) {
        return 'tunnel';
    }
    return 'undefined';
}

import { mapReducer } from './mapReducer';
import { omraderReducer } from './omraderReducer';
import { metaReducer } from './metaReducer';
import { searchReducer } from './searchReducer';
import { vegobjekterReducer } from './vegobjekterReducer';
import { vegnettReducer } from './vegnettReducer';
import { valgtReducer } from './valgtReducer';
import { overlayReducer } from './overlayReducer';
import { RootState } from '@/state';
import { splashReducer } from './splashReducer';
import { loginWindowReducer } from './loginWindowReducer';
import { userReducer } from './userReducer';
import { preferencesReducer } from './preferencesReducer';
import { configReducer } from './configReducer';
import { ActionType, BootstrapAction, TogglePolygonDrawAction } from '@/actions/actiontypes';
import { measurementReducer } from './measurementReducer';

export const rootReducer = (state: RootState, action): RootState => {
    return state
        .withMeta(metaReducer(state.metaState, action))
        .withSplash(splashReducer(state.splashState, action))
        .withLogin(loginWindowReducer(state.loginWindowState, action))
        .withUser(userReducer(state.userState, action))
        .withMap(mapReducer(state.mapState, action))
        .withSearch(searchReducer(state.searchState, action))
        .withOmrader(omraderReducer(state.omraderState, action))
        .withVegobjekter(vegobjekterReducer(state.vegobjekterState, action))
        .withVegnett(vegnettReducer(state.vegnettState, action))
        .withValgt(valgtReducer(state.valgtState, action))
        .withOverlay(overlayReducer(state.overlayState, action))
        .withConfig(configReducer(state.config, action))
        .withMeasurement(measurementReducer(state.measurementState, action))
        .withPreferences(preferencesReducer(state.preferences, action))
        .withDrawingPolygon(drawingPolygonReducer(state.drawingPolygon, action))
        .withBootstrapped(bootstrapReducer(state.bootstrapped, action));
};

const bootstrapReducer = (state: boolean, action: BootstrapAction) => {
    if (action.type === ActionType.BOOTSTRAP) return true;
    return state;
};

const drawingPolygonReducer = (state: boolean, action: TogglePolygonDrawAction) => {
    if (action.type === ActionType.TOGGLE_POLYGON_DRAW) return action.drawing;
    return state;
};

import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../selectors/selectors';
import { VegobjekterState, ValgtState, OmraderState, RootState } from '@/state';
import { setFilterPanelOpen, setFilterPanelOpenT } from '@/actions/searchActions';

interface Props {
    filterPanelOpen: boolean;
    searchPanelOpen: boolean;
    hva: VegobjekterState;
    hvor: OmraderState;
    selectedVegobjekt: ValgtState;
    setFilterPanelOpen: setFilterPanelOpenT;
}

class FilterPanelComponent extends React.Component<Props, {}> {
    render() {
        const isFilterPanelOpen = this.props.filterPanelOpen;
        const filterPanelOpenIcon = isFilterPanelOpen ? 'o-icon-chevron-down' : 'o-icon-chevron-up';
        const filterPanelOpenText = isFilterPanelOpen ? 'Skjul panel' : 'Vis panel';
        const hiddenClass =
            this.props.searchPanelOpen || !this.props.selectedVegobjekt.isEmpty() ? ' t-hidden' : '';
        const objectSelectedClass =
            this.props.hva.vegobjekttypeStates.length === 0 &&
            this.props.hvor.fylke.length === 0 &&
            this.props.hvor.kommune.length === 0 &&
            this.props.hvor.kontraktsomrade.length === 0 &&
            this.props.hvor.land.length === 0 &&
            this.props.hvor.riksvegrute.length === 0 &&
            this.props.hvor.vegsystemreferanse.length === 0
                ? ' t-hidden'
                : '';

        return (
            <div className={'c-mobile-filter' + hiddenClass}>
                <div className="c-filter-panel-body__header t-clearfix t-visible-mobile">
                    <button
                        className={`c-filter-panel-toggle-panel o-btn${objectSelectedClass}`}
                        type="button"
                        onClick={() => this.toggleFilterPanel()}
                    >
                        {filterPanelOpenText} <i className={filterPanelOpenIcon + ' c-mobile-filter__icon'} />
                    </button>
                </div>
            </div>
        );
    }

    private toggleFilterPanel() {
        this.props.setFilterPanelOpen(!this.props.filterPanelOpen);
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        filterPanelOpen: selectors.stateFilterPanelOpen(state),
        hva: selectors.stateVegobjekter(state),
        hvor: selectors.stateOmrader(state),
        searchPanelOpen: selectors.stateSearchPanelOpen(state),
        selectedVegobjekt: selectors.stateValgt(state),
    };
};
const mapDispatchToProps = {
    setFilterPanelOpen,
};
export const FilterPanel = connect(mapStateToProps, mapDispatchToProps)(FilterPanelComponent);

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { connect } from 'react-redux';
import { Vegnettlenke } from '@/domain/vegnett/Vegnettlenke';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { stateVegnettDirection, stateValgt } from '@/selectors/selectors';
import { ValgtState, RootState } from '@/state';
import { isPolygonFeature, roadNetLinkToFeature, wktObjectToFeature } from '@/utils/SpatialHelper';
import { selectedFeatureStyle } from '../style/Style';
import { FeatureLayerType, FeatureLayerTypeKey } from './FeatureLayerType';
import React from 'react';
import { MapContext } from '@/state/context/MapContext';
import { Cluster } from 'ol/source';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';

interface TagProps {
    zIndex: number;
}

interface StateProps {
    selection: ValgtState;
    vegnettRetningsgrunnlag: string;
}

type Props = TagProps & StateProps;

class ValgtFeatureLayer extends React.Component<Props, null> {
    static contextType = MapContext;
    declare context: React.ContextType<typeof MapContext>;

    private selectionLayer: VectorLayer<VectorSource<Geometry>>;

    componentDidMount() {
        this.updateSelectionLayer(this.props.selection);
    }

    componentDidUpdate(prevProps: Props) {
        const selection = this.props.selection;
        if (prevProps.selection !== selection && !selection.fetching) {
            this.updateSelectionLayer(selection);
        }
    }

    componentWillUnmount(): void {
        this.context.map.removeLayer(this.selectionLayer);
    }

    updateSelectionLayer(selected: ValgtState) {
        // Remove previous selection layer
        this.context.map.removeLayer(this.selectionLayer);

        let features;
        if (selected.isSingle()) {
            // Vegobjekt -> Feature conversion
            if (selected.singleVegobjekt()) {
                const vegobjekt: Vegobjekt = selected.singleVegobjekt();
                if (vegobjekt.geometri == null) return;
                features = [
                    wktObjectToFeature(
                        vegobjekt.geometri?.egengeometri
                            ? vegobjekt.geometri?.wkt
                            : vegobjekt.lokasjon?.geometri?.wkt,
                        vegobjekt.id,
                        vegobjekt.metadata.type.id,
                        FeatureLayerType.FEATURE_VALGT
                    ),
                ];
            }
            // Vegnettlenke -> Feature conversion
            if (selected.singleVegnettlenke()) {
                const vegnettlenke: Vegnettlenke = selected.singleVegnettlenke();
                if (vegnettlenke.geometri == null) return;
                features = [roadNetLinkToFeature(vegnettlenke, this.props.vegnettRetningsgrunnlag)];
            }

            // Selection source
            const selectedSource = new VectorSource({
                features,
            });

            // Selection layer
            this.selectionLayer = new VectorLayer({
                source: selectedSource,
                style: selectedFeatureStyle,
                visible: true,
                zIndex: selected.isSingle() && isPolygonFeature(features[0]) ? 101 : this.props.zIndex,
            });

            // Add new selection layer
            this.context.map.addLayer(this.selectionLayer);
        } else if (selected.clusterObjekter.filter(v => v.geometri !== null)?.length > 0) {
            // Vegobjekt-cluster -> Features conversion
            features = selected.clusterObjekter
                .filter(v => v.geometri != null)
                .map(vegobjekt =>
                    wktObjectToFeature(
                        vegobjekt.geometri.wkt,
                        vegobjekt.id,
                        vegobjekt.metadata.type.id,
                        FeatureLayerType.FEATURE_VALGT_CLUSTER
                    )
                );

            // Selection source
            const selectedSource = new VectorSource({
                features,
            });

            // Clustering source
            const clusterSource = new Cluster({
                distance: 50,
                source: selectedSource,
            });

            clusterSource.on('addfeature', e => {
                ValgtFeatureLayer.tagClusterFeatures(e.feature);
            });

            // Selection layer
            this.selectionLayer = new VectorLayer({
                source: clusterSource,
                style: selectedFeatureStyle,
                visible: true,
                zIndex: this.props.zIndex,
            });

            // Add new selection layer
            this.context.map.addLayer(this.selectionLayer);
        }
    }

    private static tagClusterFeatures(feature: Feature<Geometry>) {
        feature.set(FeatureLayerTypeKey, FeatureLayerType.FEATURE_VALGT_CLUSTER);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState) => ({
    selection: stateValgt(state),
    vegnettRetningsgrunnlag: stateVegnettDirection(state),
});

export const ValgtLayer = connect(mapStateToProps)(ValgtFeatureLayer);

import { ServerStatusResponse } from '../responses';

export function transformServerStatus(data: unknown): ServerStatusResponse {
    const datagrunnlag = data['datagrunnlag'];
    const { dato, id, versjon } = datagrunnlag.datakatalog;
    return new ServerStatusResponse(datagrunnlag.sist_prosesserte_transaksjon.transaksjonstidspunkt, {
        dato,
        id,
        versjon,
    });
}

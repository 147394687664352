import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { maxZoomBaseLayer, TileService } from '@/components/map/map-utils/WebMapTileServices';
import {
    ActionType,
    AddPointAction,
    GetState,
    MapMovedAction,
    RemovePointAction,
    SetMapLayerAction,
    ZoomToExtentAction,
} from './actiontypes';
import { stateMapLayer, stateVegnett, stateZoom } from '@/selectors/selectors';
import { executeAllSearches } from '@/middleware/fetchVegobjekter';
import { fetchVegnett } from '@/middleware/fetchVegnett';
import { MapPoint } from '@/domain/kart/MapPoint';
import { BaatToken } from '@/components/map/layers/useRefreshBaatToken';

export type mapMovedT = (
    center: [number, number],
    zoom: number,
    kartutsnitt: Kartutsnitt
) => (dispatch, getState) => void;
export const mapMoved: mapMovedT =
    (center: [number, number], zoom: number, kartutsnitt: Kartutsnitt) => (dispatch, getState: GetState) => {
        const vegnettState = stateVegnett(getState());
        const mapMovedAction: MapMovedAction = {
            type: ActionType.MAP_MOVED,
            center,
            zoom,
            kartutsnitt,
        };

        dispatch(mapMovedAction);
        dispatch(executeAllSearches());
        if (vegnettState.visible) {
            dispatch(fetchVegnett());
        }
        if (stateZoom(getState()) > maxZoomBaseLayer && stateMapLayer(getState()) === TileService.GEODATA) {
            dispatch(setMapLayer(TileService.TOPO4));
        } else if (
            stateZoom(getState()) <= maxZoomBaseLayer &&
            stateMapLayer(getState()) === TileService.TOPO4
        ) {
            dispatch(setMapLayer(TileService.GEODATA));
        }
    };

export type setZoomAndCenterT = (center: [number, number], zoom?: number) => (dispatch) => void;
export const setZoomAndCenter: setZoomAndCenterT = (center: [number, number], zoom?: number) => dispatch => {
    const setZoomAndCenterAction = {
        type: ActionType.MAP_CENTER_AND_ZOOM_SET,
        center,
        zoom,
    };
    dispatch(setZoomAndCenterAction);
};

export type setBaatTokenT = (baatToken: BaatToken) => (dispatch) => void;
export const setBaatToken: setBaatTokenT = (token: BaatToken) => dispatch => {
    const action = {
        type: ActionType.MAP_BAAT_TOKEN_SET,
        baatToken: token,
    };
    dispatch(action);
};

export type zoomToExtentT = (extent: Kartutsnitt) => (dispatch) => void;
export const zoomToExtent: zoomToExtentT = (kartutsnitt: Kartutsnitt) => dispatch => {
    const zoomAction: ZoomToExtentAction = {
        type: ActionType.MAP_EXTENT_ZOOMED_TO,
        kartutsnitt,
    };
    dispatch(zoomAction);
};

export type setMapLayerT = (maplayer: TileService) => (dispatch, getState: GetState) => void;
export const setMapLayer: setMapLayerT = (maplayer: TileService) => dispatch => {
    const setMapLayerAction: SetMapLayerAction = {
        type: ActionType.MAP_LAYER_SET,
        maplayer,
    };
    dispatch(setMapLayerAction);
};

export type removePointFromMapT = (id: string) => (dispatch, getState: GetState) => void;
export const removePointFromMap = (id: string) => dispatch => {
    const action: RemovePointAction = {
        type: ActionType.MAP_POINT_REMOVED,
        id,
    };
    dispatch(action);
};
export type addPointToMapT = (point: MapPoint) => (dispatch, getState: GetState) => void;
export const addPointToMap = (point: MapPoint) => dispatch => {
    const action: AddPointAction = {
        type: ActionType.MAP_POINT_ADDED,
        point,
    };
    dispatch(action);
};

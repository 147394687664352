export enum Projeksjon {
    UTM_32 = 'UTM 32',
    UTM_33 = 'UTM 33 (Standard)',
    UTM_34 = 'UTM 34',
    UTM_35 = 'UTM 35',
}

export const toSrid = (projection: Projeksjon) => {
    switch (projection) {
        case Projeksjon.UTM_32:
            return 5972;
        case Projeksjon.UTM_34:
            return 5974;
        case Projeksjon.UTM_35:
            return 5975;
        case Projeksjon.UTM_33:
        default:
            return 5973;
    }
};

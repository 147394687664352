import React, { ChangeEventHandler, FormEventHandler, KeyboardEventHandler } from 'react';
import { useSelector } from '@/state/store';
import { stateDatakatalogVegobjekttypeSelected } from '@/selectors/selectors';

interface SearchBarProps {
    handleSubmit: FormEventHandler;
    handleKeyDown: KeyboardEventHandler;
    handleOnFocus: () => void;
    handleChange: ChangeEventHandler;
    datakatalogOpen: boolean;
    setDatakatalogOpen: (boolean) => void;
    inputValue: string;
}
export const SearchBar = ({
    handleSubmit,
    handleKeyDown,
    handleOnFocus,
    handleChange,
    inputValue,
    datakatalogOpen,
    setDatakatalogOpen,
}: SearchBarProps) => {
    const placeholder = 'Skriv inn vegobjekttype eller søkeområde...';
    const showingDatakatalogVegobjekttype = useSelector(stateDatakatalogVegobjekttypeSelected)?.length > 0;
    return (
        <form
            className={'c-search-interact ' + (showingDatakatalogVegobjekttype ? 't-hidden' : '')}
            role={'search'}
            aria-label={'Søkefelt'}
            onSubmit={handleSubmit}
        >
            <i className="o-icon-search c-search-interact__search-icon" aria-hidden={true} />
            <input
                id="searchText"
                className="c-search-interact__input"
                autoFocus={true}
                autoComplete="off"
                type="search"
                onKeyDown={e => handleKeyDown(e)}
                onFocus={handleOnFocus}
                onChange={handleChange}
                value={inputValue}
                placeholder={placeholder}
            />
            <span className="c-search-interact__divider" aria-hidden={true} />
            <button
                type="button"
                onClick={() => setDatakatalogOpen(!datakatalogOpen)}
                className={`c-search-interact__datakatalog-button o-icon-book ${
                    datakatalogOpen ? 'o-icon-book--active' : ''
                }`}
                aria-controls="datakatalog"
                aria-owns="datakatalog"
                aria-label="Åpne datakatalog"
                aria-pressed={datakatalogOpen}
                value="Datakatalog"
                title="Åpne datakatalogen"
            />
        </form>
    );
};

import { Fylke } from '@/domain/omrader';
import { transformGeometri } from './geometri';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export function transformFylker(fylkerResponse: unknown): Fylke[] {
    return (fylkerResponse as []).map(fylke => transformFylke(fylke));
}

export function transformFylke(data: unknown) {
    return data
        ? new Fylke(
              data['navn'],
              data['nummer'],
              transformGeometri(data['kartutsnitt']),
              transformGeometri(data['senterpunkt']),
              Vegobjekt.dummy(data['vegobjekt'].id, data['vegobjekt'].type)
          )
        : null;
}

import { Feature } from 'ol';
import Style from 'ol/style/Style';
import { isMultiPointFeature, isPointFeature } from '@/utils/SpatialHelper';
import { FeatureGlobalDirection, FeatureLayerType, FeatureLayerTypeKey } from '../layers/FeatureLayerType';
import { lineStyle } from './LineStyle';
import { pointStyle } from './PointStyle';

export const selectedColor = '#444f56';

export function featureStyle(
    feature: Feature,
    resolution: number,
    direction = 'geometri',
    highlight = false,
    selected = false
): Style[] {
    if (feature.get('preselected')) {
        highlight = true;
    }
    if (isPointFeature(feature) || isMultiPointFeature(feature)) {
        return pointStyle(feature, highlight, selected);
    } else {
        return lineStyle(feature, resolution, direction, highlight, selected);
    }
}

export function arrowedLineStyle(
    feature: Feature,
    resolution: number,
    direction = 'geometri',
    highlight = false,
    selected = false
): Style[] {
    if (isPointFeature(feature) || isMultiPointFeature(feature)) {
        return pointStyle(feature, highlight, selected);
    } else {
        return lineStyle(feature, resolution, direction, highlight, selected, true);
    }
}

export function directionalLineStyle(
    feature: Feature,
    resolution: number,
    direction: string,
    highlight = false,
    selected = false
) {
    if (isPointFeature(feature) || isMultiPointFeature(feature)) {
        return pointStyle(feature, highlight, selected);
    } else {
        return lineStyle(feature, resolution, direction, highlight, selected);
    }
}

export function directionalArrowedLineStyle(
    feature: Feature,
    resolution: number,
    direction: string,
    highlight = false,
    selected = false
) {
    if (isPointFeature(feature) || isMultiPointFeature(feature)) {
        return pointStyle(feature, highlight, selected);
    } else {
        return lineStyle(feature, resolution, direction, highlight, selected, true);
    }
}

export function highlightFeatureStyle(feature: Feature, resolution: number) {
    const direction = feature.get(FeatureGlobalDirection);

    if (feature.get(FeatureLayerTypeKey) === FeatureLayerType.FEATURE_VEGNETTLENKE) {
        if (direction === 'metrering') {
            return directionalArrowedLineStyle(feature, resolution, direction, false, true);
        }
        return arrowedLineStyle(feature, resolution, direction, true);
    } else {
        return featureStyle(feature, resolution, direction, true);
    }
}

export function selectedFeatureStyle(feature: Feature, resolution: number) {
    const direction = feature.get(FeatureGlobalDirection);

    if (feature.get(FeatureLayerTypeKey) === FeatureLayerType.FEATURE_VEGNETTLENKE) {
        if (direction === 'metrering') {
            return directionalArrowedLineStyle(feature, resolution, direction, false, true);
        }
        return arrowedLineStyle(feature, resolution, direction, false, true);
    } else {
        return featureStyle(feature, resolution, direction, false, true);
    }
}

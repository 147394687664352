import { Omrade } from '@/domain/omrader/Omrade';
import { WKTObject } from '@/domain/WKTObject';
import { WKT } from 'ol/format';
import { Feature } from 'ol';
import { fromExtent } from 'ol/geom/Polygon';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export class Polygon extends Omrade {
    static serializer = new WKT();
    readonly wkt: WKTObject;
    constructor(feature: Feature | WKTObject) {
        if (feature instanceof Feature) {
            const geometry = new WKTObject(6173, Polygon.serializer.writeFeature(feature, { decimals: 3 }));
            super(
                'Polygon',
                Math.random(),
                new WKTObject(
                    6173,
                    Polygon.serializer.writeGeometry(fromExtent(feature.getGeometry().getExtent()), {
                        decimals: 3,
                    })
                ),
                null,
                new Vegobjekt(0, '', undefined, [], geometry, undefined, undefined, undefined)
            );
            this.wkt = geometry;
        } else if (feature instanceof WKTObject) {
            const geometry = Polygon.serializer.readGeometry(feature.wkt);
            super(
                'Polygon',
                0,
                new WKTObject(
                    6173,
                    Polygon.serializer.writeGeometry(fromExtent(geometry.getExtent()), { decimals: 3 })
                ),
                null,
                new Vegobjekt(0, '', undefined, [], feature, undefined, undefined, undefined)
            );
            this.wkt = feature;
        } else {
            throw new Error('Invalid type for constructing Polygon');
        }
    }

    override(modifyObject: { [P in keyof Polygon]?: Polygon[P] }): Polygon {
        return Object.assign(Object.create(Polygon.prototype), { ...this, ...modifyObject });
    }

    withVisible(visible: boolean): Polygon {
        return this.override({ visible });
    }

    getAreaType(): string {
        return 'Polygonfilter';
    }

    toParams(): string {
        const match = this.wkt.wkt.match(/POLYGON\(\((?<coords>.*?)\)\)/)?.groups?.['coords'];
        if (!match) throw new Error('Unable to parse coordinates from WKT!');
        return match;
    }

    toString(): string {
        return 'Polygon';
    }
}

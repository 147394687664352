import { zoomToExtent } from '@/actions/mapActions';
import { highlightRoadsystemReference } from '@/actions/searchActions';
import Accordion from '@/components/objects/Accordion';
import { Vegsystemreferanse, vegSysRefComparator } from '@/domain/omrader';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';
import { Dispatch, useDispatch } from '@/state/store';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import React from 'react';

type VegsystemreferanserProps = {
    vegobjekt: Vegobjekt;
};

function Vegsystemreferanser({ vegobjekt }: VegsystemreferanserProps) {
    const dispatch: Dispatch = useDispatch();

    return (
        <>
            {vegobjekt?.lokasjon?.vegsystemreferanser && [
                <dt key={'vsr-dt_' + vegobjekt.id} className="c-valgt-features__dt">
                    Vegsystemreferanser:
                </dt>,
                <dd
                    key={'vsr-dd_' + vegobjekt.id}
                    id={'valgt-features_objektid_' + vegobjekt.id}
                    className="c-valgt-features__dd"
                >
                    {renderVegSysRefs(vegobjekt, dispatch)}
                </dd>,
            ]}
        </>
    );
}

function renderVegSysRefs(vo: Vegobjekt, dispatch: Dispatch) {
    const vegSysRefs = vo.lokasjon.vegsystemreferanser;
    if (vegSysRefs.length === 0) {
        return 'Ingen vegsystemreferanser';
    } else if (vegSysRefs.length === 1) {
        return (
            <a
                key={vegSysRefs[0].toVegkartKortform()}
                className="c-valgt-features__link"
                role="button"
                aria-description="Gå til vegsystemreferanse i kart"
                tabIndex={0}
                id={'valgtfeatureslink_' + vo.id.toString()}
                onClick={() => zoomToExtentAndShowVSRPopup(vegSysRefs[0], vo, dispatch)}
            >
                {transformIfKPSVeg(
                    vegSysRefs[0].toVegkartKortform(),
                    vegSysRefs[0].vegsystem.vegkategori,
                    vo.lokasjon.kommuner[0]
                )}
            </a>
        );
    }

    // Generate keys based on vegkategori, fase, nummer, strekning and delstrekning.
    const acc: Map<string, Vegsystemreferanse[]> = new Map();

    for (const vsr of vegSysRefs) {
        const kortnavn = vsr.vegsystem.vegkategori
            .concat(vsr.vegsystem.fase)
            .concat(vsr.vegsystem.nummer + '')
            .concat(
                vsr.strekning
                    ? ' S' +
                          vsr.strekning.strekningsNummer.toString() +
                          'D' +
                          vsr.strekning.delstrekningsNummer.toString()
                    : ''
            );

        acc.has(kortnavn) ? acc.get(kortnavn).push(vsr) : acc.set(kortnavn, [vsr]);
    }

    return Array.from(acc.entries()).map(entry => {
        const key: string = entry[0];
        const values: Vegsystemreferanse[] = entry[1];
        values.sort(vegSysRefComparator);
        if (values.length === 1) {
            return (
                <a
                    key={values[0].toVegkartKortform()}
                    className="c-valgt-features__link-list"
                    onClick={() => zoomToExtentAndShowVSRPopup(values[0], vo, dispatch)}
                >
                    {values[0].toVegkartKortform()}
                </a>
            );
        }
        return (
            <Accordion className="c-valgt-features__accordion" key={key} header={key}>
                {values.map((vsr: Vegsystemreferanse, index: number) => (
                    <a
                        key={vsr.toVegkartKortform() + '_' + index}
                        className="c-valgt-features__link-list"
                        onClick={() => zoomToExtentAndShowVSRPopup(vsr, vo, dispatch)}
                    >
                        {vsr.toVegkartKortform()}
                    </a>
                ))}
            </Accordion>
        );
    });
}

function transformIfKPSVeg(kortform: string, vegkategori: string, kommune: number): string {
    return ['K', 'P', 'S'].includes(vegkategori) ? kommune + ' ' + kortform : kortform;
}

function zoomToExtentAndShowVSRPopup(vsr: Vegsystemreferanse, vegobjekt: Vegobjekt, dispatch: Dispatch) {
    dispatch(highlightRoadsystemReference(vsr, vegobjekt.lokasjon.kommuner[0]));
    dispatch(zoomToExtent(kartutsnittFromWktStrings(vegobjekt.geometri.wkt)));
}

export default Vegsystemreferanser;

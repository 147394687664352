import { parseJwt } from '@/utils/AuthenticationUtils';

export enum Role {
    Sensitiv1 = 'nva=0_sensitive_role1',
    Sensitiv2 = 'nva=0_sensitive_role2',
    Sensitiv3 = 'nva=0_sensitive_role3',
}

export class UserState {
    constructor(
        readonly name: string = 'Gjest',
        readonly email: string = null,
        readonly roles: Record<Role, boolean> = {
            [Role.Sensitiv1]: false,
            [Role.Sensitiv2]: false,
            [Role.Sensitiv3]: false,
        },
        readonly isLoggedIn: boolean = false
    ) {}

    override(modifyObject: { [P in keyof UserState]?: UserState[P] }): UserState {
        return Object.assign(Object.create(UserState.prototype), { ...this, ...modifyObject });
    }

    /**
     * Instantiate UserState using a base64url encoded idToken
     */
    static fromIdToken(idToken: string) {
        const decoded = parseJwt(idToken);
        const roles = Object.values(Role).reduce(
            (acc, r) => ({ ...acc, [r]: decoded.svvroles.includes(r) }),
            {} as Record<Role, boolean>
        );
        return new UserState(decoded.name, decoded.email, roles, true);
    }
}

import * as React from 'react';
import DropdownContainer from '@/components/avgrensning/search/dropdown/DropdownContainer';
import DropdownItem, { DropdownItemData } from '@/components/avgrensning/search/dropdown/DropdownItem';
import VegobjekttypeContainer from '@/components/avgrensning/datakatalog/VegobjekttypeContainer';
import { useSelector } from '@/state/store';
import { stateDatakatalogVegobjekttypeSelected } from '@/selectors/selectors';

interface DatakatalogContainerProps {
    focusIndex: number;
    items: DropdownItemData[];
}

export const DatakatalogContainer = ({ focusIndex, items }: DatakatalogContainerProps) => {
    const selected = useSelector(stateDatakatalogVegobjekttypeSelected);
    if (selected.length > 0) return <VegobjekttypeContainer />;
    if (!items || items.length === 0) return null;
    return (
        <DropdownContainer focusIndex={focusIndex} role={'listbox'} id={'datakatalogen-list'}>
            {items?.map((item, index) => (
                <DropdownItem
                    key={item.subtitle + item.title + index}
                    focusIndex={focusIndex}
                    index={index}
                    buttonClassName={item.header ? 'header' : 'item'}
                    item={item}
                />
            ))}
        </DropdownContainer>
    );
};

import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useEffect, useMemo } from 'react';
import { useSelector } from '@/state/store';
import { stateMap } from '@/selectors/selectors';
import { featureStyle } from '../style/Style';
import { FeatureColorKey, FeatureLayerType, FeatureLayerTypeKey } from './FeatureLayerType';
import useMapLayer from './useMapLayer';

interface MapMarkerLayerType {
    zIndex?: number;
}
export const MapMarkerLayer = ({ zIndex = 10000 }: MapMarkerLayerType) => {
    const { points } = useSelector(stateMap);

    const features = useMemo(() => {
        return points.map(p => {
            const feature = new Feature({ id: p.id, geometry: new Point(p.geometry) });
            feature.set(FeatureColorKey, '#ff8f00');
            feature.set(FeatureLayerTypeKey, FeatureLayerType.FEATURE_MAP_POINT);
            return feature;
        });
    }, [points]);

    const layer = useMemo(
        () =>
            new VectorLayer({
                style: featureStyle,
                visible: true,
                source: new VectorSource(),
            }),
        []
    );

    useMapLayer({ layer, index: zIndex });

    useEffect(() => {
        layer.getSource().addFeatures(features);
        return () => layer.getSource().clear();
    }, [features, layer]);
    return null;
};

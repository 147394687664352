import {
    ActionType,
    AddVSRLookupAction,
    ClearedStedsnavnAction,
    ErrorAction,
    ReceivedOmradeGeometryAction,
    ReceivedStedsnavnAction,
    UpdateVSRAction,
} from '@/actions/actiontypes';
import { Omrade, validateVegsystemreferanse, Vegsystemreferanse } from '@/domain/omrader';
import { Vegobjekt, vegobjektVersionsComparator } from '@/domain/vegobjekter/Vegobjekt';
import { StedsnavnResponse } from '@/server/responses';
import { RootState, Severity, VegkartError, VegsystemreferanseLookup } from '@/state';
import { createLogger } from '@/utils/Logger';
import { Integration } from './integration';
import { handleError } from '@/actions/metaActions';
import { zoomToExtent } from '@/actions/mapActions';
import { kartutsnittFromWktStrings } from '@/utils/SpatialHelper';
import * as selectors from '../selectors/selectors';
import { WKTObject } from '@/domain/WKTObject';
import { Dispatch } from '@/state/store';

const log = createLogger('fetchdata');

export const fetchPlaceNames =
    (searchString: string) => (dispatch, getState: () => RootState, integration: Integration) => {
        log('fetchPlaceNames {}', searchString);
        integration.server.getStedsnavn(searchString).then((stedsnavn: StedsnavnResponse) => {
            const stedsnavnAction: ReceivedStedsnavnAction = {
                stedsnavn,
                type: ActionType.SEARCH_STEDSNAVN_RECEIVED,
            };
            dispatch(stedsnavnAction);
        });
    };

export const clearPlaceNames = () => dispatch => {
    const action: ClearedStedsnavnAction = { type: ActionType.SEARCH_STEDSNAVN_CLEARED };
    dispatch(action);
};

export const lookupVSR =
    (coordinate: [number, number]) => (dispatch, getState: () => RootState, integration: Integration) => {
        integration.server
            .getVSRFromCoordinate(coordinate)
            .then(vsrLookup => {
                const addVSRLookupAction: AddVSRLookupAction = {
                    type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_ADDED,
                    vsrLookup,
                };
                dispatch(addVSRLookupAction);
            })
            .catch(rejection => {
                if (rejection !== 404) {
                    const error = new VegkartError(
                        'Feil ved vegsystemreferanse-oppslag.',
                        { response: rejection.response },
                        Severity.ERROR
                    );
                    const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error };
                    dispatch(errorAction);
                }
            });
    };

export const loopupCoordinateForRoadref =
    (roadsysref: Vegsystemreferanse, municipality: number) =>
    (dispatch, getState: () => RootState, integration: Integration) => {
        const municipalityForLookup = roadsysref.isKPS() ? municipality : null;
        const roadsysrefForLookup = roadsysref.kortform
            .replace(/-\d+/, '')
            .replaceAll(new RegExp(`^${municipality}`, 'g'), '');

        integration.server
            .getCoordinateFromVegsystemreferanse(roadsysrefForLookup, municipalityForLookup)
            .then((vsrLookup: VegsystemreferanseLookup) => {
                const addVSRAction: AddVSRLookupAction = {
                    type: ActionType.OVERLAY_VEGSYSTEMREFERANSE_ADDED,
                    vsrLookup,
                };
                dispatch(addVSRAction);
            })
            .catch(rejection => {
                if (rejection !== 404) {
                    const error = new VegkartError(
                        'Feil ved vegsystemreferanse-oppslag.',
                        { response: rejection.response },
                        Severity.ERROR
                    );
                    const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error };
                    dispatch(errorAction);
                }
            });
    };

export const fetchAllOmradeGeometries = () => (dispatch, state: RootState) => {
    const omraderInSearch = selectors.stateOmrader(state).getGeometryFetchables();

    omraderInSearch.forEach(omrade => {
        dispatch(fetchAreaGeometry(omrade, false));
    });
};

export async function fetchVegSystemReferanse(
    vegsystemreferanse: Vegsystemreferanse,
    integration: Integration
): Promise<Vegsystemreferanse> {
    let wkt: WKTObject;
    const { meterAvgrensning } = vegsystemreferanse;
    if (meterAvgrensning?.startEllerPunkt && meterAvgrensning?.slutt) {
        wkt = await integration.server.getGeometryOnVegnettsegment(vegsystemreferanse);
    } else if (meterAvgrensning?.startEllerPunkt && !meterAvgrensning?.slutt) {
        wkt = await integration.server.getVeg(vegsystemreferanse);
    } else {
        wkt = await integration.server.getGeometryOnStrekning(vegsystemreferanse);
    }
    return vegsystemreferanse.withSenterpunkt(wkt).withGeometri(wkt);
}

export const fetchVegsystemreferansePoint =
    (vegsystemreferanse: Vegsystemreferanse) =>
    async (dispatch: Dispatch, _getState: () => RootState, integration: Integration) => {
        if (!validateVegsystemreferanse(vegsystemreferanse)) {
            let message = 'Zoom til vegsystemreferanse krever vegnummer, strekning og delstrekning.';
            if (vegsystemreferanse.isKPS() && !vegsystemreferanse.kommune)
                message = 'Zoom til vegsystemreferanse krever kommune';
            const vegkartError = new VegkartError(message, {}, Severity.INFO);
            const errorAction: ErrorAction = { type: ActionType.ERROR_OCCURRED, error: vegkartError };
            dispatch(errorAction);
        } else {
            try {
                const newVsr = await fetchVegSystemReferanse(vegsystemreferanse, integration);
                const action: UpdateVSRAction = {
                    type: ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED,
                    previousVsr: vegsystemreferanse,
                    newVsr,
                };
                dispatch(action);
            } catch (err) {
                console.warn(err);
                const message =
                    err === 404 ? `Fant ikke vegsystemreferanse` : `Kunne ikke hente vegsystemreferanse`;
                const severity = err === 404 ? Severity.INFO : Severity.ERROR;
                dispatch(
                    handleError(
                        new VegkartError(
                            `${message} ${vegsystemreferanse.kortform}`,
                            { err, vegsystemreferanse: vegsystemreferanse },
                            severity
                        )
                    )
                );
                const updateAction: UpdateVSRAction = {
                    type: ActionType.OMRADE_VEGSYSTEMREFERANSE_UPDATED,
                    previousVsr: vegsystemreferanse,
                    newVsr: vegsystemreferanse.withSenterpunkt(null).withGeometri(null),
                };
                dispatch(updateAction);
            }
        }
    };

export const fetchAreaGeometry =
    (omrade: Omrade, zoom = true) =>
    (dispatch, getState: () => RootState, integration: Integration) => {
        const timestamp = getState().searchState.searchDropdownState.timestamp || new Date();
        if (omrade.vegobjekt.metadata.type.id !== null && omrade.visible) {
            return integration.server
                .getVegobjektVersionsMinimal(omrade.vegobjekt.id, omrade.vegobjekt.metadata.type.id)
                .then((vegobjekterFromServer: Vegobjekt[]) => {
                    integration.server
                        .getVegobjekt(
                            omrade.vegobjekt.id,
                            omrade.vegobjekt.metadata.type.id,
                            vegobjekterFromServer
                                .filter(v => new Date(v.metadata.startdato) <= timestamp)
                                .sort(vegobjektVersionsComparator)
                                .pop().metadata.version
                        )
                        .then(
                            (vegobjektFromServer: Vegobjekt) => {
                                const receivedOmradeGeometryAction: ReceivedOmradeGeometryAction = {
                                    type: ActionType.OMRADE_GEOMETRI_RECEIVED,
                                    vegobjekt: vegobjektFromServer,
                                    omrade,
                                };
                                dispatch(receivedOmradeGeometryAction);
                                zoom &&
                                    dispatch(
                                        zoomToExtent(
                                            kartutsnittFromWktStrings(
                                                getState()
                                                    .omraderState.getGeometryFetchables()
                                                    .filter(omrade => omrade?.vegobjekt?.geometri?.wkt)
                                                    .map(omrade => omrade.vegobjekt.geometri.wkt)
                                            )
                                        )
                                    );
                            },
                            err =>
                                dispatch(
                                    handleError(
                                        new VegkartError('Feil ved henting av områdegeometri', {
                                            err,
                                            area: omrade,
                                        })
                                    )
                                )
                        );
                });
        } else {
            const kommune = integration.data.kommuner.find(kom => kom.nummer == omrade.nummer);
            integration.server
                .getVegobjektVersionsMinimal(kommune.vegobjekt.id, kommune.vegobjekt.metadata.type.id)
                .then((vegobjekterFromServer: Vegobjekt[]) => {
                    integration.server
                        .getVegobjekt(
                            kommune.vegobjekt.id,
                            kommune.vegobjekt.metadata.type.id,
                            vegobjekterFromServer
                                .filter(v => new Date(v.metadata.startdato) <= timestamp)
                                .sort(vegobjektVersionsComparator)
                                .pop().metadata.version
                        )
                        .then(
                            (vegobjektFromServer: Vegobjekt) => {
                                const receivedOmradeGeometryAction: ReceivedOmradeGeometryAction = {
                                    type: ActionType.OMRADE_GEOMETRI_RECEIVED,
                                    vegobjekt: vegobjektFromServer,
                                    omrade: kommune,
                                };
                                dispatch(receivedOmradeGeometryAction);
                                zoom &&
                                    dispatch(
                                        zoomToExtent(
                                            kartutsnittFromWktStrings(
                                                getState()
                                                    .omraderState.getGeometryFetchables()
                                                    .filter(kommune => kommune?.vegobjekt?.geometri?.wkt)
                                                    .map(kommune => kommune.vegobjekt.geometri.wkt)
                                            )
                                        )
                                    );
                            },
                            err =>
                                dispatch(
                                    handleError(
                                        new VegkartError('Feil ved henting av områdegeometri', {
                                            err,
                                            area: kommune,
                                        })
                                    )
                                )
                        );
                });
        }
    };

import { createLogger } from './Logger';
import { Role } from '@/state';
import { VegobjektEgenskapKvalitetAttributt } from '@/domain/vegobjekter/Vegobjekt';
import { Datakatalog } from '@/bootstrap/data';
import { Egenskapstype } from '@/domain/vegobjekter/Egenskapstype';

export type Discriminate<A, B> =
    | ({ [Property in keyof A]+?: never } & B)
    | ({ [Property in keyof B]+?: never } & A);

export function assertUnreachable(_: never): never {
    throw new Error('This should never happen because compiler will find this bug!');
}

export function formatNumber(numberToFormat: number): string {
    return numberToFormat.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
}

export function uppercaseFirstLetter(string: string): string {
    return string.substring(0, 1).toUpperCase() + string.substring(1);
}

export const isToday = (someDate: Date): boolean => {
    const today = new Date();
    return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    );
};

/**
 * A sort comparator for numbers
 * @param {number} a First number
 * @param {number} b Second number
 * @returns {number} comparison result. Negative if a should be sorted before b, positive if b should be sorted before
 * a, zero if they are equal.
 */
export function numericSortComparator(a: number, b: number): number {
    return a - b;
}

export function nullIfUndefined<T>(value: T) {
    return value === undefined ? null : value;
}

const log = createLogger('Integration');
export const tryTransform = <T>(transformer: (response: string) => T) => {
    return (response: string) => {
        let data = null;
        try {
            data = transformer(JSON.parse(response));
        } catch (e) {
            log('Error transforming {} {}', response, e);
            throw e;
        }
        if (!data) {
            log('Failed to transform {}', response);
            throw new Error('Failed to transform: ' + response);
        }
        return data;
    };
};

export function findContainer(element: HTMLElement, containerClass: string) {
    if (element.className.includes(containerClass)) {
        return element;
    } else {
        return findContainer(element.parentElement, containerClass);
    }
}

export function getHash(uriOrHash: string): string {
    // decodeURI because some browsers(FF) returns encoded hash.
    // ~(farge:'0_0,id:15) is returned as ~(farge:%270_0,id:15)
    return decodeURI(uriOrHash.substring(uriOrHash.indexOf('#') + 1));
}

export function isUrl(string: string) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _ = new URL(string);
        return true;
    } catch (e) {
        return false;
    }
}

export function isLinkQuery(text: string): boolean {
    /*
     Valid queries:
     @123456
     0.4@123456
     0.3-0.9@123456
     */

    return text.includes('@') && /^(\d\.?\d*-?){0,2}@\d+$/.test(text);
}

export function hasAccess(sensitivity: number, roles: Record<Role, boolean>): boolean {
    if (!Number.isInteger(sensitivity) || sensitivity < 0 || sensitivity > 3)
        throw Error('Unknown sensitivity code');
    return (
        sensitivity == 0 ||
        (sensitivity == 1 && roles[Role.Sensitiv1]) ||
        (sensitivity == 2 && roles[Role.Sensitiv2]) ||
        (sensitivity == 3 && roles[Role.Sensitiv3])
    );
}

export const download = (url: string, name?: string) => {
    const a = document.createElement('a');
    a.href = url;
    if (name) a.download = name;
    document.body.append(a);
    a.click();
    a.parentNode?.removeChild(a);
};

export function interpretQuality(
    q: VegobjektEgenskapKvalitetAttributt,
    datakatalog: Datakatalog
): string | number {
    const nvdbDoc: Egenskapstype = datakatalog.getTypeById(793).egenskapstypeById(9553);
    const defs: KvalitetDefinisjon = {
        measureHeightMethods: nvdbDoc.egenskapById(9544),
        measureMethods: nvdbDoc.egenskapById(9543),
        visibilities: nvdbDoc.egenskapById(9545),
    };
    class KvalitetDefinisjon {
        constructor(
            readonly measureHeightMethods: Egenskapstype,
            readonly measureMethods: Egenskapstype,
            readonly visibilities: Egenskapstype
        ) {}
    }
    switch (q.name) {
        case 'målemetode':
            return `${defs['measureMethods'].verdiByKortnavn(q.value.toString()).verdi} (${q.value})`;
        case 'målemetodeHøyde':
            return `${defs['measureHeightMethods'].verdiByKortnavn(q.value.toString()).verdi} (${q.value})`;
        case 'synbarhet':
            return `${defs['visibilities'].verdiByKortnavn(q.value.toString()).verdi} (${q.value})`;
        case 'nøyaktighet':
        case 'nøyaktighetHøyde':
        case 'maksimaltAvvik':
            if (q.value <= 0) return `Ikke registrert (${q.value})`;
            return `${q.value} cm`;
        case 'datafangstmetode':
        case 'datafangstmetodeHøyde':
            return q.value in datafangstMetode ? `${datafangstMetode[q.value]} (${q.value})` : q.value;
        default:
            return q.value;
    }
}

const datafangstMetode = {
    dig: 'Digitalisert',
    fot: 'Fotogrammetri',
    gen: 'Generert',
    lan: 'Landmålt',
    pla: 'Plandata',
    sat: 'Satellittmålt',
    byg: 'Som bygget',
    ukj: 'Ukjent',
};

export const trapFocus = element => {
    const focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];
    const KEYCODE_TAB = 9;

    element.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
};

// Modulo operator.
// % operator in js works as a 'reminder' and not 'modulo', causing issues with negative numbers
export const mod = (a: number, n: number): number => ((a % n) + n) % n;

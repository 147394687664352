import { MapPoint } from 'domain/kart/MapPoint';
import { TileService } from '@/components/map/map-utils/WebMapTileServices';
import { Kartutsnitt } from '@/domain/kart/Kartutsnitt';
import { MapQueries } from '@/middleware/queries';
import { BaatToken } from '@/components/map/layers/useRefreshBaatToken';

export class MapState {
    constructor(
        readonly mapLayer: TileService = TileService.GEODATA,
        readonly center: [number, number] = [600000, 7225000],
        readonly zoom: number = 3,
        readonly kartutsnitt: Kartutsnitt = null,
        readonly zoomToExtent: Kartutsnitt = null,
        readonly points: MapPoint[] = [],
        readonly baatToken: BaatToken = null
    ) {}

    override(modifyObject: { [P in keyof MapState]?: MapState[P] }): MapState {
        return Object.assign(Object.create(MapState.prototype), { ...this, ...modifyObject });
    }

    withCenterAndZoom(center: [number, number], zoom: number): MapState {
        return this.override({ center, zoom });
    }

    withKartutsnitt(kartutsnitt: Kartutsnitt): MapState {
        return this.override({ kartutsnitt });
    }

    withMapLayer(mapLayer: TileService) {
        return this.override({ mapLayer });
    }

    withZoomToExtent(zoomToExtent: Kartutsnitt): MapState {
        return this.override({ zoomToExtent });
    }

    withPoints(points: MapPoint[]): MapState {
        return this.override({ points });
    }

    withBaatToken(baatToken: BaatToken): MapState {
        return this.override({ baatToken });
    }

    toQueries(): MapQueries {
        return new MapQueries(this.kartutsnitt?.toQueryString());
    }
}

import { ActionType, MetaAction } from '@/actions/actiontypes';
import { MetaState } from '@/state';

const initialState: MetaState = new MetaState();

export const metaReducer = (metaState: MetaState = initialState, action: MetaAction) => {
    switch (action.type) {
        // Start-up
        case ActionType.BOOTSTRAP:
            return action.bootstrappedState.metaState;
        case ActionType.HASH_CHANGED:
            return action.state.metaState;
        // Meta
        case ActionType.ERROR_OCCURRED:
            return metaState.withErrors(metaState.errors.concat(action.error));
        case ActionType.ERROR_DISMISSED:
            return metaState.withErrors(metaState.errors.filter(e => e.uuid !== action.error.uuid));
        case ActionType.WINDOW_UNFOCUSED:
            return metaState.withLastHash(action.hash);
        default:
            return metaState;
    }
};

import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';

export function transformEgenskapstype(e: unknown): Egenskapstype {
    return (
        !!e &&
        new Egenskapstype(
            e['id'],
            e['kategori'],
            e['navn'],
            e['kortnavn'],
            e['beskrivelse'],
            e['veiledning'],
            e['egenskapstype'],
            e['datatype'],
            e['sosinvdbnavn'],
            e['sosinavn'],
            e['sorteringsnummer'],
            e['feltlengde'],
            e['desimaler'],
            e['min_anbefalt'],
            e['maks_anbefalt'],
            e['min'],
            e['maks'],
            e['sensitivitet'],
            e['viktighet'],
            (e['egenskapstyper'] || []).map(e => transformEgenskapstype(e)),
            (e['tillatte_verdier'] || []).map(
                tv => new EgenskapTillattVerdi(tv.id, tv.kortnavn, tv.verdi, tv.type, tv.sorteringsnummer)
            ),
            e['enhet'],
            e['dimensjon'],
            e['geometriType'],
            e['stedfestingstype'],
            e['retning_relevant']
        )
    );
}

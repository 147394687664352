import * as React from 'react';
import { SplashState } from '@/state/SplashState';
import { useDispatch } from '@/state/store';
import { changeSplash } from '@/actions/splashActions';
import { forwardRef } from 'react';

const InfoButton = forwardRef<HTMLButtonElement>((_, ref) => {
    const dispatch = useDispatch();

    return (
        <button
            ref={ref}
            type="button"
            aria-controls="landingpage"
            aria-owns="landingpage"
            onClick={() => dispatch(changeSplash(SplashState.MAIN))}
            className="c-splash-button o-btn-square"
            title="Åpne/lukk informasjonsside"
        >
            <i className="o-icon-info c-splash-button__icon" />
        </button>
    );
});
InfoButton.displayName = 'InfoButton';
export { InfoButton };

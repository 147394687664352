import * as React from 'react';
import { forwardRef, useCallback } from 'react';
import { LoginWindowState } from '@/state';
import { useDispatch, useSelector } from '@/state/store';
import { stateUser } from '@/selectors/selectors';
import { changeLoginWindow } from '@/actions/loginActions';

const LoginButton = forwardRef<HTMLButtonElement>((_, ref) => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(stateUser);
    const onClick = useCallback(() => {
        dispatch(changeLoginWindow(LoginWindowState.LOGIN));
    }, [dispatch]);
    return (
        <button
            ref={ref}
            type="button"
            aria-controls="loginpage"
            aria-owns="loginpage"
            onClick={onClick}
            className="c-auth-button o-btn-square"
            title="Åpne/lukk innlogging"
        >
            {isLoggedIn ? (
                <i className="o-icon-logout c-auth-button__icon" />
            ) : (
                <i className="o-icon-login c-auth-button__icon" />
            )}
        </button>
    );
});
LoginButton.displayName = 'LoginButton';
export { LoginButton };

import * as uuidgen from 'uuid';
import { Operator, OperatorE } from './Operator';
import { Egenskapstype, EgenskapTillattVerdi } from '@/domain/vegobjekter/Egenskapstype';
import { Vegobjekt } from '@/domain/vegobjekter/Vegobjekt';

export type EgenskapsfilterVerdi = EgenskapTillattVerdi | number | string;
export class Egenskapsfilter {
    constructor(
        readonly egenskapstype: Egenskapstype,
        readonly operator: OperatorE,
        readonly verdi: EgenskapsfilterVerdi = 'Har ikke verdi',
        readonly uuid: string = uuidgen.v4()
    ) {}

    toString(): string {
        if (!this.egenskapstype) return '';
        return this.egenskapstype.id + Operator.asString(this.operator) + this.verdiToQuery();
    }

    // Quote strings using single quotes, every other datatype is left untouched
    verdiToQuery(): string {
        if (this.operator === OperatorE.HAS_VALUE || this.operator === OperatorE.NO_VALUE) {
            return 'null';
        } else if (this.egenskapstype.maaQuotes()) {
            return "'" + this.verdiToString() + "'";
        } else {
            return this.verdiToString();
        }
    }
    verdiToString(): string {
        if (this.operator === OperatorE.HAS_VALUE || this.operator === OperatorE.NO_VALUE) {
            return 'null';
        } else if (this.verdi !== 0 && !this.verdi) {
            return '';
        } else if (this.verdi instanceof EgenskapTillattVerdi) {
            return this.verdi.id.toString();
        } else {
            return this.verdi.toString();
        }
    }

    verdiValue(): string | number {
        if (this.operator === OperatorE.HAS_VALUE || this.operator === OperatorE.NO_VALUE) {
            return null;
        } else if (this.verdi !== 0 && !this.verdi) {
            return '';
        } else if (this.verdi instanceof EgenskapTillattVerdi) {
            return this.verdi.id;
        } else {
            return this.verdi;
        }
    }

    verdiTypeToString() {
        if (this.verdi instanceof EgenskapTillattVerdi) {
            return this.verdi.verdi;
        } else if (typeof this.verdi === 'number') {
            return this.verdi.toString();
        } else {
            return this.verdi;
        }
    }

    withEgenskapstype(egenskapstype: Egenskapstype): Egenskapsfilter {
        return new Egenskapsfilter(egenskapstype, this.operator, this.verdi, this.uuid);
    }

    withEgenskapstypeReset(egenskapstype: Egenskapstype): Egenskapsfilter {
        return new Egenskapsfilter(egenskapstype, null, null, this.uuid);
    }

    withOperator(operator: OperatorE): Egenskapsfilter {
        if (operator === null) {
            return new Egenskapsfilter(this.egenskapstype, null, null, null);
        }
        return new Egenskapsfilter(this.egenskapstype, operator, this.verdi, this.uuid);
    }

    withVerdi(verdi: EgenskapsfilterVerdi): Egenskapsfilter {
        return new Egenskapsfilter(this.egenskapstype, this.operator, verdi, this.uuid);
    }

    isValid(): boolean {
        return (
            this.egenskapstype !== null &&
            this.operator !== null &&
            (this.verdi !== null ||
                this.operator === OperatorE.NO_VALUE ||
                this.operator === OperatorE.HAS_VALUE)
        );
    }

    static satisfies(vegobjekt: Vegobjekt, filters: Egenskapsfilter[]): boolean {
        if (!vegobjekt.egenskaper) console.warn('oh fuck, missing props', vegobjekt);
        const egenskaper = vegobjekt.egenskaper.reduce((all, p) => ({ ...all, [p.id]: p }), {});
        return filters.every(filter => {
            const value = egenskaper[filter.egenskapstype.id]?.verdi;
            const filterValue =
                filter.verdi instanceof EgenskapTillattVerdi ? filter.verdi.verdi : filter.verdi;
            switch (filter.operator) {
                case OperatorE.EQ:
                    return value === filterValue;
                case OperatorE.NEQ:
                    return value !== filterValue;
                case OperatorE.BEQ:
                    return value >= filterValue;
                case OperatorE.LEQ:
                    return value <= filterValue;
                case OperatorE.LT:
                    return value < filterValue;
                case OperatorE.GT:
                    return value > filterValue;
                case OperatorE.HAS_VALUE:
                    return value !== null && value !== undefined;
                case OperatorE.NO_VALUE:
                    return value === null || value === undefined;
            }
        });
    }
}

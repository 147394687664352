import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from '@/state/context/MapContext';
import VectorSource from 'ol/source/Vector';
import { PreferencesState, RootState } from '@/state';

const useClusterConfig = (source: VectorSource): { distance: number; source: VectorSource } => {
    const { map } = useContext(MapContext);
    const prefs = useSelector<RootState, PreferencesState>(state => state.preferences);
    const zoom = map ? map.getView().getZoom() : undefined;

    const [config, setConfig] = useState<{ distance: number; source: VectorSource }>({
        distance: 50,
        source,
    });

    useEffect(() => {
        if (zoom !== undefined) {
            setConfig({
                distance: zoom >= 15 && prefs.disableClusters ? 15 : 50,
                source,
            });
        }
    }, [zoom, prefs.disableClusters, source]);

    return config;
};

export default useClusterConfig;

import React, { useCallback, useState } from 'react';
import DatePicker from 'react-date-picker';
import SimpleToggle from '../../objects/SimpleToggle';
import useDebounce from '../../../utils/useDebounce';
import { useDispatch, useSelector } from '@/state/store';
import { refreshVegobjektSearch } from '@/actions/vegobjekterActions';
import { refreshVegnett } from '@/actions/vegnettActions';
import { setSearchTimestamp } from '@/actions/searchActions';
import { stateSearchTimestamp } from '@/selectors/selectors';
import { executeAllSearches } from '@/middleware/fetchVegobjekter';

export const DateFilter = ({ className = '' }: { className?: string }) => {
    const timestamp = useSelector(stateSearchTimestamp);
    const [internal, setInternal] = useState(timestamp || new Date());
    const [enable, setEnable] = useState(timestamp != null);
    const [enterKeyPressed, setEnterKeyPressed] = useState(false);
    const dispatch = useDispatch();
    const onTimestampChange = useCallback(
        (value: Date) => {
            dispatch(setSearchTimestamp(value));
            if (value == null || isValidDate(value)) {
                refreshVegnett();
                refreshVegobjektSearch();
            }
        },
        [dispatch]
    );
    const debouncedUpdate = useDebounce(onTimestampChange, 350);

    const toggle = () => {
        const isEnabled = !enable;
        debouncedUpdate(isEnabled ? internal : null);
        setEnable(isEnabled);
    };

    const updateDate = date => {
        setInternal(date);
        if (enable) debouncedUpdate(date);
    };

    const handleEnter = event => {
        if (event.key === 'Enter') {
            setEnterKeyPressed(true);
            event.target.blur();
            dispatch(executeAllSearches());
        }
    };

    const handleBlur = () => {
        if (!enterKeyPressed) {
            dispatch(executeAllSearches());
        } else {
            setEnterKeyPressed(false);
        }
    };

    return (
        <div className={'c-date-selector ' + className} role={'widget'}>
            <div className={'c-dates'} style={{ position: 'relative' }}>
                <SimpleToggle
                    className={'c-dates__toggle'}
                    checked={enable}
                    label={'Søk basert på dato'}
                    id={'seach_date_toggle'}
                    ariaLabel={enable ? 'Aktiver datosøk' : 'Deaktiver datosøk'}
                    onChange={toggle}
                />
                <div role={'input'} aria-label={'Datofelt'} onKeyDown={handleEnter} onBlur={handleBlur}>
                    <DatePicker
                        disabled={!enable}
                        clearAriaLabel={'Nullstill datofelt'}
                        showLeadingZeros={true}
                        onChange={updateDate}
                        value={internal}
                        disableCalendar={true}
                    />
                </div>
            </div>
        </div>
    );
};

function isValidDate(maybeDate: Date) {
    const reGoodDate =
        /(([1][0-2]|[0][0-9])[-.\/]([0-2][0-9]|[3][0-1])[-.\/][1-9]([0-9]{3}))|([1-9]([0-9]{3})[-.\/]([1][0-2]|[0][0-9])[-.\/]([0-2][0-9]))/;
    return reGoodDate.test(maybeDate.toISOString().split('T')[0]);
}

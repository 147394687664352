'use strict';
import { register } from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';

export function addProjections() {
    proj4.defs([
        ['EPSG:32633', '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs'],
        ['EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs'],
    ]);
    register(proj4);
}
export const EPSG25833 = new Projection({
    code: 'EPSG:25833',
    extent: [-25e5, 35e5, 3045984, 9045984],
    units: 'm',
});
export const EPSG32633 = new Projection({
    code: 'EPSG:32633',
    extent: [-25e5, 35e5, 3045984, 9045984],
    units: 'm',
});

export class CommonQueries {
    constructor(
        readonly start: string = null,
        readonly antall: number = null,
        readonly geometritoleranse: number = null
    ) {}

    override(modifyObject: { [P in keyof CommonQueries]?: CommonQueries[P] }): CommonQueries {
        return Object.assign(Object.create(CommonQueries.prototype), { ...this, ...modifyObject });
    }

    withStart(start: string) {
        return this.override({ start });
    }

    withAntall(antall: number) {
        return this.override({ antall });
    }

    withGeometritoleranse(geometritoleranse: number) {
        return this.override({ geometritoleranse });
    }

    toParams(): Record<string, string> {
        const params: Record<string, string> = {};

        for (const [key, value] of Object.entries(this)) {
            if (value != null) {
                params[key] = value;
            }
        }

        return params;
    }
}

import * as React from 'react';
import { VegobjekterStatistic } from '@/domain/vegobjekter/VegobjekterResult';
import { formatNumber } from '@/utils/Utils';

interface Props {
    statistics: VegobjekterStatistic[];
    total: VegobjekterStatistic;
}

export const AreaSummary = ({ statistics, total }: Props) => (
    <ul className="c-area-summary">
        {statistics
            .filter(r => r.antall > 0)
            .map(r => (
                <li className={'c-area-summary__li'} key={r.omrade.navn}>
                    {r.omrade.navn} - {r.antall}{' '}
                    <span style={{ flexGrow: 1, textAlign: 'end' }}>
                        {formatNumber(Math.round(r.lengde))}m
                    </span>
                </li>
            ))}
        {total && (
            <li style={{ fontWeight: 'bolder', marginTop: '0.4rem' }} className={'c-area-summary__li'}>
                Totalt - {total.antall} <span></span>
                <span style={{ flexGrow: 1, textAlign: 'end' }}>
                    {formatNumber(Math.round(total.lengde))}m
                </span>
            </li>
        )}
    </ul>
);

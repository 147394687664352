import { Vegobjekttype } from '@/domain/vegobjekter/Vegobjekttype';
import { VegobjekterResult } from '@/domain/vegobjekter/VegobjekterResult';
import { formatNumber } from '@/utils/Utils';
import * as React from 'react';
import { connect } from 'react-redux';

interface HitStatisticsProps {
    vegobjekttype: Vegobjekttype;
    result: VegobjekterResult;
}
function format(number: number) {
    if (number > 100_000) return formatNumber(Math.round(number));
    return formatNumber(number);
}
const HitStatisticsComponent = ({ vegobjekttype, result }: HitStatisticsProps) => {
    // No Omrade in search, or omrader enclosed by extent.
    if (result.totalStatistics == null && result.extentStatistics != null) {
        const extentAntall = result.extentStatistics.antall;
        if (extentAntall >= 0 && result.vegobjekter.length > 0) {
            return (
                <>
                    <span className="c-hva-info__statistics">{`${extentAntall} vegobjekter`}</span>
                    {vegobjekttype.isLine() && (
                        <span className="c-hva-info__statistics c-hva-info__statistics--right">
                            {`${result.extentStatistics.lengde} meter`}
                        </span>
                    )}
                </>
            );
        } else {
            return (
                <span className="c-hva-info__statistics">{`${extentAntall} vegobjekter (Utenfor kartvindu)`}</span>
            );
        }
    }
    // Omrade in search, add supplementary statistics.
    else if (result?.totalStatistics?.antall > 0 && result.extentStatistics != null) {
        const extentCount = format(result.extentStatistics.antall);
        const totalCount = format(result.totalStatistics.antall);
        const totalStrech = format(result.totalStatistics.lengde);
        const extentStrech = format(result.extentStatistics.lengde);
        return (
            <>
                <span className="c-hva-info__statistics">{`${totalCount} vegobjekter`}</span>
                {vegobjekttype.isLine() && (
                    <span className="c-hva-info__statistics c-hva-info__statistics--right">
                        {`${totalStrech} meter`}
                    </span>
                )}
                {totalCount !== extentCount && (
                    <>
                        <br />
                        <span className="c-hva-info__suplementary-statistics">
                            {`${extentCount} vises i kartutsnittet`}
                        </span>
                        {vegobjekttype.isLine() && (
                            <span className="c-hva-info__suplementary-statistics c-hva-info__statistics--right ">
                                {`${extentStrech} meter`}
                            </span>
                        )}
                    </>
                )}
            </>
        );
    } else return <span className="c-hva-info__statistics">Ingen treff</span>;
};

export const HitStatistics = connect(null)(HitStatisticsComponent);

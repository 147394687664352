import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as React from 'react';
import { FeatureLayerType, FeatureLayerTypeKey, isInteractible } from '../layers/FeatureLayerType';
import { highlightFeatureStyle } from '../style/Style';
import { useMap } from '@/state/context/MapContext';
import { unByKey } from 'ol/Observable';

// Hovered features are highlighted by getting the hovered feature by pixel and moving it to a separate vector layer
// with highlight styling. We do this in such a roundabout fashion due to the fact that OpenLayers as of 6.1.1 does not
// support both hover and click selection on the same layer. This "hack" is inspired by this example:
// https://openlayers.org/en/latest/examples/hitdetect-vector.html?q=hover

export const FeatureHoverHandler = () => {
    const { map } = useMap();
    React.useEffect(() => {
        const hoverOverlay = new VectorLayer({
            source: new VectorSource(),
            style: highlightFeatureStyle,
            zIndex: 0,
        });
        map.addLayer(hoverOverlay);

        let highlightedElement;
        const highlightFeature = pixel => {
            const features = map.getFeaturesAtPixel(pixel);
            const feature = features.length ? (features[0] as Feature) : undefined;
            if (feature !== highlightedElement && isSelectableOnHover(feature)) {
                if (highlightedElement) {
                    hoverOverlay.getSource().removeFeature(highlightedElement);
                    const element = map.getTarget() as HTMLElement;
                    element.style.cursor = '';
                }
                if (feature) {
                    hoverOverlay.getSource().addFeature(feature);
                    const element = map.getTarget() as HTMLElement;
                    element.style.cursor = 'pointer';
                }
                highlightedElement = feature;
                // Special case when are outline is in search and envelops the features we are hovering over.
            } else if (
                feature &&
                highlightedElement &&
                (feature.get(FeatureLayerTypeKey) as FeatureLayerType) === FeatureLayerType.AREA_OUTLINE
            ) {
                hoverOverlay.getSource().clear();
                highlightedElement = undefined;
                const element = map.getTarget() as HTMLElement;
                element.style.cursor = '';
            }
        };
        const pointermove = map.on('pointermove', evt => {
            if (evt.dragging) return;
            const pixel = map.getEventPixel(evt.originalEvent);
            highlightFeature(pixel);
        });
        return () => {
            unByKey(pointermove);
            map.removeLayer(hoverOverlay);
        };
    }, [map]);
    return null;
};

const isSelectableOnHover = (feature: Feature): boolean => {
    // if we mouse _out_ of a feature, we still want the (un)hover effect to take place
    if (feature === undefined) {
        return true;
    }
    const featureLayerType = feature.get(FeatureLayerTypeKey) as FeatureLayerType;
    return isInteractible(featureLayerType);
};

import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import * as React from 'react';
import { Stedsnavn } from '@/server/responses';
import { statePlacenameMarker } from '@/selectors/selectors';
import { setPlacenameMarker } from '@/actions/overlayActions';
import { useDispatch, useSelector } from '@/state/store';
import { useMap } from '@/state/context/MapContext';
import { useCallback, useEffect, useMemo } from 'react';

export const PlacenameMarkerPopup = () => {
    const { map } = useMap();
    const dispatch = useDispatch();
    const place: Stedsnavn = useSelector(statePlacenameMarker);

    const popup = useMemo(() => {
        return new Overlay({});
    }, []);

    const close = useCallback(() => {
        dispatch(setPlacenameMarker(null));
    }, [dispatch]);

    useEffect(() => {
        if (place) {
            const markerCoordinate: Coordinate = [place.xCoord, place.yCoord].map(Number.parseFloat) as [
                number,
                number
            ];
            popup.setPosition(markerCoordinate);
            map.addOverlay(popup);
            return () => {
                map.removeOverlay(popup);
            };
        }
    }, [map, place, popup]);

    return (
        <div className="o-popup" ref={popupElement => popup.setElement(popupElement)}>
            <div className="o-popup__arrow" />
            <header className="o-popup__header">
                <h3 className="o-popup__header-text">Stedsnavn</h3>
                <button
                    type="button"
                    onClick={close}
                    title="Ta bort stedsnavnmarkør"
                    className="o-popup__close-button"
                >
                    <i className="o-icon-remove o-popup__remove-icon" />
                </button>
            </header>
            <p className="o-popup__body-text">{place?.name}</p>
        </div>
    );
};
